import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import classes from "./DiscountCodes.module.css";
import {
  listDiscountCodes,
  createDiscountCode,
  updateDiscountCode,
  deleteDiscountCode,
} from "../../../api/discountCodes";
import { toDateInputFormat } from "../../../utils/dateUtils";

const DiscountCodes = ({
  discountCodes = [],
  onCreateDiscountCode = () => {},
  onUpdateDiscountCode = () => {},
  onDeleteDiscountCode = () => {},
  discountOperationError = "",
  setSelectedDiscountCode,
  selectedDiscountCode,
  clearSelectedDiscountCode,
}) => {
  const [isDateDisabled, setIsDateDisabled] = useState(false);


  const clearSelected = () => {
    const selections = document.getElementsByName("selected-coupon");
    selections.forEach((e) => (e.checked = false));
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    let discountCode = { ...selectedDiscountCode };
    if (isDateDisabled) {
      discountCode.expirationDate = "";
    }
    if (discountCode.id) {
      onUpdateDiscountCode({
        ...discountCode,
        metadata: {
          orgName: discountCode?.orgName,
        },
      });
      clearSelected();
      return;
    }

    onCreateDiscountCode({
      ...discountCode,
      metadata: {
        orgName: discountCode?.orgName,
      },
    });
    clearSelected();
  };
  const onInputFieldChange = (e) => {
    let value = e.target.value;

    setSelectedDiscountCode({
      ...selectedDiscountCode,
      [e.target.name]: value,
    });
  };
  const data = discountCodes.map((dc) => {
    return {
      ...dc,
      discountPercent: dc.discountPercent ? `${dc.discountPercent}%` : "",
      expirationDate: dc.expirationDate
        ? `${new Date(dc.expirationDate).toDateString()}`
        : "",
    };
  });
  const columns = [
    {
      name: ``,
      selector: (row) => (
        <input
          type="radio"
          name={`selected-coupon`}
          onChange={(e) => {
            if (e.target.checked) {
              console.log("row",row)
              setSelectedDiscountCode({
                ...row,
                orgName: row?.metadata.orgName,
              });
            }
          }}
          id={`selected-coupon`}
        />
      ),
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Organization Name",
      selector: (row) => row?.metadata?.orgName,
      sortable: true,
    },
    {
      name: "Discount %",
      selector: (row) => row.discountPercent,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.maxDiscountAmount,
      sortable: true,
    },
    {
      name: "Expiration Date",
      selector: (row) => row.expirationDate,
      sortable: true,
      sortFunction: (rowA,rowB) => {
        const a = new Date(rowA.expirationDate)
        const b = new Date(rowB.expirationDate)
        if (a > b) {
         return 1;
       }
     
       if (b > a) {
         return -1;
       }
     
       return 0;
     }
    },
    {
      name: "Max number of usages",
      selector: (row) => row.maxUsages,
      sortable: true,
    },
    {
      name: "# of times redeemed",
      selector: (row) => row.numberOfUses,
      sortable: true,
    },
  ];
  return (
    <div>
      <div>
        <form onSubmit={onFormSubmit} className={classes.form}>
          <div>
            <label>Code</label>
            <input
              id="code"
              required
              name="code"
              disabled={selectedDiscountCode.id ? true : false}
              type="text"
              value={selectedDiscountCode.code}
              onChange={onInputFieldChange}
            ></input>
          </div>
          <div>
            <label>Organization Name</label>
            <input
              id="orgName"
              name="orgName"
              type="orgName"
              value={selectedDiscountCode.orgName}
              onChange={onInputFieldChange}
            ></input>
          </div>

          <div>
            <label>Discount %</label>
            <input
              id="discountPercent"
              required
              name="discountPercent"
              max="100"
              type="number"
              value={selectedDiscountCode?.discountPercent ? parseInt(selectedDiscountCode?.discountPercent): 0}
              onChange={onInputFieldChange}
            ></input>
          </div>
          <div>
            <label>Amount</label>
            <input
              id="maxDiscountAmount"
              required
              name="maxDiscountAmount"
              max={process.env.REACT_APP_SUB_PRICE}
              type="number"
              value={selectedDiscountCode.maxDiscountAmount || 0}
              onChange={onInputFieldChange}
            ></input>
          </div>
          <div>
            <label>Expiration Date</label>
            <input
              id="expirationDate"
              name="expirationDate"
              disabled={isDateDisabled}
              type="date"
              value={
                selectedDiscountCode && selectedDiscountCode.expirationDate
                  ? toDateInputFormat(selectedDiscountCode.expirationDate)
                  : toDateInputFormat(new Date())
              }
              onChange={onInputFieldChange}
            ></input>
            <div>
              <label for="noExpiration">No Expiration</label>
              <input
                style={{ marginLeft: "10px" }}
                name="noExpiration"
                type={"checkbox"}
                onChange={(e) => {
                  setIsDateDisabled(e.target.checked);
                }}
              ></input>
            </div>
          </div>

          <div>
            <label>Max number of usages</label>
            <input
              id="maxUsages"
              name="maxUsages"
              type="number"
              value={selectedDiscountCode.maxUsages || 0}
              onChange={onInputFieldChange}
            ></input>
          </div>
          <div>
            <button type="submit">
              {" "}
              {`${selectedDiscountCode.id ? "Update" : "Create"}`}{" "}
            </button>
            {selectedDiscountCode.id ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteDiscountCode(selectedDiscountCode);
                }}
              >
                {" "}
                Delete{" "}
              </button>
            ) : null}

            {discountOperationError ? (
              <label>{discountOperationError}</label>
            ) : null}

            {selectedDiscountCode.id ? (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  clearSelectedDiscountCode();
                  clearSelected();
                }}
              >
                {" "}
                Cancel{" "}
              </button>
            ) : null}
          </div>
        </form>
      </div>
      <div className="uk-overflow-auto">
        <form>
          <DataTable 
          className="uk-table uk-table-striped uk-table-hover"
          columns={columns} data={data} />
        </form>
      </div>
    </div>
  );
};

const emptyDiscountCode = {
  code: "",
  discountPercent: 0,
  maxDiscountAmount: 0,
  expirationDate: "",
  maxUsages: "",
  orgName: "",
};
const Container = (props) => {
  const [discountCodes, setDiscountCodes] = useState([]);
  const [selectedDiscountCode, setSelectedDiscountCode] =
    useState(emptyDiscountCode);
  const [discountOperationError, setDiscountOperationError] = useState("");

  const _getDiscountCodes = async () => {
    return listDiscountCodes().then((response) => response.data);
  };
  useEffect(() => {
    _getDiscountCodes().then((data) => {
      setDiscountCodes(data);
    });
  }, []);

  const clearSelectedDiscountCode = () => {
    setSelectedDiscountCode(emptyDiscountCode);
  };
  const onCreateDiscountCode = async (discountCode) => {
    try {
      await createDiscountCode(discountCode);
      const discountCodes = await _getDiscountCodes();
      setDiscountCodes(discountCodes);
      clearSelectedDiscountCode();
    } catch (error) {
      setDiscountOperationError(error.response.data.error);

      console.error(error.response.data.error);
    }
  };

  const onUpdateDiscountCode = async (discountCode) => {
    try {
      await updateDiscountCode(discountCode);
      const discountCodes = await _getDiscountCodes();
      setDiscountCodes(discountCodes);
      clearSelectedDiscountCode();
    } catch (error) {
      setDiscountOperationError(error.response.data.error);

      console.error(error.response.data.error);
    }
  };
  const onDeleteDiscountCode = async (discountCode) => {
    try {
      await deleteDiscountCode(discountCode.id);
      const discountCodes = await _getDiscountCodes();
      setDiscountCodes(discountCodes);
      clearSelectedDiscountCode();
    } catch (error) {
      setDiscountOperationError(error.response.data.error);

      console.error(error.response.data.error);
    }
  };

  return (
    <DiscountCodes
      discountCodes={discountCodes || []}
      onCreateDiscountCode={onCreateDiscountCode}
      discountOperationError={discountOperationError}
      setSelectedDiscountCode={setSelectedDiscountCode}
      selectedDiscountCode={selectedDiscountCode}
      clearSelectedDiscountCode={clearSelectedDiscountCode}
      onUpdateDiscountCode={onUpdateDiscountCode}
      onDeleteDiscountCode={onDeleteDiscountCode}
    ></DiscountCodes>
  );
};

export default Container;

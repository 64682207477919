import React from "react";
import classes from "./Introduction.module.css";

import { HeadQuarters } from "../HeadQuarters/HeadQuarters";

import { FirmSizeIcon } from "../UI/FirmSizeIcon";
import { SocialMediaLinks } from "../SocialMediaLinks/SocialMediaLinks";
import { OtherOffices } from "../OtherOffices/OtherOffices";
import { capitalize } from "lodash";


const GetInTouchButton = ({ email,onClick = (e) => { } }) => {
  if (!onClick) {
    return null;
  }
  const subject = "A ValuesAdvisor User would like to learn more about you";
  const body = `Greetings,
  
I’ve reviewed your profile on the ValuesAdvisor platform and would like to schedule an introductory (30-minute)  
meeting to learn more about you and your firm.
 
Thank you
`;

  return (
    <a
      onClick={onClick}
      className="button--primary"
      href={`mailto:${email}?subject=${subject}&body=${encodeURIComponent(
        body
      )}`}
    >
      Contact Advisor
    </a>
  );
};
const EditProfileButton = ({ onEditProfile = () => { } }) => {
  if (!onEditProfile) {
    return null;
  }
  return (
    <a
      className="button--secondary"
      style={{ margin: "5px" }}
      href={`/`}
      onClick={(e) => {
        e.preventDefault();
        onEditProfile();
      }}
    >
      Edit Profile
    </a>
  );
};

const ApproveProfileButton = ({ onApproveProfile = () => { } }) => {
  if (!onApproveProfile) {
    return null;
  }
  return (
    <a
      style={{ margin: "5px" }}
      href={`/`}
      onClick={(e) => {
        e.preventDefault();
        onApproveProfile();
      }}
    >
      Approve
    </a>
  );
};
const RejectProfileButton = ({ onRejectProfile = () => { } }) => {
  if (!onRejectProfile) {
    return null;
  }
  return (
    <a
      href={`/`}
      onClick={(e) => {
        e.preventDefault();
        onRejectProfile();
      }}
    >
      Reject
    </a>
  );
};
export const Introduction = ({
  profilePic,
  name,
  firm,
  suggestedByMemberTypes,
  user,
  materialTypes,
  richMediaLinks,
  addresses,
  socialLinkTypes,
  website,
  currentADV,
  title,
  unsdgTypes,
  teamInfo,
  onPrintProfile = () => { },
  onGetInTouch = () => { },
  onEditProfile = () => { },
  onApproveProfile = () => { },
  onRejectProfile = () => { },
}) => {
  const percentageOfValuesAligned = teamInfo.percentageOfValuesAligned;
  const minimumAccountSize = firm.minimumAccountSize
  const unit = firm.unit
  return (
    <div className="introbar no-print-show">
      <div className="uk-container uk-container--fix">
        {/* intro starts here */}

        <div className="uk-grid uk-grid-divider@m">
          <div className="uk-width-1-4@s uk-first-column first-custom-column-width">
            <img src={profilePic} className="pic" alt="" />
          </div>
          <div className="uk-width-1-2@s middle-custom-column-width uk-first-column profile--right-border right-border">
            <div className="profile">
              <h5 className="profile__name">{name}</h5>
              <h6 className="profile__position">
                {`${title ? title + ' | ' : ""}`}{firm.name}
              </h6>
              <ul className="firm__icons">
                {firm.publicBenefit  ? (
                  <li className="fix">
                    <div className="info">
                      <img src="img/certified.svg" alt="" />
                      <div className="firm__tooltip" id="firm__tooltip1">
                        <span>B Corp Certified</span>
                      </div>
                    </div>
                    <p className={classes["firm-size-text"]}>
                      B Corp Certified
                    </p>
                  </li>
                ): null}


                {minimumAccountSize  ? (
                  <li>
                    <div className="info info--align">
                      <div>
                        <p className="minimumAccountSize-info"><span>$</span>{new Intl.NumberFormat("en-US").format(minimumAccountSize)}<span>{unit}</span></p>
                      </div>
                      {/*<img src="img/propietary.svg" alt="" />*/}

                      <div className="firm__tooltip" id="firm__tooltip1">
                        <span>Minimum Account Size ${new Intl.NumberFormat("en-US").format(minimumAccountSize)}{unit}</span>
                      </div>
                    </div>
                    <p>
                      Minimum Account Size
                      </p>
                  </li>
                ): <li>
                <div className="info info--align">
                  <div>
                    <p className="minimumAccountSize-info"><span className="no-minimum">None</span></p>
                  </div>
                  {/*<img src="img/propietary.svg" alt="" />*/}

                  <div className="firm__tooltip" id="firm__tooltip1">
                    <span>No Minimum Account Size</span>
                  </div>
                </div>
                <p>
                  Minimum Account Size
                  </p>
              </li>}

                <li>
                  <FirmSizeIcon
                    textClassName={classes["firm-size-text"]}
                    firmSize={firm.firmSizeType}
                  ></FirmSizeIcon>
                </li>
                <li>
                  <div className="info info--align">
                    <p>
                      {percentageOfValuesAligned}
                      <small>%</small>
                    </p>
                    <div className="firm__tooltip" id="firm__tooltip1">
                      <span>Team’s Values-Aligned AUM</span>
                    </div>
                  </div>
                  <p className={classes["firm-size-text"]}>
                    Team’s Values-Aligned AUM
                  </p>
                </li>
              </ul>
            </div>
            <div className="expertise expertise__mobile uk-hidden@m uk-width-1-1">
              <div className="expertise__title">
                <h3>Suggested by</h3>
              </div>
              <div className="suggested__list">
                <p>
                  {suggestedByMemberTypes
                    .map((item) =>
                      item.label)
                    .join(" | ")}
                </p>
              </div>
            </div>
          </div>
          <div className="uk-width-1-4@s uk-width-expand last-custom-column-width profile__actions-container">

            <div className="expertise uk-visible@m uk-width-1-1">
              <div className="expertise__title">
                <h3>Suggested by</h3>
              </div>
              <div className="suggested__list">
                <p>
                  {suggestedByMemberTypes
                    .map((item) =>
                      item.label
                    )
                    .map((v) => {
                      return [v, <br key={`key-${v}`} />];
                    })}
                </p>
              </div>
            </div>
            <div className="profile__actions uk-flex-middle uk-width-1-1">
              <GetInTouchButton
                email={user.email}
                advisorName={name}
                onClick={onGetInTouch}
              ></GetInTouchButton>
              {
                <a
                  className="button--secondary"
                  href="/"
                  //className="--active uk-text-large"
                  onClick={(e) => {
                    e.preventDefault();
                    onPrintProfile();
                  }}
                >
                  Print Profile
                </a>
              }
              <EditProfileButton
                onEditProfile={onEditProfile}
              ></EditProfileButton>
              <div>
                <ApproveProfileButton
                  onApproveProfile={onApproveProfile}
                ></ApproveProfileButton>

                <RejectProfileButton
                  onRejectProfile={onRejectProfile}
                ></RejectProfileButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="introactions introactions--shadow --mt uk-hidden@m ">
        <div className="uk-container@s">
          <div className="intro-container">
            <div className="uk-grid fix-mobile uk-grid-divider@s">
              <div className={unsdgTypes.length > 0 ? 'uk-width-1-4@s uk-width-1-2 uk-first-column fix uk-flex' : 'uk-width-1-2 uk-first-column fix uk-flex'}>
                <button
                  className="adress__interact introactions__btn introactions__btn--start introactions__btn--black"
                  id="arrow1"
                  onClick={() => unToggleOtherContent('adress__interact')}
                  uk-toggle="target: #adress, #arrow1; cls: show; animation: uk-animation-fade"
                >
                  <svg
                    className="filter__svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                  >
                    <g id="noun_contact_1589831" transform="translate(-1 -4)">
                      <g id="Group_239" data-name="Group 239">
                        <path
                          id="Path_540"
                          data-name="Path 540"
                          d="M21,4H3A2,2,0,0,0,1,6V18a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V6A2,2,0,0,0,21,4Zm1,14a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V6A1,1,0,0,1,3,5H21a1,1,0,0,1,1,1ZM15,8h5V9H15Zm0,3h5v1H15Zm2,3h3v1H17Zm-6.7-1.661-.014-.128a2.886,2.886,0,0,0,1.21-2.4A2.67,2.67,0,0,0,9,7,2.67,2.67,0,0,0,6.5,9.813a2.887,2.887,0,0,0,1.21,2.4l-.014.128C5.568,12.626,4,13.6,4,14.75V17H14V14.75C14,13.6,12.432,12.626,10.3,12.339ZM13,16H5V14.75c0-.424,1.008-1.174,2.83-1.42l.774-.1.167-1.5-.5-.339A1.9,1.9,0,0,1,7.5,9.813,1.681,1.681,0,0,1,9,8a1.681,1.681,0,0,1,1.5,1.813,1.9,1.9,0,0,1-.774,1.572l-.5.339.167,1.5.774.1c1.822.246,2.83,1,2.83,1.42Z"
                          fill="#4d4d4d"
                        />
                      </g>
                    </g>
                  </svg>
                  CONTACT
                </button>
              </div>
              <div className={unsdgTypes.length > 0 ? 'uk-width-1-4@s uk-width-1-2 fix uk-flex uk-flex-middle --divider' : 'uk-width-1-2 fix uk-flex uk-flex-middle --divider'}>
                <button
                  className="downloads__interact introactions__btn introactions__btn--border introactions__btn--start introactions__btn--black"
                  id="arrow2"
                  onClick={() => unToggleOtherContent('downloads__interact')}
                  uk-toggle="target: #downloads, #arrow2; cls: show; animation: uk-animation-fade"
                >
                  <span>
                    <svg
                      className="filter__svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.219"
                      height="16.21"
                      viewBox="0 0 16.219 16.21"
                    >
                      <g id="noun_link_766124" transform="translate(0 0)">
                        <path
                          id="Path_541"
                          data-name="Path 541"
                          d="M2.237,10.714a.811.811,0,0,1,1.146,0,2.437,2.437,0,0,0,3.441,0L9.691,7.847A2.433,2.433,0,0,0,6.249,4.406L4.487,6.168A.81.81,0,0,1,3.341,5.022L5.1,3.26a4.054,4.054,0,1,1,5.733,5.733L7.97,11.86a4.061,4.061,0,0,1-5.733,0A.811.811,0,0,1,2.237,10.714Z"
                          transform="translate(4.195 -2.075)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_542"
                          data-name="Path 542"
                          d="M197.166,170.327l2.867-2.867a4.059,4.059,0,0,1,5.733,0,.81.81,0,0,1-1.146,1.146,2.437,2.437,0,0,0-3.441,0l-2.867,2.867a2.435,2.435,0,0,0,0,3.441,2.491,2.491,0,0,0,3.441,0l1.759-1.762a.81.81,0,1,1,1.146,1.146l-1.762,1.759a4.052,4.052,0,1,1-5.73-5.73Z"
                          transform="translate(-195.975 -161.034)"
                          fill="#4d4d4d"
                        />
                      </g>
                    </svg>
                  </span>
                  REPORTS
                </button>
              </div>
              <div
                className="adress__interact adress introactions__content uk-width-1-1"
                id="adress"
                aria-hidden="true"
              >
                <div className="no-padding-left-tablet uk-container">
                  <div className="uk-grid uk-grid-divider@s uk-grid-stack uk-grid-match">
                    <div className="uk-width-1-2@s uk-first-column">
                      <div uk-accordion={"true"}>
                        <div className="uk-open">
                          <HeadQuarters
                            addresses={addresses}
                            website={website}
                          />

                          <SocialMediaLinks socialLinkTypes={socialLinkTypes} />
                        </div>
                      </div>
                    </div>
                    <div className="uk-width-1-2@m uk-hidden@s uk-margin">
                      <hr />
                    </div>
                    <div className="other-offices-container-mobile display-block uk-width-1-2@s">
                      <OtherOffices addresses={addresses} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="downloads__interact download introactions__content" id="downloads" aria-hidden="true">
                <div className="fix-mobile">
                  <div className="no-padding-left-tablet uk-container">
                    <div className="uk-container">
                      <div className="uk-grid">
                        <div className="uk-width-1-1">
                          <h3 className="section-title">Download links</h3>
                        </div>
                      </div>
                      <div className="uk-grid uk-grid-divider uk-margin-remove-top">
                        <div className="uk-width-1-2@s uk-first-column">
                          {materialTypes.map((m) => {
                            return (
                              <div key={m.link} className="downlown-list">
                                <a href={m.link} target="_blank" rel="noopener noreferrer">
                                  <img src="img/download.svg" alt="" />
                                  <span>{m.value}</span>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                        <div className="uk-width-1-2@s">
                          {richMediaLinks.map((m) => {
                            return (
                              <div key={m.url} className="downlown-list">
                                <a href={m.url} target="_blank" rel="noopener noreferrer">
                                  <img src="img/download.svg" alt=""/>
                                  <span>{m.description}</span>
                                </a>
                              </div>
                            );
                          })}
                          {currentADV ? (
                            <div className="downlown-list">
                              <a href={currentADV}>
                                <img src="img/download.svg" alt="" />
                                <span>ADV (SEC Public Disclosure)</span>
                              </a>
                            </div>
                          ):null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {unsdgTypes.length > 0 ?
                <div className="uk-width-1-2@s uk-width-1-1 fix border-top-mobile uk-flex uk-flex-middle">
                  <button
                    className="impact__interact introactions__btn introactions__btn--border introactions__btn--start introactions__btn--black"
                    id="arrow3"
                    onClick={() => unToggleOtherContent('impact__interact')}
                    uk-toggle="target: #impact, #arrow3; cls: show; animation: uk-animation-fade"
                  >
                    <span className="impact-svg">
                      <svg
                        id="SDG"
                        xmlns="http://www.w3.org/2000/svg"
                        width="21.86"
                        height="21.845"
                        viewBox="0 0 21.86 21.845"
                      >
                        <path
                          id="Path_565"
                          data-name="Path 565"
                          d="M505.056,21.967a6.206,6.206,0,0,1,.98-1.26l-3.213-3.521a10.994,10.994,0,0,0-2.039,2.653Z"
                          transform="translate(-499.44 -14.161)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_566"
                          data-name="Path 566"
                          d="M538.493,14.933a6.127,6.127,0,0,1,1.347.844l3.222-3.516a10.969,10.969,0,0,0-2.856-1.781Z"
                          transform="translate(-525.002 -9.615)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_567"
                          data-name="Path 567"
                          d="M552.409,27.6l-4.27,2.128a6.11,6.11,0,0,1,.428,1.508l4.75-.448a10.828,10.828,0,0,0-.907-3.188"
                          transform="translate(-531.541 -21.22)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_568"
                          data-name="Path 568"
                          d="M545.2,22.234l4.269-2.128a10.984,10.984,0,0,0-1.995-2.647l-3.223,3.515a6.131,6.131,0,0,1,.948,1.26"
                          transform="translate(-528.902 -14.346)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_569"
                          data-name="Path 569"
                          d="M501.38,40.207c0-.1,0-.192.008-.288l-4.752-.425c-.015.236-.023.473-.023.713a10.946,10.946,0,0,0,.323,2.641l4.588-1.316a6.168,6.168,0,0,1-.144-1.325"
                          transform="translate(-496.613 -29.286)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_570"
                          data-name="Path 570"
                          d="M542.9,53.829a6.232,6.232,0,0,1-1.159,1.09l2.508,4.059A11.051,11.051,0,0,0,546.7,56.7Z"
                          transform="translate(-527.2 -39.006)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_571"
                          data-name="Path 571"
                          d="M549.377,40.3a6.107,6.107,0,0,1-.141,1.31l4.588,1.317a10.893,10.893,0,0,0,.32-2.627c0-.225-.008-.449-.022-.67l-4.75.449c0,.074,0,.148,0,.222"
                          transform="translate(-532.284 -29.376)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_572"
                          data-name="Path 572"
                          d="M508,54.111,504.205,57a11.02,11.02,0,0,0,2.479,2.245l2.508-4.055A6.205,6.205,0,0,1,508,54.111"
                          transform="translate(-501.759 -39.197)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_573"
                          data-name="Path 573"
                          d="M501.644,30.991a6.078,6.078,0,0,1,.455-1.529l-4.267-2.126a10.847,10.847,0,0,0-.943,3.229Z"
                          transform="translate(-496.799 -21.043)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_574"
                          data-name="Path 574"
                          d="M539.366,62.423l-2.5-4.054a6.131,6.131,0,0,1-1.462.593l.884,4.69a10.893,10.893,0,0,0,3.083-1.23"
                          transform="translate(-522.905 -42.084)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_575"
                          data-name="Path 575"
                          d="M547.265,47.839a6.145,6.145,0,0,1-.667,1.372l3.81,2.872a10.865,10.865,0,0,0,1.441-2.927Z"
                          transform="translate(-530.496 -34.945)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_576"
                          data-name="Path 576"
                          d="M528.063,60.638a6.126,6.126,0,0,1-.889.065,6.342,6.342,0,0,1-.717-.042l-.884,4.689a10.971,10.971,0,0,0,3.373-.026Z"
                          transform="translate(-516.244 -43.622)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_577"
                          data-name="Path 577"
                          d="M531.766,12.572a6.051,6.051,0,0,1,1.525.295L535,8.415a10.85,10.85,0,0,0-3.238-.612Z"
                          transform="translate(-520.442 -7.799)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_578"
                          data-name="Path 578"
                          d="M517.743,59.139a6.116,6.116,0,0,1-1.513-.572l-2.509,4.057a10.907,10.907,0,0,0,3.139,1.2Z"
                          transform="translate(-508.21 -42.218)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_579"
                          data-name="Path 579"
                          d="M521.163,12.837a6.172,6.172,0,0,1,1.557-.277V7.792a10.847,10.847,0,0,0-3.282.6Z"
                          transform="translate(-512.085 -7.792)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_580"
                          data-name="Path 580"
                          d="M503.517,49.342a6.129,6.129,0,0,1-.727-1.456L498.2,49.2a10.869,10.869,0,0,0,1.514,3.026Z"
                          transform="translate(-497.692 -34.976)"
                          fill="#4d4d4d"
                        />
                        <path
                          id="Path_581"
                          data-name="Path 581"
                          d="M511.925,15.657a6.148,6.148,0,0,1,1.322-.8l-1.724-4.446a10.917,10.917,0,0,0-2.812,1.721Z"
                          transform="translate(-504.813 -9.568)"
                          fill="#4d4d4d"
                        />
                      </svg>
                    </span>
                        Impact Expertise in UN Sustainable <br /> Development Goals
                    </button>
                </div>
                : null}
              <div className="introactions__content impact__interact impact" id="impact" aria-hidden="true">
                <div className="report">
                  <div className="uk-container">
                    <div className="advisor-profile-report-item-row-gap uk-grid">
                      {unsdgTypes.map((u) => {
                        return (
                          <div
                            key={u.id}
                            className="uk-width-1-2 uk-width-1-4@s"
                          >
                            <div className="report__item">
                              <img src={`img/icons/${u.icon}`} alt="" />
                              <p>
                                {u.value
                                  .split(" ")
                                  .map((s) => capitalize(s))
                                  .join(" ")}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function unToggleOtherContent(classToSkip) {
  const introactionsContentAndButtons = document.querySelectorAll('.introactions__content.show, .introactions__btn.show')

  for (let item of introactionsContentAndButtons) {
    if (!item.classList.contains(classToSkip)) {
      item.classList.remove("show")
    }
  }

}
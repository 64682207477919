import axiosInstance from "./index";

export function signUpFreeVisitor(data) {
  return axiosInstance({
    method: "post",
    url: "/sign-up/free-visitor",
    data,
  });
}
export function setVisitorPassword(data) {
  return axiosInstance({
    method: "post",
    url: "/sign-up/setVisitorPassword",
    data,
  });
}
import { createContext } from "react";

export const SearchResultsContext = createContext({
  advisors: [],
  setAdvisors: () => {},
  filterTypes: {},
  setFilterTypes: () => {},
  selectedFilters: {},
  setSelectedFilters: () => {},
  selectedSort: {},
  setSelectedSort: () => {},
  selectedOrder: {},
  setSelectedOrder: () => {},
  setInitialFilters: () => {},
  trackSelectedAdvisor: () => {},
});

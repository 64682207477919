import axiosInstance from ".";

export const getDiscountCode = (code,email) => {
  return axiosInstance({
    method: "get",
    url: `/discount-codes/get-discount-code?code=${encodeURIComponent(code)}&email=${email}`,
  });
};

export const listDiscountCodes = () => {
  return axiosInstance({
    method: "get",
    url: `/discount-codes/list-discount-codes`,
  });
};

export const createDiscountCode = (discountCode) => {
  return axiosInstance({
    method: "post",
    url: `/discount-codes/create-discount-code`,
    data:discountCode
  });
};

export const updateDiscountCode = (discountCode) => {
  return axiosInstance({
    method: "put",
    url: `/discount-codes/update-discount-code`,
    data:discountCode
  });
};

export const deleteDiscountCode = (discountCodeId) => {
  return axiosInstance({
    method: "delete",
    url: `/discount-codes/delete-discount-code`,
    data:{
      id:discountCodeId
    }
  });
};
import emptyFieldvalidate from './emptyFieldValidate';

function validateAllFields(state) {
    let newStates = state
    Object.keys(state).forEach((key) => {
        if (Array.isArray(state[key])) {
            let nestedErrorCount = 0
            newStates = {
                ...newStates,
                [key]: [
                    ...newStates[key].map((arrayKey) => {
                        const validatedArrayKey = validateAllFields(arrayKey)
                        nestedErrorCount = nestedErrorCount + validatedArrayKey.errorCount
                        return {
                            ...validatedArrayKey
                        }
                    })
                ],
                errorCount: newStates.errorCount + nestedErrorCount
            }
        }
        else {
            if (typeof (state[key]) === 'object') {
                const validatedField = emptyFieldvalidate(state[key])
                newStates = {
                    ...newStates,
                    [key]: {
                        ...validatedField
                    },
                    errorCount: validatedField.showError ? newStates.errorCount + 1 : newStates.errorCount
                }
            }
        }
    })
    return newStates
}

export default validateAllFields;

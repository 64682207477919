import { uploadFile, uploadImage } from "../api/upload";

export const upload = async (file,currentFileValue,fileType) => {
    const fileForm = new FormData();
    fileForm.append(file.name, file);
    fileForm.append("previousObjectUrl", currentFileValue);
    switch(fileType){
      case "image":
        return await uploadImage(fileForm);
      case "file":
        return await uploadFile(fileForm);
      default: 
        return {data:{success:false}}
    }
  }
import React, { useState } from "react";
import "./Payment.css";
const subPrice = Number(process.env.REACT_APP_SUB_PRICE);
export const JoinPayment = ({
  onGetDiscountCode = async () => {},
  next = () => {},
  back = () => {},
}) => {
  const [discountText, setDiscountText] = useState("");
  const [discountPercentText,setDiscountPercentText] = useState("")
  const [total, setTotal] = useState(subPrice);
  const [isDiscountCodeApplied, setIsDiscountCodeApplied] = useState(false);
  const _onGetDiscountCode = async (code) => {
    try {
      const discountCode = await onGetDiscountCode(code);
      if (discountCode) {
        let newTotal = subPrice;
        if (discountCode.discountPercent) {
          const discountAmount = subPrice * (discountCode.discountPercent / 100);
          newTotal = subPrice - discountAmount;
          setDiscountText(`-${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(discountAmount)}`);
          setDiscountPercentText(`(${discountCode.discountPercent}%)`)
        } else {
          newTotal = subPrice - discountCode.maxDiscountAmount;
          setDiscountText(
            `${new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(discountCode.maxDiscountAmount)}`
          );
          setDiscountPercentText('')
        }
        setTotal(newTotal);
        setIsDiscountCodeApplied(true);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="payment--container">
      <div className="grid">
        <div className="checkout">
          <>
            <div className="description">
              <h3>Payment</h3>
              <p>{`${
                total === 0
                  ? `Continue to confirmation page.`
                  : `Continue to the checkout page to process payment.`
              }`}</p>
              <div className="price--table">
                <div className="justify-space-between price">
                  <span>1 Year Unlimited Access</span>
                  <span>{`$${subPrice}`} </span>
                </div>
                {discountText ? (
                  <div className="justify-space-between price">
                    <span>{`Code Applied ${discountPercentText}`}</span>
                    <span>{discountText}</span>
                  </div>
                ) : null}
                <hr />
                <div className="justify-space-between price">
                  <span>Total</span>
                  <span>{`${new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(total)}`}</span>
                </div>
              </div>
              {isDiscountCodeApplied ? (
                <p>
                  Your partner code has been successfully applied. Enjoy your
                  complimentary access to ValuesAdvisor.
                </p>
              ) : null}
            </div>
          </>
        </div>
        <div className="promo">
          <div className="promo--container">
            <div className="title">
              <div>1 Year Unlimited Access</div>
            </div>
            <div
              className={isDiscountCodeApplied ? `content success` : `content`}
            >
              <p>
                If you belong to one of our Affinity Group Partners, please
                enter your partner code for complimentary access.
              </p>
              <h3>PARTNER CODE</h3>
              <input
                type="text"
                id="partner-code"
                name="card-holder-first-name"
                autoComplete="off"
                placeholder=""
                className="uk-input input-box"
              />
              <div className="justify-space-between">
                <button
                  className="partner-apply-button"
                  onClick={(e) => {
                    e.preventDefault();
                    _onGetDiscountCode(
                      document.getElementById("partner-code").value
                    );
                  }}
                >
                  {isDiscountCodeApplied ? (
                    <span>Applied</span>
                  ) : (
                    <span>Apply</span>
                  )}
                </button>
                <h4>Success!</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buttons">
        <button
          onClick={(e) => {
            back(e);
          }}
          className="homelink create-account back"
        >
          <svg
            className="homelink__chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19.521"
            viewBox="0 0 11 19.521"
          >
            <g id="Expand" transform="translate(0 19.521) rotate(-90)">
              <path
                id="Path_434"
                data-name="Path 434"
                d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                transform="translate(-178.807 -14.7)"
              />
            </g>
          </svg>
          Back
        </button>
        <button
          onClick={(e) => {
            next(e);
          }}
          className="homelink create-account"
        >
          {`${
            total === 0
              ? `Continue to confirmation page`
              : `Continue to the checkout page`
          }`}
          <svg
            className="homelink__chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19.521"
            viewBox="0 0 11 19.521"
          >
            <g id="Expand" transform="translate(0 19.521) rotate(-90)">
              <path
                id="Path_434"
                data-name="Path 434"
                d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                transform="translate(-178.807 -14.7)"
                fill="#ff6b00"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { SEND_TO, useContactForm } from "../../hooks/useContactForm";
import bodymovin from "lottie-web";
import handAnimation from "../../assets/Lotties/hand_animation.json";
function setHiBtnPosition() {
  document.addEventListener("scroll", function (e) {
    const sayHiBtnContainer = document.getElementById("say-hi-btn-container");
    const sayHiButtonLimitElement = document.getElementById("say-hi-btn-limit");
    if (sayHiButtonLimitElement) {
      const limitRect = document;
      sayHiButtonLimitElement.getBoundingClientRect();

      if (window.innerHeight - limitRect.bottom >= 1) {
        sayHiBtnContainer.style.bottom = "unset";
        sayHiBtnContainer.style.top =
          limitRect.top - sayHiBtnContainer.offsetHeight + "px";
      } else {
        sayHiBtnContainer.style.top = "unset";
        sayHiBtnContainer.style.bottom = "0";
      }
    }
  });
}
const FloatingContact = ({location = "Advisor Profile"}) => {
  const [isSendHiMessageDisabled, setIsSendHiMessageDisabled] = useState(true);
  const {
    name: sayHiName,
    setName: setSayHiName,
    email: sayHiEmail,
    setEmail: setSayHiEmail,
    message: sayHiMessage,
    setMessage: setSayHiMessage,
    submitForm,
  } = useContactForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    await submitForm(SEND_TO.VALUES_ADVISOR_HELP, {
      name: sayHiName,
      email: sayHiEmail,
      message: sayHiMessage,
      subject: "A Message to ValuesAdvisor team",
    });
    ReactGA.event({
      category: `Quick Ask feature`,
      action: "inquiry sent",
      label: `Inquiry sent from ${location}`,
      value: 1,
    });
    let saiHiDivs = document.getElementsByClassName("say-hi-form-container");
    for (let i = 0; i < saiHiDivs.length; i++) {
      saiHiDivs[i].classList.add("thank-you");
    }
  };
  useEffect(() => {
    setHiBtnPosition();

    /* Shapes */
    const svgContainers = document.getElementsByClassName(
      "inner-lottie-container"
    );

    for (let item of svgContainers) {
      setTimeout(function () {
        let animItem = bodymovin.loadAnimation({
          wrapper: item,
          animType: "svg",
          loop: false,
          // autoplay: false,
          // pause: true,
          animationData: handAnimation,
        });
        animItem.setSpeed(0.55);

        let wigglingHands = document.getElementsByClassName("wiggle");
        for (var i = 0; i < wigglingHands.length; i++) {
          wigglingHands.item(i).classList.add("wiggling");
        }

        animItem.addEventListener("complete", function () {
          animItem.hide();
          for (var i = 0; i < wigglingHands.length; i++) {
            wigglingHands.item(i).classList.remove("wiggling");
          }
          setTimeout(function () {
            animItem.goToAndPlay(0);
            animItem.show();
            for (var i = 0; i < wigglingHands.length; i++) {
              wigglingHands.item(i).classList.add("wiggling");
            }
          }, 5000);
        });
      }, 5000);
    }
  }, []);
  return (
    <>
      <div id="say-hi-btn-container">
        <div className="lottie-container mobile-only">
          <div className="inner-lottie-container"></div>
          <button
          onClick={(e) => {
            ReactGA.event({
                category: `Quick Ask feature`,
                action: "click",
                label: `Clicked at ${location}`,
                value: 1,
              });
            }}
            id="drop-a-note-mobile"
            className="say-hi-btn uk-hidden@s"
            href="#my-id"
            type="button"
            uk-toggle="target: #mobile-say-hi-form-wrapper"
          >
            <img className="wiggle" src="img//icons/say-hi-icon.svg" alt="" />
            <p>Need Help?</p>
          </button>
        </div>
        <div className="uk-visible@s medium-up-form-wrapper">
          <div className="lottie-container medium-up">
            <button
              onClick={(e) => {
                ReactGA.event({
                  category: `Quick Ask feature`,
                  action: "click",
                  label: `Clicked at ${location}`,
                  value: 1,
                });
              }}
              className="say-hi-btn"
              type="button"
              id="medium-up-say-hi-btn"
            >
              <img className="wiggle" src="img//icons/say-hi-icon.svg" alt="" />
              <p>Need Help?</p>
            </button>
            <div
              className="medium-up-say-hi-form-container say-hi-form-container"
              uk-drop="mode: click; pos: top-right"
            >
              <div className="say-hi-header">
                <p>Message to ValuesAdvisor team</p>
                <button
                  id="medium-up-say-hi-btn-clone"
                  className="uk-drop-close"
                  type="button"
                  uk-close={"true"}
                >
                  <img src="img/icons/say-hi-close.svg" alt="" />
                </button>
              </div>

              <form onSubmit={onSubmit} className="say-hi-form">
                <div className="say-hi-input-wrapper">
                  <label>Name</label>
                  <input
                    type="text"
                    required
                    name="name"
                    onChange={(e) => {
                      setSayHiName(e.target.value);
                    }}
                  />
                </div>
                <div className="say-hi-input-wrapper">
                  <label>Email</label>
                  <input
                    type="email"
                    required
                    name="email"
                    onChange={(e) => {
                      setSayHiEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="say-hi-form-radio-btns-wrapper">
                  <label className="radio-main-label">Message</label>
                  <div className="say-hi-radio-btn-container">
                    <input
                      type="radio"
                      name="question_message"
                      value="I have a question on how to use the platform."
                      onChange={(e) => {
                        setSayHiMessage(e.target.value);
                        setIsSendHiMessageDisabled(true);
                      }}
                    />
                    <div className="input-dummy"></div>
                    <label htmlFor="html">
                      I have a question on how to use the platform.
                    </label>
                  </div>
                  <div className="say-hi-radio-btn-container">
                    <input
                      type="radio"
                      name="question_message"
                      value="I’d love some help with terminology."
                      onChange={(e) => {
                        setSayHiMessage(e.target.value);
                        setIsSendHiMessageDisabled(true);
                      }}
                    />
                    <div className="input-dummy"></div>
                    <label htmlFor="html">
                      I’d love some help with terminology.
                    </label>
                  </div>
                  <div className="say-hi-radio-btn-container">
                    <input
                      type="radio"
                      name="question_message"
                      value="Can I get more support? What other resources do you have?"
                      onChange={(e) => {
                        setSayHiMessage(e.target.value);
                        setIsSendHiMessageDisabled(true);
                      }}
                    />
                    <div className="input-dummy"></div>
                    <label htmlFor="html">
                      Can I get more support? What other resources do you have?
                    </label>
                  </div>
                  <div className="say-hi-radio-btn-container">
                    <input
                      type="radio"
                      name="question_message"
                      value="Other message"
                      onChange={() => {
                        setIsSendHiMessageDisabled(false);
                      }}
                    />
                    <div className="input-dummy"></div>
                    <label htmlFor="html">Other message</label>
                  </div>
                </div>
                <textarea
                  className="say-hi-form-text-area"
                  placeholder="Type your message here."
                  name="question_text_area_message"
                  disabled={isSendHiMessageDisabled}
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                  }}
                />
                <div className="send-hi-message-container">
                  <span>SEND</span>
                  <button type="submit">
                    <img src="img/icons/say-hi-send.svg" alt="" />
                  </button>
                </div>
              </form>
              <div className="thank-you-content">
                <div className="say-hi-header">
                  <p></p>
                  <button
                    id="medium-up-say-hi-btn-clone"
                    className="uk-drop-close"
                    type="button"
                    uk-close={"true"}
                  >
                    <img src="img/icons/thank-you-close.svg" alt="" />
                  </button>
                </div>
                <p className="thank-you-text">Thank You!</p>
                <div className="thank-you-separator"></div>
                <p className="in-touch-text">
                  We’ll be in touch <br /> shortly.
                </p>
                <div className="send-hi-message-container close-hi-message-container">
                  <span>CLOSE</span>
                  <button
                    className="uk-drop-close"
                    type="button"
                    uk-close={"true"}
                  >
                    <img src="img/icons/thank-you-content-close.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
            <div className="inner-lottie-container"></div>
          </div>
        </div>
      </div>
      <div
        id="mobile-say-hi-form-wrapper"
        uk-offcanvas="overlay: true"
        className="uk-hidden@s"
      >
        <div className="uk-offcanvas-bar mobile-say-hi-form-container say-hi-form-container">
          <div className="say-hi-header">
            <p>Message to ValuesAdvisor team</p>
            <button
              className="uk-offcanvas-close"
              type="button"
              uk-close={"true"}
            >
              <img src="img/icons/say-hi-close.svg" alt="" />
            </button>
          </div>

          <form onSubmit={onSubmit} className="say-hi-form">
            <div className="say-hi-input-wrapper">
              <label>Name</label>
              <input
                type="text"
                name="name"
                onChange={(e) => {
                  setSayHiName(e.target.value);
                }}
              />
            </div>
            <div className="say-hi-input-wrapper">
              <label>Email</label>
              <input
                type="email"
                name="email"
                onChange={(e) => {
                  setSayHiEmail(e.target.value);
                }}
              />
            </div>
            <div className="say-hi-form-radio-btns-wrapper">
              <label className="radio-main-label">Message</label>
              <div className="say-hi-radio-btn-container">
                <input
                  type="radio"
                  name="question_message"
                  value="I have a question on how to use the platform."
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                    setIsSendHiMessageDisabled(true);
                  }}
                />
                <div className="input-dummy"></div>
                <label htmlFor="html">
                  I have a question on how to use the platform.
                </label>
              </div>
              <div className="say-hi-radio-btn-container">
                <input
                  type="radio"
                  name="question_message"
                  value="I’d love some help with terminology."
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                    setIsSendHiMessageDisabled(true);
                  }}
                />
                <div className="input-dummy"></div>
                <label htmlFor="html">
                  I’d love some help with terminology.
                </label>
              </div>
              <div className="say-hi-radio-btn-container">
                <input
                  type="radio"
                  name="question_message"
                  value="Can I get more support? What other resources do you have?"
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                    setIsSendHiMessageDisabled(true);
                  }}
                />
                <div className="input-dummy"></div>
                <label htmlFor="html">
                  Can I get more support? What other resources do you have?
                </label>
              </div>

              <div className="say-hi-radio-btn-container">
                <input
                  type="radio"
                  name="question_message"
                  value="Other message"
                  disabled={isSendHiMessageDisabled}
                  onChange={(e) => {
                    setSayHiMessage(e.target.value);
                  }}
                />
                <div className="input-dummy"></div>
                <label htmlFor="html">Other message</label>
              </div>
            </div>
            <textarea
              className="say-hi-form-text-area"
              placeholder="Type your message here."
              name="question_text_area_message"
            />
            <div className="send-hi-message-container">
              <span>SEND</span>
              <button type="submit">
                <img src="img/icons/say-hi-send.svg" alt="" />
              </button>
            </div>
          </form>
          <div className="thank-you-content">
            <div className="say-hi-header">
              <p></p>
              <button
                className="uk-offcanvas-close"
                type="button"
                uk-close={"true"}
              >
                <img src="img/icons/thank-you-close.svg" alt="" />
              </button>
            </div>
            <p className="thank-you-text">Thank You!</p>
            <div className="thank-you-separator"></div>
            <p className="in-touch-text">
              We’ll be in touch <br /> shortly.
            </p>
            <div className="send-hi-message-container close-hi-message-container">
              <span>CLOSE</span>
              <button
                className="uk-offcanvas-close"
                type="button"
                uk-close={"true"}
              >
                <img src="img/icons/thank-you-content-close.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="say-hi-btn-limit no-print-show"></div>
    </>
  );
};

export default FloatingContact;

export default function storeAuthData({role,status,id,token,firstName,lastName,email,clientType,isLoggedIn, advisorId}) {
    window.localStorage.setItem("role", role);
    window.localStorage.setItem("status", status);
    window.localStorage.setItem("id", id);
    window.localStorage.setItem("token", token);
    window.localStorage.setItem("isLoggedIn", isLoggedIn);
    window.localStorage.setItem("firstName", firstName);
    window.localStorage.setItem("lastName", lastName);
    window.localStorage.setItem("email", email);
    window.localStorage.setItem("clientType", clientType);
    window.localStorage.setItem("advisorId", advisorId);

}

export function removeAuthData() { 
    window.localStorage.removeItem("role");
    window.localStorage.removeItem("status");
    window.localStorage.removeItem("id");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("isLoggedIn");
    window.localStorage.removeItem("firstName");
    window.localStorage.removeItem("lastName");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("clientType");
    window.localStorage.removeItem("advisorId");
}
import React from "react";
import "./BlogPost.css";
import { Helmet } from "react-helmet-async";
const Component = () => {
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={
            "https://www.ncfp.org/knowledge/finding-the-right-advisor-to-help-align-your-investments-with-your-values/"
          }
        />
      </Helmet>
      <div className="blog-post-container">
        <article className="content">
          <h1 className="article-title">
            Finding the Right Advisor to Help Align Your Investments With Your
            Values
          </h1>
          <h4 className="article-author">By Kate Simpson</h4>
          <div className="copy-wrap">
            <p>
              Addressing the world’s most pressing issues will require
              harnessing the power of all investment capital—including the
              corpus of foundations. While allocating 5% (the <em>minimum </em>
              required by law) of your foundation’s resources to support its
              mission can result in powerful solutions and social change,
              imagine if the remaining 95% were invested to further your goals
              and objectives?
            </p>
            <p>
              <strong>Values-aligned investing</strong>, an umbrella term that
              includes mission-aligned, impact, and sustainable investing,
              refers to investing in a way that furthers an asset owner’s
              environmental and/or social goals at market or concessionary rates
              of return. When a foundation moves its investment capital into
              mission alignment, it has the opportunity to “walk its talk” and
              leverage 100% of its available capital to solve important social
              issues. Adopting a values-aligned investment strategy can be a
              powerful force for change and removes the risk that a foundation’s
              investments may work against its mission (i.e. investing in fossil
              fuel companies while granting to organizations that work on
              climate change).
            </p>
            <div
              style={{ width: "970px" }} //"width: 970px"
            >
              <img
                decoding="async"
                src={`${process.env.PUBLIC_URL}/img/asset_class_definition.png`}
                alt="Chart of values-aligned investing examples in various asset classes"
                width="960"
                height="540"
                sizes="(max-width: 960px) 100vw, 960px"
              />
              <p>
                Values-aligned investing examples in various asset classes.
                Courtesy of The Take Two Money Course
                <a href="https://courses.www.taketwojournal.com/">
                  (https://courses.www.taketwojournal.com/)
                </a>
              </p>
            </div>
            <h3>How to get started?</h3>
            <p>
              It can be difficult to know where to begin and talking to your
              existing financial advisor is a great place to start. The first
              step is to determine your intended impact. If you’re trying to
              determine whether your investment advisor has the expertise to
              implement your mission-aligned investing strategy, below are some
              questions that may help:
            </p>
            <ul>
              <li>
                What percentage of the advisor’s assets under management (AUM)
                are values-aligned (i.e. how many clients and how much capital
                are they managing in this way)?
              </li>
              <li>
                For how many years has the advisor offered services in
                values-aligned investing?
              </li>
              <li>
                What are the impact areas that they offer values-aligned
                investment opportunities in?
              </li>
            </ul>
            <ul>
              <li>
                In which asset classes will the advisor invest to meet both your
                impact and financial goals?
              </li>
              <li>
                Can the advisor share specific examples of impact investment
                options in each asset class? For example, if you’re seeking
                investment in private equity, the advisor might offer
                investments in companies that work in cleantech and renewable
                energy, education, healthcare, access to fair financial
                services, and those that promote diversity and gender equality.
                If you’re investing in public equities, the advisor might apply
                positive/negative screens, integrate ESG (environmental, social,
                and governance) criteria, and/or engage in shareholder advocacy.
              </li>
              <li>
                How will the advisor enable you to engage in shareholder
                advocacy (i.e. vote proxy statements) for the public equities
                portion of your portfolio?
              </li>
            </ul>
            <p>
              Additional questions can be found{" "}
              <a
                href="/blog/5-questions-to-ask-your-advisor-about-values-aligned-investing"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
            <p>
              If you’ve determined that your advisor doesn’t have the expertise
              you need, you need not be concerned: there are many advisors who
              do. ValuesAdvisor, a nonprofit resource developed to help move
              money into alignment with values, offers a database of financial
              advisors specifically chosen for their expertise in values-aligned
              investing services. The platform provides relevant information
              about an advisor’s experience and enables investors to filter the
              universe of advisors based on their specific needs.
            </p>
            <p className="orange-highlight">
              This article originally appeared in NCFP:{" "}
              <a href="https://www.ncfp.org/knowledge/finding-the-right-advisor-to-help-align-your-investments-with-your-values/">
                https://www.ncfp.org/knowledge/finding-the-right-advisor-to-help-align-your-investments-with-your-values/
              </a>
            </p>
          </div>
        </article>
      </div>
    </>
  );
};
export default Component

import React, { useContext } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import "./ProfileSubmit.css";
import logo from "../../../assets/images/logo.png";

import { FADashboardContext } from "../Context";

const ProfileSubmit = () => {
    const {form} = useContext(FADashboardContext);
    const navigate = () => {
      const role = window.localStorage.getItem("role");
      switch (role) {
        case "admin":
          window.location = "/admin";
          return;
        case "advisor":
          const advisorId = window.localStorage.getItem("advisorId");
          window.location = `/my-profile/${advisorId}`;
          return;
        default:
          window.location = "/login";
      }
    };
  
    return (
      <div>
        <Modal
          isOpen={form.isSubmitSuccess}
          className={"profile-submit-modal"}
          keyboard={false}
        >
          <ModalBody>
            <div className="checkbox-container text-center">
              <div className="title-description">
                <img src={logo} alt="ValueAdvisor" />
              </div>
              <div className="checkbox-description d-table">
                 Profile saved successfully.
              </div>
              <Button
                className="submit-btn login-btn accept-btn rounded-0"
                onClick={(e)=> {
                  e.preventDefault();
                  navigate();
                }}
              >
                OK
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

export default ProfileSubmit

import React, { useContext, useState } from "react";
import {
  Form,
  Input,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FormInput from "../../../components/_shared/FormInput";
import FormTextarea from "../../../components/_shared/FormTextarea";
import cx from "classnames";
import calculatePercentage from "../../../utils/calculatePercentage";
import { FADashboardContext } from "../Context";

const OBAboutFirm = (props) => {
  const { firm, typeList } = useContext(FADashboardContext);
  const { firmSizeType } = typeList;
  const [sizeBasedAumDropdown, setSizeBasedAumDropdown] = useState(false);

  const setAum = (e) => {
    firm.setAum(e.target.value);
  };

  const setAumUnitAsOfDate = (e) => {
    firm.setAumUnitAsOfDate(e.target.value);
  };

  const setRequiredDisclosures = (e) => {
    firm.setRequiredDisclosures(e.target.value);
  };

  const setFirmSize = (value, label) => {
    firm.setFirmSize(value, label);
  };

  const setMission = (e) => {
    firm.setMission(e.target.value);
  };

  const setProduct = (e) => {
    firm.setProduct([e.target.value]);
  };

  const setNoOfClients = (e) => {
    firm.setNoOfClients(e.target.value);
  };

  const setSeniorsColor = (e) => {
    firm.setSeniorsColor(e.target.value);
  };

  const setSeniorsWomen = (e) => {
    firm.setSeniorsWomen(e.target.value);
  };

  const setNoOfEmployees = (e) => {
    firm.setNoOfEmployees(e.target.value);
  };

  const setPublicBenefit = (e) => {
    firm.setPublicBenefit(e.target.value);
  };

  const setYearsLicensed = (e) => {
    firm.setYearsLicensed(e.target.value);
  };

  const setDirectorsColor = (e) => {
    firm.setDirectorsColor(e.target.value);
  };

  const setDirectorsWomen = (e) => {
    firm.setDirectorsWomen(e.target.value);
  };

  const setEmployeesColor = (e) => {
    firm.setEmployeesColor(e.target.value);
  };

  const setEmployeesWomen = (e) => {
    firm.setEmployeesWomen(e.target.value);
  };

  const setValuesAlignedAum = (e) => {
    firm.setValuesAlignedAum(e.target.value);
  };

  const setMinimumAccountSize = (e) => {
    firm.setMinimumAccountSize(e.target.value);
  };

  const setTypicalAccountSize = (e) => {
    firm.setTypicalAccountSize(e.target.value);
  };

  const setGovernmentInvestigation = (e) => {
    firm.setGovernmentInvestigation(e.target.value);
  };

  const toggleSizeBasedAumDropdown = () => {
    setSizeBasedAumDropdown((prevState) => !prevState);
  };

  const setFirmOfferedVaNImpactYears = (e) => {
    firm.setFirmOfferedVaNImpactYears(e.target.value);
  };

  const isTypicalAccountSizeDisclosableChecked = (e) => {
    firm.setIsTypicalAccountSizeDisclosable(
      !firm.isTypicalAccountSizeDisclosable
    );
  };

  const isNoOfClientsDisclosableChecked = (e) => {
    firm.setIsNoOfClientDisclosable(!firm.isNoOfClientsDisclosable);
  };

  const isEmployeeNaChecked = (e) => {
    firm.setIsEmployeeNA(!firm.isEmployeeNA);
  };

  const isCheckedDirectors = (e) => {
    firm.setIsDirectorNA(!firm.isDirectorNA);
  };

  const isCheckedSeniors = (e) => {
    firm.setIsSeniorNA(!firm.isSeniorNA);
  };

  const changeOrderOfMagnitude = (e) => {
    firm.setOrderOfMagnitude(e.target.value);
  };

  const changeAumUnit = (e) => {
    firm.setAumUnit(e.target.value);
  };

  const changeTypicalAccountSizeUnit = (e) => {
    firm.setTypicalAccountSizeUnit(e.target.value);
  };

  const percentageOfVA = calculatePercentage(
    firm.valuesAlignedAum.value,
    firm.aum.value
  );

  const aumUnitAsOfDate = !firm.aumUnitAsOfDate.value
    ? ""
    : firm.aumUnitAsOfDate.value.split("T")[0];

  return (
    <React.Fragment>
      <p className="pdf-download-text">
        Answer the following questions for your Firm. If your Firm’s data is the
        same as your Team’s, then you will enter the same data for this section
        and the next “About the Team” section.
      </p>
      <div className="FA-form-container">
        <Form className="FA-form">
          <div className="FA-form-fields FA-form2">
            <FormTextarea
              type="textarea"
              label="Mission"
              value={firm.mission.value}
              error={firm.mission.error}
              showError={firm.mission.showError}
              onChange={setMission}
              maxLength={750}
              className="input-full-line textarea-input"
            />

            <div className="three-fields-container three-fields-container-multicheck">
              <div className="position-relative account-size-section">
                <FormInput
                  label="Firm minimum account size"
                  error={firm.minimumAccountSize.error}
                  showError={firm.minimumAccountSize.showError}
                  value={firm.minimumAccountSize.value}
                  onChange={setMinimumAccountSize}
                />
                <div className="radio-buttons">
                  <div>
                    <Input
                      type="radio"
                      value="K"
                      checked={firm.unit.value === "K"}
                      onChange={changeOrderOfMagnitude}
                    />
                    <span>Thousands - K</span>
                  </div>
                  <div>
                    <Input
                      type="radio"
                      value="M"
                      checked={firm.unit.value === "M"}
                      onChange={changeOrderOfMagnitude}
                    />
                    <span>Millions - M</span>
                  </div>
                  <div>
                    <Input
                      type="radio"
                      value="B"
                      checked={firm.unit.value === "B"}
                      onChange={changeOrderOfMagnitude}
                    />
                    <span>Billions -B</span>
                  </div>
                </div>
              </div>

              <div className="position-relative account-size-section">
                {firm.isTypicalAccountSizeDisclosable ? (
                  <React.Fragment>
                    <FormInput
                      label="Firm typical account size."
                      error={firm.typicalAccountSize.error}
                      showError={firm.typicalAccountSize.showError}
                      value={firm.typicalAccountSize.value}
                      onChange={setTypicalAccountSize}
                      disabled={!firm.isTypicalAccountSizeDisclosable}
                    />
                    <div className="radio-buttons">
                      <div>
                        <Input
                          type="radio"
                          value="K"
                          checked={firm.typicalAccountSizeUnit.value === "K"}
                          onChange={changeTypicalAccountSizeUnit}
                        />
                        <span>Thousands - K</span>
                      </div>
                      <div>
                        <Input
                          type="radio"
                          value="M"
                          checked={firm.typicalAccountSizeUnit.value === "M"}
                          onChange={changeTypicalAccountSizeUnit}
                        />
                        <span>Millions - M</span>
                      </div>
                      <div>
                        <Input
                          type="radio"
                          value="B"
                          checked={firm.typicalAccountSizeUnit.value === "B"}
                          onChange={changeTypicalAccountSizeUnit}
                        />
                        <span>Billions -B</span>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <Label>Firm typical account size.</Label>
                )}
                <div
                  className="NA-container"
                  style={{
                    marginLeft: "1.8rem",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={!firm.isTypicalAccountSizeDisclosable}
                    onChange={(e) => {
                      isTypicalAccountSizeDisclosableChecked(e);
                    }}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      paddingLeft: "10px",
                      lineHeight: "normal",
                      fontWeight: "300",
                    }}
                  >
                    Firm does not disclose/collect this data
                  </span>
                </div>
              </div>
              <div className="position-relative account-size-section">
                <Dropdown
                  isOpen={sizeBasedAumDropdown}
                  toggle={toggleSizeBasedAumDropdown}
                  className={cx("dropdown-FA")}
                >
                  <DropdownToggle>
                    <FormInput
                      label="Size based on AUM"
                      error={firm.firmSize.error}
                      showError={firm.firmSize.showError}
                      value={firm.firmSize.label}
                      readOnly={true}
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    {(firmSizeType || []).map((firmSize, index) => {
                      return (
                        <DropdownItem key={index}>
                          <Input
                            type="radio"
                            name="size-based-on-aum"
                            checked={firm.firmSize.value === firmSize.value}
                            onChange={(e) => {
                              setFirmSize(firmSize.value, firmSize.label);
                            }}
                            value={firmSize.value}
                          />
                          <Label>{firmSize.label}</Label>
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div className="two-fields-container two-fields-container-multicheck">
              <div className="position-relative account-size-section">
                {firm.isNoOfClientsDisclosable ? (
                  <React.Fragment>
                    <FormInput
                      label="Total number of clients"
                      value={firm.noOfClients.value}
                      maxLength={5}
                      error={firm.noOfClients.error}
                      showError={firm.noOfClients.showError}
                      onChange={setNoOfClients}
                    />
                  </React.Fragment>
                ) : (
                  <Label>Total number of clients.</Label>
                )}
                <div
                  className="NA-container"
                  style={{
                    marginLeft: "1.8rem",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={!firm.isNoOfClientsDisclosable}
                    onChange={(e) => {
                      isNoOfClientsDisclosableChecked(e);
                    }}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      paddingLeft: "10px",
                      lineHeight: "normal",
                      fontWeight: "300",
                    }}
                  >
                    Firm does not disclose/collect this data
                  </span>
                </div>
              </div>

              <div className="position-relative account-size-section">
                <FormInput
                  label="Total number of employees"
                  value={firm.noOfEmployees.value}
                  error={firm.noOfEmployees.error}
                  showError={firm.noOfEmployees.showError}
                  maxLength={9}
                  onChange={setNoOfEmployees}
                />
              </div>
            </div>

            <div
              className={`multiple-percentage-fields percentage-field-na-option ${
                firm.isEmployeeNA ? "hide-percentage-field" : ""
              }`}
            >
              <h3>
                Percentage of employees in the Firm that are Women / Non-Binary
                or People of color
              </h3>
              <div className="NA-container">
                <input
                  type="checkbox"
                  checked={firm.isEmployeeNA}
                  onChange={(e) => {
                    isEmployeeNaChecked(e);
                  }}
                />
                <span>Firm does not disclose/collect this data</span>
              </div>
              <div className="percentage-field">
                <h4>Women / Non-Binary </h4>
                <div>
                  <FormInput
                    value={firm.employeesWomen.value}
                    onChange={setEmployeesWomen}
                  />
                  <span>%</span>
                </div>
                {firm.employeesWomen.showError ? (
                  <span className="error-message-percentage contains-error">
                    {firm.employeesWomen.error}
                  </span>
                ) : null}
              </div>
              <div className="percentage-field">
                <h4>People of color</h4>
                <div>
                  <FormInput
                    value={firm.employeesColor.value}
                    onChange={setEmployeesColor}
                  />
                  <span>%</span>
                </div>
                {firm.employeesColor.showError ? (
                  <span className="error-message-percentage contains-error">
                    {firm.employeesColor.error}
                  </span>
                ) : null}
              </div>
            </div>
            <div
              className={`multiple-percentage-fields percentage-field-na-option ${
                firm.isSeniorNA ? "hide-percentage-field" : ""
              }`}
            >
              <h3>
                Percentage of employees in senior positions that are Women /
                Non-Binary or People of color
              </h3>
              <div className="NA-container">
                <input
                  type="checkbox"
                  checked={firm.isSeniorNA}
                  onChange={(e) => {
                    isCheckedSeniors(e);
                  }}
                />
                <span>Firm does not disclose/collect this data</span>
              </div>
              <div className="percentage-field">
                <h4>Women / Non-Binary </h4>
                <div>
                  <FormInput
                    value={firm.seniorsWomen.value}
                    onChange={setSeniorsWomen}
                  />
                  <span>%</span>
                </div>
                {firm.seniorsWomen.showError ? (
                  <span className="error-message-percentage contains-error">
                    {firm.seniorsWomen.error}
                  </span>
                ) : null}
              </div>
              <div className="percentage-field">
                <h4>People of color</h4>
                <div>
                  <FormInput
                    value={firm.seniorsColor.value}
                    onChange={setSeniorsColor}
                  />
                  <span>%</span>
                </div>
                {firm.seniorsColor.showError ? (
                  <span className="error-message-percentage contains-error">
                    {firm.seniorsColor.error}
                  </span>
                ) : null}
              </div>
            </div>
            <div
              className={`multiple-percentage-fields percentage-field-na-option ${
                firm.isDirectorNA ? "hide-percentage-field" : ""
              }`}
            >
              <h3>
                Percentage of the Board of Directors that are Women / Non-Binary
                or people of color
              </h3>
              <div className="NA-container">
                <input
                  type="checkbox"
                  checked={firm.isDirectorNA}
                  onChange={(e) => {
                    isCheckedDirectors(e);
                  }}
                />
                <span>Firm does not have a Board of Directors</span>
              </div>
              <div className="percentage-field">
                <h4>Women / Non-Binary</h4>
                <div>
                  <FormInput
                    value={firm.directorsWomen.value}
                    onChange={setDirectorsWomen}
                  />
                  <span>%</span>
                </div>
                {firm.directorsWomen.showError ? (
                  <span className="error-message-percentage contains-error">
                    {firm.directorsWomen.error}
                  </span>
                ) : null}
              </div>
              <div className="percentage-field">
                <h4>People of color</h4>
                <div>
                  <FormInput
                    value={firm.directorsColor.value}
                    onChange={setDirectorsColor}
                  />
                  <span>%</span>
                </div>
                {firm.directorsColor.showError ? (
                  <span className="error-message-percentage contains-error">
                    {firm.directorsColor.error}
                  </span>
                ) : null}
              </div>
            </div>
            <div className="radio-buttons-container">
              <h3>
                Is the Firm a Public Benefit Corporation or B Corp certified?
              </h3>
              <div className="radio-buttons">
                <div>
                  <Input
                    type="radio"
                    name="people-color"
                    checked={
                      firm.publicBenefit.value === true ||
                      firm.publicBenefit.value === "true"
                    }
                    value={true}
                    onChange={setPublicBenefit}
                  />
                  <Label>Yes</Label>
                </div>
                <div>
                  <Input
                    type="radio"
                    name="people-color"
                    checked={
                      firm.publicBenefit.value === false ||
                      firm.publicBenefit.value === "false"
                    }
                    value={false}
                    onChange={setPublicBenefit}
                  />
                  <Label>No</Label>
                </div>
              </div>
              {/* <span className="error-message-radio">error message</span> */}
            </div>
            <FormInput
              label="Number of years the Firm has been licensed to offer advisory services."
              value={firm.yearsLicensed.value}
              error={firm.yearsLicensed.error}
              showError={firm.yearsLicensed.showError}
              onChange={setYearsLicensed}
              maxLength={3}
            />
            <FormInput
              label="Number of years the Firm has offered Impact or values based investment services."
              error={firm.firmOfferedVaNImpactYears.error}
              showError={firm.firmOfferedVaNImpactYears.showError}
              value={firm.firmOfferedVaNImpactYears.value}
              onChange={setFirmOfferedVaNImpactYears}
              maxLength={3}
            />
            <div className="radio-buttons-container radio-buttons-container-firm">
              <h3>Firm offers</h3>
              <div className="radio-buttons">
                <div>
                  <Input
                    type="radio"
                    name="firm-offers"
                    checked={
                      firm.product.value[0] === "sells proprietary products"
                    }
                    value="sells proprietary products"
                    onChange={setProduct}
                  />
                  <Label>Proprietary products</Label>
                </div>
                <div>
                  <Input
                    type="radio"
                    name="firm-offers"
                    checked={
                      firm.product.value[0] === "offers no proprietary products"
                    }
                    value="offers no proprietary products"
                    onChange={setProduct}
                  />
                  <Label>No Proprietary products</Label>
                </div>
              </div>
              {/* <span className="error-message-radio">error message</span> */}
            </div>
            <div className="two-fields-container">
              <div className="position-relative account-size-section">
                <FormInput
                  label="Firm total AUM/AUA"
                  value={firm.aum.value}
                  error={firm.aum.error}
                  showError={firm.aum.showError}
                  onChange={setAum}
                />
                <div className="radio-buttons">
                  <div>
                    <Input
                      type="radio"
                      value="K"
                      checked={firm.aumUnit.value === "K"}
                      onChange={changeAumUnit}
                    />
                    <span>Thousands - K</span>
                  </div>
                  <div>
                    <Input
                      type="radio"
                      value="M"
                      checked={firm.aumUnit.value === "M"}
                      onChange={changeAumUnit}
                    />
                    <span>Millions - M</span>
                  </div>
                  <div>
                    <Input
                      type="radio"
                      value="B"
                      checked={firm.aumUnit.value === "B"}
                      onChange={changeAumUnit}
                    />
                    <span>Billions -B</span>
                  </div>
                </div>
              </div>
              <div className="position-relative account-size-section">
                <FormInput
                  label="Firm approximate $ amount of values aligned AUM/AUA"
                  value={firm.valuesAlignedAum.value}
                  error={firm.valuesAlignedAum.error}
                  showError={firm.valuesAlignedAum.showError}
                  onChange={setValuesAlignedAum}
                />
                <div className="radio-buttons">
                  <div>
                    <Input
                      type="radio"
                      value="K"
                      checked={firm.aumUnit.value === "K"}
                      onChange={changeAumUnit}
                    />
                    <span>Thousands - K</span>
                  </div>
                  <div>
                    <Input
                      type="radio"
                      value="M"
                      checked={firm.aumUnit.value === "M"}
                      onChange={changeAumUnit}
                    />
                    <span>Millions - M</span>
                  </div>
                  <div>
                    <Input
                      type="radio"
                      value="B"
                      checked={firm.aumUnit.value === "B"}
                      onChange={changeAumUnit}
                    />
                    <span>Billions -B</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="two-fields-container">
              <div>
                <FormInput
                  inputStyle={{ borderBottom: "none" }}
                  label="Firm AUM/AUA numbers as of:"
                  focus={true}
                  value={aumUnitAsOfDate}
                  type="date"
                  error={firm.aumUnitAsOfDate.error}
                  showError={firm.aumUnitAsOfDate.showError}
                  onChange={setAumUnitAsOfDate}
                />
              </div>

              <div>
                <FormInput
                  inputStyle={{ borderBottom: "none" }}
                  label="Percent of your Firm AUM/AUA that is values aligned (SRI/ESG/Impact)"
                  value={percentageOfVA}
                  readOnly={true}
                />
              </div>
            </div>
            <FormTextarea
              //maxLength={1080}
              type="textarea"
              label="Past or ongoing litigation, regulatory actions, or government investigations involving the Firm or Team"
              error={firm.governmentInvestigation.error}
              showError={firm.governmentInvestigation.showError}
              value={firm.governmentInvestigation.value}
              onChange={setGovernmentInvestigation}
              className="input-full-line textarea-input"
            />

            <FormTextarea
              //maxLength={1080}
              type="textarea"
              label="Disclosures"
              error={firm.requiredDisclosures.error}
              showError={firm.requiredDisclosures.showError}
              value={firm.requiredDisclosures.value}
              onChange={setRequiredDisclosures}
              className="input-full-line textarea-input"
            />
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default OBAboutFirm;

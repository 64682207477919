import { animateScroll } from 'react-scroll';

function scrollToError(className = 'err-message', offset = 100) {
    const rows = document.getElementsByClassName(className);
    if (rows && rows.length) {
        const firstErrorTop = rows[0].getBoundingClientRect().top + window.pageYOffset;
        animateScroll.scrollTo(firstErrorTop - offset)
    }
}

export default scrollToError;

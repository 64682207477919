import React from "react";

export const SimilarAdvisors = ({ similarAdvisors = [] }) => {
  if (!similarAdvisors.length) {
    return null;
  }
  return (
    <div className="similar no-print-show">
      <div className="uk-container no-right-padding__tablet">
        <div className="uk-grid">
          <div className="uk-width-1-1">
            <h2 className="similar__title">
              SIMILAR PROFILES
            </h2>
          </div>
          <div className="horizontal uk-grid">
            {similarAdvisors.map((advisor) => {
              return (
                <div key={advisor.id} className="uk-width-1-4">
                  <a className="similar__links" href={`/profile/${advisor.id}`}>
                    <div className="similar__card">
                      <img
                        className="similar__profile"
                        src={advisor.profilePic}
                        alt=""
                      />
                      <div className="similar__name">
                        <h3>{advisor.name}</h3>
                        <h4>{advisor.firm.name}</h4>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

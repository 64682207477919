import React, { useEffect, useState } from "react";

import { getSubscriptions } from "../../../api/subscriptions";

const Subscriptions =  ({ subscriptions = [] }) => {
  if (!subscriptions.length) {
    return null;
  }
  const headersKeys = [
    "Name",
    "Email",
    "Customer Type",
    "Date signed up",
    "Subscription expires at",
    "Partner Code",
    "Amount Paid",
  ];
  const headers = headersKeys.map((key) => {
    return <th key={key}>{key}</th>;
  });
  const body = subscriptions.map((obj) => {
    return (
      <tr>
        {headersKeys.map((key) => {
          return <td>{obj[key]}</td>;
        })}
      </tr>
    );
  });
  return (
    <div className="uk-overflow-auto">
      <table className="uk-table uk-table-striped uk-table-hover">
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody className="test">{body}</tbody>
      </table>
    </div>
  );
};
const Container = (props) => {
  const [subscriptions, setSubscriptions] = useState([]);

  const _getSubscriptions = async () => {
    return getSubscriptions().then((response) => response.data);
  };
  useEffect(() => {
    _getSubscriptions().then((data) => {
      setSubscriptions(data);
    });
  }, []);

  return <Subscriptions subscriptions={subscriptions || []}></Subscriptions>;
};

export default Container;

import React from "react";

import { Row, Col } from "reactstrap";
import StatusFilter from "./StatusFilter/StatusFilter";

import Spinner from "../../../components/Spinner/Spinner";
import { ADVISOR_STATUS } from "../../../constants/constants";
import "./Dashboard.css";

const AdvisorRows = ({
  advisorList = [],
  onView = () => {},
  onEditProfile = () => {},
  onDisableProfile = () => {},
  onEnableProfile = () => {},
  onCloneProfile = () => {},
}) => {
  const AdvisorInfo = ({ advisor }) => {
    const differentClients = advisor.teamInfo.differentClients || [];
    const differentClientTypes = differentClients
      .map((client) => client.differentClientType)
      .filter((clientType) => clientType);
    const hasInstitutionalClients = differentClientTypes.some(
      (clientType) =>
        clientType.value.toLowerCase() === "institutional" ||
        clientType.value.toLowerCase() === "endowments/foundations/trusts"
    );
    const hasIndividualClients = differentClientTypes.some(
      (clientType) => clientType.value.toLowerCase() === "individual/families"
    );
    let clientType = "";
    if (hasInstitutionalClients && hasIndividualClients) {
      clientType = "- Institutional & Individual/Families";
    }
    if (hasInstitutionalClients && !hasIndividualClients) {
      clientType = "- Institutional";
    }
    if (!hasInstitutionalClients && hasIndividualClients) {
      clientType = "- Individual/Families";
    }
    return (
      <Col md="9" className="advisor-info">
        <span>
          {advisor.name}

          <React.Fragment>
            <label className="firm-name">
              {`- ${advisor.firm.name} ${clientType}`}{" "}
            </label>
          </React.Fragment>
        </span>
        {advisor ? <p>{advisor.firm.mission}</p> : null}
      </Col>
    );
  };

  const EnableAdvisorButton = ({ isDisabled, advisorId }) => {
    if (!isDisabled) {
      return null;
    }
    return (
      <Col md="3" className="button-action">
        <button
          onClick={(e) => {
            e.preventDefault();

            onEnableProfile(advisorId);
          }}
          className="btn-edit-profile"
        >
          Enable Profile
        </button>
      </Col>
    );
  };

  const EditProfileButton = ({ isApproved, advisorId }) => {
    if (!isApproved) {
      return null;
    }
    return (
      <button
        href=""
        onClick={(e) => {
          e.preventDefault();

          onEditProfile(advisorId);
        }}
        className="btn-edit-profile"
      >
        Edit Profile
      </button>
    );
  };
  const CloneProfileButton = ({ isApproved, advisorId }) => {
    if (!isApproved) {
      return null;
    }
    return (
      <button
        href=""
        onClick={(e) => {
          e.preventDefault();

          onCloneProfile(advisorId);
        }}
        className="btn-edit-profile"
      >
        Clone Profile
      </button>
    );
  };

  const ViewProfileButton = ({ advisorId }) => {
    return (
      <button
        onClick={(e) => {
          e.preventDefault();

          onView(advisorId);
        }}
      >
        View
      </button>
    );
  };

  const DisableAdvisorButton = ({ advisorId }) => {
    return (
      <button
        onClick={(e) => {
          e.preventDefault();

          onDisableProfile(advisorId);
        }}
        className="btn-edit-profile"
      >
        Disable Profile
      </button>
    );
  };

  const AdvisorActionButtons = ({
    isPending,
    isApproved,
    isDisabled,
    isRejected,
    advisorId,
  }) => {
    if (isRejected) {
      return (
        <Col md="3" className="button-action">
          <DisableAdvisorButton advisorId={advisorId} />
        </Col>
      );
    }
    if (isPending || isApproved) {
      return (
        <React.Fragment>
          <Col md="3" className="button-action">
            <ViewProfileButton advisorId={advisorId} />
            <EditProfileButton isApproved={isApproved} advisorId={advisorId} />
            <DisableAdvisorButton advisorId={advisorId} />
            <CloneProfileButton isApproved={isApproved} advisorId={advisorId} />
          </Col>
        </React.Fragment>
      );
    }

    if (isDisabled) {
      return (
        <React.Fragment>
          <Col md="3" className="button-action">
            <ViewProfileButton advisorId={advisorId} />
            <EnableAdvisorButton
              isDisabled={isDisabled}
              advisorId={advisorId}
            />
          </Col>
        </React.Fragment>
      );
    }
  };

  return advisorList.map((advisor, index) => {
    const advisorStatus = advisor.user.status.name;
    const isPending = advisorStatus === ADVISOR_STATUS.PENDING;
    const isApproved = advisorStatus === ADVISOR_STATUS.APPROVED;
    const isDisabled = advisorStatus === ADVISOR_STATUS.DISABLED;
    const isRejected = advisorStatus === ADVISOR_STATUS.REJECTED;
    return (
      <Row
        key={`advisor:${index}`}
        className={
          isPending || isApproved || isDisabled || isRejected
            ? "advisor-info-container"
            : "advisor-info-container advisor-info-without-action"
        }
      >
        <AdvisorInfo advisor={advisor} isPending={isPending} />
        <AdvisorActionButtons
          isPending={isPending}
          isApproved={isApproved}
          advisorId={advisor.id}
          isDisabled={isDisabled}
          isRejected={isRejected}
        />
      </Row>
    );
  });
};
const Dashboard = ({
  fetchAdvisorsList,
  disableAdvisor,
  enableAdvisor,
  tab,
  setTab,
  advisorList,
  loading,
  setAdvisorProfileReview,
  setAvisorProfileUpdate,
  cloneProfile,
}) => {
  let Content = (
    <AdvisorRows
      advisorList={advisorList}
      onView={setAdvisorProfileReview}
      onEditProfile={setAvisorProfileUpdate}
      onEnableProfile={enableAdvisor}
      onDisableProfile={disableAdvisor}
      onCloneProfile={cloneProfile}
    />
  );
  if (loading) {
    Content = <Spinner />;
  }
  if (!advisorList || !advisorList.length) {
    Content = <p className="text-center no-records">No Records</p>;
  }

  return (
    <React.Fragment>
      <section className="admin-dashboard-container">
        <div className="admin-content-container custom-container">
          <StatusFilter
            selectedTab={tab}
            setTab={setTab}
            fetchAdvisorsList={fetchAdvisorsList}
          />
          <section className="filtered-content-container"> {Content}</section>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;

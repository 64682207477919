import React from "react";

const OFFERS_PROPIETARY_PRODUCTS_ID = 1;

export const AboutFirmTab = ({
                                 mission,
                                 employeesWomen,
                                 seniorsWomen,
                                 directorsWomen,
                                 employeesColor,
                                 directorsColor,
                                 seniorsColor,
                                 typicalAccountSize,
                                 isTypicalAccountSizeDisclosable,
                                 typicalAccountSizeUnit,
                                 firmOfferedVaNImpactYears,
                                 yearsLicensed,
                                 valuesAlignedAum,
                                 aumUnit,
                                 aum,
                                 noOfClients,
                                 isNoOfClientsDisclosable,
                                 noOfEmployees,
                                 aumUnitAsOfDate,
                                 products
                             }) => {
    const offersPropietaryProducts = (products || []).find(p => p.productId === OFFERS_PROPIETARY_PRODUCTS_ID)
    const womenCharts = [
        {label: "The Firm", value: employeesWomen, notApplyMessage: "N/A"},
        {label: "Senior Position", value: seniorsWomen, notApplyMessage: "N/A"},
        {label: "Board of Directors", value: directorsWomen, notApplyMessage: "No Board of Directors"},
    ].map((wc) => {
        const content = Number.isFinite(wc.value) ? (
            <div>
                <figure className="pie-chart" style={{background: `radial-gradient( circle closest-side, transparent 100%, white 0 ), conic-gradient( #ff6b00 0, #ff6b00 ${wc.value >= 100 ? 100 : wc.value}%, #e9e9e8 0, #e9e9e8 100% )`}}>
                    <span className="pencentaje">{wc.value}%</span>
                </figure>
            </div>
        ) : (
            <div className="not-apply-message-donut"><span className="pencentaje">{wc.notApplyMessage}</span></div>
        );
        return (
            <div
                key={`${wc.label}-${wc.value || 0}`}
                className="uk-width-1-3"
                style={{textAlign: "center"}}
            >
                <h3 className="chart-title">{wc.label}</h3>
                {content}
            </div>
        );
    });
    const peopleOfColorCharts = [
        {label: "The Firm", value: employeesColor, notApplyMessage: "N/A"},
        {label: "Senior Position", value: seniorsColor, notApplyMessage: "N/A"},
        {label: "Board of Directors", value: directorsColor, notApplyMessage: "No Board of Directors"},
    ].map((wc) => {
        const content = Number.isFinite(wc.value) ? (
            <div>
                <figure className="pie-chart" style={{background: `radial-gradient( circle closest-side, transparent 100%, white 0 ), conic-gradient( #ff6b00 0, #ff6b00 ${wc.value >= 100 ? 100 : wc.value}%, #e9e9e8 0, #e9e9e8 100% )`}}>
                    <span className="pencentaje">{wc.value}%</span>
                </figure>
            </div>
        ) : (
            <div className="not-apply-message-donut"><span className="pencentaje">{wc.notApplyMessage}</span></div>
        );
        return (
            <div
                key={`${wc.label}-${wc.value || 0}`}
                className="uk-width-1-3"
                style={{textAlign: "center"}}
            >
                <h3 className="chart-title">{wc.label}</h3>
                {content}
            </div>
        );
    });
    const percentageOfValuesAligned =
        aum > 0 ? Math.round((valuesAlignedAum / aum) * 100) : 0;
    const valueAlignedDate = aumUnitAsOfDate
        ? new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
        }).format(new Date(aumUnitAsOfDate))
        : "";

    return (
        <li className="firm uk-active">
            <div className="uk-grid">
                <div className="uk-width-1-1@s">
                    <h3 className="generic__title">About the Firm</h3>
                </div>
            </div>
            <div className="uk-grid uk-margin-remove-top uk-margin-remove-bottom">
                <div className="uk-width-2-3@s">
                    <div className="uk-grid">
                        <div className="uk-grid uk-margin">
                            <div className="uk-width-1-1">
                                <p className="firm__parragraf">{mission}</p>
                            </div>
                        </div>
                        <div className="piechart">
                            <div className="uk-grid uk-margin uk-grid--fullscreen">
                                <div className="uk-width-1-1">
                                    <div className="piechart__box">
                                        <h3 className="piechart__title">
                      <span className="text">
                        Percentage of Women / Non-Binary in:
                      </span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-grid uk-grid-small uk-grid-divider">
                                {womenCharts}
                            </div>
                        </div>
                        <div className="piechart">
                            <div className="uk-grid uk-margin uk-grid--fullscreen">
                                <div className="uk-width-1-1">
                                    <div className="piechart__box">
                                        <h3 className="piechart__title">
                      <span className="text">
                        Percentage of People of color in:
                      </span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="uk-grid uk-grid-small uk-grid-divider">
                                {peopleOfColorCharts}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-3@s">
                    <div className="digits">
                        <div className="digits__container">
                            <div>
                                <h3 className="digits__title">Typical Account Size</h3>
                                {isTypicalAccountSizeDisclosable ? (
                                    <h4 className="digits__number">
                                        ${new Intl.NumberFormat("en-US").format(typicalAccountSize)}
                                        {typicalAccountSizeUnit}
                                    </h4>
                                ) : (
                                    <h2 style={{fontSize: "20px"}} className="digits__number">
                                        Not Disclosed / Collected
                                    </h2>
                                )}
                            </div>
                        </div>
                        <div className="digits__container">
                            <div>
                                <h3 className="digits__title">Offers Value-Aligned Services</h3>
                                <h4 className="digits__number">
                                    {firmOfferedVaNImpactYears} Years
                                </h4>
                            </div>
                        </div>
                        <div className="digits__container">
                            <div>
                                <h3 className="digits__title">Offers Advisory Services</h3>
                                <h4 className="digits__number">{yearsLicensed} Years</h4>
                            </div>
                        </div>
                        <div className="digits__container">
                            <div>
                                <h3 className="digits__title">Firm’s Total AUM</h3>
                                <h4 className="digits__number">
                                    $
                                    {new Intl.NumberFormat("en-US").format(
                                        aum > 1000_000 && aumUnit === "M" ? aum / 1000_000 : aum
                                    )}
                                    {aumUnit}
                                </h4>
                            </div>
                        </div>
                        <div className="digits__container">
                            <div>
                                <h3 className="digits__title">
                                    Values-Aligned AUM{" "}
                                    {valueAlignedDate && (
                                        <React.Fragment>as of {valueAlignedDate}</React.Fragment>
                                    )}
                                </h3>
                                <h4 className="digits__number">
                                    ${new Intl.NumberFormat("en-US").format(
                                    valuesAlignedAum > 1000_000 && aumUnit === "M" ? valuesAlignedAum / 1000_000 : valuesAlignedAum//valuesAlignedAum
                                )}
                                    {aumUnit}</h4>
                            </div>
                        </div>
                        <div className="digits__container">
                            <div>
                                <h3 className="digits__title">% of Values-Aligned AUM</h3>
                                <h4 className="digits__number">{percentageOfValuesAligned}%</h4>
                            </div>
                        </div>
                        <div className="digits__container">
                            <div>
                                <h3 className="digits__title">Total Employees</h3>
                                <h4 className="digits__number">{noOfEmployees}</h4>
                            </div>
                        </div>
                        <div className="digits__container --last-odd">
                            <div>
                                <h3 className="digits__title">Total Clients</h3>

                                {isNoOfClientsDisclosable ? (
                                    <h4 className="digits__number">{noOfClients}</h4>
                                ) : (
                                    <h2 style={{fontSize: "20px"}} className="digits__number">
                                        Not Disclosed / Collected
                                    </h2>
                                )}
                            </div>
                        </div>
                        <div className="digits__container">
                            <div>
                                <h3 className="digits__title">Offers Proprietary Products</h3>
                                <h4 className="digits__number">
                                    {offersPropietaryProducts ? (
                                        "Yes"
                                    ) : (
                                        "No"
                                    )}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};
import initialStates from "../states/onBoardingForm";
import createNamespace from "../commons/createNamespace";
import { combineReducers } from "redux";
import validateAllFields from "../validations/validateAllFields";
import getStartedOtherValidations from "../validations/getStartedOtherValidations";
import {
  addressTemplate,
  socialLinkTemplate,
  createMaterialTemplate,
} from "../commons/stateTemplates";
import {
  firmMapper,
  addressMapper,
  teamInfoMapper,
  getStartedMapper,
  independentMapper,
  valuesAlignedInvestingInfoMapper,
} from "../commons/onBoardingStateMapper";
import populateRejectDataCreator from "../utils/populateRejectDataCreator";

const formNameSpacer = createNamespace("ON_BOARDING_FORM");
const getStartedNameSpacer = createNamespace(formNameSpacer("GET_STARTED"));
const firmNameSpacer = createNamespace(formNameSpacer("FIRM"));
const teamInfoNameSpacer = createNamespace(formNameSpacer("TEAM_INFO"));
const valuesAlignedNameSpacer = createNamespace(
  formNameSpacer("VALUES_ALIGNED")
);
const typeListNameSpacer = createNamespace(formNameSpacer("TYPES"));

const getStartedReducer = (
  state = {
    ...initialStates.getStarted,
    address: [
      {
        ...addressTemplate,
        primary: true,
      },
      {
        ...addressTemplate,
      },
    ],
    socialLink: [],
  },
  action
) => {
  for (const key in getStartedMapper) {
    if (action.type === getStartedNameSpacer(`SET_${key}`)) {
      return {
        ...state,
        [getStartedMapper[key]]: {
          ...state[getStartedMapper[key]],
          value: action.payload.value,
        },
      };
    }
  }

  for (const key in addressMapper) {
    if (action.type === getStartedNameSpacer(`SET_${key}`)) {
      return {
        ...state,
        address: [
          ...state.address.slice(0, action.payload.index),
          {
            ...state.address[action.payload.index],
            [addressMapper[key]]: {
              ...state.address[action.payload.index][addressMapper[key]],
              value: action.payload.value,
            },
          },
          ...state.address.slice(action.payload.index + 1),
        ],
      };
    }
  }

  switch (action.type) {
    case formNameSpacer("CLEAR_STATE"):
      return {
        ...{
          ...initialStates.getStarted,
          address: [
            {
              ...addressTemplate,
              primary: true,
            },
            {
              ...addressTemplate,
            },
          ],
          socialLink: [],
        },
      };
    case getStartedNameSpacer("SET_PROFILE_PIC_LIMIT_ERROR"):
      return {
        ...state,
        profilePic: {
          ...state.profilePic,
          showError: true,
          error: "Max allowed size is 5MB",
        },
      };
    case getStartedNameSpacer("SET_PIC_DIMENSION_ERROR"):
      return {
        ...state,
        profilePic: {
          ...state.profilePic,
          showError: true,
          error: "Upload an image with minimum size of 506 X 308 pixels.",
        },
      };

    case getStartedNameSpacer("SET_CURRENT_ADV_LIMIT_ERROR"):
      return {
        ...state,
        currentADV: {
          ...state.currentADV,
          showError: true,
          error: "Max allowed size is 10MB",
        },
      };

    case getStartedNameSpacer("SET_SOCIAL_LINK_TYPE"):
      return {
        ...state,
        socialLink: [
          ...state.socialLink.slice(0, action.payload.index),
          {
            ...state.socialLink[action.payload.index],
            name: {
              ...state.socialLink[action.payload.index].name,
              value: action.payload.value,
            },
          },
          ...state.socialLink.slice(action.payload.index + 1),
        ],
      };

    case getStartedNameSpacer("SET_SOCIAL_LINK_URL"):
      return {
        ...state,
        socialLink: [
          ...state.socialLink.slice(0, action.payload.index),
          {
            ...state.socialLink[action.payload.index],
            link: {
              ...state.socialLink[action.payload.index].link,
              value: action.payload.value,
            },
          },
          ...state.socialLink.slice(action.payload.index + 1),
        ],
      };

    case getStartedNameSpacer("SET_EMAIL_SERVER_ERROR"):
      const alreadyEmailError = state.email.showError;
      return {
        ...state,
        email: {
          ...state.email,
          showError: true,
          error: action.payload.value,
        },
        errorCount: alreadyEmailError ? state.errorCount : state.errorCount + 1,
      };

    case getStartedNameSpacer("SET_DEGREES"):
      return {
        ...state,
        degrees: {
          ...state.degrees,
          value: [...action.payload.value],
        },
      };
    case getStartedNameSpacer("SET_SUGGESTED_BY_MEMBER"):
      return {
        ...state,
        suggestedByMembers: {
          ...state.suggestedByMembers,
          value: [...action.payload.value],
        },
      };

    case getStartedNameSpacer("SET_PROFILE_PIC"):
      return {
        ...state,
        profilePic: {
          ...state.profilePic,
          value: action.payload.value,
          src: "",
        },
      };
    case getStartedNameSpacer("VALIDATE_FIELDS"):
      const otherValidations = getStartedOtherValidations(state);
      return {
        ...otherValidations.newState,
        email: {
          ...otherValidations.email,
        },
        password: {
          ...otherValidations.password,
        },
        address: [...otherValidations.address],
        socialLink: [...otherValidations.socialLink],
        website: {
          ...otherValidations.website,
        },
        profilePic: {
          ...otherValidations.profilePic,
        },
        errorCount:
          otherValidations.newState.errorCount + otherValidations.errorCount,
      };

    case getStartedNameSpacer("INCREMENT_ADDRESS_COUNTER"):
      return {
        ...state,
        addressCounter: state.addressCounter + 1,
        address: [
          ...state.address,
          {
            ...addressTemplate,
          },
        ],
      };

    case getStartedNameSpacer("INCREMENT_SOCIAL_LINK_COUNTER"):
      return {
        ...state,
        socialLinkCounter: state.socialLinkCounter + 1,
        socialLink: [
          ...state.socialLink,
          {
            ...socialLinkTemplate,
          },
        ],
      };

    case getStartedNameSpacer("CLEAR_ERROR_COUNT"):
      return {
        ...state,
        address: [
          ...state.address.map((singleAddress) => {
            return {
              ...singleAddress,
              errorCount: 0,
            };
          }),
        ],
        socialLink: [
          ...state.socialLink.map((singleSocialLink) => {
            return {
              ...singleSocialLink,
              errorCount: 0,
            };
          }),
        ],
        errorCount: 0,
      };

    case getStartedNameSpacer("SET_LOCAL_STORAGE_STATE"):
      return {
        ...action.payload.value,
      };

    case getStartedNameSpacer("POPULATE_REJECTED_DATA"):
      const result = populateRejectDataCreator(
        getStartedMapper,
        action.payload,
        state
      );
      return {
        ...state,
        ...result,
      };

    case getStartedNameSpacer("POPULATE_ADDRESS"):
      const remainingAddress = (addresses) => {
        const result = [];
        for (let index = 0; index < 2 - addresses.length; index++) {
          result.push(addressTemplate);
        }
        return result;
      };
      return {
        ...state,
        address: [
          ...action.payload.addresses.map((address) => {
            return {
              ...populateRejectDataCreator(
                addressMapper,
                address,
                addressTemplate
              ),
              primary: !!address.primary,
              errorCount: 0,
            };
          }),
          ...remainingAddress(action.payload.addresses),
        ],
      };
    case getStartedNameSpacer("POPULATE_PROFILE_PIC"):
      return {
        ...state,
        profilePic: {
          ...state.profilePic,
          value: action.payload.value,
        },
      };
    case formNameSpacer("SET_PROFILE_PIC_SRC"):
      return {
        ...state,
        profilePic: profilePicReducer(state.profilePic, action),
      };
    case formNameSpacer("SET_PROFILE_PIC_FILE"):
      return {
        ...state,
        profilePic: profilePicReducer(state.profilePic, action),
      };
    case formNameSpacer("SET_PREV_PROFILE_PHOTO"):
      return {
        ...state,
        prevProfilePic: PrevProfilePhotoReducer(state.prevProfilePic, action),
      };
    case formNameSpacer("SET_PROFILE_PIC_CROPPED"):
      return {
        ...state,
        profilePic: profilePicReducer(state.profilePic, action),
      };

    case formNameSpacer("RESET_PROFILE_PIC"):
      return {
        ...state,
        profilePic:
          localStorage.getItem("role") === "admin"
            ? state.prevProfilePic
            : initialStates.getStarted.profilePic,
      };
    case formNameSpacer("SET_PROFILE_PIC_NAME"):
      return {
        ...state,
        profilePic: profilePicReducer(state.profilePic, action),
      };
    case getStartedNameSpacer("REMOVE_ADDRESS_INDEX"):
      return {
        ...state,
        addressCounter: state.addressCounter - 1,
        address: [
          ...state.address.slice(0, action.payload.index),
          ...state.address.slice(action.payload.index + 1),
        ],
      };

    case getStartedNameSpacer("REMOVE_SOCIAL_LINK_INDEX"):
      return {
        ...state,
        socialLinkCounter: state.socialLinkCounter - 1,
        socialLink: [
          ...state.socialLink.slice(0, action.payload.index),
          ...state.socialLink.slice(action.payload.index + 1),
        ],
      };

    default:
      return {
        ...state,
      };
  }
};

const firmReducer = (state = initialStates.firm, action) => {
  for (const key in firmMapper) {
    if (action.type === firmNameSpacer(`SET_${key}`)) {
      return {
        ...state,
        [firmMapper[key]]: {
          ...state[firmMapper[key]],
          value: action.payload.value,
          label: action.payload.label,
        },
      };
    }
  }

  switch (action.type) {
    case formNameSpacer("CLEAR_STATE"):
      return {
        ...initialStates.firm,
      };
    case firmNameSpacer("SET_PRODUCT"):
      return {
        ...state,
        product: {
          ...state.product,
          value: [action.payload.value],
        },
      };

    case firmNameSpacer("VALIDATE_FIELDS"):
      return {
        ...validateAllFields(state),
      };
    case firmNameSpacer("SET_DIRECTOR_NA"):
      return {
        ...state,
        isDirectorNA: action.payload.value,
      };
    case firmNameSpacer("SET_EMPLOYEE_NA"):
      return {
        ...state,
        isEmployeeNA: action.payload.value,
      };
    case firmNameSpacer("SET_SENIOR_NA"):
      return {
        ...state,
        isSeniorNA: action.payload.value,
      };
    case firmNameSpacer("SET_IS_TYPICAL_ACCOUNT_SIZE_DISCLOSABLE"):
      return {
        ...state,
        isTypicalAccountSizeDisclosable: action.payload.value,
      };
      case firmNameSpacer("SET_IS_NO_CLIENTS_DISCLOSABLE"):
        return {
          ...state,
          isNoOfClientsDisclosable: action.payload.value,
        };
    case firmNameSpacer("CLEAR_ERROR_COUNT"):
      return {
        ...state,
        errorCount: 0,
      };

    case firmNameSpacer("SET_LOCAL_STORAGE_STATE"):
      return {
        ...action.payload.value,
      };

    case firmNameSpacer("POPULATE_REJECTED_DATA"):
      const result = populateRejectDataCreator(
        firmMapper,
        action.payload,
        state
      );
      return {
        ...state,
        ...result,
      };

    default:
      return state;
  }
};

const teamInfoReducer = (state = initialStates.teamInfo, action) => {
  for (const key in teamInfoMapper) {
    if (action.type === teamInfoNameSpacer(`SET_${key}`)) {
      return {
        ...state,
        [teamInfoMapper[key]]: {
          ...state[teamInfoMapper[key]],
          value: action.payload.value,
        },
      };
    }
  }

  for (const key in independentMapper) {
    if (action.type === teamInfoNameSpacer(`SET_${key}`)) {
      return {
        ...state,
        [independentMapper[key]]: {
          ...state[independentMapper[key]],
          value: [...action.payload.value],
        },
      };
    }
  }

  switch (action.type) {
    case formNameSpacer("CLEAR_STATE"):
      return {
        ...initialStates.teamInfo,
      };
    case teamInfoNameSpacer("VALIDATE_FIELDS"):
      return {
        ...validateAllFields(state),
      };

    case teamInfoNameSpacer("CLEAR_ERROR_COUNT"):
      return {
        ...state,
        errorCount: 0,
      };

    case teamInfoNameSpacer("SET_LOCAL_STORAGE_STATE"):
      return {
        ...action.payload.value,
      };

    case teamInfoNameSpacer("POPULATE_REJECTED_DATA"):
      const result = populateRejectDataCreator(
        teamInfoMapper,
        action.payload,
        state
      );
      return {
        ...state,
        ...result,
      };
    case teamInfoNameSpacer("SET_TEAM_DEGREES"):
      return {
        ...state,
        degrees: {
          ...state.degrees,
          value: [...action.payload.value],
        },
      };
    case teamInfoNameSpacer("SET_IS_NO_CLIENTS_DISCLOSABLE"):
        return {
          ...state,
          isNoOfClientsDisclosable: action.payload.value,
        };
    case teamInfoNameSpacer("SET_IS_TYPICAL_ACCOUNT_SIZE_DISCLOSABLE"):
          return {
            ...state,
            isTypicalAccountSizeDisclosable: action.payload.value,
          };
    default:
      return state;
  }
};

const valuesAlignedInvestingReducer = (
  state = initialStates.valuesAlignedInvestingInfo,
  action
) => {
  for (const key in valuesAlignedInvestingInfoMapper) {
    if (action.type === valuesAlignedNameSpacer(`SET_${key}`)) {
      return {
        ...state,
        [valuesAlignedInvestingInfoMapper[key]]: {
          ...state[valuesAlignedInvestingInfoMapper[key]],
          value: action.payload.value,
        },
      };
    }
  }

  switch (action.type) {
    case formNameSpacer("CLEAR_STATE"):
      return {
        ...initialStates.valuesAlignedInvestingInfo,
      };
    case valuesAlignedNameSpacer("SET_PROXY_VOTING"):
      return {
        ...state,
        proxyVoting: {
          ...state.proxyVoting,
          value: [...action.payload.value],
        },
      };

    case valuesAlignedNameSpacer("VALIDATE_FIELDS"):
      return {
        ...validateAllFields(state),
      };

    case valuesAlignedNameSpacer("CLEAR_ERROR_COUNT"):
      return {
        ...state,
        errorCount: 0,
      };

    case valuesAlignedNameSpacer("SET_LOCAL_STORAGE_STATE"):
      return {
        ...action.payload.value,
      };

    case valuesAlignedNameSpacer("POPULATE_REJECTED_DATA"):
      const result = populateRejectDataCreator(
        valuesAlignedInvestingInfoMapper,
        action.payload,
        state
      );
      return {
        ...state,
        ...result,
      };

    default:
      return state;
  }
};

const materialReducer = (
  state = [...initialStates.material, ...createMaterialTemplate()],
  action
) => {
  switch (action.type) {
    case formNameSpacer("CLEAR_STATE"):
      return [...initialStates.material, ...createMaterialTemplate()];

    case formNameSpacer("SET_MATERIAL"):
      return [
        ...state.slice(0, action.payload.index),
        {
          ...state[action.payload.index],
          name: {
            ...state[action.payload.index].name,
            value: action.payload.name,
          },
          link: {
            ...state[action.payload.index].link,
            value: action.payload.link,
          },
        },
        ...state.slice(action.payload.index + 1),
      ];

    case formNameSpacer("POPULATE_REJECTED_DATA"):
      return {
        ...action.payload.value,
      };

    case formNameSpacer("SET_MATERIAL_LIMIT_ERROR"):
      return [
        ...state.slice(0, action.payload.index),
        {
          ...state[action.payload.index],
          link: {
            ...state[action.payload.index].link,
            showError: true,
            error: "Max allowed size is 10MB",
          },
        },
        ...state.slice(action.payload.index + 1),
      ];

    default:
      return state;
  }
};
/*this reducers returns the new list of richMediaLinks that is attached to the state*/
const mediaLinkReducer = (state = initialStates.richMediaLinks, action) => {
  const newState = [...state];
  switch (action.type) {
    case formNameSpacer("ADD_RICH_MEDIA_LINK"):
      newState.push(action.payload.value);
      return newState;
    case formNameSpacer("UPDATE_RICH_MEDIA_LINK"):
      newState[action.payload.itemIndex] = action.payload.value;
      return newState;
    case formNameSpacer("REMOVE_RICH_MEDIA_LINK"):
      return newState.filter((item, indx) => action.payload.itemIndex !== indx);
    case formNameSpacer("SET_RICH_MEDIA_LINK_ERROR"):
      newState[action.payload.itemIndex] = {
        ...action.payload.value,
        error: action.payload.error,
      };
      return newState;
    default:
      return state;
  }
};

const typeReducer = (state = initialStates.typeList, action) => {
  switch (action.type) {
    case formNameSpacer("CLEAR_STATE"):
      return {
        ...initialStates.typeList,
      };
    case typeListNameSpacer("GET_ALL_TYPES"):
      return {
        ...state,
        ...action.payload.value,
        allTypeFetched: true,
      };

    default:
      return state;
  }
};

const onBoardingReducer = (state = initialStates.form, action) => {
  switch (action.type) {
    case formNameSpacer("CLEAR_STATE"):
      return {
        ...initialStates.form,
      };
    case formNameSpacer("TOGGLE_TAB"):
      return {
        ...state,
        tab: action.payload.value,
      };

    case formNameSpacer("DISABLE_SUBMIT"):
      return {
        ...state,
        disableSubmit: action.payload.value,
      };

    case formNameSpacer("SUBMIT_SUCCESS"):
      return {
        ...state,
        submitSuccess: action.payload.value,
      };

    case formNameSpacer("UPDATE"):
      return {
        ...state,
        update: action.payload.value,
      };

    case formNameSpacer("SET_LOADING"):
      return {
        ...state,
        loading: action.payload.value,
      };

    default:
      return state;
  }
};

const profilePicReducer = (
  state = initialStates.getStarted.profilePic,
  action
) => {
  switch (action.type) {
    case formNameSpacer("CLEAR_STATE"):
      return {
        ...initialStates.getStarted.prevProfilePic,
      };
    case formNameSpacer("SET_PROFILE_PIC_SRC"):
      return {
        ...state,
        src: action.payload.value,
      };

    case formNameSpacer("SET_PROFILE_PIC_FILE"):
      return {
        ...state,
        file: action.payload.value,
      };

    case formNameSpacer("SET_PROFILE_PIC_CROPPED"):
      return {
        ...state,
        croppedImageUrl: action.payload.value,
        value: action.payload.value,
      };

    case formNameSpacer("SET_PROFILE_PIC_NAME"):
      return {
        ...state,
        name: action.payload.value,
      };

    default:
      return state;
  }
};

const PrevProfilePhotoReducer = (
  state = initialStates.getStarted.prevProfilePic,
  action
) => {
  switch (action.type) {
    case formNameSpacer("SET_PREV_PROFILE_PHOTO"):
      return {
        ...state,
        value: action.payload.value,
      };
    default:
      return state;
  }
};

const onBoardingForm = combineReducers({
  firm: firmReducer,
  form: onBoardingReducer,
  type: typeReducer,
  material: materialReducer,
  teamInfo: teamInfoReducer,
  getStarted: getStartedReducer,
  valuesAlignedInvestingInfo: valuesAlignedInvestingReducer,
  richMediaLinks: mediaLinkReducer,
});

export default onBoardingForm;

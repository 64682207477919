import React, { useContext } from "react";
import { Form } from "reactstrap";
import FormTextarea from "../../../components/_shared/FormTextarea";
//import MultiSelect from '../../_shared/FormCheckbox';
//import cx from 'classnames';
import FormSelect from "../../../components/_shared/FormSelect";
import { FADashboardContext } from "../Context";

const OBavai = () => {
  const { valuesAlignedInvestingInfo, typeList } =
    useContext(FADashboardContext);
  const { proxyVotingType } = typeList;
  const setVoteProxies = (e) => {
    valuesAlignedInvestingInfo.setVoteProxies(e.target.value);
  };

  const setImpactStrategy = (e) => {
    valuesAlignedInvestingInfo.setImpactStrategy(e.target.value);
  };

  const setAssetAllocation = (e) => {
    valuesAlignedInvestingInfo.setAssetAllocation(e.target.value);
  };

  const setProxyVotingType = (e) => {
    valuesAlignedInvestingInfo.setProxyVoting(e);
  };

  const setImpactInvestment = (e) => {
    valuesAlignedInvestingInfo.setImpactInvestment(e.target.value);
  };

  const setMeasureAndReport = (e) => {
    valuesAlignedInvestingInfo.setMeasureAndReport(e.target.value);
  };

  const setInterestedInImpact = (e) => {
    valuesAlignedInvestingInfo.setInterestedInImpact(e.target.value);
  };

  const setMeaningfulInvestment = (e) => {
    valuesAlignedInvestingInfo.setMeaningfulInvestment(e.target.value);
  };

  const setDescOfWhatClientCares = (e) => {
    valuesAlignedInvestingInfo.setDescOfWhatClientCares(e.target.value);
  };

  const setEngagedWithManagement = (e) => {
    valuesAlignedInvestingInfo.setEngagedWithManagement(e.target.value);
  };

  const setHelpClientMakeBetterDecision = (e) => {
    valuesAlignedInvestingInfo.setHelpClientMakeBetterDecision(e.target.value);
  };
  return (
    <div className="FA-form-container">
      <Form className="FA-form">
        <div className="FA-form-fields FA-form4">
          <FormTextarea
            type="textarea"
            label="Our Firm’s founder became interested in impact and values aligned investing:"
            maxLength={1000}
            error={valuesAlignedInvestingInfo.interestedInImpact.error}
            showError={valuesAlignedInvestingInfo.interestedInImpact.showError}
            value={valuesAlignedInvestingInfo.interestedInImpact.value}
            onChange={setInterestedInImpact}
            className="input-full-line textarea-input"
          />

          <FormTextarea
            type="textarea"
            label="Our team’s investment philosophy (e.g. active versus passive) and style is:"
            maxLength={1000}
            error={valuesAlignedInvestingInfo.meaningfulInvestment.error}
            showError={
              valuesAlignedInvestingInfo.meaningfulInvestment.showError
            }
            value={valuesAlignedInvestingInfo.meaningfulInvestment.value}
            onChange={setMeaningfulInvestment}
            className="input-full-line textarea-input"
          />

          <FormTextarea
            type="textarea"
            label="A client can expect the following communications, measurement and reporting on their social results"
            maxLength={1000}
            error={valuesAlignedInvestingInfo.assetAllocation.error}
            showError={valuesAlignedInvestingInfo.assetAllocation.showError}
            value={valuesAlignedInvestingInfo.assetAllocation.value}
            onChange={setAssetAllocation}
            className="input-full-line textarea-input"
          />

          <FormTextarea
            type="textarea"
            label=" An example of an investment that had significant meaning for a client is:"
            maxLength={1000}
            error={valuesAlignedInvestingInfo.descOfWhatClientCares.error}
            showError={
              valuesAlignedInvestingInfo.descOfWhatClientCares.showError
            }
            value={valuesAlignedInvestingInfo.descOfWhatClientCares.value}
            onChange={setDescOfWhatClientCares}
            className="input-full-line textarea-input"
          />

          <FormTextarea
            type="textarea"
            label="Our Team identifies, evaluates, and selects investment opportunities in the following ways:"
            maxLength={1000}
            error={valuesAlignedInvestingInfo.measureAndReport.error}
            showError={valuesAlignedInvestingInfo.measureAndReport.showError}
            value={valuesAlignedInvestingInfo.measureAndReport.value}
            onChange={setMeasureAndReport}
            className="input-full-line textarea-input"
          />

          <FormTextarea
            type="textarea"
            label="Our Team has experience using UN SDGs goals to track social impact in the following ways, if applicable:"
            maxLength={1000}
            error={valuesAlignedInvestingInfo.impactStrategy.error}
            showError={valuesAlignedInvestingInfo.impactStrategy.showError}
            value={valuesAlignedInvestingInfo.impactStrategy.value}
            onChange={setImpactStrategy}
            className="input-full-line textarea-input"
          />

          <FormTextarea
            type="textarea"
            label="How and how often the advisor communicates about meeting financial and impact investment goals."
            maxLength={1000}
            error={valuesAlignedInvestingInfo.impactInvestment.error}
            showError={valuesAlignedInvestingInfo.impactInvestment.showError}
            value={valuesAlignedInvestingInfo.impactInvestment.value}
            onChange={setImpactInvestment}
            className="input-full-line textarea-input"
          />
          <FormTextarea
            type="textarea"
            label="The resources the advisor has available to help clients make better decisions."
            maxLength={1000}
            error={
              valuesAlignedInvestingInfo.helpClientMakeBetterDecision.error
            }
            showError={
              valuesAlignedInvestingInfo.helpClientMakeBetterDecision.showError
            }
            value={
              valuesAlignedInvestingInfo.helpClientMakeBetterDecision.value
            }
            onChange={setHelpClientMakeBetterDecision}
            className="input-full-line textarea-input"
          />

          <FormTextarea
            type="textarea"
            label="How the advisor enables their clients to vote their proxies."
            maxLength={1000}
            error={valuesAlignedInvestingInfo.voteProxies.error}
            showError={valuesAlignedInvestingInfo.voteProxies.showError}
            value={valuesAlignedInvestingInfo.voteProxies.value}
            onChange={setVoteProxies}
            className="input-full-line textarea-input"
          />
          <FormTextarea
            type="textarea"
            label="An example of how our Team has engaged with company management, filed or co-filed an ESG related shareholder resolution, if applicable, is:"
            maxLength={1000}
            error={valuesAlignedInvestingInfo.engagedWithManagement.error}
            showError={
              valuesAlignedInvestingInfo.engagedWithManagement.showError
            }
            value={valuesAlignedInvestingInfo.engagedWithManagement.value}
            onChange={setEngagedWithManagement}
            className="input-full-line textarea-input"
          />
          <div className="container-SM-links">
            <FormSelect
              style={{ width: "100%" }}
              options={proxyVotingType}
              value={valuesAlignedInvestingInfo.proxyVoting.value}
              onChange={setProxyVotingType}
              isMulti={true}
              error={valuesAlignedInvestingInfo.proxyVoting.error}
              showError={valuesAlignedInvestingInfo.proxyVoting.showError}
              label="Our Team enables proxy voting and Shareholder Advocacy in the following ways (check all that apply)"
              labelClass="label-name"
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
export default OBavai;

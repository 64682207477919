import React from "react";
import classes from "./FirmSizeIcon.module.css";
export const FirmSizeIcon = ({ firmSize, textClassName }) => {
  let icon = "";
  let postText = "Firm’s AUM ";
  switch (firmSize.value) {
    case "boutique <125m":
      icon = "Company Sizes - S";
      postText += "< 125m".toUpperCase();
      break;
    case "medium 125m-1b":
      icon = "Company Sizes - M";
      postText += "125m-1b".toUpperCase();
      break;
    case "large 1b-5b":
      icon = "Company Sizes - L";
      postText += "1b-5b".toUpperCase();
      break;
    case "very large 5b+":
      icon = "Company Sizes - XL";
      postText += "5b+".toUpperCase();
      break;
    default:
      return null;
  }
  return (
    <React.Fragment>
      
      <div className="info">
          <img className={classes.img} src={`img/icons/${icon}.svg`} alt="" />
          <div className="firm__tooltip" id="firm__tooltip1">
            <span>{postText}</span>
          </div>
        </div>
        <p className={textClassName}>{postText}</p>
    </React.Fragment>
  );
};

const modal = {
    signInModal: window.location.pathname === '/sign-in',
    surveyModal: false,
    firstVisitModal: false,
    profilePicModal: false,
    profileReviewModal: false,
    profileSubmitModal: false,
    resetPasswordModal: false,
    acceptAdvisorModal: false,
    rejectAdvisorModal: false,
    forgotPasswordModal: false,
    surveyDownloadModal: false,
}
export default modal 
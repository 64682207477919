import React from "react";
import Button from "../_shared/UI/Button/Button";
const loginform = (props) => {
  const setEmail = (event) => {
    props.setEmail(event.target.value);
  };

  const setPassword = (event) => {
    props.setPassword(event.target.value);
  };
  return (
    <form
      className="login__form"
      onSubmit={async (e) => {
        e.preventDefault();
        await props.submit();
      }}
    >
      <div className="uk-grid">
        <div className="form-group uk-width-1-1">
          <label htmlFor={"Email-ID"} className="input-title">
            EMAIL / USER NAME
          </label>
          <input
            className="uk-input input-box"
            type="text"
            name="Email-ID"
            placeholder=""
            value={props.email.value}
            onChange={setEmail}
          />
        </div>
        {props.email.showError ? (
          <p className="error-message">{props.email.error}</p>
        ) : null}
        <div className="form-group form-group--no-margin uk-width-1-1">
          <label htmlFor="Password" className="input-title">
            PASSWORD
          </label>
          <input
            className="uk-input input-box"
            type="password"
            name="Password"
            placeholder=""
            value={props.password.value}
            onChange={setPassword}
          />
        </div>
        {props.password.showError ? (
          <p className="error-message">{props.password.error}</p>
        ) : null}
        <div className="uk-width-1-1">
          <a className="forgot-password" href="/forgot-password">
            Forgot Password?
          </a>
        </div>
        <div className="form-group">
          <Button type="submit" buttonType="primary">  Sign In </Button>
        </div>
        <div>
          <span style={{ display: "block" }}>
            Don’t have an account or not a member of one of our affinity groups?{" "}
            <a className="join-now" href="/join">
              Join Now
            </a>
            {/*If you do not have an affinity group password, please email{" "}*/}
            {/*<a href="info@valuesadvisor.com">info@valuesadvisor.com</a>*/}
          </span>
        </div>
      </div>
    </form>
  );
};

export default loginform
import { Address } from "./address";

export class User {
  statusId = 0;
}

export class FirmSizeType {
  id = 0;
  label = "";
  value = "";
  isApproved = false;
  createdAt = null;
  updatedAt = null;
}

export class Firm {
  id = 0;
  advisorId = 0;
  firmSizeId = 0;
  name = "";
  unit = "";
  mission = "";
  aumUnit = "";
  aumUnitAsOfDate = null;
  minimumAccountSize = 0;
  typicalAccountSize = 0;
  typicalAccountSizeUnit = "";
  firmOfferedVaNImpactYears = 0;
  noOfClients = 0;
  noOfEmployees = 0;
  employeesWomen = 0;
  employeesColor = 0;
  seniorsWomen = 0;
  seniorsColor = 0;
  directorsWomen = 0;
  directorsColor = 0;
  publicBenefit = false;
  yearsLicensed = 0;
  aum = 0;
  valuesAlignedAum = 0;
  governmentInvestigation = "";
  requiredDisclosures = "";
  createdAt = null;
  updatedAt = null;
  firmSizeType = new FirmSizeType();
  products = []
}

export class ProxyVoting {
  id = 0;
  investingInfoId = 0;
  proxyVotingId = 0;
  createdAt = null;
  updatedAt = null;
}

export class ValuesAlignedInvestingInfo {
  id = 0;
  advisorId = 0;
  interestedInImpact = "";
  meaningfulInvestment = "";
  assetAllocation = "";
  descOfWhatClientCares = "";
  measureAndReport = "";
  impactStrategy = "";
  impactInvestment = "";
  helpClientMakeBetterDecision = "";
  relationWithThreshold = "";
  voteProxies = "";
  engagedWithManagement = "";
  createdAt = null;
  updatedAt = null;
  proxyVotings = [];
}

export class ServingCountry {
  id = 0;
  teamInfoId = 0;
  servingCountryId = 0;
  createdAt = null;
  updatedAt = null;
}

export class AdvisorOffering {
  id = 0;
  teamInfoId = 0;
  advisorOfferingId = 0;
  createdAt = null;
  updatedAt = null;
}

export class ImpactTheme {
  id = 0;
  teamInfoId = 0;
  impactThemeId = 0;
  createdAt = null;
  updatedAt = null;
}

export class AssetClass {
  id = 0;
  teamInfoId = 0;
  assetClassId = 0;
  createdAt = null;
  updatedAt = null;
}

export class DifferentClient {
  id = 0;
  teamInfoId = 0;
  clientId = 0;
  createdAt = null;
  updatedAt = null;
}

export class CompensationStructure {
  id = 0;
  teamInfoId = 0;
  compensationStructureId = 0;
  createdAt = null;
  updatedAt = null;
}

export class Affiliation {
  id = 0;
  teamInfoId = 0;
  affiliationId = 0;
  createdAt = null;
  updatedAt = null;
}

export class TeamInfo {
  get percentageOfValuesAligned(){
    return this.aum > 0 ? Math.round((this.valuesAlignedAum / this.aum) * 100) : 0;
  }
  id = 0;
  advisorId = 0;
  aum = 0;
  aumUnit = "";
  aumUnitAsOfDate = null;
  valuesAlignedAum = 0;
  noOfPeople = 0;
  noOfClients = 0;
  impactField = "";
  clientAccountSize = 0;
  employeesColor = 0;
  employeesWomen = 0;
  clientAccountSizeUnit = "";
  teamOfferedVaNImpactYears = 0;
  createdAt = null;
  updatedAt = null;
  servingCountries = [];
  advisorOfferings = [];
  impactThemes = [];
  assetClasses = [];
  teamInfoDegrees = [];
  differentClients = [];
  compensationStructures = [];
  affiliations = [];
}

export class Advisor {
  /**
   * Represents an Advisor.
   * @constructor
   * @param {Object} advisorObj - JSON Object that contains advisor data.
   */
  constructor(advisorObj = {}) {
    Object.assign(this, advisorObj);
    this.addresses = this.addresses.map((a) => Object.assign(new Address(), a));
    this.firm = Object.assign(new Firm(), this.firm);
    this.teamInfo = Object.assign(new TeamInfo(), this.teamInfo);
    this.valuesAlignedInvestingInfo = Object.assign(
      new ValuesAlignedInvestingInfo(),
      this.valuesAlignedInvestingInfo
    );
  }
  id = 0;
  userId = 0;
  name = "";
  title = "";
  profilePic = "";
  profilePicInfo = "";
  contactNumber = "";
  suggestedBy = "";
  yearsWithFirm = 0;
  yearsAsAdvisor = 0;
  currentADV = "";
  website = "";
  createdAt = null;
  updatedAt = null;
  user = new User();
  firm = new Firm();
  valuesAlignedInvestingInfo = new ValuesAlignedInvestingInfo();
  teamInfo = new TeamInfo();
  addresses = [];
  suggestedByMembers = [];
  primaryAddress = new Address();
  toBeCompared = false;
  richMediaLinks = [];
}


const getStartedMapper = {
    'EMAIL': 'email',
    'WEBSITE': 'website',
    'PASSWORD': 'password',
    'FIRM_NAME': 'firmName',
    'CURRENT_ADV': 'currentADV',
    'ADVISOR_NAME': 'advisorName',
    'ADVISOR_TITLE':'title',
    //'CONTACT_NUMBER': 'contactNumber',
    'YEARS_WITH_FIRM': 'yearsWithFirm',
    'PROFILE_PIC_INFO': 'profilePicInfo',
    'YEARS_AS_ADVISOR': 'yearsAsAdvisor',
    'USER_ID':'userId'

}


const firmMapper = {
    'AUM': 'aum',
    'MISSION': 'mission',
    'AUM_UNIT': 'aumUnit',
    'AUM_UNIT_AS_OF_DATE':'aumUnitAsOfDate',
    'FIRM_SIZE': 'firmSize',
    'NO_OF_CLIENTS': 'noOfClients',
    'SENIORS_COLOR': 'seniorsColor',
    'SENIORS_WOMEN': 'seniorsWomen',
    'PUBLIC_BENEFIT': 'publicBenefit',
    'YEARS_LICENSED': 'yearsLicensed',
    'NO_OF_EMPLOYEES': 'noOfEmployees',
    'DIRECTORS_COLOR': 'directorsColor',
    'DIRECTORS_WOMEN': 'directorsWomen',
    'EMPLOYEES_COLOR': 'employeesColor',
    'EMPLOYEES_WOMEN': 'employeesWomen',
    'ORDER_OF_MAGNITUDE':'unit',
    'VALUES_ALIGNED_AUM': 'valuesAlignedAum',
    'MINIMUM_ACCOUNT_SIZE': 'minimumAccountSize',
    'TYPICAL_ACCOUNT_SIZE': 'typicalAccountSize',
    'TYPICAL_ACCOUNT_UNIT': 'typicalAccountSizeUnit',
    'GOVERNMENT_INVESTIGATION': 'governmentInvestigation',
    'FIRM_OFFERED_VA_N_IMPACT_YEARS': 'firmOfferedVaNImpactYears',
    'REQUIRED_DISCLOSURES':'requiredDisclosures',
}

const teamInfoMapper = {
    'AUM': 'aum',
    'AUM_UNIT': 'aumUnit',
    'AUM_UNIT_AS_OF_DATE':'aumUnitAsOfDate',
    'NO_OF_PEOPLE': 'noOfPeople',
    'IMPACT_FIELD': 'impactField',
    'NO_OF_CLIENTS': 'noOfClients',
    'EMPLOYEES_COLOR': 'employeesColor',
    'EMPLOYEES_WOMEN': 'employeesWomen',
    'VALUES_ALIGNED_AUM': 'valuesAlignedAum',
    'CLIENT_ACCOUNT_SIZE': 'clientAccountSize',
    'CLIENT_ACCOUNT_UNIT': 'clientAccountSizeUnit',
    'TEAM_OFFERED_VA_N_IMPACT_YEARS': 'teamOfferedVaNImpactYears',
}

const valuesAlignedInvestingInfoMapper = {
    'VOTE_PROXIES': 'voteProxies',
    'IMPACT_STRATEGY': 'impactStrategy',
    'ASSET_ALLOCATION': 'assetAllocation',
    'IMPACT_INVESTMENT': 'impactInvestment',
    'MEASURE_AND_REPORT': 'measureAndReport',
    'INTERESTED_IN_IMPACT': 'interestedInImpact',
    'MEANINGFUL_INVESTMENT': 'meaningfulInvestment',
    'ENGAGED_WITH_MANAGEMENT': 'engagedWithManagement',
    'RELATION_WITH_THRESHOLD': 'relationWithThreshold',
    'DESC_OF_WHAT_CLIENT_CARES': 'descOfWhatClientCares',
    'HELP_CLIENT_MAKE_BETTER_DECISION': 'helpClientMakeBetterDecision',
}

const addressMapper = {
    'CITY': 'city',
    'LINE1': 'line1',
    'LINE2': 'line2',
    'STATE': 'state',
    'COUNTRY': 'country',
    'ZIP_CODE': 'zipCode',
}

const independentMapper = {
    'AFFILIATION': 'affiliation',
    'ASSET_CLASSES': 'assetClasses',
    'IMPACT_THEMES': 'impactThemes',
    'ADVISOR_OFFERING': 'advisorOffering',
    'DIFFERENT_CLIENTS': 'differentClients',
    'SERVING_COUNTRIES': 'servingCountries',
    'UN_SDGS':'unSdgs',
    //'INVESTMENT_SERVICES': 'investmentServices',
    'COMPENSATION_STRUCTURE': 'compensationStructure',
}


export {
    firmMapper,
    addressMapper,
    teamInfoMapper,
    getStartedMapper,
    independentMapper,
    valuesAlignedInvestingInfoMapper
}
import React from "react";
import Modal from "../_shared/Modal/Modal";
const DownloadPdfModal = ({ show, downloadButtonTitle, title,onClose, copyText, onDownload }) => {
  return (
    <>
      <Modal show={show} title={title} onClose={onClose}>
        <p >
       {copyText}
        </p>
        <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
          onSubmit={(e) => {
            e.preventDefault();
            const formFields = e.target.elements;
            const firstName = formFields.firstName.value;
            const lastName = formFields.lastName.value;
            const email = formFields.email.value;
            onDownload({
              firstName,
              lastName,
              email
            })
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <div 
            style={{
              flexGrow: 1,
            }}
            >
              <label className="input-title" htmlFor={"email"}>
                FIRST NAME
              </label>

              <input
                className="uk-input input-box"
                type="text"
                name="firstName"
                placeholder=" "
                required
              />
            </div>
            <div
             style={{
              flexGrow: 1,
            }}
            >
              <label className="input-title" htmlFor={"email"}>
                LAST NAME
              </label>
              <input
                className="uk-input input-box"
                type="text"
                name="lastName"
                placeholder=" "
                required
              />
            </div>
          </div>

       
          <div
          
          >
            <label className="input-title" htmlFor={"email"}>
              EMAIL ADDRESS
            </label>

            <input
              className="uk-input input-box"
              type="email"
              name="email"
              placeholder=" "
     
              required
            />
          </div>

          <div class="buttons">
            <button type="submit" className="homelink create-account">
              {downloadButtonTitle}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default DownloadPdfModal;

import React from "react";
const Footer = () => {
const currentYear = new Date().getFullYear();
 return (
   <React.Fragment>
     <footer className="footer no-print-show">
       <div className="uk-container footer__disclaimer-wrapper">
         <div className="align-items-start uk-grid-large" uk-grid={"true"}>
           <div className="uk-width-1-2@s uk-first-column">
             <p className="footer__disclaimer uk-text-middle">
               <label>ABOUT US</label>
               <label>ValuesAdvisor is a fiscally sponsored project of RSF Social Finance.</label>

               <label className="m-0">
               Copyright © {currentYear} · ValuesAdvisor{" "}
               <span className="uk-visible@s">·</span> <br></br> All rights
               reserved.
               </label>
             </p>
           </div>
           <div className="uk-width-1-4@s uk-grid-margin footer-icons-container">
             <p>FOLLOW US</p>
             <div className="uk-flex-first uk-flex uk-grid-small" uk-grid={"true"}>
               <div className="">
                 <a href="https://www.linkedin.com/company/17873957" target={"_blank"} rel="noopener noreferrer"><img src="img/icons/linkedin-footer-logo.svg" alt=""/></a>
               </div>
               <div className="">
                 <a href="https://www.facebook.com/valuesadvisor" target={"_blank"} rel="noopener noreferrer"><img src="img/icons/facebook-footer-logo.svg" alt=""/></a>
               </div>
               <div className="">
                 <a href="https://www.instagram.com/valuesadvisor/" target={"_blank"} rel="noopener noreferrer"><img src="img/icons/instagram-footer-logo.svg" alt=""/></a>
               </div>
               <div className="">
                 <a href="https://twitter.com/ValuesAdvisor" target={"_blank"} rel="noopener noreferrer"><img src="img/icons/twitter-footer-logo.svg" alt=""/></a>
               </div>
             </div>
           </div>
           <div className="uk-width-1-4@s uk-grid-margin">
             <p>QUICK LINKS</p>
             <div>
               <div>
                 <a href="https://rsfsocialfinance.org/give/give-to-rsf-projects/give-today-projects/" target="_blank" rel="noopener noreferrer">Donate</a>
               </div>
               <div>
                 <a href="/contact">Contact us</a>
               </div>
             </div>
             <a
               href="/"
               onClick={(e) => e.preventDefault()}
               className="footer__link"
               uk-toggle="target: #modal-legal"
             >
               Legal Disclaimers
             </a>
           </div>
         </div>
       </div>
     </footer>
     <div id="modal-legal" className="disclaimer-modal" uk-modal={"true"}>
       <div className="uk-modal-dialog uk-modal-body">
         <h2 className="uk-modal-title">DISCLAIMERS</h2>
         <p>
           <span>
             Nothing on the Website should be construed as investment,
             financial, tax, accounting, insurance, legal, or any other form
             of professional advice
           </span>{" "}
           (collectively, “Professional Advice”), and should not be relied
           upon for any corresponding decision-making. Professional Advice is
           specific to each individual and entity, and you should consult
           with a professional advisor to receive such personalized advice.
           The decision to utilize a professional advisor, as well as the
           specific professional advisor to be utilized, is a decision to be
           made solely by you in your absolute discretion. Nothing contained
           on this Website should be construed as an offer to sell, a
           solicitation of an offer to buy, or a recommendation of any
           security or other financial product or investment strategy. To the
           extent any security or other financial product or investment
           strategy is referenced or discussed on this Website, you should
           understand that past performance is no guarantee of future returns
           and that investing in any security or other financial product or
           investment strategy can result in the partial or complete loss of
           principal. Investing involves risk that you as an investor should
           be prepared to bear.
         </p>
         <p>
           <span>
             ValuesAdvisor is not a registered investment adviser and, as
             such, does not render investment advice.
           </span>{" "}
           References to independent and unaffiliated financial professionals
           on the Website (the “Independent Financial Professionals”) are
           included for convenience purposes only, and such references are
           inherently impersonal and are not based on your specific
           investment needs. ValuesAdvisor does not recommend, endorse,
           promote, warrant, validate, or otherwise direct you to any
           particular Independent Financial Professional, and instead
           provides a platform through which professional services may be
           obtained by you separate and apart from ValuesAdvisor.
           ValuesAdvisor does not pre-screen, approve, supervise, or
           otherwise monitor any investment advisory or other services that
           may be provided to you by an Independent Financial Professional
           separate and apart from ValuesAdvisor. The terms and conditions of
           any relationship between you and an Independent Financial
           Professional shall be governed by a separate agreement between you
           and the Independent Financial Professional; such agreement has not
           been reviewed by ValuesAdvisor.
         </p>
         <p>
           <span>
             Disclaimer of Liability. The information presented on or through
             the Website is made available solely for general information
             purposes. We do not warrant the accuracy or completeness of this
             information.
           </span>{" "}
           Any reliance you place on such information is strictly at your own
           risk. We disclaim all liability and responsibility arising from
           any reliance placed on such materials by you or any other visitor
           to the Website, or by anyone who may be informed of any of its
           contents, on behalf of both ValuesAdvisor and any third parties
           who either contribute content to the Website or assist in its
           distribution (“Distribution Partners.”) This Website may include
           content provided by third parties, including materials provided by
           the Independent Financial Professionals and Distribution Partners.
           All statements and/or opinions expressed in these materials are
           solely the opinions and the responsibility of the Independent
           Financial Professional or persons providing these materials, and
           not those of ValuesAdvisor or its Distribution Partners. These
           materials do not necessarily reflect the opinion of ValuesAdvisor
           or its Distribution Partners.{" "}
         </p>
         <p>
           <span>
             By clicking "I Accept" and using the ValuesAdvisor website you
             acknowledge and agree the same.
           </span>
         </p>
         <p>
           <button type="button" uk-toggle="target: #modal-legal">
             I Accept
           </button>
         </p>
       </div>
     </div>
   </React.Fragment>
 );
}


export default Footer;

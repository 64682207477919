import React, {useEffect,useState, useContext} from "react";
//import Home from "../../components/Home/Home";
import { getTotalCount } from "../../api/totalContent";
import { AuthContext } from "../../Context/AuthContext";
import "./Home.css";
import { DevOnly } from "../../HoC/DevOnly";
import ButtonLink from "../../components/_shared/UI/Button/ButtonLink";

const Home = ({ isLoggedIn, keyFigures, isAdvisor, userId, advisorId }) => {
  const isBillions = keyFigures.valuesAlignedSum > 1000;
  const currencyUnit = isBillions ? "BN" : "M";
  const updatedValuesAligned = isBillions
    ? (keyFigures.valuesAlignedSum / 1000).toFixed(2)
    : keyFigures.valuesAlignedSum;

  const redirectToUrl = isLoggedIn ? "/search" : "/login";
  const mainButtonText = isLoggedIn ? "Find an Advisor" : "Sign In";
  return (
    <>
      <main>
        <div className="home__banner">
          <div className="uk-container">
            <h1 className="banner__title">
              You have the power to create <br />{" "}
              <label className="home_change_label">change</label> with your
              investments
            </h1>

            <DevOnly>
              <div className="banner__subtitle">
                <h2>Use your money to support</h2>
                <div className="home__scroll-box">
                  <p>
                    <span>Sustainability and Climate Actions</span>
                    <span>Racial and Gender Equity</span>
                    <span>Affordable Housing</span>
                  </p>
                </div>
              </div>
            </DevOnly>
         
            <p className="description">
              Find a Financial Advisor from a curated collection of experts who
              deliver both financial and environmental/social returns.
            </p>
            <div className="uk-grid uk-margin-medium uk-margin-large">
              <div className="uk-width-1-1 uk-flex uk-flex-center">
                {isLoggedIn && isAdvisor ? (
                  <ButtonLink href={`/my-profile/${advisorId}`}>
                    My Profile
                  </ButtonLink>
                ) : (
                  [
                    <ButtonLink href={`${redirectToUrl}`} type="primary">
                      {`${mainButtonText}`}
                    </ButtonLink>,
                    !isLoggedIn ? (
                      <ButtonLink href="/join" type="secondary">
                        Get Started
                      </ButtonLink>
                    ) : null,
                  ]
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="home__figures">
          <div className="uk-container">
            <p>
              ValuesAdvisor is a nonprofit, steadfastly devoted to helping
              investors, foundations, and endowments move to impact investing
              and to help create the world we want to see.
            </p>
          </div>
        </div>

        <div className="figures service--gray">
          <div className="uk-container">
            <div className="uk-grid">
              <div className="uk-width-1-1">
                <p className="figures__title">The ValuesAdvisor Universe</p>
              </div>
            </div>
            <div className="uk-grid">
              <div className="uk-width-1-3@s fix">
                <h3 className="figures__number">
                  <sub>$</sub>
                  {updatedValuesAligned}
                  <sup>{currencyUnit}</sup>
                </h3>
                <p className="figures__description">
                  Total Values-Aligned <br /> Assets Under Management (AUM)
                </p>
              </div>
              <div className="uk-width-1-3@s uk-width-1-2 fix">
                <h3 className="figures__number">
                  {new Intl.NumberFormat("en-US").format(
                    keyFigures.noOfClientsSum
                  )}
                </h3>
                <p className="figures__description">Total Clients</p>
              </div>
              <div className="uk-width-1-3@s uk-width-1-2 fix">
                <h3 className="figures__number">{keyFigures.noOfAdvisors}</h3>
                <p className="figures__description">Total Advisors</p>
              </div>
            </div>
          </div>
        </div>

        <div className="service">
          <div className="uk-container">
            <div
              className="uk-flex-middle uk-grid uk-grid-small uk-flex"
              uk-grid={"true"}
            >
              <div className="uk-width-2-3@s about-us-img-container">
                <img src="img/what-matter-to-us–bin-resized-12@2x.png" alt="" />
              </div>
              <div className="uk-width-1-3@s">
                <h3 className="service__title">What Matters to Us</h3>
                <p className="service__text">
                  Your ability to invest with impact matters. Together we are
                  empowering a financial system where values-aligned investing
                  is the norm and capital markets build a regenerative economy.
                  ValuesAdvisor was created and funded by HNW investors to help
                  their peers advance investment in the UN Sustainable
                  Development Goals. Our vision is a world where values aligned
                  investing is the norm and capital markets are building a
                  regenerative economy.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="service service--gray">
          <div className="uk-container">
            <div
              className="uk-flex-middle uk-grid uk-grid-small uk-flex"
              uk-grid={"true"}
            >
              <div className="uk-width-2-3@s about-us-img-container">
                <img src="img/what-we-do@2x.png" alt="" />
              </div>
              <div className="uk-width-1-3@s uk-flex-first@s">
                <h3 className="service__title">What We Do</h3>
                <p className="service__text">
                  ValuesAdvisor offers investors a curated collection of
                  financial professionals suggested by experienced impact
                  investors. Impact-based financial advisory services are a core
                  business for those on our platform, all of whom demonstrate
                  impact investing expertise. Our elegant, simple interface
                  allow investors to customize searches as well as providing
                  substantive answers to investors’ questions.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="service">
          <div className="uk-container">
            <div
              className="uk-flex-middle uk-grid uk-grid-small uk-flex"
              uk-grid={"true"}
            >
              <div className="uk-width-2-3@s about-us-img-container">
                <img src="img/how-we-do-it–bin-resized-14@2x.png" alt="" />
              </div>
              <div className="uk-width-1-3@s">
                <h3 className="service__title">How We Do It</h3>
                <p className="service__text">
                  ValuesAdvisor is a social initiative that works in partnership
                  with high net wealth (HNW) investor{" "}
                  <a href="/about#affinity-group-participants">
                    affinity groups.
                  </a>{" "}
                  The members suggest financial professionals with impact
                  investing expertise to be included on the platform; and those
                  professionals that meet the{" "}
                  <a href="/about#our-financial-advisors">criteria</a> are
                  invited, for a fee, to join. Users have access to the full
                  suite of profiles suggested by all affinity group members.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-link">
          <div className="uk-container">
            {!isLoggedIn ? (
              <p className="uk-text-center ready-to-get-started">
                Ready to Get Started?
              </p>
            ) : null}
            <div className="uk-grid">
              <div className="uk-width-1-1 uk-flex uk-flex-center">
                {isLoggedIn && isAdvisor ? (
                  <ButtonLink href={`/my-profile/${advisorId}`}>
                    My Profile
                  </ButtonLink>
                ) : (
                  [
                    <ButtonLink
                      href={`${redirectToUrl}`}
                      className="uk-margin-right"
                      type="primary"
                    >
                      {`${mainButtonText}`}
                    </ButtonLink>,
                    !isLoggedIn ? (
                      <ButtonLink
                        href="/join"
                        type="secondary"
                        className={"uk-margin-left"}
                      >
                        Get Started
                      </ButtonLink>
                    ) : null,
                  ]
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
const Container = ({location}) => {
  const authContext = useContext(AuthContext);
  const {
    autoLogin,
    currentUserID,
    isAdvisor,
    isLoggedIn,
    advisorId
  } = authContext;
 
  const [keyFigures, setKeyFigures] = useState({});
 
  useEffect(() => {
    getTotalCount().then(({ data }) => {
      setKeyFigures(data);
    });
  }, []);

  useEffect(() => {
    if (location.hash) {
      autoLogin(location.hash).then(() => {
        window.location = "/search"
      });
    }
  }, [location,autoLogin]);


  return (
    <React.Fragment>
      <Home
        isLoggedIn={isLoggedIn}
        isAdvisor={isAdvisor} 
        keyFigures={keyFigures} 
        userId={currentUserID}  
        advisorId={advisorId} 
      ></Home>
    
    </React.Fragment>
  );
};
export default Container; 

import { combineReducers } from 'redux';

import onBoarding from './onBoardingForm';


import modalReducer from './modal';

import authenticationReducer from './authentication';


const rootReducer = combineReducers({
    auth: authenticationReducer,
    modal: modalReducer,
    onBoarding,
})

export default rootReducer;

import React from "react";
import RightChevron from "./RigthChevron";
import classes from "./Button.module.css";
const Button = ({
  children,
  onClick,
  style = {},
  className,
  type,
  buttonType = "primary",
}) => {
  const buttonTypeClass =
    buttonType === "primary"
      ? classes["primary-button"]
      : classes["secondary-button"];
  return (
    <button
      className={`${classes.button} ${buttonTypeClass} ${className}`}
      style={style}
      onClick={onClick}
      type={type}
    >
      {children}
      {<RightChevron />}
    </button>
  );
};
export default Button

import React from "react";
import { createRoot } from 'react-dom/client';
import { ToastContainer } from "react-toastify";
// eslint-disable-next-line
import "bootstrap/dist/css/bootstrap.min.css";
// eslint-disable-next-line
import "./bootstrap_modal.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./pages/App";
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";

import { Provider } from "react-redux";
import store from "./store";
unregister();
const root = createRoot(document.getElementById('root'));
root.render(
<Provider store={store}>
    <React.Fragment>
      <App />
      <ToastContainer></ToastContainer>
    </React.Fragment>
  </Provider>
);
//registerServiceWorker();

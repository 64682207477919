import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { AccountSizeRangeFilter } from "./AccountSizeRangeFilter/AccountSizeRangeFilter";
import { ButtonFilters } from "./ButtonFilters/ButtonFilters";
import { CheckboxFilters } from "./CheckboxFilters/CheckboxFilters";
import { INITIAL_MINIMUM_ACCOUNT_SIZE } from "../../../constants/constants";
import { ProductFilter } from "./ProductsFilter/ProductsFilter";
import Modal from "../../../components/_shared/Modal/Modal";
import { SearchResultsContext } from "../../../Context/SearchResultsContext";
//import { TypicalAccountSizeRangeFilter } from "./TypicalAccountSizeRangeFilter/TypicalAccountSizeRangeFilter";
//import { DevOnly } from "../../../HoC/DevOnly";

export const FiltersList = ({
  onFilterSelected,
  onAccountSizeSelected,
  //onTypicalAccountSizeChange,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const { filterTypes, selectedFilters } = useContext(SearchResultsContext);

  if (!filterTypes || !Object.keys(filterTypes).length) {
    return null;
  }
  const {
    servingCountriesType,
    impactThemesType,
    advisorOfferingType,
    assetClassesType,
    proxyVotingType,
    suggestedByMemberType,
    minimumAccountSizeType,
    productType,
    leadershipDiversityType,
    teamDiversityType,
    firmSizeType,
  } = filterTypes;
  if (!minimumAccountSizeType || !minimumAccountSizeType.length) {
    return null;
  }
  const initialAccountSize = minimumAccountSizeType.find(
    (as) => as.value === INITIAL_MINIMUM_ACCOUNT_SIZE.toLowerCase()
  );

  if (!initialAccountSize) {
    return null;
  }

  if (!suggestedByMemberType || !suggestedByMemberType.length) {
    return null;
  }

  return (
    <div className="filter-midle" data-testid="filter-list">
      <div className="accountsize filter-section">
        <div className="uk-grid">
          <div className="uk-width-1-1">
            <h3 className="section-title">Account Size</h3>
            <div className="uk-accordion-content">
              <p className="section-text">
                I am seeking an advisor for the following account size
              </p>
              <AccountSizeRangeFilter
                accountSizes={minimumAccountSizeType}
                onAccountSizeChange={onAccountSizeSelected}
                filtersId={"minimumAccountSizeType"}
                initialAccountSize={initialAccountSize}
                selectedFilters={selectedFilters}
              />
            </div>
          </div>
        </div>
      </div>

      <CheckboxFilters
        title={"Firm Size (AUM)"}
        text={"I am seeking a firm that is"}
        toolTipText={
          "We use Assets Under Management (AUM), which is the total of a firm’s investments managed on behalf of its clients, to determine its size."
        }
        toolTipStyle={{ top: "18px", left: "145px" }}
        toolTipTextStyle={{ top: "-27px" }}
        items={firmSizeType.map((firmSize) => {
          let label = "";
          switch (firmSize.value) {
            case "boutique <125m":
              label += "SMALL (Firm’s AUM < 125M)";
              break;
            case "medium 125m-1b":
              label += "MEDIUM (Firm’s AUM 125M-1B)";
              break;
            case "large 1b-5b":
              label += "LARGE (Firm’s AUM 1B-5B)";
              break;
            case "very large 5b+":
              label += "VERY LARGE (Firm’s AUM 5B+)";
              break;
            default:
              return null;
          }
          return { ...firmSize, label };
        })}
        listId={"firmSizeType"}
        filtersId={"firmSizeType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
        toolTipTextId="firmSizeType-filter"
      />

      {/*      <DevOnly>
        <div className="typical-account-size filter-section">
          <div className="uk-grid">
            <div className="uk-width-1-1">
              <h3 className="section-title">TYPICAL ACCOUNT SIZE</h3>
              <div className="uk-accordion-content">
                <p className="section-text">
                  I want to work with a team whose average account size is in
                  the following range:
                </p>
                <TypicalAccountSizeRangeFilter
                  onTypicalAccountSizeChange={onTypicalAccountSizeChange}
                  filtersId={"typicalAccountSizeType"}
                  initialTypicalAccountSize={[250000, 100000000]}
                ></TypicalAccountSizeRangeFilter>
              </div>
            </div>
          </div>
        </div>
      </DevOnly>
 */}
      <ButtonFilters
        title={"Geography"}
        text={`I am seeking an advisor who can serve me in the following region/s`}
        items={servingCountriesType}
        filtersId={"servingCountriesType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
        additionalWrapperClasses={"geography-filter-container"}
        toolTipText={
          "If you choose more than one region, you will only see advisors who work in ALL regions you select"
        }
        toolTipTextId="servingCountriesType-filter"
      />

      <CheckboxFilters
        title={"Services"}
        text={`The advisor specializes in (select all applies)`}
        items={advisorOfferingType}
        filtersId={"advisorOfferingType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
      />

      <CheckboxFilters
        title={"Asset Class"}
        text={`I want to invest with impact in the following asset classes`}
        items={assetClassesType}
        filtersId={"assetClassesType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
      />

      <CheckboxFilters
        title={"Impact Themes"}
        text={`I am looking to contribute to solutions
                                    in these specific areas`}
        items={impactThemesType}
        filtersId={"impactThemesType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
      />

      <ButtonFilters
        title={"Leadership Diversity"}
        text={`Firm has a majority in senior positions that are (Select all that apply)`}
        items={leadershipDiversityType}
        filtersId={"leadershipDiversityType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
      />

      <ButtonFilters
        title={"Team Diversity"}
        text={`Advisor's team has a majority that are (Select all that apply)`}
        items={teamDiversityType}
        filtersId={"teamDiversityType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
      />

      <CheckboxFilters
        title={"Active Ownership"}
        text={`I want my Advisor to assist me with shareholder advocacy by`}
        items={proxyVotingType}
        listId={"proxyVotingType"}
        filtersId={"proxyVotingType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
        toolTipText={
          "Active Ownership is the practice of using your rights as a shareholder to drive change in a company's behavior. You or your financial advisor can engage with companies, vote on shareholder resolutions on social or environmental issues or even file them. Being an active owner enables you to have impact with public companies."
        }
        toolTipTextId="proxyVotingType-filter"
      />

      <ButtonFilters
        title={"Affinity Group"}
        text={`I am seeking an advisor who has been
                                        suggested by`}
        items={suggestedByMemberType}
        filtersId={"suggestedByMemberType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
      />

      <ProductFilter
        title={"Products"}
        text={`I want to exclude firms who sell proprietary products`}
        items={productType}
        listId={"productType"}
        filtersId={"productType"}
        onFilterSelected={onFilterSelected}
        selectedFilters={selectedFilters}
        toolTipText={
          "Firms sometimes sell their own proprietary investment funds to address client demand or a lack of existing investment options, but can generate additional fees."
        }
        toolTipTextId="product-filter"
      />

      <button
        className="homelink walkthrough-link"
        onClick={(e) => {
          e.preventDefault();
          setShowVideo(true);
        }}
      >
        Watch Video Walkthrough
      </button>
      <Modal
        show={showVideo}
        onClose={() => setShowVideo(false)}
        title={"Video Walkthrough"}
        contentStyle={{ maxWidth: "680px" }}
      >
        <div>
          <iframe
            id={"walkthrough-video-player"}
            title={"walkthroughIframe"}
            src="//fast.wistia.com/embed/iframe/ubofy4wkys?version=1"
            width="640"
            height="564"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </div>
  );
};
FiltersList.propTypes = {
  filters: PropTypes.object.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  onFilterSelected: PropTypes.func.isRequired,
  onAccountSizeSelected: PropTypes.func.isRequired,
};

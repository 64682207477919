import React, { useReducer } from "react";
import FADashboard from "./FADashboard";
import { fetchAdvisorProfile, fetchAllType } from "../../api/advisor";

import { FADashboardContext, FORM_TYPES, deleteStateFromLocalStorage, getStateFromLocalStorage, initialState } from "./Context";
import { useState } from "react";
import { useEffect } from "react";
import { getStartedReducer } from "./OnBoardingGetStarted/state";
import { aboutFirmReducer } from "./OnBoardingAboutFirm/state";
import { aboutTeamsReducer } from "./OnBoardingAboutTeam/state";
import { aboutAVAIreducer } from "./OnBoardingAVAI/state";
import { materialsReducer } from "./OnBoardingMaterials/state";
import { getCountries } from "../../api/countries";
const setEntireForm = async (token) => {
  try {
    const response = await fetchAdvisorProfile(token);
    const newState = { ...initialState };
    const advisor = response.data.profile;
    return {
      ...newState,
      getStarted: newState.getStarted.fromAdvisor(advisor),
      firm: newState.firm.fromAdvisor(advisor),
      teamInfo: newState.teamInfo.fromAdvisor(advisor),
      valuesAlignedInvestingInfo:
        newState.valuesAlignedInvestingInfo.fromAdvisor(advisor),
      material: newState.material.fromAdvisor(advisor),
    };
  } catch (err) {
    console.error(err);
  }
};
const setFormFromLocalStorage = (state = initialState) => {
  const savedState = getStateFromLocalStorage();
  console.log("savedState", savedState)
  if (savedState) {
    return {
      ...state,
      getstarted: state.getStarted.fromSavedState(savedState.getStarted),
      firm: state.firm.fromSavedState(savedState.firm),
      teamInfo: state.teamInfo.fromSavedState(savedState.teamInfo),
      valuesAlignedInvestingInfo:
        state.valuesAlignedInvestingInfo.fromSavedState(
          savedState.valuesAlignedInvestingInfo
        ),
      material: state.material.fromSavedState(savedState.material),
    };
  }
};
const Container = () => {
  const [formType, setFormType] = useState(FORM_TYPES.CREATE);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [state, setState] = useState(initialState);
  const [countries, setCountries] = useState([]);
  const [getStarted, dispatchGetStarted] = useReducer(
    getStartedReducer,
    state.getStarted
  );
  const [aboutFirm, dispatchAboutFirm] = useReducer(
    aboutFirmReducer,
    state.firm
  );
  const [aboutTeam, dispatchAboutTeam] = useReducer(
    aboutTeamsReducer,
    state.teamInfo
  );
  const [valuesAlignedInvestingInfo, dispatchValuesAlignedInvestingInfo] =
    useReducer(aboutAVAIreducer, state.valuesAlignedInvestingInfo);

  const [material, dispatchMaterial] = useReducer(
    materialsReducer,
    state.material
  );

  const [allTypes, setAllTypes] = useState([]);
  const location = window.location;
  const fetchData = async () => {
    setIsLoading(true);
    const response = await fetchAllType();
    const countriesResponse = await getCountries();
    setAllTypes(response.data);
    setCountries(countriesResponse.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData().then(() => {
      if (location.search) {
        setIsLoading(true);
        const params = new URLSearchParams(location.search);
        const token = params.get("token");
        const cloneToken = params.get("clone");
        if (token) {
          setEntireForm(token).then((newState) => {
            setState(newState);
            setFormType(FORM_TYPES.UPDATE);
            setIsLoading(false);
          });
        }
        if (cloneToken) {
          setEntireForm(cloneToken).then((newState) => {
            setState(newState);
            setFormType(FORM_TYPES.CLONE);
            setIsLoading(false);
          });
        }
      } else {
        setFormType(FORM_TYPES.CREATE);
        setFormFromLocalStorage();
      }
    });

    return () => {
      deleteStateFromLocalStorage()
    };
  }, [location.search]);

  return (
    <FADashboardContext.Provider
      value={{
        ...state,
        countries,
        typeList: { ...allTypes },
        getStarted: {
          ...getStarted,
          dispatch: dispatchGetStarted,
        },
        firm: {
          ...aboutFirm,
          dispatch: dispatchAboutFirm,
        },
        teamInfo: {
          ...aboutTeam,
          dispatch: dispatchAboutTeam,
        },
        valuesAlignedInvestingInfo: {
          ...valuesAlignedInvestingInfo,
          dispatch: dispatchValuesAlignedInvestingInfo,
        },
        material: {
          ...material,
          dispatch: dispatchMaterial,
        },
        form: {
          ...state.form,
          setIsLoading: setIsLoading,

          isLoading: isLoading,
          isSubmitSuccess: isSubmitSuccess,
          setIsSubmitSuccess: setIsSubmitSuccess,
          formType: formType,
        },
      }}
    >
      <FADashboard />
    </FADashboardContext.Provider>
  );
};
export default Container;

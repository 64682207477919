import React, { Component } from 'react';
import { Label, FormGroup } from 'reactstrap';
import cx from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';


export default class FormTextarea extends Component {
	state = {
		focused: false
	}

	handleFocus = (event) => {
		this.setState({
			focused: true,
		})
	}

	handleBlur = (event) => {
		this.setState({
			focused: false,
		})
	}

	render() {
		const { type, value, onChange, label, disabled, error, showError,
			className, labelClass, maxLength, accept, readOnly, max, focus } = this.props
			const textAreaValue = value || "";
		return (
			<React.Fragment>
				<FormGroup className={className}>
					<TextareaAutosize rows={3} type={type || "text" } className={cx("form-control", {
						"field-focus": this.state.focused || !!textAreaValue || focus,
					})} disabled={disabled}
						value={textAreaValue}
						readOnly={readOnly}
						onChange={onChange}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
						maxLength={maxLength}
						accept={accept}
						max={max}
						data-gramm="false"
					/>
					<Label className={labelClass}>{label}</Label>
					{type === 'textarea' && maxLength ? <span className="text-count">{textAreaValue.length}/{maxLength}</span> : null}
					{
						showError ?
						(<div className="error-message contains-error">{error}</div>) : null
					}
				</FormGroup>
			</React.Fragment>
		)
	}
}
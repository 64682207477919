import React, { useState,useEffect,useCallback } from "react";
import produce from "immer";
import Dashboard from "./Dashboard";
import { ADVISOR_STATUS } from "../../../constants/constants";
import {
  getUserToken,
  getAdvisorList,
  disableAdvisor,
  enableAdvisor,
} from "../../../api/admin";
const Container = ({history}) => {
  const [tab, setTab] = useState("All");
  const [loading, setLoading] = useState(false);
  const [advisorList, setAdvisorList] = useState([]);
  const fetchAdvisorsList = useCallback(async (status) => {
    setLoading(true);
    const response = await getAdvisorList(status);
    setAdvisorList(response.data.list);
    setLoading(false);
  },[]);

 useEffect(() => {
    fetchAdvisorsList(tab);
  }, [tab,fetchAdvisorsList]);

  const setAdvisorProfileReview = (advisorId) => {
    history.push(`/profile/${advisorId}`);
    window.location.reload();
  };
  const setAvisorProfileUpdate = async (advisorId) => {
    try {
      const response = await getUserToken(advisorId);
      if (response) {
        history.push(`/profile-edit?token=${response.data.token}`);
      }
    } catch (error) {
      throw error;
    }
  };
  const cloneProfile = async (advisorId) => {
    try {
      const response = await getUserToken(advisorId);
      if (response) {
        history.push(`/profile-edit?clone=${response.data.token}`);
      }
    } catch (error) {
      throw error;
    }
  }
  const _enableAdvisor = async (advisorId) => {
    const response = await enableAdvisor(advisorId);
    if (response.data.success) {
      setAdvisorList(
        produce((draft) => {
          const advisor = draft.find((a) => a.id === advisorId);
          advisor.user.status.name = ADVISOR_STATUS.APPROVED;
        })
      );
      fetchAdvisorsList(tab);
    }
  };
  const _disableAdvisor = async (advisorId) => {
    const response = await disableAdvisor(advisorId);
    if (response.data.success) {
      setAdvisorList(
        produce((draft) => {
          const advisor = draft.find((a) => a.id === advisorId);
          advisor.user.status.name = ADVISOR_STATUS.DISABLED;
        })
      );
      fetchAdvisorsList(tab);
    }
  };
  return (
    <Dashboard
      setTab={setTab}
      tab={tab}
      advisorList={advisorList}
      fetchAdvisorsList={fetchAdvisorsList}
      setAdvisorProfileReview={setAdvisorProfileReview}
      setAvisorProfileUpdate={setAvisorProfileUpdate}
      enableAdvisor={_enableAdvisor}
      disableAdvisor={_disableAdvisor}
      cloneProfile={cloneProfile}
      loading={loading}
    ></Dashboard>
  );
};
export default Container;

import React from "react";
import "./BlogPost.css";
const BlogPost = () => {
  return (
    <div className="blog-post-container">
      <article>
        <h1 className="article-title">A ValuesAdvisor Success Story</h1>
        <h4 className="article-author">By ValuesAdvisor</h4>
        <div>
          <p>
            <img
              src="img/EllenNapa.webp"
              loading="lazy"
              alt="Ellen Napa"
              style={{
                width: "534px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              width="534"
            />
          </p>
          <p>
            About ten years ago, when I became an inheritor, all I knew was that
            I wanted to get the money out of Merrill Lynch and into someplace
            where I could be sure it would do no harm, while I was figuring out
            how to give it away. I met with a few people, looked at the Coop
            America (now Green America) Directory, read back issues of More Than
            Money magazine, and settled on Greg Garvan/Money with a Mission,
            which turned out to be a good choice for me.&nbsp;
          </p>

          <p>
            Last month, Greg announced his retirement, and so began my search
            for a new money manager. Thankfully, this time, I had the
            ValuesAdvisor tool at my disposal! What a helpful tool it turned out
            to be. First of all, every advisor listed has been put forth by
            members of respected High Net Wealth groups like Toniic, WDN, and
            Resource Generation, and vetted by the ValuesAdvisor team prior to
            joining the database. Secondly, all the data on each firm is in one
            place, and with a few clicks, I could filter and compare my choices
            without having to search online and compile the data
            myself.&nbsp;&nbsp;
          </p>
          <p>
            <img
              src="img/a-valuesadvisors-success-story-img-1.png"
              loading="lazy"
              alt="A ValuesAdvisor sidebar filter"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              width="148"
              height="415"
            />
          </p>
          <p>
            Thirdly, I learned a lot by using the tool. The more I explored,
            using the simple search and filter functions, the more I was able to
            articulate my priorities. I wanted to find someone in my general
            geographic area, and I wanted their AUM (assets under management) to
            be 100% impact. Also, I didn’t want to look at any firms that
            offered their own investment products. I wanted my AUM to lie
            comfortably within the firm’s range — not their biggest client, but
            not their smallest either. Shareholder action is also important to
            me.&nbsp;&nbsp;
          </p>
          <p>
            Once I narrowed my search down to four firms, I could make initial
            contact with one click. Zoom meetings were scheduled, during which I
            could ask questions specific to my situation, get a sense of the
            firm, and assess whether it felt like a good fit. One firm was
            eliminated quickly because they did not do private placements
            (direct investments in companies outside the stock market), but it
            was still valuable to have the conversation with them. Each
            interview helped me know better what to ask during the next
            one.&nbsp;
          </p>
          <p>
            Narrowing down to the final three firms, I felt fully confident that
            any of them would be a good choice.&nbsp; I had two virtual meetings
            with each firm, followed by in person meetings to get a feel for the
            offices. I asked lots of questions, and shared my financial info in
            order to get specific feedback. &nbsp; It ultimately came down to a
            matter of geography, the advisors’ management style, and how I
            connected with the team.&nbsp; In the end, I chose a local-to-me
            firm with a very diverse all female team.&nbsp;&nbsp;
          </p>
          <p>
            Part of being a responsible wealth-holder is getting your
            investments aligned with your values. For people like me, who are
            happy to pay someone to manage their money, ValuesAdvisor makes it
            very easy to find a socially responsible firm to fill that role.{" "}
          </p>
          <p>-Ellen</p>
        </div>
      </article>
    </div>
  );
};
export default BlogPost;

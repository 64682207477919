export class Geocoding {
    id = 0;
    addressId = 0;
    latitude = 0;
    longitude = 0;
    createdAt = null;
    updatedAt = null;
}

export class Address {
    id = 0;
    advisorId = 0;
    line1 = "";
    line2 = "";
    city = "";
    state = "";
    country = "";
    primary = false;
    zipCode = "";
    createdAt = null;
    updatedAt = null;
    geocoding = new Geocoding();
}
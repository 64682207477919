import { isEmail } from "validator";


export default function validateEmail(email) {
    if(!email.value.length){
        return {
            ...email,
            error: 'Required',
            showError: true,
        }
    }

    if(isEmail(email.value, {domain_specific_validation: true})){
        return {
            ...email,
            error: '',
            showError: false
        }
    } else {
        return {
            ...email,
            error: 'Enter a valid E-mail address',
            showError: true,
        }
    }
}
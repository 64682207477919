import { getDistanceFromLatLongInKm } from "../../utils/getDistanceFromLatLonInKm";

import { SORT_BY } from "../../constants/constants";
const accountSizeToNumber = (accountSize) => {
  const number = Number.parseInt(accountSize.label);
  const multiple = accountSize.label.toLocaleLowerCase().includes("k")
    ? 1000
    : 1000000;
  return { number, multiple };
};
const aumToNumber = (aum, aumUnit = "") => {
  const multipliers = {
    k: 1_000,
    m: 1_000_000,
    b: 1_000_000_000,
  };
  if (aumUnit.toLocaleLowerCase() === "m" && aum >= 1000) {
    return (aum / 1000) * multipliers["b"];
  }

  return aum * multipliers[aumUnit.toLocaleLowerCase()];
};
export const numberToAum = (number = 0) => {
  if (number >= 1_000_000_000) {
    return `${(number / 1_000_000_000).toFixed(0)}B`;
  }
  if (number >= 1_000_000) {
    return `${(number / 1_000_000).toFixed(0)}M`;
  }
  if (number >= 1_000) {
    return `${(number.toFixed(0) / 1_000).toFixed(0)}K`;
  }
  return number;
};
export const addNumericValToAccountSizes = (minimumAccountSizeType = []) => {
  return minimumAccountSizeType
    .map((i) => {
      const { number, multiple } = accountSizeToNumber(i);
      return { ...i, numericVal: number * multiple };
    })
    .sort((a, b) => a.numericVal - b.numericVal);
};
const FilterTypes = {
  SERVING_COUNTRIES_TYPE: "servingCountriesType",
  IMPACT_THEMES_TYPE: "impactThemesType",
  ADVISOR_OFFERING_TYPE: "advisorOfferingType",
  ASSET_CLASSES_TYPE: "assetClassesType",
  SUGGESTED_BY_MEMBER_TYPE: "suggestedByMemberType",
  PRODUCT_TYPE: "productType",
  MINIMUM_ACCOUNT_SIZE_TYPE: "minimumAccountSizeType",
  LEADERSHIP_DIVERSITY_TYPE: "leadershipDiversityType",
  TEAM_DIVERSITY_TYPE: "teamDiversityType",
  DIFFERENT_CLIENT_TYPE: "differentClientType",
  FIRM_SIZE_TYPE: "firmSizeType",
  TYPICAL_ACCOUNT_SIZE_TYPE: "typicalAccountSizeType",
};

export const filterAdvisors = (advisors = [], selectedFilters, filterTypes) => {
  const filtersKeys = Object.keys(selectedFilters);
  if (filtersKeys.every((f) => !selectedFilters[f].length)) {
    return advisors;
  }
  let results = advisors;
  const getFilterByTypeFunc = (type, idProp, filters) => {
    return (a) => {
      return filters[type].every((id) => a[idProp][id]);
    };
  };
  const filterFuncs = {
    [FilterTypes.SERVING_COUNTRIES_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter(
        getFilterByTypeFunc(
          FilterTypes.SERVING_COUNTRIES_TYPE,
          "servingCountriesIds",
          selectedFilters
        )
      );
    },
    [FilterTypes.IMPACT_THEMES_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter(
        getFilterByTypeFunc(
          FilterTypes.IMPACT_THEMES_TYPE,
          "impactThemesIds",
          selectedFilters
        )
      );
    },
    [FilterTypes.ADVISOR_OFFERING_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter(
        getFilterByTypeFunc(
          FilterTypes.ADVISOR_OFFERING_TYPE,
          "advisorOfferingsIds",
          selectedFilters
        )
      );
    },
    [FilterTypes.ASSET_CLASSES_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter(
        getFilterByTypeFunc(
          FilterTypes.ASSET_CLASSES_TYPE,
          "assetClassesIds",
          selectedFilters
        )
      );
    },
    [FilterTypes.SUGGESTED_BY_MEMBER_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter(
        getFilterByTypeFunc(
          FilterTypes.SUGGESTED_BY_MEMBER_TYPE,
          "suggestedByMembersIds",
          selectedFilters
        )
      );
    },
    [FilterTypes.PRODUCT_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter((a) => {
        const productObj = filterTypes[FilterTypes.PRODUCT_TYPE].find(
          (product) => product.value !== "sells proprietary products"
        );
        const doesNotSellProprietary = a.firm.products.some(
          (p) => p.productId === productObj.id
        );

        const doesNotSellProprietarySelected = selectedFilters[
          FilterTypes.PRODUCT_TYPE
        ].includes(productObj.id);

        return !doesNotSellProprietarySelected || doesNotSellProprietary;
      });
    },
    [FilterTypes.MINIMUM_ACCOUNT_SIZE_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter((a) => {
        const selectedAccountSize = accountSizeToNumber(
          filterTypes[FilterTypes.MINIMUM_ACCOUNT_SIZE_TYPE].find(
            (type) =>
              type.id ===
              selectedFilters[FilterTypes.MINIMUM_ACCOUNT_SIZE_TYPE][0]
          )
        );

        const minAccountSize =
          selectedAccountSize.number * selectedAccountSize.multiple;

        const advisorAccountSize = accountSizeToNumber({
          label: `${a.firm.minimumAccountSize}${a.firm.unit}`,
        });

        const advisorNumericAccountSize =
          advisorAccountSize.number * advisorAccountSize.multiple;

        return advisorNumericAccountSize <= minAccountSize;
      });
    },
    [FilterTypes.TYPICAL_ACCOUNT_SIZE_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter((a) => {
        if (!selectedFilters[FilterTypes.TYPICAL_ACCOUNT_SIZE_TYPE].length) {
          return true;
        }
        const lowerBound =
          selectedFilters[FilterTypes.TYPICAL_ACCOUNT_SIZE_TYPE][0];
        const upperBound =
          selectedFilters[FilterTypes.TYPICAL_ACCOUNT_SIZE_TYPE][1];
        const accountSizeNumber = aumToNumber(
          a.teamInfo.clientAccountSize,
          a.teamInfo.clientAccountSizeUnit
        );
        return (
          accountSizeNumber >= lowerBound && accountSizeNumber <= upperBound
        );
      });
    },
    [FilterTypes.LEADERSHIP_DIVERSITY_TYPE]: (arr = [], selectedFilters) => {
      const selectedOptions = selectedFilters[
        FilterTypes.LEADERSHIP_DIVERSITY_TYPE
      ].map((f) =>
        filterTypes[FilterTypes.LEADERSHIP_DIVERSITY_TYPE].find(
          (t) => t.id === f
        )
      );
      return arr.filter((a) => {
        if (selectedOptions.length === 2) {
          return a.firm.seniorsColor >= 51 && a.firm.seniorsWomen >= 51;
        }
        const [selectedOption] = selectedOptions;

        if (selectedOption.value === "women/won - binary") {
          return a.firm.seniorsWomen >= 51;
        }
        if (selectedOption.value === "people of color") {
          return a.firm.seniorsColor >= 51;
        }

        return true;
      });
    },
    [FilterTypes.TEAM_DIVERSITY_TYPE]: (arr = [], selectedFilters) => {
      const selectedOptions = selectedFilters[
        FilterTypes.TEAM_DIVERSITY_TYPE
      ].map((f) =>
        filterTypes[FilterTypes.TEAM_DIVERSITY_TYPE].find((t) => t.id === f)
      );
      return arr.filter((a) => {
        if (selectedOptions.length === 2) {
          return (
            a.teamInfo.employeesColor >= 51 && a.teamInfo.employeesWomen >= 51
          );
        }
        const [selectedOption] = selectedOptions;

        if (selectedOption.value === "women/won - binary") {
          return a.teamInfo.employeesWomen >= 51;
        }
        if (selectedOption.value === "people of color") {
          return a.teamInfo.employeesColor >= 51;
        }
        return true;
      });
    },
    [FilterTypes.DIFFERENT_CLIENT_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter(
        getFilterByTypeFunc(
          FilterTypes.DIFFERENT_CLIENT_TYPE,
          "differentClientsIds",
          selectedFilters
        )
      );
    },
    [FilterTypes.FIRM_SIZE_TYPE]: (arr = [], selectedFilters) => {
      return arr.filter((a) =>
        selectedFilters[FilterTypes.FIRM_SIZE_TYPE].includes(a.firm.firmSizeId)
      );
    },
  };
  filtersKeys
    .filter((key) => filterFuncs[key] && selectedFilters[key].length)
    .forEach((key) => {
      results = filterFuncs[key](results, selectedFilters);
    });
  return results;
};
export const sortAdvisors = (advisors = [], selectedSort, selectedOrder) => {
  switch (selectedSort.value) {
    case "distance":
      if (
        selectedSort.zipCode &&
        Boolean(selectedSort.zipCode.latitude) &&
        Boolean(selectedSort.zipCode.longitude)
      ) {
        const results = advisors.sort((a, b) => {
          const distanceA = getDistanceFromLatLongInKm(
            selectedSort.zipCode.latitude,
            selectedSort.zipCode.longitude,
            a.primaryAddress.geocoding.latitude,
            a.primaryAddress.geocoding.longitude
          );
          const distanceB = getDistanceFromLatLongInKm(
            selectedSort.zipCode.latitude,
            selectedSort.zipCode.longitude,
            b.primaryAddress.geocoding.latitude,
            b.primaryAddress.geocoding.longitude
          );
          return distanceA - distanceB;
        });

        return results;
      }
      break;
    case "alignedAum":
      const result = advisors.sort((a, b) => {
        const percentageOfValuesAlignedA =
          a.teamInfo.aum > 0
            ? Math.round((a.teamInfo.valuesAlignedAum / a.teamInfo.aum) * 100)
            : 0;
        const percentageOfValuesAlignedB =
          a.teamInfo.aum > 0
            ? Math.round((b.teamInfo.valuesAlignedAum / b.teamInfo.aum) * 100)
            : 0;

        if (selectedOrder === SORT_BY.HIGH_TO_LOW) {
          return percentageOfValuesAlignedB - percentageOfValuesAlignedA;
        } else {
          return percentageOfValuesAlignedA - percentageOfValuesAlignedB;
        }
      });
      return result;
    case "minimumAccountSize":
      return advisors.sort((a, b) => {
        const advisorAccountSizeA = accountSizeToNumber({
          label: `${a.firm.minimumAccountSize}${a.firm.unit}`,
        });
        const advisorNumericAccountSizeA =
        advisorAccountSizeA.number * advisorAccountSizeA.multiple;

        const advisorAccountSizeB = accountSizeToNumber({
          label: `${b.firm.minimumAccountSize}${b.firm.unit}`,
        });
        const advisorNumericAccountSizeB =
        advisorAccountSizeB.number * advisorAccountSizeB.multiple;
       
        if (selectedOrder === SORT_BY.HIGH_TO_LOW) {
          return advisorNumericAccountSizeB - advisorNumericAccountSizeA;
        } else {
          return advisorNumericAccountSizeA - advisorNumericAccountSizeB;
        }
      });
    case "teamTypicalAccountSize":
      return advisors.sort((a, b) => {
        const teamTypicalAccountSizeA = aumToNumber(
          a.teamInfo.clientAccountSize,
          a.teamInfo.clientAccountSizeUnit
        );
        const teamTypicalAccountSizeB = aumToNumber(
          b.teamInfo.clientAccountSize,
          b.teamInfo.clientAccountSizeUnit
        );

        if (selectedOrder === SORT_BY.HIGH_TO_LOW) {
          return teamTypicalAccountSizeB - teamTypicalAccountSizeA;
        } else {
          return teamTypicalAccountSizeA - teamTypicalAccountSizeB;
        }
      });

    default:
      return advisors;
  }
  return advisors;
};

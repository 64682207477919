import React, { Component } from 'react';
import gifSrc from '../../assets/images/Spinner-1s-200px/Spinner-1s-200px.gif'
import './Spinner.css'

class Spinner extends Component {
    render() {
        return (
            <div className="spinner-loader">
                <img src={gifSrc} alt="spinner" />
            </div>
        );
    }
}

export default Spinner;
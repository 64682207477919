import axiosInstance from '.';

export function createAdvisor(data) {
    return axiosInstance({
        method: 'post',
        url: '/advisor/create',
        data,
    })
}

export function updateAdvisor(data) {
    
    return axiosInstance({
        method: 'put',
        url: `/advisor/update`,
        data,
    })
   
}

export async function fetchAdvisorProfile(token) {
    const response = await axiosInstance({
        method: 'get',
        url: `/advisor/rejectFetch?token=${token}`,
    })
    const [teamInfoResponse,teamAddInfoResponse] = await Promise.all([
        axiosInstance({
            method: 'get',
            url: `/advisor/rejectFetchTeamInfo?token=${token}`,
        }),
        axiosInstance({
            method: 'get',
            url: `/advisor/rejectFetchTeamAddInfo?token=${token}`,
        })
    ])
   
    response.data.profile.teamInfo = {
        ...teamInfoResponse.data.teamInfo,
        ...teamAddInfoResponse.data.teamAddInfo
    }
    return response
}
export function fetchAllType() {
    return axiosInstance({
        method: 'get',
        url: '/advisor/type'
    })
}
export function getAdvisorProfile(advisorId) {
    return axiosInstance({
        method: 'get',
        url: `/advisor/profile/${advisorId}`
    })
}
export function getSimilarAdvisors(advisorId) {
    return axiosInstance({
        method: 'get',
        url: `/advisor/profile/${advisorId}/similar`
    })
}

export function checkEmailUnique(data) {
    return axiosInstance({
        method: 'post',
        url: '/advisor/uniqueEmail',
        data
    })
}
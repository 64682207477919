import React from "react";
import classes from "./Tabs.module.css";
export const PhilosophyAndCommTab = ({
  firm,
  meaningfulInvestment,
  assetAllocation,
  impactInvestment,
  helpClientMakeBetterDecision,
}) => {
  return (
    <li className="filosophy uk-margin-large-bottom">
      <div className={`uk-grid ${classes["full-width"]}`}>
        <div className="uk-width-1-1">
          <h3 className="generic__title">Philosophy &amp; Communications</h3>
        </div>
        <div
          className={`uk-grid uk-grid uk-margin-remove-top uk-margin-remove-bottom fix-align ${classes["full-width"]}`}
        >
          <div className="uk-width-1-3@s">
            <p className="value__title">
              Team’s Investment Philosophy and Style
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
              <p>{meaningfulInvestment}</p>
            </pre>
          </div>
        </div>
        <div className="uk-width-1-1">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">Impact Measurement and Reporting</p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
              <p>{assetAllocation}</p>
            </pre>
          </div>
        </div>
        <div className="uk-width-1-1">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">Discerning Goals and Priorities</p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
              <p>{impactInvestment}</p>
            </pre>
          </div>
        </div>
        <div className="uk-width-1-1">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">
              Available Resources to Help Clients Make Better Decisions
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
              <p>{helpClientMakeBetterDecision}</p>
            </pre>
          </div>
        </div>

        <div className="uk-width-1-1">
          <hr />
        </div>

        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">Disclosures</p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
              <p>{firm.requiredDisclosures}</p>
            </pre>
          </div>
        </div>

        <div className="uk-width-1-1">
          <hr />
        </div>

        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">
              {" "}
              Past or Ongoing Litigation, Regulatory Actions, or Government
              Investigations Involving the Firm or the Team{" "}
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
              <p>{firm.governmentInvestigation}</p>
            </pre>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <p>ValuesAdvisor is not a client of any financial advisor listed on this website. In consideration of their
        profiles being included on this website, all listed financial advisors paid compensation to ValuesAdvisor in the
        form of a membership fee. This compensation creates a material conflict of interest. To review the material
        terms of the compensation paid, please refer to <a href="https://7887571.fs1.hubspotusercontent-na1.net/hubfs/7887571/Public%20Facing%20Files/Financial%20Advisor%20Fees%202022.pdf" target="_blank" rel="noopener noreferrer">this page</a> for more information.</p>
      <p>ValuesAdvisor is an online directory of financial advisors that must meet certain <a href="https://valuesadvisor.com/about#our-financial-advisors" target="_blank" rel="noopener noreferrer">eligibility criteria</a>. ValuesAdvisor charges financial advisors a membership fee for the sole purpose of offsetting the cost to host and administer the online directory.</p>
    </li>);
};

import axiosInstance from './index';

export function compareAdvisors(id) {
    return axiosInstance({
        method: 'get',
        url: `/advisor/compare?id=${id}`,
    })
}

export function getAllAdvisors() {
    return axiosInstance({
        method: 'get',
        url: `/search/allAdvisors`,
    })
}

import React from "react";

export const AboutTeamTab = ({
                                 valuesAlignedAum,
                                 aum,
                                 aumUnit,
                                 noOfClients,
                                 clientAccountSize,
                                 clientAccountSizeUnit,
                                 servingCountries,
                                 servingCountriesType,
                                 compensationStructures,
                                 compensationStructureType,
                                 noOfPeople,
                                 employeesColor,
                                 employeesWomen,
                                 advisorOfferings,
                                 advisorOfferingType,
                                 differentClients,
                                 differentClientType,
                                 aumUnitAsOfDate,
                                 teamOfferedVaNImpactYears,
                                 teamInfoDegrees,
                                 isNoOfClientsDisclosable,
                                 isTypicalAccountSizeDisclosable,
                                 degreesType,
                                 affiliations,
                                 affiliationType,
                             }) => {
    const percentageOfValuesAligned =
        aum > 0 ? Math.round((valuesAlignedAum / aum) * 100) : 0;

    const servingCountriesList = servingCountries.map((it) => {
        const type = servingCountriesType.find((t) => t.id === it.servingCountryId);
        return <li key={type.id}>{type.label}</li>;
    });
    const degrees = teamInfoDegrees.map((it) => {
        const type = degreesType.find((t) => t.id === it.degreeId);
        return <li key={type.id}>{type.label}</li>;
    });
    const profAffiliations = affiliations.map((it) => {
        const type = affiliationType.find((t) => t.id === it.affiliationId);
        return <li key={type.id}>{type.label}</li>;
    });
    const compensationStructuresList = compensationStructures.map((it) => {
        const type = compensationStructureType.find(
            (t) => t.id === it.compensationStructureId
        );
        return <li key={type.id}>{type.label}</li>;
    });
    const advisorOfferingsList = advisorOfferings.map((it) => {
        const type = advisorOfferingType.find((t) => t.id === it.advisorOfferingId);
        return <li key={type.id}>{type.label}</li>;
    });
    const differentClientsList = differentClients.map((it) => {
        const type = differentClientType.find((t) => t.id === it.clientId);
        return <li key={type.id}>{type.label}</li>;
    });
    const valueAlignedDate = aumUnitAsOfDate
        ? new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
        }).format(new Date(aumUnitAsOfDate))
        : "";

    return (
        <li className="team">
            <div className="uk-grid">
                <div className="uk-width-1-1">
                    <h3 className="generic__title">About the Team</h3>
                </div>
            </div>

            <div
                className="team_info uk-grid-collapse@s uk-margin-remove-top fix-grid uk-child-width-1-2 uk-child-width-1-3@s uk-grid uk-flex uk-stretch">
                <div className="col-underline">
                    <div className="team__block">
                        <div className="team__block__inner">
                            <div>
                                <h2 className="team__title">Team’s Total AUM</h2>
                                <h1 className="team__number">
                                    ${aum}
                                    {aumUnit}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">
                            Team’s Values-Aligned AUM{" "}
                            {valueAlignedDate && (
                                <React.Fragment>as of {valueAlignedDate}</React.Fragment>
                            )}
                        </h2>
                        <h1 className="team__number">
                            ${valuesAlignedAum}
                            {aumUnit}
                        </h1>
                    </div>
                </div>
                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">% of AUM that is Values-Aligned</h2>
                        <h1 className="team__number">{percentageOfValuesAligned}%</h1>
                    </div>
                </div>
                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">Total Clients</h2>
                        {isNoOfClientsDisclosable ? (
                                    <h4 className="digits__number">{noOfClients}</h4>
                                ) : (
                                    <h2 style={{fontSize: "20px"}} className="digits__number">
                                        Not Disclosed / Collected
                                    </h2>
                                )}
                    </div>
                </div>

                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">Typical Account Size</h2>

                        {isTypicalAccountSizeDisclosable ? (
                                    <h4 className="digits__number">
                                        ${new Intl.NumberFormat("en-US").format(clientAccountSize)}
                                        {clientAccountSizeUnit}
                                    </h4>
                                ) : (
                                    <h2 style={{fontSize: "20px"}} className="digits__number">
                                        Not Disclosed / Collected
                                    </h2>
                                )}
                    </div>
                </div>

                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">Offers Values-Aligned Services</h2>
                        <h1 className="team__number">{teamOfferedVaNImpactYears} Years</h1>
                    </div>
                </div>

                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">Clients Reside in</h2>
                        <ul className="team__list">{servingCountriesList}</ul>
                    </div>
                </div>

                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">
                            Team’s Professional Degrees, Certifications, Licenses or
                            Designations
                        </h2>
                        <ul className="team__list">{degrees}</ul>
                    </div>
                </div>

                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">Team’s Professional Affiliations</h2>
                        <ul className="team__list">{profAffiliations}</ul>
                    </div>
                </div>

                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">Team Size</h2>
                        <h1 className="team__number">{noOfPeople}</h1>
                    </div>
                </div>

                {
                    <div className="col-underline">
                        <div className="team__block">
                            <h2 className="team__title">Women / Non-Binary</h2>
                            <div className="piechart">
                                <div
                                    className="donut"
                                    style={{
                                        animationDelay: `-${
                                            (employeesWomen || 0) >= 100 ? 99.99 : employeesWomen || 0
                                        }s`,
                                    }}
                                >
                                    <span className="pencentaje">{employeesWomen || 0}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    <div className="col-underline">
                        <div className="team__block">
                            <h2 className="team__title">People of Color</h2>
                            <div className="piechart">
                                <div
                                    className="donut"
                                    style={{
                                        animationDelay: `-${
                                            (employeesColor || 0) >= 100 ? 99.99 : employeesColor || 0
                                        }s`,
                                    }}
                                >
                                    <span className="pencentaje">{employeesColor || 0}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">Compensation Structure</h2>
                        <ul className="team__list">{compensationStructuresList}</ul>
                    </div>
                </div>
                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">
                            In Addition to Portfolio Management, the Team offers
                        </h2>
                        <ul className="team__list">{advisorOfferingsList}</ul>
                    </div>
                </div>

                <div className="col-underline">
                    <div className="team__block">
                        <h2 className="team__title">Types of clients</h2>
                        <ul className="team__list">{differentClientsList}</ul>
                    </div>
                </div>
            </div>
        </li>
    );
};

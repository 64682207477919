import React, {useState} from "react";
import ButtonLink from "../UI/Button/ButtonLink";
import "./OfficeHoursBanner.css";
const OfficeHoursBanner = ({showBanner = true, text, tillDate}) => {
  
  const [show,setShow] = useState(showBanner);

  if (!show){
    return null
  }
  
  if(tillDate){
    
    const now = new Date()
    now.setHours(0,0,0,0)
    const nowMilis = now.getTime()
    const endDateMili = tillDate.getTime()
    
    if(nowMilis > endDateMili){
      return null;
    }
  }
  
  return (
    <div className="office-hours-banner">
      <div className="office-hours-copy">
        <label>
         {text}
        </label>
      </div>
      <div className="office-hours-copy-buttons-container">
        <ButtonLink
          className={"office-hours-copy-link-button"}
          includeChevron={false}
          href={"https://portal.valuesadvisor.org/office-hours"}
        >
          {" "}
          Click for Details{" "}
        </ButtonLink>
        <div className="banner-close-icon-container">
          <span
            onClick={(e) => {
              e.preventDefault();
              setShow(false)
            }}
            className="banner-close-icon"
            uk-icon="icon: close; ratio: 1"
          ></span>
        </div>
      </div>
    </div>
  );
};

export default OfficeHoursBanner;

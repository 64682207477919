import React, { useContext } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import FormInput from "../../../components/_shared/FormInput";
import FormTextarea from "../../../components/_shared/FormTextarea";
//import MultiSelect from "../../_shared/FormCheckbox";
//import cx from "classnames";
import calculatePercentage from "../../../utils/calculatePercentage";
import FormSelect from "../../../components/_shared/FormSelect";
import { FADashboardContext } from "../Context";

const OBAboutTeam = (props) => {
  const { teamInfo, typeList, firm } = useContext(FADashboardContext);
  const {
    degreesType,
    affiliationType,
    advisorOfferingType,
    servingCountriesType,
    unSdgType,
    differentClientType,
    compensationStructureType,
    impactThemesType,
    assetClassesType,
  } = typeList;
  const setAum = (e) => {
    teamInfo.setAum(e.target.value);
  };

  const setNoOfPeople = (e) => {
    teamInfo.setNoOfPeople(e.target.value);
  };

  const setImpactField = (e) => {
    teamInfo.setImpactField(e.target.value);
  };

  const setNoOfClients = (e) => {
    teamInfo.setNoOfClients(e.target.value);
  };

  const setEmployeesColor = (e) => {
    teamInfo.setEmployeesColor(e.target.value);
  };

  const setEmployeesWomen = (e) => {
    teamInfo.setEmployeesWomen(e.target.value);
  };

  const isNoOfClientsDisclosableChecked = (e) => {
    const currentState = teamInfo.isNoOfClientsDisclosable;
    teamInfo.setIsNoOfClientDisclosable(!currentState);
  };

  const isClientAccountSizeDisclosableChecked = (e) => {
    const currentState = teamInfo.isClientAccountSizeDisclosable;
    teamInfo.setIsClientAccountSizeDisclosable(!currentState);
  };

  const setAffiliation = (e) => {
    teamInfo.setAffiliation(e);
  };

  const setAssetClasses = (e) => {
    teamInfo.setAssetClasses(e);
  };

  const setImpactThemes = (e) => {
    teamInfo.setImpactThemes(e);
  };

  const setValuesAlignedAUM = (e) => {
    teamInfo.setValuesAlignedAum(e.target.value);
  };

  const setClientAccountSize = (e) => {
    teamInfo.setClientAccountSize(e.target.value);
  };

  const setAdvisorOffering = (e) => {
    teamInfo.setAdvisorOffering(e);
  };

  const setDifferentClients = (e) => {
    teamInfo.setDifferentClients(e);
  };

  const setServingCountries = (e) => {
    teamInfo.setServingCountries(e);
  };

  const setCompensationStructure = (e) => {
    teamInfo.setCompensationStructure(e);
  };

  const setTeamOfferedVaNImpactYears = (e) => {
    console.log(e.target.value);
    teamInfo.setTeamOfferedVaNImpactYears(e.target.value);
  };

  const setAumUnit = (e) => {
    teamInfo.setAumUnit(e.target.value);
  };

  const setAumUnitAsOfDate = (e) => {
    teamInfo.setAumUnitAsOfDate(e.target.value);
  };

  const setClientAccountSizeUnit = (e) => {
    teamInfo.setClientAccountSizeUnit(e.target.value);
  };

  const setDegrees = (e) => {
    teamInfo.setDegrees(e);
  };

  const setUnSdg = (e = []) => {
    //limit selection to maximum 4 options
    const value = e.length > 4 ? teamInfo.unSdgs.value : e;
    teamInfo.setUnSdgs(value);
  };

  const setCopyInformationFromFirm = (isSame) => {
    if (isSame) {
      const {
        aum,
        aumUnit,
        valuesAlignedAum,
        aumUnitAsOfDate,
        noOfEmployees,
        noOfClients,
        typicalAccountSize,
        typicalAccountSizeUnit,
        employeesColor,

        employeesWomen,
        firmOfferedVaNImpactYears,
      } = firm;

      teamInfo.setAum(aum.value);
      teamInfo.setAumUnit(aumUnit.value);
      teamInfo.setValuesAlignedAum(valuesAlignedAum.value);
      teamInfo.setAumUnitAsOfDate(aumUnitAsOfDate.value);
      teamInfo.setNoOfPeople(noOfEmployees.value);
      teamInfo.setNoOfClients(noOfClients.value);
      teamInfo.setClientAccountSize(typicalAccountSize.value);
      teamInfo.setClientAccountSizeUnit(typicalAccountSizeUnit.value);
      teamInfo.setEmployeesColor(employeesColor.value);
      teamInfo.setEmployeesWomen(employeesWomen.value);
      teamInfo.setTeamOfferedVaNImpactYears(firmOfferedVaNImpactYears.value);
    }
  };

  const percentageOfVA = calculatePercentage(
    teamInfo.valuesAlignedAum.value,
    teamInfo.aum.value
  );

  const aumUnitAsOfDate = !teamInfo.aumUnitAsOfDate.value
    ? ""
    : teamInfo.aumUnitAsOfDate.value.split("T")[0];

  return (
    <div className="FA-form-container">
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            onChange={(e) => {
              setCopyInformationFromFirm(e.target.checked);
            }}
          />{" "}
          All of the data for “team” is the same as for the “firm”
        </Label>
      </FormGroup>
      <Form className="FA-form">
        <div className="FA-form-fields FA-form3">
          <div className="three-fields-container">
            <div className="position-relative account-size-section">
              <FormInput
                label="Team’s total AUM/AUA"
                value={teamInfo.aum.value}
                error={teamInfo.aum.error}
                showError={teamInfo.aum.showError}
                onChange={setAum}
              />
              <div className="radio-buttons">
                <div>
                  <Input
                    type="radio"
                    value="K"
                    checked={teamInfo.aumUnit.value === "K"}
                    onChange={setAumUnit}
                  />
                  <span>Thousands - K</span>
                </div>
                <div>
                  <Input
                    type="radio"
                    value="M"
                    checked={teamInfo.aumUnit.value === "M"}
                    onChange={setAumUnit}
                  />
                  <span>Millions - M</span>
                </div>
                <div>
                  <Input
                    type="radio"
                    value="B"
                    checked={teamInfo.aumUnit.value === "B"}
                    onChange={setAumUnit}
                  />
                  <span>Billions -B</span>
                </div>
              </div>
            </div>

            <div className="position-relative account-size-section">
              <FormInput
                label="Team's values aligned AUM/AUA (SRI/ESG/Impact)"
                error={teamInfo.valuesAlignedAum.error}
                showError={teamInfo.valuesAlignedAum.showError}
                value={teamInfo.valuesAlignedAum.value}
                onChange={setValuesAlignedAUM}
              />
              <div className="radio-buttons">
                <div>
                  <Input
                    type="radio"
                    value="K"
                    checked={teamInfo.aumUnit.value === "K"}
                    onChange={setAumUnit}
                  />
                  <span>Thousands - K</span>
                </div>
                <div>
                  <Input
                    type="radio"
                    value="M"
                    checked={teamInfo.aumUnit.value === "M"}
                    onChange={setAumUnit}
                  />
                  <span>Millions - M</span>
                </div>
                <div>
                  <Input
                    type="radio"
                    value="B"
                    checked={teamInfo.aumUnit.value === "B"}
                    onChange={setAumUnit}
                  />
                  <span>Billions -B</span>
                </div>
              </div>
            </div>
            <div className="position-relative account-size-section">
              <FormInput
                inputStyle={{ border: "none" }}
                label="Percent of Team's AUM/AUA that is values aligned"
                value={percentageOfVA}
                readOnly={true}
              />
            </div>
          </div>
          <div className="two-fields-container">
            <div>
              <FormInput
                label="Team AUM numbers as of:"
                focus={true}
                value={aumUnitAsOfDate}
                type="date"
                error={teamInfo.aumUnitAsOfDate.error}
                showError={teamInfo.aumUnitAsOfDate.showError}
                onChange={setAumUnitAsOfDate}
              />
            </div>
            <div></div>
            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={degreesType}
                value={teamInfo.degrees.value}
                onChange={setDegrees}
                isMulti={true}
                error={teamInfo.degrees.error}
                showError={teamInfo.degrees.showError}
                label="Team professional degrees, certifications, licenses or designations. (check all that apply)"
              />
            </div>
          </div>
          <div className="three-fields-container three-fields-container-multicheck">
            <div>
              <FormInput
                label="Number of people on our Team?"
                value={teamInfo.noOfPeople.value}
                error={teamInfo.noOfPeople.error}
                showError={teamInfo.noOfPeople.showError}
                maxLength={3}
                onChange={setNoOfPeople}
                inputStyle={{ borderBottom: "none" }}
              />
            </div>
            <div className="position-relative account-size-section">
              {teamInfo.isNoOfClientsDisclosable ? (
                <React.Fragment>
                  <FormInput
                    label="Number of clients our Team serves"
                    value={teamInfo.noOfClients.value}
                    maxLength={5}
                    error={teamInfo.noOfClients.error}
                    showError={teamInfo.noOfClients.showError}
                    onChange={setNoOfClients}
                  />
                </React.Fragment>
              ) : (
                <Label>Number of clients our Team serves</Label>
              )}
              <div
                className="NA-container"
                style={{
                  marginLeft: "1.8rem",
                }}
              >
                <input
                  type="checkbox"
                  checked={!teamInfo.isNoOfClientsDisclosable}
                  onChange={(e) => {
                    isNoOfClientsDisclosableChecked(e);
                  }}
                />
                <span
                  style={{
                    fontSize: "14px",
                    paddingLeft: "10px",
                    lineHeight: "normal",
                    fontWeight: "300",
                  }}
                >
                  Firm does not disclose/collect this data
                </span>
              </div>
            </div>

            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={affiliationType}
                value={teamInfo.affiliation.value}
                onChange={setAffiliation}
                isMulti={true}
                error={teamInfo.affiliation.error}
                showError={teamInfo.affiliation.showError}
                labelClass="label-name"
                label="Team professional affiliations. (check all that apply)"
              />
            </div>
          </div>
          <FormTextarea
            type="textarea"
            label="Team’s leadership in the impact field includes"
            error={teamInfo.impactField.error}
            showError={teamInfo.impactField.showError}
            maxLength={1000}
            value={teamInfo.impactField.value}
            onChange={setImpactField}
            className="input-full-line textarea-input"
          />
          <div className="two-fields-container">
            <div>
              <FormInput
                inputStyle={{ borderBottom: "none" }}
                label="Number of years our Team has offered impact or values based investment services"
                error={teamInfo.teamOfferedVaNImpactYears.error}
                showError={teamInfo.teamOfferedVaNImpactYears.showError}
                value={teamInfo.teamOfferedVaNImpactYears.value}
                onChange={setTeamOfferedVaNImpactYears}
              />
            </div>
            <div className="position-relative account-size-section">
              {teamInfo.isClientAccountSizeDisclosable ? (
                <React.Fragment>
                  <FormInput
                    label="Team typical account size."
                    error={teamInfo.clientAccountSize.error}
                    showError={teamInfo.clientAccountSize.showError}
                    value={teamInfo.clientAccountSize.value}
                    onChange={setClientAccountSize}
                    disabled={!teamInfo.isClientAccountSizeDisclosable}
                  />
                  <div className="radio-buttons">
                    <div>
                      <Input
                        type="radio"
                        value="K"
                        checked={teamInfo.clientAccountSizeUnit.value === "K"}
                        onChange={setClientAccountSizeUnit}
                      />
                      <span>Thousands - K</span>
                    </div>
                    <div>
                      <Input
                        type="radio"
                        value="M"
                        checked={teamInfo.clientAccountSizeUnit.value === "M"}
                        onChange={setClientAccountSizeUnit}
                      />
                      <span>Millions - M</span>
                    </div>
                    <div>
                      <Input
                        type="radio"
                        value="B"
                        checked={teamInfo.clientAccountSizeUnit.value === "B"}
                        onChange={setClientAccountSizeUnit}
                      />
                      <span>Billions -B</span>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <Label>Team typical account size.</Label>
              )}
              <div
                className="NA-container"
                style={{
                  marginLeft: "1.8rem",
                }}
              >
                <input
                  type="checkbox"
                  checked={!teamInfo.isClientAccountSizeDisclosable}
                  onChange={(e) => {
                    isClientAccountSizeDisclosableChecked(e);
                  }}
                />
                <span
                  style={{
                    fontSize: "14px",
                    paddingLeft: "10px",
                    lineHeight: "normal",
                    fontWeight: "300",
                  }}
                >
                  Firm does not disclose/collect this data
                </span>
              </div>
            </div>
          </div>
          <div className="two-fields-container">
            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={servingCountriesType}
                value={teamInfo.servingCountries.value}
                onChange={setServingCountries}
                isMulti={true}
                error={teamInfo.servingCountries.error}
                showError={teamInfo.servingCountries.showError}
                label="Team serves customers in the following regions (Select all that apply)"
              />
            </div>

            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={unSdgType}
                value={teamInfo.unSdgs.value}
                onChange={setUnSdg}
                isMulti={true}
                error={teamInfo.unSdgs.error}
                showError={teamInfo.unSdgs.showError}
                label="What are the top UN SDG goals that you work toward with your clients (max 4)"
              />
            </div>
          </div>
          <div className="multiple-percentage-fields">
            <h3>
              Percentage of employees/team members that are Women / Non-Binary
              or people of color
            </h3>
            <div className="percentage-field">
              <h4>Women / Non-Binary</h4>
              <div>
                <FormInput
                  value={teamInfo.employeesWomen.value}
                  onChange={setEmployeesWomen}
                />
                <span>%</span>
              </div>
              {teamInfo.employeesWomen.showError ? (
                <span className="error-message-percentage contains-error">
                  {teamInfo.employeesWomen.error}
                </span>
              ) : null}
            </div>
            <div className="percentage-field">
              <h4>People of color</h4>
              <div>
                <FormInput
                  value={teamInfo.employeesColor.value}
                  onChange={setEmployeesColor}
                />
                <span>%</span>
              </div>
              {teamInfo.employeesColor.showError ? (
                <span className="error-message-percentage contains-error">
                  {teamInfo.employeesColor.error}
                </span>
              ) : null}
            </div>
          </div>
          <div className="three-fields-container three-fields-container-only-multicheck">
            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={differentClientType}
                value={teamInfo.differentClients.value}
                onChange={setDifferentClients}
                isMulti={true}
                error={teamInfo.differentClients.error}
                showError={teamInfo.differentClients.showError}
                label="The Team works with these types of clients (select all that apply)"
              />
            </div>

            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={compensationStructureType}
                value={teamInfo.compensationStructure.value}
                onChange={setCompensationStructure}
                isMulti={true}
                error={teamInfo.compensationStructure.error}
                showError={teamInfo.compensationStructure.showError}
                label="Team's compensation structure include (check all that apply)"
              />
            </div>

            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={advisorOfferingType}
                value={teamInfo.advisorOffering.value}
                onChange={setAdvisorOffering}
                isMulti={true}
                error={teamInfo.advisorOffering.error}
                showError={teamInfo.advisorOffering.showError}
                label="In addition to portfolio management,  we offer in house (check all that apply)"
              />
            </div>
          </div>
          <div className="three-fields-container ">
            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={impactThemesType}
                value={teamInfo.impactThemes.value}
                onChange={setImpactThemes}
                isMulti={true}
                error={teamInfo.impactThemes.error}
                showError={teamInfo.impactThemes.showError}
                label="Themes our Team specializes in and offers impact investment opportunities"
              />
            </div>

            <div className="container-SM-links">
              <FormSelect
                style={{ width: "100%" }}
                options={assetClassesType}
                value={teamInfo.assetClasses.value}
                onChange={setAssetClasses}
                isMulti={true}
                error={teamInfo.assetClasses.error}
                showError={teamInfo.assetClasses.showError}
                label="Themes our Team specializes in and offers impact investment opportunities"
                labelClass="label-name"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OBAboutTeam;

import React, { useContext } from "react";

import { patchUserClientType } from "../../api/users";
import "./SwitchClientType.css";
import { AuthContext } from "../../Context/AuthContext";
import { CLIENT_TYPES } from "../../constants/constants";
import { JoinClientType } from "../Join/ClientType/ClientType";
const Component = (props) => {
  const authContext = useContext(AuthContext);

  return (
    <>
      <div className="switch--container">
        <JoinClientType
          next={(clientType) => {
            if (CLIENT_TYPES[clientType]) {
              patchUserClientType({ clientType });
              authContext.setClientType(clientType);
            }
          }}
        />
      </div>
    </>
  );
};
export default Component

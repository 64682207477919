import React from "react";
import { toLinkWithHttps } from "../../../utils/toLinkWithHttps";
import classes from "./DownloadLinks.module.css";
export const DownloadLinks = ({
  materialTypes = [],
  richMediaLinks = [],
  currentADV,
}) => {
  const materialTypesLinksWithHttps = materialTypes.map(toLinkWithHttps);
  const richMediaLinksLinskWithHttps = richMediaLinks
    .map((rml) => {
      return {
        value: rml.description,
        link: rml.url,
      };
    })
    .map(toLinkWithHttps);
  const links = [
    ...materialTypesLinksWithHttps,
    ...richMediaLinksLinskWithHttps,
  ];
  return (
    <div className="uk-grid">
      <div className="uk-width-1-1 downlown-list-container">
        <h3 className={`section-title ${classes.title}`}>REPORTS</h3>
        {links.map((m) => {
          return (
            <div key={m.link} className="downlown-list">
              <a href={m.link} target="_blank" rel="noopener noreferrer">
                <img src="img/download.svg" alt="" />
                <span>{m.value}</span>
              </a>
            </div>
          );
        })}
        {currentADV && (
          <div className="downlown-list">
            <a href={currentADV} target="_blank" rel="noopener noreferrer">
              <img src="img/download.svg" alt="" />
              <span>ADV (SEC Public Disclosure)</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

import { isEmail, isURL, isNumeric } from "validator";
import { isValidObj, requiredObj } from "../utils";
import { checkEmailUnique } from "../../../api/advisor";

export const actions = {
  UPDATE_STATE: "UPDATE_STATE",
};
export const getStartedReducer = (state = getStartedState, action) => {
  if (action.type === actions.UPDATE_STATE) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};
const newAddress = {
  line1: { value: "", error: "", showError: false, required: true },
  line2: { value: "", error: "", showError: false, required: false },
  city: { value: "", error: "", showError: false, required: true },
  state: { value: "", error: "", showError: false, required: true },
  country: { value: "", error: "", showError: false, required: true },
  zipCode: { value: "", error: "", showError: false, required: true },
};
const newSocialLink = {
  type: { value: "", error: "", showError: false, required: true },
  link: { value: "", error: "", showError: false, required: true },
};

const state = {
  setAddresses: function (addresses) {
    this.addresses = addresses;
    return this;
  },
  addAddress: function () {
    this.addresses.push(newAddress);
    return this;
  },
  setSocialLinks: function (socialLinks) {
    this.socialLinks = socialLinks;
    return this;
  },
  addNewSocialLink: function () {
    this.socialLinks.push(newSocialLink);
    return this;
  },
  removeAddressAtIndex: function (index) {
    this.addresses.splice(index, 1);
    return this;
  },
  removeSocialLinkAtIndex: function (index) {
    this.socialLinks.splice(index, 1);
    return this;
  },
  updateAddressAtIndex: function (index, address) {
    this.addresses[index] = {
      ...this.addresses[index],
      ...address,
    };
    return this;
  },
  updateSocialLinkAtIndex: function (index, socialLink) {
    this.socialLinks[index] = {
      ...this.socialLinks[index],
      ...socialLink,
    };
    return this;
  },
  userId: "",
  isValid: true,
  dispatch: (action) => {},
  validate: async function (isUpdate = false) {
    let objs = [];
    let isValid = false;
    for (const key in this) {
      if (typeof this[key] === "object" && key !== "headquarters") {
        requiredObj(this[key]);
        objs.push(this[key]);
      }
    }
    for (const key in this.headquarters) {
      requiredObj(this.headquarters[key]);
      objs.push(this.headquarters[key]);
    }
    for (const address of this.addresses) {
      for (const key in address) {
        requiredObj(address[key]);
        objs.push(address[key]);
      }
    }
    for (const socialLink of this.socialLinks) {
      for (const key in socialLink) {
        requiredObj(socialLink[key]);
        objs.push(socialLink[key]);
      }
    }
    isValid = objs.every((obj) => isValidObj(obj));

    if (this.email.value && !isEmail(this.email.value)) {
      this.email.error = "Invalid email";
      this.email.showError = true;
      isValid = false;
    }
    if (this.website.value && !isURL(this.website.value)) {
      this.website.error = "Invalid Website URL";
      this.website.showError = true;
      isValid = false;
    }

    if (!isUpdate) {
      const emailCheckResult = await checkEmailUnique({
        email: this.email.value,
      });
      if (emailCheckResult.data.success && !emailCheckResult.data.unique) {
        this.email.error = "Email has already been taken";
        this.email.showError = true;
        isValid = false;
      }
    }

    return {
      ...this,
      isValid,
    };
  },
  fromAdvisor: function (advisor) {
    this.userId = advisor.userId;
    this.password.value = advisor.user.password ? advisor.user.password : "";
    this.setFirmName(advisor.firm.name);
    this.setAdvisorName(advisor.name);
    this.setAdvisorTitle(advisor.title);
    this.setEmail(advisor.user.email);
    this.setYearsWithFirm(advisor.yearsWithFirm);
    this.setYearsAsAdvisor(advisor.yearsAsAdvisor);
    this.setWebsite(advisor.website);
    this.setCurrentADV({
      ...this.currentADV,
      value: advisor.currentADV,
      previousObjectUrl: advisor.currentADV,
    });
    this.setProfilePic({
      ...this.profilePic,
      src: advisor.profilePic,
      value: advisor.profilePic,
      previousObjectUrl: advisor.profilePic,
    });
    this.setSuggestedByMembers(advisor.suggestedBy);
    this.setHeadQuarters(advisor.firm.headquarters);

    this.setSuggestedByMembers(
      (advisor.suggestedByMembers || [])
        .filter((member) => member.suggestedByMemberType)
        .map((member) => member.suggestedByMemberType.value)
    );
    this.setDegrees(
      (advisor.degrees || []).map((degree) => degree.degreesType.value)
    );
    const headQuarters = (advisor.addresses || []).find(
      (address) => address.primary
    );
    this.setHeadQuarters(this.headquarters.setLine1(headQuarters.line1));
    this.setHeadQuarters(this.headquarters.setLine2(headQuarters.line2));
    this.setHeadQuarters(this.headquarters.setCity(headQuarters.city));
    this.setHeadQuarters(this.headquarters.setState(headQuarters.state));
    this.setHeadQuarters(this.headquarters.setCountry(headQuarters.country));
    this.setHeadQuarters(this.headquarters.setZipCode(headQuarters.zipCode));
    const addresses = (advisor.addresses || [])
      .filter((address) => !address.primary)
      .map((address) => {
        return {
          line1: { ...newAddress.line1, value: address.line1 },
          line2: { ...newAddress.line2, value: address.line2 },
          city: { ...newAddress.city, value: address.city },
          state: { ...newAddress.state, value: address.state },
          country: { ...newAddress.country, value: address.country },
          zipCode: { ...newAddress.zipCode, value: address.zipCode },
        };
      });
    this.setAddresses(addresses);
    const socialLinks = (advisor.socialLinks || [])
      .filter((sl) => sl.socialLinkType)
      .map((socialLink) => {
        return {
          type: {
            ...newSocialLink.type,
            value: socialLink.socialLinkType.value,
            label: socialLink.socialLinkType.label,
          },
          link: { ...newSocialLink.link, value: socialLink.link },
        };
      });

    this.setSocialLinks(socialLinks);
    return this;
  },
  fromSavedState: function (savedState = this) {
    for(const key in savedState){
      if(this[key]){
        this[key] = savedState[key]
      }
    }
    return this;
  },
  setProfilePic: function (profilePic) {
    this.profilePic = profilePic;

    return this;
  },
  profilePic: {
    value: "",
    error: "",
    showError: false,
    src: "",
    file: {},
    name: "",
    croppedImageUrl: "",
    previousObjectUrl: "",
    required: true,
  },
  setFirmName: function (value) {
    this.firmName = {
      ...this.firmName,
      error: "",
      showError: false,
      value: value,
    };
    return this;
  },
  firmName: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setSuggestedByMembers: function (value = []) {
    this.suggestedByMembers = {
      ...this.suggestedByMembers,
      value: value || [],
    };
    return this;
  },
  suggestedByMembers: {
    value: [],
    error: "",
    showError: false,
    required: false,
  },
  setAdvisorName: function (value) {
    this.advisorName = {
      ...this.advisorName,
      error: "",
      showError: false,
      value: value,
    };

    return this;
  },
  advisorName: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setAdvisorTitle: function (value) {
    this.title = {
      ...this.title,
      error: "",
      showError: false,
      value: value,
    };

    return this;
  },
  title: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setEmail: function (value) {
    this.email = {
      ...this.email,
      error: "",
      showError: false,
      value: value,
    };

    return this;
  },
  email: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setPassword: function (value) {
    this.password = {
      ...this.password,
      error: "",
      showError: false,
      value: value,
    };

    return this;
  },
  password: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setYearsWithFirm: function (value) {
    if (
      !isNumeric(`${value}`)
    ) {
      this.yearsWithFirm = {
        ...this.yearsWithFirm,
        value: "",
      };
      return this;
    }

    this.yearsWithFirm = {
      ...this.yearsWithFirm,
      error: "",
      showError: false,
      value: Number(value),
    };

    return this;
  },
  yearsWithFirm: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setYearsAsAdvisor: function (value) {
    if (
      !isNumeric(`${value}`)
    ) {
      this.yearsAsAdvisor = {
        ...this.yearsAsAdvisor,
        value: "",
      };
      return this;
    }
    this.yearsAsAdvisor = {
      ...this.yearsAsAdvisor,
      error: "",
      showError: false,
      value: Number(value),
    };

    return this;
  },
  yearsAsAdvisor: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setDegrees: function (value) {
    this.degrees = {
      ...this.degrees,
      value: value,
    };

    return this;
  },
  degrees: {
    value: [],
    error: "",
    showError: false,
    required: false,
  },
  setCurrentADV: function (currentAdv) {
    this.currentADV = {
      ...this.currentADV,
      ...currentAdv,
    };

    return this;
  },
  currentADV: {
    value: "",
    error: "",
    file: {},
    previousObjectUrl: "",
    showError: false,
    required: false,
  },
  setWebsite: function (value) {
    this.website = {
      ...this.website,
      error: "",
      showError: false,
      value: value,
    };

    return this;
  },
  website: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setHeadQuarters: function (headquarters) {
    this.headquarters = {
      ...this.headquarters,
      ...headquarters,
    };
    return this;
  },
  headquarters: {
    setLine1: function (value) {
      this.line1 = {
        ...this.line1,
        error: "",
        showError: false,
        value: value,
      };
      return this;
    },
    setLine2: function (value) {
      this.line2 = {
        ...this.line2,
        error: "",
        showError: false,
        value: value,
      };
      return this;
    },
    setCity: function (value) {
      this.city = {
        ...this.city,
        error: "",
        showError: false,
        value: value,
      };
      return this;
    },
    setState: function (value) {
      this.state = {
        ...this.state,
        error: "",
        showError: false,
        value: value,
      };
      return this;
    },
    setCountry: function (value) {
      this.country = {
        ...this.country,
        error: "",
        showError: false,
        value: value,
      };
      return this;
    },
    setZipCode: function (value) {
      this.zipCode = {
        ...this.zipCode,
        error: "",
        showError: false,
        value: value,
      };
      return this;
    },
    ...newAddress,
  },
  socialLinks: [],
  addresses: [newAddress],
};
export const getStartedState = new Proxy(state, {
  get: function (target, prop, receiver) {
    const value = target[prop];
    // curry setters so its easier to update state by calling dispatch after modifying state, this avoid having to call dispatch at the end of every setter
    if (value instanceof Function) {
      return function (...args) {
        const obj = this === receiver ? target : this;
        const result = value.apply(obj, args);
        if (result instanceof Promise) {
          result.then((res) => {
            obj.dispatch({
              type: actions.UPDATE_STATE,
              payload: res,
            });
          });
        } else {
          obj.dispatch({
            type: actions.UPDATE_STATE,
            payload: result,
          });
        }
        return result;
      };
    }
    return value;
  },
});

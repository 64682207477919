import React, { useEffect, useContext } from "react";
import { getAllAdvisors } from "../../api/searchResults";
import { fetchAllType } from "../../api/advisor";
import { getCountries } from "../../api/countries";
import SearchResults from "./SearchResults";
import { useSearchResults } from "../../hooks/useSearchResults";
import { SearchResultsContext } from "../../Context/SearchResultsContext";
import { Advisor } from "../../models/advisor";
import {
  sortAdvisors,
  filterAdvisors,
  addNumericValToAccountSizes,
} from "./_util";
import {
  CLIENT_TYPES,
  INITIAL_MINIMUM_ACCOUNT_SIZE,
} from "../../constants/constants";

import { AuthContext } from "../../Context/AuthContext";

const fetchAllAdvisors = async () => {
  const response = await getAllAdvisors();
  return response.data;
};
const fetchAllCountries = async () => {
  const response = await getCountries();
  return response.data;
};

const prepareAllAdvisors = async () => {
  const fetchedAdvisors = await fetchAllAdvisors();
  const fetchedCountries = await fetchAllCountries();

  const newAdvisors = fetchedAdvisors.map((advisor) => {
    let primaryAddress = advisor.addresses.find((a) => a.primary);
    const hasCoords =
      Boolean(primaryAddress.geocoding.latitude) &&
      Boolean(primaryAddress.geocoding.longitude);
    if (!hasCoords) {
      const country = fetchedCountries.find(
        (c) => c.name === primaryAddress.country
      );
      if (country) {
        primaryAddress.geocoding.latitude = country.latitude;
        primaryAddress.geocoding.longitude = country.longitude;
      }
    }
    return new Advisor({ ...advisor, toBeCompared: false, primaryAddress });
  });
  return newAdvisors;
};

const getInitialFilters = (types) => {
  const typesKeys = Object.keys(types);
  const initialSelectedFilters = typesKeys.reduce((acc, nextVal) => {
    return { ...acc, [nextVal]: [] };
  }, {});
  //set filter by accountSize on init
  const initialAccountSize = types["minimumAccountSizeType"]?.find(
    (as) => as.value === INITIAL_MINIMUM_ACCOUNT_SIZE.toLocaleLowerCase()
  );
  if (initialAccountSize) {
    initialSelectedFilters["minimumAccountSizeType"].push(
      initialAccountSize.id
    );
  }
  return initialSelectedFilters;
};

const openDisclaimerPopUp = () => {
  //open legal disclaimer upon entering page
  if (!window.localStorage.getItem("disclaimerSeen") && window.UIkit) {
    window.UIkit.modal("#modal-legal").show();
    window.localStorage.setItem("disclaimerSeen", "1");
  }
};
const filterAdvisorsByClientType = (advisors, clientType, filterTypes) => {
  if (!filterTypes || !Object.keys(filterTypes).length || !clientType) {
    return advisors;
  }

  const differentClientTypes = filterTypes["differentClientType"] || [];
  const institutionalClientType = differentClientTypes.find(
    (type) => type.value === "institutional"
  );
  const individualFamiliesClientType = differentClientTypes.find(
    (type) => type.value === "individual/families"
  );
  const endowerClientType = differentClientTypes.find(
    (type) => type.value === "endowments/foundations/trusts"
  );
  const result = advisors.filter((a) => {
    const ids = a.teamInfo.differentClients.map((it) => it.clientId);
    if (clientType === CLIENT_TYPES.INSTITUTIONAL) {
      return (
        ids.includes(institutionalClientType.id) ||
        ids.includes(endowerClientType.id)
      );
    }
    if (clientType === CLIENT_TYPES.INDIVIDUAL) {
      return ids.includes(individualFamiliesClientType.id);
    }
    return a;
  });
  return result;

};
const Container = () => {
  const {
    advisors,
    setAdvisors,
    filterTypes,
    setFilterTypes,
    selectedFilters,
    setSelectedFilters,
    selectedSort,
    setSelectedSort,
    trackSelectedFilter,
    trackSelectedAdvisor,
    selectedOrder,
    setSelectedOrder,
    getSearchState,
  } = useSearchResults();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    const savedState = getSearchState();
    prepareAllAdvisors().then((newAdvisors) => {
      setAdvisors(newAdvisors);
      fetchAllType().then(({ data }) => {
        const types = { ...data };
        types.minimumAccountSizeType = addNumericValToAccountSizes(
          types.minimumAccountSizeType.filter((sz) => sz.value !== "100k")
        );

        setFilterTypes(types);
        //const initialSelectedFilters = getInitialFilters(types);
        //setSelectedFilters(initialSelectedFilters);

        if (!savedState) {
          const initialSelectedFilters = getInitialFilters(types);
          setSelectedFilters(initialSelectedFilters);
        } else {
          setSelectedFilters(savedState.selectedFilters);
          setSelectedSort(savedState.selectedSort);
        }
      });
    });

    openDisclaimerPopUp();
  }, [
    setAdvisors,
    getSearchState,
    setFilterTypes,
    setSelectedFilters,
    setSelectedSort,
  ]);

  const setInitialFilters = (types) => {
    const initialSelectedFilters = getInitialFilters(types);
    setSelectedFilters(initialSelectedFilters);
    setSelectedSort({});
  };
  const filteredByClientType = filterAdvisorsByClientType(
    advisors,
    authContext.clientType,
    filterTypes
  );
  const filteredAdvisors = sortAdvisors(
    filterAdvisors(filteredByClientType, selectedFilters, filterTypes),
    selectedSort,
    selectedOrder
  );

  const _setSelectedFilters = (selectedFilters, selectedFilterData) => {
    setSelectedFilters(selectedFilters);
    if (selectedFilterData) {
      trackSelectedFilter(selectedFilterData);
    }
  };

  return (
    <>
      <SearchResultsContext.Provider
        value={{
          advisors: filteredAdvisors,
          setAdvisors,
          filterTypes,
          selectedFilters,
          setSelectedFilters: _setSelectedFilters,
          selectedSort,
          setSelectedSort,
          selectedOrder,
          setSelectedOrder,
          setInitialFilters,
          trackSelectedAdvisor,
        }}
      >
        <SearchResults />
      </SearchResultsContext.Provider>
    </>
  );
};
export default Container;

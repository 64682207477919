import React, { useEffect } from "react";
import { SimilarAdvisors } from "./SimilarAdvisors/SimilarAdvisors";
import { DownloadLinks } from "./DownloadLinks/DownloadLinks";
import { SocialMediaLinks } from "./SocialMediaLinks/SocialMediaLinks";
import { OtherOffices } from "./OtherOffices/OtherOffices";
import { PrintProfile } from "./PrintProfile/PrintProfile";
import { HeadQuarters } from "./HeadQuarters/HeadQuarters";
import { Tabs } from "./Tabs/Tabs";
import { Introduction } from "./Introduction/Introduction";
import { capitalize } from "lodash";
import PropTypes from "prop-types";


import { Address } from "../../models/address";
import {
  Advisor,
  Firm,
  TeamInfo,
  ValuesAlignedInvestingInfo,
} from "../../models/advisor";
import FloatingContact from "../../components/FloatingContact/FloatingContact";

function setHiBtnPosition() {
  document.addEventListener("scroll", function (e) {
    const sayHiBtnContainer = document.getElementById("say-hi-btn-container");
    const sayHiButtonLimitElement = document.getElementById("say-hi-btn-limit");
    if (sayHiButtonLimitElement) {
      const limitRect = document;
      sayHiButtonLimitElement.getBoundingClientRect();

      if (window.innerHeight - limitRect.bottom >= 1) {
        sayHiBtnContainer.style.bottom = "unset";
        sayHiBtnContainer.style.top =
          limitRect.top - sayHiBtnContainer.offsetHeight + "px";
      } else {
        sayHiBtnContainer.style.top = "unset";
        sayHiBtnContainer.style.bottom = "0";
      }
    }
  });
}

const AdvisorProfile = ({
  firm = {},
  teamInfo = {},
  introductionProps = {},
  addresses = [],
  website = "",
  socialLinkTypes = [],
  downloadLinksProps,
  unsdgTypes = [],
  tabProps = {},
  similarAdvisors = [],

  onPrintProfile = () => {},
  onGetInTouch = (e) => {},
  onEditProfile = () => {},
  onApproveProfile = () => {},
  onRejectProfile = () => {},
  showSimilarAdvisors = true,
}) => {
  useEffect(() => {
    setHiBtnPosition();
  }, []);
  if (!teamInfo || !Object.keys(teamInfo).length) {
    return null;
  }
  if (!firm || !Object.keys(firm).length) {
    return null;
  }
  if (!introductionProps || !Object.keys(introductionProps).length) {
    return null;
  }
  if (!tabProps || !Object.keys(tabProps).length) {
    return null;
  }

  return (
    <main data-testid={"advisor-profile-container"}>
      <Introduction
        className="no-print-show"
        {...introductionProps}
        firm={firm}
        teamInfo={teamInfo}
        unsdgTypes={unsdgTypes}
        {...downloadLinksProps}
        socialLinkTypes={socialLinkTypes}
        addresses={addresses}
        website={website}
        onPrintProfile={onPrintProfile}
        onGetInTouch={onGetInTouch}
        onEditProfile={onEditProfile}
        onApproveProfile={onApproveProfile}
        onRejectProfile={onRejectProfile}
      />
      {/* maincomponents start here */}
      <div className="maincomponent advisor-profile__maincomponent --no-margin-top no-print-show">
        <div className="uk-container">
          <div className="uk-grid uk-grid-divider fix-border">
            <div className="uk-width-1-4 uk-first-column uk-visible@m">
              <div className="filter-midle advisor-profile-filter">
                <div className="filter-section">
                  <div className="uk-grid">
                    <div className="uk-width-1-1">
                      <div uk-accordion={"true"} className="uk-accordion">
                        <div className="uk-open headquarters-nested-section">
                          <HeadQuarters
                            addresses={addresses}
                            website={website}
                          />

                          <SocialMediaLinks socialLinkTypes={socialLinkTypes} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="filter-section">
                  <div className="uk-grid">
                    <div className="uk-width-1-1">
                      <OtherOffices addresses={addresses} />
                    </div>
                  </div>
                </div>

                <div className="filter-section">
                  <DownloadLinks {...downloadLinksProps} />
                </div>
                {unsdgTypes.length ? (
                  <React.Fragment>
                    {/*<hr />*/}
                    <div className="filter-section">
                      <div className="uk-grid">
                        <div className="uk-width-1-1 impact-section">
                          <h3 className="section-title">
                            Impact Expertise in UN Sustainable Development Goals
                          </h3>
                        </div>
                      </div>
                      <div className="report">
                        <div className="uk-grid">
                          {unsdgTypes.map((u) => {
                            return (
                              <div key={u.id} className="uk-width-1-2">
                                <div className="report__item">
                                  <div>
                                    <img src={`img/icons/${u.icon}`} alt="" />
                                  </div>
                                  <p>
                                    {u.value
                                      .split(" ")
                                      .map((s) => capitalize(s))
                                      .join(" ")}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
            <Tabs {...tabProps} teamInfo={teamInfo} firm={firm} />
          </div>
        </div>
      </div>
      <FloatingContact location="Advisor Profile"></FloatingContact>

      {/* maincomponents ends here */}
      {/* similar profiles starts here */}
      {showSimilarAdvisors ? (
        <SimilarAdvisors
          className="no-print-show"
          similarAdvisors={similarAdvisors}
        />
      ) : null}
      {/* similar profiles ends here */}
      <PrintProfile
        {...introductionProps}
        firm={firm}
        teamInfo={teamInfo}
        unsdgTypes={unsdgTypes}
        {...downloadLinksProps}
        socialLinkTypes={socialLinkTypes}
        addresses={addresses}
        website={website}
        {...tabProps}
      />
    </main>
  );
};
AdvisorProfile.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.instanceOf(Address)),
  firm: PropTypes.instanceOf(Firm),
  teamInfo: PropTypes.instanceOf(TeamInfo),
  introductionProps: PropTypes.exact({
    impactThemesExpertise: PropTypes.array,
    name: PropTypes.string,
    profilePic: PropTypes.string,
    suggestedByMemberTypes: PropTypes.array,
    title: PropTypes.string,
    user: PropTypes.object,
  }),
  downloadLinksProps: PropTypes.exact({
    currentADV: PropTypes.string,
    materialTypes: PropTypes.array,
    richMediaLinks: PropTypes.array,
  }),
  tabProps: PropTypes.exact({
    valuesAlignedInvestingInfo: PropTypes.instanceOf(
      ValuesAlignedInvestingInfo
    ),
    types: PropTypes.object,
  }),
  unsdgTypes: PropTypes.array,
  website: PropTypes.string,
  socialLinkTypes: PropTypes.array,
  similarAdvisors: PropTypes.arrayOf(PropTypes.instanceOf(Advisor)),
  onPrintProfile: PropTypes.func,
  onGetInTouch: PropTypes.func,
  onEditProfile: PropTypes.func,
  onApproveProfile: PropTypes.func,
  onRejectProfile: PropTypes.func,
};
export default AdvisorProfile;

function percentageCalculator(arg1, arg2) {
    const num1 = Number(arg1)
    const num2 = Number(arg2)

    if (num2 === 0) {
        return '-'
    }

    if (num2 !== 0) {
        const percentage = Math.round((num1 / num2) * 100)
        return `${percentage}%`
    }
}


export default percentageCalculator;

import { isNumeric } from "validator";
import { isValidObj, requiredArray, requiredObj } from "../utils";

const actions = {
  UPDATE_STATE: "UPDATE_STATE",
};

export const aboutTeamsReducer = (state = teamInfoState, action) => {
  if (action.type === actions.UPDATE_STATE) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

const state = {
  isValid: false,
  dispatch: () => {},
  validate: async function () {
    let objs = [];
    let isValid = false;
    for (const key in this) {
      if (typeof this[key] === "object") {
        requiredObj(this[key]);
        objs.push(this[key]);
      }
      if (Array.isArray( this[key]?.value )) {
        requiredArray(this[key]);
        objs.push(this[key]);
      }
    }
    isValid = objs.every((obj) => isValidObj(obj));

    return {
      ...this,
      isValid,
    };
  },
  fromAdvisor: function (advisor) {
    this.setAum(advisor.teamInfo.aum)
    .setAumUnit(advisor.teamInfo.aumUnit)
    .setAumUnitAsOfDate(advisor.teamInfo.aumUnitAsOfDate)
    .setNoOfPeople(advisor.teamInfo.noOfPeople)
    .setImpactField(advisor.teamInfo.impactField)
    .setNoOfClients(advisor.teamInfo.noOfClients)
    .setEmployeesColor(advisor.teamInfo.employeesColor)
    .setEmployeesWomen(advisor.teamInfo.employeesWomen)
    .setValuesAlignedAum(advisor.teamInfo.valuesAlignedAum)
    .setIsClientAccountSizeDisclosable(advisor.teamInfo.isClientAccountSizeDisclosable)
    .setClientAccountSize(advisor.teamInfo.clientAccountSize)
    .setClientAccountSizeUnit(advisor.teamInfo.clientAccountSizeUnit)
    .setIsNoOfClientDisclosable(advisor.teamInfo.isNoOfClientsDisclosable)
    .setTeamOfferedVaNImpactYears(advisor.teamInfo.teamOfferedVaNImpactYears)

    const affiliation =  advisor.teamInfo.affiliations
    .filter((affiliation) => affiliation)
    .map((affiliation) => affiliation.affiliationType.value)

    const teamInfoDegrees = advisor.teamInfo.teamInfoDegrees
    .filter((teamInfoDegree) => teamInfoDegree)
    .map((teamInfoDegree) => teamInfoDegree.degreesType.value)

    const unSdgs = advisor.teamInfo.unSdgs
    .filter((unsdg) => unsdg)
    .map((unsdg) => unsdg.unSdgType.value)

    const assetClasses = advisor.teamInfo.assetClasses
    .filter((assetClass) => assetClass)
    .map((assetClass) => assetClass.assetClassesType.value)

    const impactThemes = advisor.teamInfo.impactThemes
    .filter((impactTheme) => impactTheme)
    .map((impactTheme) => impactTheme.impactThemesType.value)

    const advisorOfferings = advisor.teamInfo.advisorOfferings
    .filter((advisorOffering) => advisorOffering)
    .map(
      (advisorOffering) => advisorOffering.advisorOfferingType.value
    )

    const differentClients = advisor.teamInfo.differentClients
    .filter((differentClient) => differentClient)
    .map(
      (differentClient) => differentClient.differentClientType.value
    )

    const servingCountries = advisor.teamInfo.servingCountries
    .filter((servingCountry) => servingCountry)
    .map(
      (servingCountry) => servingCountry.servingCountriesType.value
    )

    const compensationStructures = advisor.teamInfo.compensationStructures
    .filter((compensationStructure) => compensationStructure)
    .map(
      (compensationStructure) =>
        compensationStructure.compensationStructureType.value
    )

    this.setAffiliation(affiliation)
    .setAdvisorOffering(advisorOfferings)
    .setDegrees(teamInfoDegrees)
    .setUnSdgs(unSdgs)
    .setAssetClasses(assetClasses)
    .setImpactThemes(impactThemes)
    .setDifferentClients(differentClients)
    .setServingCountries(servingCountries)
    .setCompensationStructure(compensationStructures)

    return this;
  },
  fromSavedState: function (savedState) {
    for(const key in savedState){
      if(this[key]){
        this[key] = savedState[key]
      }
    }
    return this
  },
  setAum: function (value) {
    if (!isNumeric(`${value}`)) {
      this.aum = {
        ...this.aum,
        value: "",
      };
      return this;
    }
    this.aum = {
      ...this.aum,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  aum: {
    value: "",
    error: "",
    showError: false,
    required: true
  },
  setAumUnit: function (value) {
    this.aumUnit = {
      ...this.aumUnit,
      value,
    };
    return this;
  },
  aumUnit: {
    value: "M",
    error: "",
    required: false,
    showError: false,
  },
  setAumUnitAsOfDate: function (value) {
    this.aumUnitAsOfDate = {
      ...this.aumUnitAsOfDate,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  aumUnitAsOfDate: {
    value: null,
    error: "",
    required: true,
    showError: false,
  },
  setNoOfPeople: function (value) {
    if (!isNumeric(`${value}`)) {
      this.noOfPeople = {
        ...this.noOfPeople,
        value: "",
      };
      return this;
    }
    this.noOfPeople = {
      ...this.noOfPeople,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  noOfPeople: {
    value: "",
    error: "",
    showError: false,
    required: true
  },
  setImpactField: function (value) {
    this.impactField = {
      ...this.impactField,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  impactField: {
    value: "",
    error: "",
    showError: false,
    required: false,
  },
  setNoOfClients: function (value) {
    if (!isNumeric(`${value}`)) {
      this.noOfClients = {
        ...this.noOfClients,
        required: this.isNoOfClientsDisclosable ? true : false,
        value: "",
      };
      return this;
    }
    this.noOfClients = {
      ...this.noOfClients,
      required: this.isNoOfClientsDisclosable ? true : false,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  setIsNoOfClientDisclosable: function (value) {
    this.isNoOfClientsDisclosable = value;
    return this.setNoOfClients(this.noOfClients.value);
  },
  isNoOfClientsDisclosable: true,
  noOfClients: {
    value: "",
    error: "",
    showError: false,
    required: true
  },
  setEmployeesColor: function (value) {
    if (!isNumeric(`${value}`)) {
      this.employeesColor = {
        ...this.employeesColor,
        value: "",
      };
      return this;
    }
    this.employeesColor = {
      ...this.employeesColor,
      required: this.isEmployeeNA ? false : true,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  employeesColor: {
    value: "",
    error: "",
    showError: false,
    required: true
  },
  setEmployeesWomen: function (value) {
    if (!isNumeric(`${value}`)) {
      this.employeesWomen = {
        ...this.employeesWomen,
        value: "",
      };
      return this;
    }
    this.employeesWomen = {
      ...this.employeesWomen,
      required: this.isEmployeeNA ? false : true,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  employeesWomen: {
    value: "",
    error: "",
    showError: false,
    required: true
  },
  setValuesAlignedAum: function (value) {
    if (!isNumeric(`${value}`)) {
      this.valuesAlignedAum = {
        ...this.valuesAlignedAum,
        value: "",
      };
      return this;
    }
    this.valuesAlignedAum = {
      ...this.valuesAlignedAum,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  valuesAlignedAum: {
    value: "",
    error: "",
    showError: false,
    required: true
  },
  setIsClientAccountSizeDisclosable: function (value) {
    this.isClientAccountSizeDisclosable = value;
    return this.setClientAccountSize(this.clientAccountSize.value);
  },
  isClientAccountSizeDisclosable: true,
  setClientAccountSize: function (value) {
    if (!isNumeric(`${value}`)) {
      this.clientAccountSize = {
        ...this.clientAccountSize,
        required: this.isClientAccountSizeDisclosable ? true : false,
        value: "",
      };
      return this;
    }
    this.clientAccountSize = {
      ...this.clientAccountSize,
      required: this.isClientAccountSizeDisclosable ? true : false,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  clientAccountSize: {
    value: "",
    error: "",
    showError: false,
    required: true
  },
  setClientAccountSizeUnit: function (value) {
    this.clientAccountSizeUnit = {
      ...this.clientAccountSizeUnit,
      value,
    };
    return this;
  },
  clientAccountSizeUnit: {
    value: "M",
    error: "",
    required: false,
    showError: false,
  },
  setAffiliation: function (value) {
    this.affiliation = {
      ...this.affiliation,
      value,
    };
    return this;
  },
  affiliation: {
    value: [],
    error: "",
    showError: false,
    required: false,
  },
  setDegrees: function (value) {
    this.degrees = {
      ...this.degrees,
      value,
    };
    return this;
  },
  degrees: {
    value: [],
    error: "",
    showError: false,
    required: false,
  },
  setServingCountries: function (value) {
    this.servingCountries = {
      ...this.servingCountries,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  servingCountries: {
    value: [],
    error: "",
    showError: false,
    required: true
  },
  setDifferentClients: function (value) {
    this.differentClients = {
      ...this.differentClients,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  differentClients: {
    value: [],
    error: "",
    showError: false,
    required: true
  },
  setCompensationStructure: function (value) {
    this.compensationStructure = {
      ...this.compensationStructure,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  compensationStructure: {
    value: [],
    error: "",
    showError: false,
    required: true
  },
  setInvestmentServices: function (value) {
    this.investmentServices = {
      ...this.investmentServices,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  investmentServices: {
    value: [],
    error: "",
    showError: false,
    required: false,
  },
  setAdvisorOffering: function (value) {
    this.advisorOffering = {
      ...this.advisorOffering,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  advisorOffering: {
    value: [],
    error: "",
    showError: false,
    required: true
  },
  setImpactThemes: function (value) {
    this.impactThemes = {
      ...this.impactThemes,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  impactThemes: {
    value: [],
    error: "",
    showError: false,
    required: true
  },
  setAssetClasses: function (value) {
    this.assetClasses = {
      ...this.assetClasses,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  assetClasses: {
    value: [],
    error: "",
    showError: false,
    required: true
  },
  setTeamOfferedVaNImpactYears: function (value) {
    if (!isNumeric(`${value}`)) {
      this.teamOfferedVaNImpactYears = {
        ...this.teamOfferedVaNImpactYears,
        value: "",
      };
      return this;
    }
    this.teamOfferedVaNImpactYears = {
      ...this.teamOfferedVaNImpactYears,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this
  },
  teamOfferedVaNImpactYears: {
    value: "",
    error: "",
    showError: false,
    required: true
  },
  setUnSdgs: function (value) {
    this.unSdgs = {
      ...this.unSdgs,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  unSdgs: {
    value: [],
    error: "",
    showError: false,
    required: false,
  },
};
export const teamInfoState = new Proxy(state, {
  get: function (target, prop, receiver) {
    const value = target[prop];
    // curry setters so its easier to update state by calling dispatch after modifying state, this avoid having to call dispatch at the end of every setter
    if (value instanceof Function) {
      return function (...args) {
        const obj = this === receiver ? target : this;
        const result = value.apply(obj, args);
        if (result instanceof Promise) {
          result.then((res) => {
            obj.dispatch({
              type: actions.UPDATE_STATE,
              payload: res,
            });
          });
        } else {
          obj.dispatch({
            type: actions.UPDATE_STATE,
            payload: result,
          });
        }
        return result;
      };
    }
    return value;
  },
});

import emptyFieldvalidate from './emptyFieldValidate';
import emailValidate from './emailValidate';
import passwordValidate from './password';
import zipCodeValidate from './formZipCode';
import urlValidate from './urlValidate';
import validateAllFields from './validateAllFields';

function getStartedOtherValidations(state) {
    const email = emailValidate(state.email)
    const password = passwordValidate(state.password)
    const website = urlValidate(state.website)
    const profilePic = emptyFieldvalidate(state.profilePic)
    let errorCount = 0
    const newState = validateAllFields(state)
    const address = newState.address.map((singleAddress) => {
        const validatedZipCode = zipCodeValidate(singleAddress.zipCode)
        errorCount = validatedZipCode.showError ? errorCount + 1 : errorCount
        return {
            ...singleAddress,
            zipCode: {
                ...validatedZipCode
            },
            errorCount: validatedZipCode.showError ? singleAddress.errorCount + 1 : singleAddress.errorCount
        }
    })
    const socialLink = newState.socialLink.map((singleSocialLink) => {
        const validatedLink = urlValidate(singleSocialLink.link)
        errorCount = validatedLink.showError ? errorCount + 1 : errorCount
        return {
            ...singleSocialLink,
            link: {
                ...validatedLink
            },
            errorCount: validatedLink.showError ? singleSocialLink.errorCount + 1 : singleSocialLink.errorCount
        }
    })
    errorCount = email.showError ? errorCount + 1 : errorCount
    errorCount = password.showError ? errorCount + 1 : errorCount
    errorCount = website.showError ? errorCount + 1 : errorCount
    errorCount = profilePic.showError ? errorCount + 1 : errorCount

    return {
        email,
        address,
        website,
        newState,
        password,
        errorCount,
        profilePic,
        socialLink,
    }
}

export default getStartedOtherValidations;


import React, { useContext } from "react";
import { Form } from "reactstrap";

import FormInput from "../../../components/_shared/FormInput";
import deleteIcon from "../../../assets/images/delete.png";
import { FADashboardContext } from "../Context";
const fileLimit = 10 * 1024;

const OBMaterials = () => {
  const { material } = useContext(FADashboardContext);

  const getFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size / 1024 <= fileLimit) {
        return {
          file: e.target.files[0],
          error: "",
        };
      } else {
        return {
          error: "File size should be less than 10MB",
          file: {},
        };
      }
    }

    return {
      file: {},
      error: "",
    };
  };
  const renderLinks = (props) => {
    return material.richMediaLinks.map((link, index) => {
      return (
        <div key={"link_" + index}>
          <div className="SM-links-right">
            <FormInput
              label="Add URL"
              value={link.url.value}
              onChange={(e) => {
                const value = e.target.value;
                material.updateRichMediaLinkAtIndex(index, {
                  ...link,
                  url: {
                    value,
                    error: "",
                    showError: true,
                  },
                });
              }}
              error={link.url.error}
              showError={link.url.showError}
            />
          </div>
          <div className="SM-links-right">
            <FormInput
              label="Add Description"
              value={link.description.value}
              onChange={(e) => {
                const value = e.target.value;
                material.updateRichMediaLinkAtIndex(index, {
                  ...link,
                  description: {
                    value,
                    error: "",
                    showError: true,
                  },
                });
              }}
              error={link.description.error}
              showError={link.description.showError}
            />
          </div>
          <div className="SM-button-container">
            <button
              className="button-add-another"
              type="button"
              onClick={(e) => {
                material.removeRichMediaLinkAtIndex(index);
              }}
            >
              {<img className="delete-icon" src={deleteIcon} alt="delete" />}
            </button>
          </div>
        </div>
      );
    });
  };
  return (
    <div className="FA-form-container">
      <Form className="FA-form">
        <div className="FA-form-fields FA-form5">
          <h3 className="title-upload-files">
            Please provide one or more of the following:
          </h3>

          <div className={"input-type-file"}>
            {material.sampleRedactedClientReport.value && (
              <img
                className="delete-icon"
                src={deleteIcon}
                alt="delete"
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "5px",
                  cursor: "pointer",
                  zIndex: 3,
                }}
                onClick={(e) => {
                  material.setRedactedClientReport({
                    value: "",
                    error: "",
                    file: {},
                    showError: false,
                    required: false,
                  });
                }}
              />
            )}
            <span id="selected-file-name">
              {material.sampleRedactedClientReport.value
                ? `Sample redacted client report.pdf`
                : ""}
            </span>
            <span id="text-choose-file">Choose File...</span>
            <FormInput
              type="file"
              accept=".pdf"
              label={"Sample redacted client report"}
              focus={material.sampleNewsletter.value}
              onChange={(e) => {
                const { file, error } = getFile(e);
                material.setRedactedClientReport({
                  value: file?.name || "",
                  error,
                  file,
                  showError: error ? true : false,
                });
              }}
              error={material.sampleRedactedClientReport.error}
              showError={material.sampleRedactedClientReport.showError}
            />
          </div>

          <div className={"input-type-file"}>
            {material.sampleNewsletter.value && (
              <img
                className="delete-icon"
                src={deleteIcon}
                alt="delete"
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "5px",
                  cursor: "pointer",
                  zIndex: 3,
                }}
                onClick={(e) => {
                  material.setSampleNewsletter({
                    value: "",
                    error: "",
                    file: {},
                    showError: false,
                    required: false,
                  });
                }}
              />
            )}
            <span id="selected-file-name">
              {material.sampleNewsletter.value ? `Sample newsletter.pdf` : ""}
            </span>
            <span id="text-choose-file">Choose File...</span>
            <FormInput
              type="file"
              accept=".pdf"
              label={"Sample newsletter"}
              focus={material.researchReport.value}
              onChange={(e) => {
                const { file, error } = getFile(e);
                material.setSampleNewsletter({
                  value: file?.name || "",
                  error,
                  file,
                  showError: error ? true : false,
                });
              }}
              error={material.sampleNewsletter.error}
              showError={material.sampleNewsletter.showError}
            />
          </div>

          <div className={"input-type-file"}>
            {material.researchReport.value && (
              <img
                className="delete-icon"
                src={deleteIcon}
                alt="delete"
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "5px",
                  cursor: "pointer",
                  zIndex: 3,
                }}
                onClick={(e) => {
                  material.setResearchReport({
                    value: "",
                    error: "",
                    file: {},
                    showError: false,
                    required: false,
                  });
                }}
              />
            )}
            <span id="selected-file-name">
              {material.researchReport.value ? `Research report.pdf` : ""}
            </span>
            <span id="text-choose-file">Choose File...</span>
            <FormInput
              type="file"
              accept=".pdf"
              label={"Research report"}
              focus={material.researchReport.value}
              onChange={(e) => {
                const { file, error } = getFile(e);
                material.setResearchReport({
                  value: file?.name || "",
                  error,
                  file,
                  showError: error ? true : false,
                });
              }}
              error={material.researchReport.error}
              showError={material.researchReport.showError}
            />
          </div>

          <div className={"input-type-file"}>
            {material.thoughtLeadershipPublication.value && (
              <img
                className="delete-icon"
                src={deleteIcon}
                alt="delete"
                style={{
                  position: "absolute",
                  left: "5px",
                  top: "5px",
                  cursor: "pointer",
                  zIndex: 3,
                }}
                onClick={(e) => {
                  material.setThoughtLeadershipPublication({
                    value: "",
                    error: "",
                    file: {},
                    showError: false,
                    required: false,
                  });
                }}
              />
            )}
            <span id="selected-file-name">
              {material.thoughtLeadershipPublication.value
                ? `Thought leadership publication.pdf`
                : ""}
            </span>
            <span id="text-choose-file">Choose File...</span>
            <FormInput
              type="file"
              accept=".pdf"
              label={"Thought leadership publication"}
              focus={material.thoughtLeadershipPublication.value}
              onChange={(e) => {
                const { file, error } = getFile(e);
                material.setThoughtLeadershipPublication({
                  value: file?.name || "",
                  error,
                  file,
                  showError: error ? true : false,
                });
              }}
              error={material.thoughtLeadershipPublication.error}
              showError={material.thoughtLeadershipPublication.showError}
            />
          </div>
        </div>
      </Form>
      <div className="FA-form-fields FA-form5">
        <Form className="FA-form">
          <div className="FA-form-fields FA-form5 container-SM-links">
            <h3 className="title-upload-files">
              Link to media sources (video of presentations, podcasts, blog
              posts, etc):
            </h3>

            {renderLinks()}
          </div>
          <div className="button-location-container" style={{ border: "none" }}>
            <button
              className="button-add-location"
              onClick={(w) => {
                material.addRichMediaLink();
              }}
              type="button"
            >
              + Add another link
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default OBMaterials;

import { isValidObj, requiredArray, requiredObj } from "../utils";

const actions = {
  UPDATE_STATE: "UPDATE_STATE",
};

export const aboutAVAIreducer = (state = avaiState, action) => {
  if (action.type === actions.UPDATE_STATE) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

const state = {
  isValid: false,
  dispatch: () => {},
  validate: async function () {
    let objs = [];
    let isValid = false;
    for (const key in this) {
      if (typeof this[key] === "object") {
        requiredObj(this[key]);
        objs.push(this[key]);
      }
      if (Array.isArray(this[key].value)) {
        requiredArray(this[key]);
        objs.push(this[key]);
      }
    }
    isValid = objs.every((obj) => isValidObj(obj));

    return {
      ...this,
      isValid,
    };
  },
  fromAdvisor: function (advisor) {
    this.setVoteProxies(advisor.valuesAlignedInvestingInfo.voteProxies)
    .setImpactStrategy(advisor.valuesAlignedInvestingInfo.impactStrategy)
    .setAssetAllocation(advisor.valuesAlignedInvestingInfo.assetAllocation)
    .setImpactInvestment(advisor.valuesAlignedInvestingInfo.impactInvestment)
    .setMeasureAndReport(advisor.valuesAlignedInvestingInfo.measureAndReport)
    .setInterestedInImpact(advisor.valuesAlignedInvestingInfo.interestedInImpact)
    .setMeaningfulInvestment(advisor.valuesAlignedInvestingInfo.meaningfulInvestment)
    .setDescOfWhatClientCares(advisor.valuesAlignedInvestingInfo.descOfWhatClientCares)
    .setEngagedWithManagement(advisor.valuesAlignedInvestingInfo.engagedWithManagement)
    .setHelpClientMakeBetterDecision(advisor.valuesAlignedInvestingInfo.helpClientMakeBetterDecision)
    .setProxyVoting(advisor.valuesAlignedInvestingInfo.proxyVotings
      .filter((proxyVoting) => proxyVoting)
      .map((proxyVoting) => proxyVoting.proxyVotingType.value))
    return this;
  },
  fromSavedState: function (savedState) {
    for(const key in savedState){
      if(this[key]){
        this[key] = savedState[key]
      }
    }
    return this
  },
  setVoteProxies: function (value) {
    this.voteProxies = {
      ...this.voteProxies,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  voteProxies: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setImpactStrategy: function (value) {
    this.impactStrategy = {
      ...this.impactStrategy,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  impactStrategy: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setAssetAllocation: function (value) {
    this.assetAllocation = {
      ...this.assetAllocation,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  assetAllocation: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setImpactInvestment: function (value) {
    this.impactInvestment = {
      ...this.impactInvestment,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  impactInvestment: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setMeasureAndReport: function (value) {
    this.measureAndReport = {
      ...this.measureAndReport,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  measureAndReport: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setInterestedInImpact: function (value) {
    this.interestedInImpact = {
      ...this.interestedInImpact,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  interestedInImpact: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setMeaningfulInvestment: function (value) {
    this.meaningfulInvestment = {
      ...this.meaningfulInvestment,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  meaningfulInvestment: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setDescOfWhatClientCares: function (value) {
    this.descOfWhatClientCares = {
      ...this.descOfWhatClientCares,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  descOfWhatClientCares: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setEngagedWithManagement: function (value) {
    this.engagedWithManagement = {
      ...this.engagedWithManagement,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  engagedWithManagement: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },

  setHelpClientMakeBetterDecision: function (value) {
    this.helpClientMakeBetterDecision = {
      ...this.helpClientMakeBetterDecision,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  helpClientMakeBetterDecision: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setProxyVoting: function (value) {
    this.proxyVoting = {
      ...this.proxyVoting,
      value,
      error: "",
      showError: false,
    };
    return this;
  },
  proxyVoting: {
    value: [],
    error: "",
    showError: false,
    required: true,
  },
};
export const avaiState = new Proxy(state, {
  get: function (target, prop, receiver) {
    const value = target[prop];
    // curry setters so its easier to update state by calling dispatch after modifying state, this avoid having to call dispatch at the end of every setter
    if (value instanceof Function) {
      return function (...args) {
        const obj = this === receiver ? target : this;
        const result = value.apply(obj, args);
        if (result instanceof Promise) {
          result.then((res) => {
            obj.dispatch({
              type: actions.UPDATE_STATE,
              payload: res,
            });
          });
        } else {
          obj.dispatch({
            type: actions.UPDATE_STATE,
            payload: result,
          });
        }
        return result;
      };
    }
    return value;
  },
});

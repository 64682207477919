import React, { useState } from "react";
import { forgotPassword } from "../../api/sign-in";

const ForgotPassword = ({
  email,
  setEmail,
  onSubmit,
  resetSuccesful,
  error,
}) => {
  return (
    <div className="login">
      <div className="uk-container">
        <div className="uk-grid">
          <div className="uk-width-1-1">
            <img className="login__logo" src="img/logo.svg" alt="" />
          </div>
          <div className="uk-width-1-1">
            <h3 className="login__title">Forgot Password?</h3>
          </div>
          {resetSuccesful ? (
            <p className="success-message">
              An Email to change your password has been sent, please check your
              email for further instructions.
            </p>
          ) : null}

          <div className="uk-width-1-1">
            <form
              className="login__form"
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="uk-grid">
                <div className="form-group uk-width-1-1">
                  <label htmlFor={"Email-ID"}>Email/Username</label>
                  <input
                    required
                    className="uk-input "
                    type="email"
                    name="Email-ID"
                    placeholder="Email/Username"
                    value={email}
                    onChange={(e) => {
                      e.preventDefault();
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                {error ? <p className="error-message">{error}</p> : null}

                <div className="form-group artificial-form-div-margin-bottom uk-width-1-1">
                  <button type="submit" className="homelink">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
            <div className="login__form">
              <div className="uk-width-1-1">
                <a className="forget" href="/login">
                  Back to Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Container = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [resetSuccesful, setResetSuccesful] = useState(false);

  const onSubmit = async () => {
    try {
      const result = await forgotPassword({ email }).then(
        (response) => response.data
      );
      if (result.success) {
        setResetSuccesful(true);
        setError("");
      }
    } catch (err) {
      setResetSuccesful(false);
      setError(err.response.data.error);
    }
  };

  return (
    <ForgotPassword
      email={email}
      setEmail={setEmail}
      resetSuccesful={resetSuccesful}
      error={error}
      onSubmit={onSubmit}
    ></ForgotPassword>
  );
};

export default Container;

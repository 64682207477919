import React from "react";
import "./FAQ.css";
const FAQ = () => {
  let baseUrl = window.location.origin + window.location.pathname;

  return (
    <div class="faq--container uk-container">
      <aside>
        <div class="index">
          <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            data-svg="navbar-toggle-icon"
          >
            <rect y={9} width={20} height={2} />
            <rect y={3} width={20} height={2} />
            <rect y={15} width={20} height={2} />
          </svg>
          <span>INDEX</span>
        </div>
        <hr />
        <a href={`${baseUrl}#values_advisor`}>
          <h3>ValuesAdvisor & Our Partners</h3>
        </a>
        <hr />
        <a href={`${baseUrl}#the_financial_advisors`}>
          <h3>The Financial Advisors</h3>
        </a>
        <ul>
          <li>
            <a href={`${baseUrl}#values_aligned_financial`}>
              What is a Values-Aligned Financial Advisor?
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#values_aligned_consultant`}>
              What is a Values-Aligned Strategic Consultant?
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#financial_advisors_criteria`}>
              What criteria must Financial Advisors meet to be included in
              ValuesAdvisor?
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#recommend_financial_advisors`}>
              How can I recommend my Financial Advisor?
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#who_provide_info`}>
              Who provides the information about the Financial Advisor?
            </a>
          </li>
        </ul>
        <hr></hr>
        <a href={`${baseUrl}#how_use_platform`}>
          <h3>How to Use the Platform</h3>
        </a>
        <ul>
          <li>
            <a href={`${baseUrl}#how_do_i_get_started`}>
              How do I get started?
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#reduce_and_sort`}>
              Reduce and sort the universe of Advisors
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#compare_advisors`}>
              Compare Advisors side by side
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#look_at_an_advisor`}>
              Look at an Advisor’s Full Profile
            </a>
          </li>
        </ul>
        <hr />
        <a href={`${baseUrl}#support_our_vision`}>
          <h3>Support Our Vision and Growth!</h3>
        </a>
        <ul>
          <li>
            <a href={`${baseUrl}#vision_and_mision`}>
              What is the ValuesAdvisor Vision and Mission?
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#support_its_expansion`}>
              I love this resource and would like to support its expansion.
            </a>
          </li>
          <li>
            <a href={`${baseUrl}#love_using_values_advisors`}>
              I’m a member of another group that I think will love using
              ValuesAdvisor.
            </a>
          </li>
        </ul>
      </aside>
      <main>
        <h1>Frequently Asked Questions</h1>
        <img class="main" src="img/faq-img.png" alt="FAQ" />
        <h3 id="values_advisor">ValuesAdvisor & Our Partners</h3>
        <p>
          It is a tool that makes it easier for high net wealth individuals,
          families, foundations and institutional investors You can see a list
          of our current Partners{" "}
          <a href="https://valuesadvisor.com/about"> here</a>
        </p>
        <hr />
        <h3 id="the_financial_advisors">The Financial Advisors</h3>
        <h4 id="values_aligned_financial">
          What is a Values-Aligned Financial Advisor?
        </h4>
        <p>
          At ValuesAdvisor we use the term “values-aligned” as an umbrella term
          for financial professionals who help you invest in accordance with
          your individual or organization’s values, as opposed to focusing
          entirely on maximizing returns. Our site focuses on helping you find a
          Values-Aligned Financial Advisor, although we also have a Guide with
          Values-Aligned Strategic Consultants{" "}
          <a href="https://f.hubspotusercontent20.net/hubfs/7887571/Public%20Facing%20Files/ValuesAdvisor_Strategic%20Consultant%20Directory.pdf">
            here
          </a>
          .
        </p>
        <h4 id="values_aligned_consultant">
          What is a Values-Aligned Strategic Consultant?
        </h4>
        <p>
          A professional that helps investors along their values-aligned
          journey. Examples of services include identifying values, creating an
          investment policy statement, working with family dynamics, education
          about the impact field, helping find a values-aligned Financial
          Advisor, or working with your current advisor to educate them about
          the ESG and impact investing field.
        </p>
        <h4 id="financial_advisors_criteria">
          What criteria must Financial Advisors meet to be included in
          ValuesAdvisor?
        </h4>
        <p>
          To be included on the platform, a Financial Advisor must:
          <br />
          <br />
          <ul>
            <li>
              Be recommended by at least one member of a ValuesAdvisor Partner
              organization or an expert in the impact investing field.
            </li>
            <li>
              Offer impact-based financial advisory services as a core business
              (demonstrated by approximately 25% or more of current AUM of
              Advisor/team and highlighted on the Advisor’s website).
            </li>
            <li>
              Demonstrate significant knowledge of the impact investing field
              (offering values aligned investment options for a minimum of three
              years and/or expertise as determined by ValuesAdvisor).
            </li>
          </ul>
        </p>
        <h4 id="recommend_financial_advisors">
          How can I recommend my Financial Advisor?
        </h4>
        <p>
          If your Financial Advisor has helped you align your investments with
          your values, and you think your peers will benefit from using them, we
          would love you to recommend them{" "}
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeL2MvCXE7-L14EGE5rlznHyvKemkgHc0ArMs9T3kUEgSqqzQ/viewform">
            HERE
          </a>
          .
        </p>
        <p>
          We welcome all suggestions, including someone already on the platform.
          Recommendations are kept strictly confidential and will not be shared
          outside of ValuesAdvisor in any way. The Advisor’s profile will
          display the name of your group, but will NOT include any personal
          information.
        </p>
        <h4 id="who_provide_info">
          Who provides the information about the Financial Advisor?
        </h4>
        <p>
          The Financial Advisors provide the information for their profile.
          ValuesAdvisor does not assume responsibility for the information
          provided by the Financial Advisors, but does require Advisors in the
          United States to upload their Investment Advisor Registration (ADV), a
          filing to the Securities and Exchange Commission (SEC), which allows
          confirmation of specific disclosures such as fee structure.
        </p>
        <hr />
        <h3 id="how_use_platform">How to Use the Platform: 4 Easy Steps</h3>
        <h4 id="how_do_i_get_started">1. How do I get started?</h4>
        <p>
          Here is an “
          <a href="https://7887571.fs1.hubspotusercontent-na1.net/hubfs/7887571/Platform%20Demo%20Video%20with%20Kate.mp4">
            explainer
          </a>
          ” video that walks you through the platform, reviewing the filters and
          other information you can find about the Advisors. It’s a great place
          to start. If you have additional questions, don’t hesitate to reach
          out to your group’s ValuesAdvisor liaison, or email us at:
          info@valuesadvisor.com.
        </p>
        <h4 id="reduce_and_sort">
          2. Reduce and sort the universe of Advisors
        </h4>
        <p>
          Unlike many Advisor search platforms, you are in the driver's seat the
          entire time. You see the entire universe of Advisors when you come to
          the home page, and then can choose filters to reduce the Advisors you
          see based on your specific needs.
        </p>
        <p>
          <strong>
            <u>The filters</u>
          </strong>
          <ol>
            <li>ACCOUNT SIZE of the assets you are seeking to invest.</li>
            <li>FIRM SIZE based on Assets under Management.</li>
            <li>GEOGRAPHY the Advisor team serves.</li>
            <li>
              The kinds of SERVICES the Advisor team can provide (e.g., wealth,
              tax, and retirement planning, foundation services, family office
              services).
            </li>
            <li>ASSET CLASSES that they can align with your values.</li>
            <li>
              IMPACT THEMES are specific areas, based on the United Nations
              Sustainable Development Goals, that you want to invest in (e.g.,
              racial equity, energy, sustainable agriculture).
            </li>
            <li>
              LEADERSHIP DIVERSITY allows you to choose to see only firms that
              have a majority in senior positions that are women/non-binary
              and/or POC.
            </li>
            <li>
              TEAM DIVERSITY allows you to choose to see only Advisor teams that
              have a majority that are women/non-binary and/or POC.
            </li>
            <li>How they support ACTIVE OWNERSHIP.</li>
            <li>AFFINITY GROUP whose member suggested them.</li>
            <li>
              Whether you want the Advisor team to have proprietary PRODUCTS or
              not.
            </li>
          </ol>
        </p>
        <p>
          <strong>
            <u>The sort function</u>
          </strong>
          <br />
          The left sidebars are your FILTERs. SORT BY is in the upper right
          corner of your screen.
        </p>
        <img src="img/sort_1.png" alt="sort by feature" />
        <p>
          This allows you to ARRANGE the order of the Advisors you see by “Firm
          AUM Size,” “Team % Values-Aligned AUM,” and “Team Typical Account
          Size.”
        </p>
        <p>
          If you select “USA'' under geography, then you can also sort the
          Financial Advisors by their distance from a specified zip code.
        </p>
        <img src="img/sort_2.png" alt="active sort by feature" />
        <p>
          You can click on the arrows to the left of the SORT BY feature to
          change from ascending to descending order.
        </p>
        <h4 id="compare_advisors">3. Compare Advisors side by side</h4>
        <p>
          At the bottom of each Advisors tile, you will see the following:{" "}
          <img
            class="inline"
            width="150px"
            src="img/add_to_compare.png"
            alt="add to compare"
          />
          . Click on this button to choose up to 4 Advisors to compare. At the
          bottom of your screen, you will see an orange arrow - click on it and
          you will see high level data on each Advisor (assets under management,
          values-aligned assets under management, etc.).
        </p>
        <h4 id="look_at_an_advisor">4. Look at an Advisor’s Full Profile</h4>
        <p>
          When you click on the Advisor’s full profile, you will see some of the
          same quantitative information you find on the compare page, but you
          will also see the Advisor’s answer to important questions about their
          impact investing practice and expertise (be sure to click on all the
          tabs at the top of their profile).
        </p>
        <p>
          On the top right of the page, you’ll see their “group tag,” or name of
          the organization/s whose member suggested them.
        </p>
        <p>
          Along the left side, you’ll see additional materials that the Advisor
          uploaded, or links they’ve included, which can provide helpful
          information on any impact expertise they’ve developed.
        </p>

        <hr />

        <h3 id="support_our_vision">Support Our Vision and Growth!</h3>
        <h4 id="vision_and_mision">
          What is the ValuesAdvisor Vision and Mission?
        </h4>
        <p>
          Our vision is a world where values aligned investing is the norm and
          capital markets are building a regenerative economy. Our mission is to
          accelerate the flow of money into the United Nations&nbsp;
          <a href="https://sdgs.un.org/goals">Sustainable Development Goals</a>
          &nbsp; (SDGs). We achieve our mission by providing a seamless
          interface for investors to find investment professionals to align
          their capital with their values and by increasing the diversity and
          preserving the integrity of the impact investing field.
        </p>
        <h4 id="support_its_expansion">
          I love this resource and would like to support its expansion.
        </h4>
        <p>
          ValuesAdvisor‘s budget comes from fees from the financial Advisors, as
          well as from philanthropic support. As a fiscally sponsored
          organization at RSF Social Finance, ValuesAdvisor can receive personal
          donations and Donor Advised Fund (DAF) contributions&nbsp;
          <a href="https://rsfsocialfinance.org/give/give-to-rsf-projects/give-today-projects/">
            here
          </a>
          .
        </p>
        <h4 id="love_using_values_advisors">
          I’m a member of another group that I think will love using
          ValuesAdvisor.
        </h4>
        <p>
          We love to learn about new potential partners. To suggest a name,
          reach out to a team member or info@valuesadvisor.org.
        </p>
      </main>
    </div>
  );
};
export default FAQ;

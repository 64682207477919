
const addressTemplate = {
    city: {
        value: '',
        error: '',
        showError: false
    },
    line1: {
        value: '',
        error: '',
        showError: false
    },
    line2: {
        value: '',
        error: '',
        showError: false,
        mandatory: false
    },
    state: {
        value: '',
        error: '',
        showError: false
    },
    country: {
        value: '',
        error: '',
        showError: false
    },
    zipCode: {
        value: '',
        error: '',
        showError: false
    },
    errorCount: 0
}

const socialLinkTemplate = {
    name: {
        value: '',
        error: '',
        showError: false
    },
    link: {
        value: '',
        error: '',
        showError: false
    },
    errorCount: 0,
    required: false
}


function createMaterialTemplate() {
    const result = []
    for (let index = 0; index < 4; index++) {
        result.push(socialLinkTemplate)
    }
    return result
}

export {
    addressTemplate,
    socialLinkTemplate,
    createMaterialTemplate
}
import React, { useContext, useState } from "react";
import Modal from "../_shared/Modal/Modal";
import LoginForm from "../Forms/LoginForm";
import { AuthContext } from "../../Context/AuthContext";

const LoginModal = ({ show, onClose }) => {
  const [loginEmail, setLoginEmail] = useState({
    value: "",
    error: "",
    showError: false,
  });
  const [loginPassword, setLoginPassword] = useState({
    value: "",
    error: "",
    showError: false,
  });
  const onLogging = async () => {
    const userCreds = await login(loginEmail.value, loginPassword.value);
    if (!userCreds) {
      return;
    }
    onClose();
  };
  const authContext = useContext(AuthContext);
  const { login } = authContext;
  return (
    <>
      <Modal show={show} title={"Sign In"} onClose={onClose}>
        <LoginForm
          email={loginEmail}
          password={loginPassword}
          setEmail={(value) => {
            setLoginEmail({
              ...loginEmail,
              value: value,
            });
          }}
          setPassword={(value) => {
            setLoginPassword({
              ...loginPassword,
              showError: false,
              value: value,
            });
          }}
          submit={onLogging}
        ></LoginForm>
      </Modal>
    </>
  );
};

export default LoginModal;

import axios from 'axios';
import { baseUrl } from "../config/index";
import { toast } from 'react-toastify';
import { removeAuthData } from '../utils/storeAuthData';
const axiosInstance = axios.create({
    baseURL: baseUrl,
    withCredentials: true
})

axiosInstance.interceptors.request.use(function (config) {
    const newToken = window.localStorage.getItem('token')
    config.headers = {
        token: newToken
    }
    return config;
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, (error) => {
    if (error.response && error.response.data && error.response.data.error) {
        console.log('axios error data',error.response.data);
        console.log('axios error status',error.response.status);
        console.log('axios error headers',error.response.headers);
        if (error.response.data.error === "Invalid Token") {

            //credentials are no longer valid, remove credentials from localstorage
            removeAuthData( )

            //redirect to home
            window.location = "/"
        }else{
            //alert(error.response.data.error)
            toast.error(error.response.data.error)
        }
    }

    throw error
})

export default axiosInstance;

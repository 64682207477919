import React from "react";
//import LearnMore from "../../components/LearnMore/LearnMore";

const LearnMore = (props) => {
  return (
    <div className="">
      <div className="uk-container learn-more-div video-section">
        <p className="learn-more-div-section-title">How to Use ValuesAdvisor</p>
        <div className="video">
          <div className="uk-container">
            <div className="video__media">
              <iframe
                title={"Valuesadvisors walkthrough"}
                src="https://player.vimeo.com/video/365779217"
                width={1920}
                height={1080}
                frameBorder={0}
                allowFullScreen
                uk-responsive={"true"}
                uk-video="autoplay: false; automute: false"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="uk-container learn-more-div interview-section gray_background">
        <p className="learn-more-div-section-title">
          How to Interview Financial Advisors
        </p>
        <p>
          After contacting the financial advisor(s) you select from the
          ValuesAdvisor platform you may want to ask a few additional questions
          via email or set up a call.
        </p>
        <br />
        <p className="highlight">
          What are the most important questions to ask about both their impact
          and financial offerings?
        </p>
        <br />
        <p>
          Our interview guide has some suggestions to dig deeper into an
          advisor’s services, fees, and investment strategy.
        </p>
        <a
          href={
            "https://f.hubspotusercontent20.net/hubfs/7887571/ValuesAdvisor.Interview.Guide.pdf"
          }
          target={"_blank"}
          rel="noopener noreferrer"
          className="homelink"
        >
          Download the Advisor Interview Guide{" "}
          <svg
            className="homelink__chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19.521"
            viewBox="0 0 11 19.521"
          >
            <g id="Expand" transform="translate(0 19.521) rotate(-90)">
              <path
                id="Path_434"
                data-name="Path 434"
                d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                transform="translate(-178.807 -14.7)"
                fill="#ff6b00"
              />
            </g>
          </svg>
        </a>
      </div>
      <div className="uk-container learn-more-div interview-section">
        <p className="learn-more-div-section-title">
          Strategic Consultants Can Further Support Your Impact Journey
        </p>
        <p>
          Complementing values-aligned financial advisors, there are{" "}
          <i>Strategic Consultants</i> with impact experience who can assist you
          in managing the process of{" "}
          <i>
            <b>hiring</b>
          </i>{" "}
          a financial advisor, and also provide a broad range of support such as
          helping you clarify your values, educating you about impact
          terminology, or providing advice on choosing aligned investments.
          <br />
          <br />
          The Impact Strategic Consultant profiles provide background
          information on the skills, issue areas of expertise, consulting
          services offered along with contact information, of Strategic
          Consultants who were recommended by ValuesAdvisor partners, leaders in
          the impact investing field and investors.
        </p>
        <a
          href={
            "https://f.hubspotusercontent20.net/hubfs/7887571/Public%20Facing%20Files/ValuesAdvisor_Strategic%20Consultant%20Directory.pdf"
          }
          // href={"https://drive.google.com/uc?id=17hZjcIbOhdPqZv_uq9K4VsH1zLxr4haE&export=download"}
          className="homelink"
          target={"_blank"}
          rel="noopener noreferrer"
        >
          Download our Strategic Consultant Guide{" "}
          <svg
            className="homelink__chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19.521"
            viewBox="0 0 11 19.521"
          >
            <g id="Expand" transform="translate(0 19.521) rotate(-90)">
              <path
                id="Path_434"
                data-name="Path 434"
                d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                transform="translate(-178.807 -14.7)"
                fill="#ff6b00"
              />
            </g>
          </svg>
        </a>
      </div>

      <div className="uk-container learn-more-div support-section gray_background">
        <p className="learn-more-div-section-title">
          Curious to learn more about values aligned investing?
        </p>
        <p>
          ValuesAdvisor co-founder and author of the Take Two Journal, Kate
          Simpson, created an online course that integrates her experience
          working with HNW impact investors with current research on emotional
          resilience and financial psychology, to create a one-of-a-kind
          journey. Using guided meditations, reflective journal prompts and
          tailored lessons, you will:
          <br />
          <br />
        </p>
        <ul>
          <li>explore your money story and limiting beliefs about money,</li>
          <li>clarify the values you wish to apply to your investments, and</li>
          <li>learn the basics about the impact field.</li>
        </ul>
        <p>
          <br />
        </p>
        <p className="highlight">
          You’ll leave the course empowered to take action!
        </p>
        <a
          href={"https://www.taketwojournal.com/va-course"}
          className="homelink"
          target={"_blank"}
          rel="noopener noreferrer"
        >
          Sign up for the Course{" "}
          <svg
            className="homelink__chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19.521"
            viewBox="0 0 11 19.521"
          >
            <g id="Expand" transform="translate(0 19.521) rotate(-90)">
              <path
                id="Path_434"
                data-name="Path 434"
                d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                transform="translate(-178.807 -14.7)"
                fill="#ff6b00"
              />
            </g>
          </svg>
        </a>
      </div>

      <div className="uk-container learn-more-div support-section">
        <p className="learn-more-div-section-title">
          Support ValuesAdvisor’s Mission
        </p>
        <p>
          ValuesAdvisor is a nonprofit supported by individuals, foundations,
          fees from Financial Advisors, and users. If ValuesAdvisor has helped
          you along your journey to impact, please pay it forward to ensure
          others will have access to values-aligned financial professionals.
        </p>
        <a
          href={
            "https://rsfsocialfinance.org/give/give-to-rsf-projects/give-today-projects/"
          }
          className="homelink"
          target={"_blank"}
          rel="noopener noreferrer"
        >
          Pay it Forward Here{" "}
          <svg
            className="homelink__chevron"
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="19.521"
            viewBox="0 0 11 19.521"
          >
            <g id="Expand" transform="translate(0 19.521) rotate(-90)">
              <path
                id="Path_434"
                data-name="Path 434"
                d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                transform="translate(-178.807 -14.7)"
                fill="#ff6b00"
              />
            </g>
          </svg>
        </a>
      </div>
    </div>
  );
};

const Container = (props) => {
  return <LearnMore></LearnMore>;
};

export default Container;

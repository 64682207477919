import React, { useContext } from "react";

import Header from "../../components/_shared/header/header";
import { withRouter } from "react-router-dom";
//import { useAuth } from "../../hooks/useAuth";
import { AuthContext } from "../../Context/AuthContext";


const Container = (props) => {
  const authContext = useContext(AuthContext);
  const {
    isAdmin,
    isAdvisor,
    logout,
    isLoggedIn,
    advisorId
  } = authContext;

  const authHandler = (e) => {
    e.preventDefault()
    if(isLoggedIn){
      logout();
      window.location = "/"
      return;
    }
    window.location = "/login"
  };

  return (
    <React.Fragment>
      <Header
        isLoggedIn={isLoggedIn}
        isAdvisor={isAdvisor}
        isAdmin={isAdmin}
        advisorId={advisorId}
        authHandler={authHandler}
        logoutHandler={authHandler}
        {...props}
      ></Header>
 
    </React.Fragment>
  );
};
export default withRouter(Container);

import React from 'react'
import classes from './Button.module.css'
const RightChevron = () => {
    return (
      <svg
        className={classes.chevron}
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="19.521"
        viewBox="0 0 11 19.521"
      >
        <g id="Expand" transform="translate(0 19.521) rotate(-90)">
          <path
            id="Path_434"
            data-name="Path 434"
            d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
            transform="translate(-178.807 -14.7)"
            fill="#ff6b00"
          />
        </g>
      </svg>
    );
  };

export default RightChevron;
import axiosInstance from ".";

export const listAffinityGroups = () => {
  return axiosInstance({
    method: "get",
    url: `/affinity-groups`,
  });
};

export const createAffinityGroup = (affinityGroup) => {
  return axiosInstance({
    method: "post",
    url: `/affinity-groups`,
    data: affinityGroup,
  });
};

export const updateAffinityGroup= (affinityGroup) => {
  return axiosInstance({
    method: "put",
    url: `/affinity-groups`,
    data: affinityGroup,
  });
};

export const deleteAffinityGroup = (affinityGroupId) => {
  return axiosInstance({
    method: "delete",
    url: `/affinity-groups`,
    data: {
      id: affinityGroupId,
    },
  });
};

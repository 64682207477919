import React, { useContext } from "react";
import ReactGA from "react-ga4";
import { AdvisorsList } from "./AdvisorsList/AdvisorsList";
import { FiltersList } from "./FiltersList/FiltersList";
import { AdvisorsComparer } from "./AdvisorComparer/AdvisorsComparer";
import { isMobile } from "../../utils/isMobile";
import { SorterMobile } from "./SorterMobile/SorterMobile";
import { SearchResultsContext } from "../../Context/SearchResultsContext";
import FloatingContact from "../../components/FloatingContact/FloatingContact";

/**
 * SearchResults handles the rendering of advisors on screen.
 */
const SearchResults = () => {
  const {
    filterTypes,
    selectedFilters,
    setSelectedFilters,
    setSelectedSort,
    setInitialFilters,
    setSelectedOrder,
    selectedOrder,
    setAdvisors,
    advisors,
  } = useContext(SearchResultsContext);
  const onSortSelected = (value, zipCode) => {
    setSelectedSort({ value, zipCode });
  };

  const onAccountSizeSelected = (filtersId, filterItemId) => {
    setSelectedFilters({
      ...selectedFilters,
      minimumAccountSizeType: [filterItemId],
    });
  };
  const onTypicalAccountSizeChange = (value = []) => {
    setSelectedFilters({
      ...selectedFilters,
      typicalAccountSizeType: value,
    });
  };
  const onFilterSelected = (filtersId, filterItemId) => {
    if(filtersId === "typicalAccountSizeType") {
      onTypicalAccountSizeChange([]);
      return
    }
    let newSelected = [];
    if (selectedFilters[filtersId]) {
      if (!selectedFilters[filtersId].includes(filterItemId)) {
        newSelected = [...selectedFilters[filtersId], filterItemId];
        setSelectedFilters(
          { ...selectedFilters, [filtersId]: newSelected },
          { filtersId, filterItemId }
        );
        return;
      }
      newSelected = selectedFilters[filtersId].filter(
        (id) => id !== filterItemId
      );
      setSelectedFilters({ ...selectedFilters, [filtersId]: newSelected });
    }
  };

  const onAddToCompare = (advisorId) => {
    const advisorsOnCompare = advisors.filter((a) => a.toBeCompared).length;
    const maxAllowedOnCompare = isMobile() ? 2 : 4;
    if (advisorsOnCompare >= maxAllowedOnCompare) {
      return;
    }
    const foundIndex = advisors.findIndex(
      (advisor) => advisor.id === advisorId
    );
    const newAdvisors = [...advisors];
    newAdvisors[foundIndex].toBeCompared = true;
    setAdvisors(newAdvisors);

    ReactGA.event({
      category: `advisor profile`,
      action: "compared",
      label: newAdvisors[foundIndex].name,
      value: 1,
    });
  };

  const onRemoveFromCompare = (advisorId) => {
    const foundIndex = advisors.findIndex(
      (advisor) => advisor.id === advisorId
    );
    const newAdvisors = [...advisors];
    newAdvisors[foundIndex].toBeCompared = false;
    setAdvisors(newAdvisors);
  };

  return (
    <main>
      <FiltersListContainerMobile
        setInitialFilters={setInitialFilters}
        filterTypes={filterTypes}
        onFilterSelected={onFilterSelected}
        onAccountSizeSelected={onAccountSizeSelected}
        advisors={advisors}
        onSortSelected={onSortSelected}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
      />

      {/* intro ends here */}
      {/* maincomponents start here */}
      <div className="maincomponent">
        <div className="uk-container">
          <div className="uk-grid uk-grid-divider">
            {/* filter */}
            <FiltersListContainer
              setInitialFilters={setInitialFilters}
              filterTypes={filterTypes}
              onFilterSelected={onFilterSelected}
              onAccountSizeSelected={onAccountSizeSelected}
              onTypicalAccountSizeChange={onTypicalAccountSizeChange}
            />
            {/* search results */}

            <AdvisorsList
              selectedFilters={selectedFilters}
              onFilterSelected={onFilterSelected}
              onSortSelected={onSortSelected}
              onAddToCompare={onAddToCompare}
              onRemoveFromCompare={onRemoveFromCompare}
            />
          </div>
        </div>
        <AdvisorsComparer
          advisors={advisors.filter((a) => a.toBeCompared)}
          types={filterTypes}
          onRemoveFromCompare={onRemoveFromCompare}
        ></AdvisorsComparer>
      </div>
      {/* maincomponents ends here */}
    </main>
  );
};
const FiltersListContainer = ({
  setInitialFilters,
  filterTypes,
  onFilterSelected,
  onAccountSizeSelected,
  onTypicalAccountSizeChange
}) => {
  return (
    <div className="uk-width-1-4 filter-container uk-first-column uk-visible@m">
      <div className="filter-top">
        <div className="title-bar">
          <div className="filters">
            <div className="filterstitle">
              <div className="uk-grid">
                <div className="uk-width-1-2 uk-flex uk-flex-middle">
                  <h4 className="filterstitle__filter">
                    <span uk-icon="icon: settings" className="uk-icon" />
                    FILTERS
                  </h4>
                </div>
                <div className="uk-width-1-2 uk-text-right">
                  <button
                    className="filterstitle__reset"
                    onClick={() => {
                      setInitialFilters(filterTypes);
                    }}
                  >
                    <span className=" uk-icon" uk-icon="icon: refresh" />
                    <span>RESET</span>
                  </button>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <FiltersList
        onFilterSelected={onFilterSelected}
        onAccountSizeSelected={onAccountSizeSelected}
        onTypicalAccountSizeChange={onTypicalAccountSizeChange}
      />

<FloatingContact location="Search Page"></FloatingContact>
    </div>
  );
};
const FiltersListContainerMobile = ({
  setInitialFilters,
  filterTypes,
  onFilterSelected,
  onAccountSizeSelected,
  advisors,
  onSortSelected,
  selectedOrder,
  setSelectedOrder,
}) => {
  return (
    <>
      <div className="introactions uk-hidden@m">
        <div className="uk-container">
          <div className="intro-container intro-container--small-fix --no-shadow">
            <div className="uk-child-width-1-2 uk-grid uk-grid-divider">
              <div className="uk-first-column">
                <button
                  className="introactions__btn"
                  type="button"
                  //uk-toggle="target: #offcanvasFilter"
                  onClick={(e) => {
                     document.getElementById('offcanvasFilter').style.display = 'block'
                  }}
                >
                  <svg
                    className="filter__svg"
                    id="Group_137"
                    data-name="Group 137"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.224"
                    height="14.003"
                    viewBox="0 0 18.224 14.003"
                  >
                    <path
                      id="Path_159"
                      data-name="Path 159"
                      d="M16.453,29.252h8.4a3.314,3.314,0,0,0,6.4,0h1.773a.828.828,0,0,0,0-1.657H31.248a3.314,3.314,0,0,0-6.4,0h-8.4a.828.828,0,0,0,0,1.657Zm11.6-2.485a1.657,1.657,0,1,1-1.657,1.657A1.657,1.657,0,0,1,28.051,26.767Zm4.97,8.284h-8.4a3.314,3.314,0,0,0-6.4,0H16.453a.828.828,0,0,0,0,1.657h1.773a3.314,3.314,0,0,0,6.4,0h8.4a.828.828,0,1,0,0-1.657Zm-11.6,2.485a1.657,1.657,0,1,1,1.657-1.657A1.657,1.657,0,0,1,21.424,37.536Z"
                      transform="translate(-15.625 -25.15)"
                      fill="#4d4d4d"
                    />
                  </svg>
                  FILTERS
                </button>
              </div>
              <div>
                <button
                  className="introactions__btn"
                  uk-toggle="target: #sortphone; cls: show; animation: uk-animation-fade"
                >
                  <svg
                    className="filter__svg"
                    id="SortBy"
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.583"
                    height="17.082"
                    viewBox="0 0 19.583 17.082"
                  >
                    <g id="Group_4" data-name="Group 4">
                      <path
                        id="Path_434"
                        data-name="Path 434"
                        d="M258.681,16.071a.7.7,0,0,1-.985.017v0l-3.03-2.9V24.953a.7.7,0,0,1-1.4,0V13.187l-3.029,2.9a.7.7,0,0,1-.987-.034.686.686,0,0,1,.017-.96l4.2-4.023h0l.008-.009.006,0a.687.687,0,0,1,.2-.134l0,0,.017,0,.019-.006a.658.658,0,0,1,.218-.042l.011,0,.009,0,.008,0,.014,0a.709.709,0,0,1,.218.042l.016.006.017,0,0,0a.679.679,0,0,1,.2.134l.006,0,.009.009h0l4.2,4.023A.687.687,0,0,1,258.681,16.071Zm9.774,6.673a.7.7,0,0,0-.987-.017h0l-3.029,2.9V13.863a.7.7,0,0,0-1.4,0V25.627l-3.03-2.9a.7.7,0,0,0-.987.035.687.687,0,0,0,.017.959l4.2,4.025h0l.008.008.008,0a.516.516,0,0,0,.072.059l.016.012a.614.614,0,0,0,.121.065l.009,0,.012,0,.04.012a.57.57,0,0,0,.068.02.7.7,0,0,0,.277,0,.744.744,0,0,0,.078-.023l.031-.009.012,0,.009,0a.574.574,0,0,0,.121-.065l.009-.008a.6.6,0,0,0,.078-.063l.006,0,.009-.008h0l4.2-4.025a.687.687,0,0,0,.017-.977Z"
                        transform="translate(-249.067 -10.866)"
                        fill="#ff6b00"
                      />
                    </g>
                  </svg>
                  SORT BY
                </button>
              </div>
            </div>
          </div>
          <div
            id="offcanvasFilter"
            className="offcanvas-filter uk-offcanvas uk-offcanvas-overlay "
            //uk-offcanvas="overlay: true"
          >
            <div className="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">
              <div className="filter-top">
                <div className="title-bar">
                  <div className="filters position-relative">
                    <div className="filterstitle">
                      <div className="uk-grid">
                        <div className="uk-width-1-3">
                          <h4 className="filterstitle__close">
                            <button
                              className="uk-offcanvas-close widthfit"
                              type="button"
                              uk-close={"true"}
                              onClick={(e) => {
                                document.getElementById('offcanvasFilter').style.display = 'none'
                             }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#4D4D4D"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x"
                              >
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                              </svg>
                            </button>
                          </h4>
                        </div>
                        <div className="uk-width-1-3 uk-flex uk-flex-middle">
                          <h4 className="filterstitle__filter ">
                            <svg
                              className="filter__svg filter__svg--black"
                              id="Group_137"
                              data-name="Group 137"
                              xmlns="http://www.w3.org/2000/svg"
                              width="18.224"
                              height="14.003"
                              viewBox="0 0 18.224 14.003"
                            >
                              <path
                                id="Path_159"
                                data-name="Path 159"
                                d="M16.453,29.252h8.4a3.314,3.314,0,0,0,6.4,0h1.773a.828.828,0,0,0,0-1.657H31.248a3.314,3.314,0,0,0-6.4,0h-8.4a.828.828,0,0,0,0,1.657Zm11.6-2.485a1.657,1.657,0,1,1-1.657,1.657A1.657,1.657,0,0,1,28.051,26.767Zm4.97,8.284h-8.4a3.314,3.314,0,0,0-6.4,0H16.453a.828.828,0,0,0,0,1.657h1.773a3.314,3.314,0,0,0,6.4,0h8.4a.828.828,0,1,0,0-1.657Zm-11.6,2.485a1.657,1.657,0,1,1,1.657-1.657A1.657,1.657,0,0,1,21.424,37.536Z"
                                transform="translate(-15.625 -25.15)"
                                fill="#4d4d4d"
                              />
                            </svg>
                            FILTERS
                          </h4>
                        </div>
                        <div className="uk-width-1-3 uk-text-right">
                          <button
                            className="filterstitle__reset"
                            onClick={() => {
                              setInitialFilters(filterTypes);
                            }}
                          >
                            <svg
                              className="reset__svg"
                              xmlns="http://www.w3.org/2000/svg"
                              width="10.084"
                              height="11.18"
                              viewBox="0 0 10.084 11.18"
                            >
                              <g
                                id="Group_148"
                                data-name="Group 148"
                                transform="translate(-8.182 -2.561)"
                              >
                                <path
                                  id="Path_160"
                                  data-name="Path 160"
                                  d="M16.342,5.791a.456.456,0,0,0-.636-.118.472.472,0,0,0-.118.659A4.11,4.11,0,1,1,12.14,4.578v.753a.349.349,0,0,0,.53.294l1.942-1.212a.342.342,0,0,0,0-.589L12.67,2.613a.347.347,0,0,0-.53.294V3.66A5,5,0,0,0,7.22,8.074a5.043,5.043,0,1,0,9.123-2.284Z"
                                  transform="translate(1)"
                                  fill="#4d4d4d"
                                />
                              </g>
                            </svg>
                            <span>RESET</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <FiltersList
                onFilterSelected={onFilterSelected}
                onAccountSizeSelected={onAccountSizeSelected}
              />
              <div className="filter-bottom">
                <span className="filter-bottom__results">
                  {`${advisors.length} Matching Advisors`}
                </span>
                <a
                  className="filter-bottom__link"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault()
                    document.getElementById('offcanvasFilter').style.display = 'none'
                 }}
                >
                  {" "}
                  SHOW RESULTS{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SorterMobile
        onSortSelected={onSortSelected}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
      ></SorterMobile>
    </>
  );
};
export default SearchResults;

import React, { useState, useContext } from "react";
import { JoinContext } from "../../../pages/Join/Context";

import { US_STATES } from "../../../constants/constants";
import "./CreateUser.css";
import FormSelect from "../../../components/_shared/FormSelect";

export const JoinCreateUser = ({ next = () => {}, back = () => {} }) => {
  const {
    userEmail,
    userPassword,
    setUserEmail,
    setUserPassword,
    emailError,
    passwordError,
    confirmPassword,
    setconfirmPassword,
    userFirstName,
    setUserFirstName,
    userLastName,
    setUserLastName,
    countries,
    userCountry,
    setUserCountry,
    userState,
    setUserState,
    userCity,
    setUserCity,
  } = useContext(JoinContext);
  const [showPassword, setShowPassword] = useState(false);
  const passwordFieldType = showPassword ? "text" : "password";
  const showPasswordButtonText = showPassword ? (
    <img
      className="how-hide-password-icon"
      src={`${process.env.PUBLIC_URL}/img/icons/hide-password.svg`}
      alt=""
    />
  ) : (
    <img
      className="how-hide-password-icon"
      src={`${process.env.PUBLIC_URL}/img/icons/show-password.svg`}
      alt=""
    />
  );
  return (
    <div className="create-account-container">
      <div className="login__title__sub-title-container">
        <h3 className="login__title accout_title">Let's Get Started</h3>
        <h4 className="login__sub-title">
          We just need a little information from you <br /> to start your
          subscription
        </h4>
      </div>
      <form
        style={{
          padding: "0px 25%",
        }}
        onSubmit={(e) => {
          e.preventDefault();

          next(e);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "48%",
            }}
          >
            <label className="input-title" htmlFor={"email"}>
              FIRST NAME
            </label>

            <input
              className="uk-input input-box"
              type="text"
              name="firstName"
              placeholder=" "
              value={userFirstName}
              onChange={setUserFirstName}
              required
            />
          </div>
          <div
            style={{
              width: "48%",
            }}
          >
            <label className="input-title" htmlFor={"email"}>
              LAST NAME
            </label>
            <input
              className="uk-input input-box"
              type="text"
              name="lastName"
              placeholder=" "
              value={userLastName}
              onChange={setUserLastName}
              required
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              width: "30%",
            }}
          >
            <label className="input-title" htmlFor={"email"}>
              SELECT COUNTRY
            </label>
            <FormSelect
              name="country"
              style={{ width: "100%", marginBottom: "0px" }}
              options={countries.map((country) => {
                return {
                  value: country.code,
                  label: country.name,
                };
              })}
              value={userCountry}
              onChange={(country) => {
                setUserCountry(country);
              }}
              required={true}
              error={""}
              showError={""}
            />
          </div>
          <div
            style={{
              width: "30%",
            }}
          >
            <label className="input-title" htmlFor={"email"}>
              SELECT STATE
            </label>
            <FormSelect
              style={{ width: "100%", marginBottom: "0px" }}
              options={(US_STATES || []).map((state) => {
                return {
                  value: state.code,
                  label: state.name,
                };
              })}
              value={userState}
              onChange={(e) => {
                setUserState(e);
              }}
              error={""}
              showError={""}
              isDisabled={userCountry?.value !== "US"}
              required={userCountry?.value === "US"}
            />
          </div>
          <div
            style={{
              width: "30%",
            }}
          >
            <label className="input-title" htmlFor={"email"}>
              ENTER CITY
            </label>
            <input
              className="uk-input input-box"
              type="text"
              name="city"
              placeholder=" "
              value={userCity}
              onChange={setUserCity}
              required
            />
          </div>
        </div>
        <div>
          <label className="input-title" htmlFor={"email"}>
            EMAIL ADDRESS
          </label>
          {emailError ? (
            <p className="error-message uk-text-danger">{emailError}</p>
          ) : null}
          <input
            className="uk-input input-box"
            type="email"
            name="email"
            placeholder=" "
            value={userEmail}
            onChange={setUserEmail}
            required
          />
        </div>
        <div>
          <label className="input-title" htmlFor="Password">
            CREATE PASSWORD
          </label>
          {passwordError ? (
            <p className="error-message uk-text-danger">{passwordError}</p>
          ) : null}
          <div className="placeholder-with-required-indicator input-show-wrapper">
            <input
              className="uk-input no-border input-box"
              type={passwordFieldType}
              name="password"
              placeholder=" "
              value={userPassword}
              onChange={setUserPassword}
              required
              minLength={8}
            />
            <a
              className="no-select"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPasswordButtonText}
            </a>
          </div>
        </div>

        <div>
          <label className="input-title" htmlFor="confirm_password">
            CONFIRM PASSWORD
          </label>
          <div>
            <input
              className="uk-input input-box"
              type={passwordFieldType}
              name="confirm_password"
              placeholder=" "
              required
              value={confirmPassword}
              onChange={setconfirmPassword}
              minLength={8}
            />
          </div>
        </div>
        <div className="buttons">
          <button
            onClick={(e) => {
              back(e);
            }}
            className="homelink create-account back"
          >
            <svg
              className="homelink__chevron"
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="19.521"
              viewBox="0 0 11 19.521"
            >
              <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                <path
                  id="Path_434"
                  data-name="Path 434"
                  d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                  transform="translate(-178.807 -14.7)"
                />
              </g>
            </svg>
            Back
          </button>
          <button type="submit" className="homelink create-account">
            Create Account
            <svg
              className="homelink__chevron"
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="19.521"
              viewBox="0 0 11 19.521"
            >
              <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                <path
                  id="Path_434"
                  data-name="Path 434"
                  d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                  transform="translate(-178.807 -14.7)"
                  fill="#ff6b00"
                />
              </g>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

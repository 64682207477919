import React from "react";
import "./BlogPost.css";

const Component = () => {
  return (
    <div className="blog-post-container">
      <article>
        <h1 className="article-title">Four Steps to Leave Your Advisor</h1>
        <h4 className="article-author">By ValuesAdvisor</h4>
        <img
          src="img/four-steps-to-leave-your-advisor.webp"
          width="413"
          height="348"
          loading="lazy"
          alt="Screen Shot 2023-03-27 at 11.22.44 AM"
          style={{
            height: "auto",
            maxWidth: "100%",
            width: "413px",
            margin: "0px 0px 0px 132px",
          }}
          srcset="https://portal.valuesadvisor.org/hs-fs/hubfs/Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png?width=207&amp;height=174&amp;name=Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png 207w, https://portal.valuesadvisor.org/hs-fs/hubfs/Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png?width=413&amp;height=348&amp;name=Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png 413w, https://portal.valuesadvisor.org/hs-fs/hubfs/Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png?width=620&amp;height=522&amp;name=Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png 620w, https://portal.valuesadvisor.org/hs-fs/hubfs/Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png?width=826&amp;height=696&amp;name=Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png 826w, https://portal.valuesadvisor.org/hs-fs/hubfs/Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png?width=1033&amp;height=870&amp;name=Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png 1033w, https://portal.valuesadvisor.org/hs-fs/hubfs/Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png?width=1239&amp;height=1044&amp;name=Screen%20Shot%202023-03-27%20at%2011.22.44%20AM.png 1239w"
          sizes="(max-width: 413px) 100vw, 413px"
        />
        <br />
        <br />
        <div>
          <p>
            It can feel hard - scary even - to think about leaving your current
            advisor.
          </p>

          <p>
            You may have a long standing relationship with your advisor that
            makes you feel loyal to them. Your family may still work with them,
            which can bring up difficult family conversations. Yet, despite your
            history with the advisor, you've decided that you need to find
            someone new, perhaps an advisor who understands your desire to align
            your investments with the things that are important to you.{" "}
          </p>

          <p>
            Yet, despite how it sometimes feels, the process of leaving an
            advisor is relatively straight forward. Before be find a new
            advisor, they will help you with the process and walk you through
            it. The hardest part may be mentally prepared for a possible
            “retention pitch” when you let them know. But if you’re committed to
            having your assets invested in alignment with your values, and your
            existing advisor is not meeting this need, stick with your decision
            and follow these steps!
          </p>

          <p>
            Before leaving your current advisor, find a new one. If you don't
            know how to get started, ValuesAdvisor is a great place to start and
            has a{" "}
            <a
              href="https://www.valuesadvisor.org/blog/find-advisor-with-confidence"
              rel="noreferrer"
              target="_blank"
            >
              blog post
            </a>{" "}
            on how to find one that can help you meet both your values and your
            financial goals.&nbsp;
          </p>
          <strong>STEP ONE: Set up a call with your new advisor</strong>

          <p>
            Set up a call with your new advisor to specifically discuss how to
            transfer your account to them.
          </p>

          <p>Questions: </p>

          <ul>
            <li>
              <p>
                What are the exact steps you need to take to move your account
                to them? Are there forms you need to fill out? <br /> Your new
                advisor will help you navigate the paperwork of moving your
                assets from the old firm and transferring them to a new one, and
                will let you know of any tax consequences, if any.
              </p>
            </li>
            <li>
              <p>
                What information do they need from you - either provided by you
                or your existing advisor? What documents do they need copies of?
                I.e statements, review presentations, online transaction
                records, contracts, and the{" "}
                <a href="https://www.investopedia.com/terms/c/costbasis.asp">
                  cost bases
                </a>{" "}
                of all securities. <br /> If you don't have copies of the
                documents your new advisor needs, you often can find them online
                or can get copies from your existing advisor.
              </p>
            </li>
          </ul>
          <strong>STEP TWO: Set up a call with your existing advisor</strong>
          <p>
            Schedule a call with the advisor you are leaving to inform them of
            your decision and discuss the transferring of your account.
            <br />
            Let them know that the new advisor will be handling the details. Ask
            them to cooperate with your new advisor to facilitate the transfer.
          </p>
          <ul>
            <li>
              <p>
                Inform them you are leaving. If you feel comfortable, you can
                tell them why (A few ideas: "I am choosing a new advisor for the
                next chapter of my life;" "I am seeking an advisor who has a
                history of impact investing expertise", "I am moving to a new
                advisor whose approach fits my needs and can execute the
                investment philosophy I want going forward." If you don't feel
                comfortable, you don't have to share why you are leaving.
                <br />
                <br />
                Thank them for, their guidance, and assistance.
                <br /> Resist the urge to say you’re sorry. There is no reason
                to apologize.
              </p>
            </li>
            <li>
              <p>
                Ask them if there are any specific actions you need to take to
                release your account to the new advisor (are there any
                non-transferable funds, lines of credit, or anything else that
                may hold up the transfer of assets). If you don't have copies of
                your statements, or other information the new advisor needs, ask
                the advisor to provide it. Federal regulation requires that your
                current advisor transfer the historical account records and
                transaction history to your new advisor.
              </p>
            </li>
            <li>
              <p>
                Ask them whether there are costs associated with moving assets,
                which can include closing-account fees, exit fees from certain
                funds, commissions for selling investments that can't be
                transferred (and any losses).
              </p>
            </li>
          </ul>
          <strong>STEP THREE: Follow up in writing</strong>
          <p>
            Follow up your verbal conversation to your existing advisor with a
            written notice of your intent to leave soon after your call with
            them, either via email or a physical letter. This will serve as a
            formal record of your decision and provide clarity on the next
            steps.
          </p>

          <strong>STEP FOUR: Stay in contact with your new advisor</strong>
          <p>
            Your new advisor will hold your hand as much as you need during the
            transition process and walk you through it. If they're not available
            directly, they should have members on their team always available to
            answer questions. Remember that they have done this many times and
            they know exactly how to transfer your account.
            <br />
            <br />
            If you'd like to ensure you're informed, ask that you be copied on
            all correspondence between your old and new advisor
          </p>

          <h1>The mechanics of moving assets from one advisor to another</h1>

          <p>
            You may be curious to learn more about the technical side of
            transferring your account from one firm to another. Below are a few
            common ways. Be sure to ask your new advisor if you'd like to learn
            more.{" "}
          </p>
          <ul>
            <li>
              <p>
                <strong>In-kind transfers </strong>
                move all investments held by a major{" "}
                <a href="https://www.investopedia.com/terms/c/custodian.asp">
                  custodian
                </a>{" "}
                such as Fidelity or Charles Schwab, to your new custodian or
                account. Most custodians use the ACAT (
                <a href="https://www.investopedia.com/terms/a/acat.asp">
                  Automated Customer Account Transfer Service
                </a>
                ) system which moves assets electronically with negligible fees.
                The transfer only requires a simple authorization (signature)
                prepared for you, by your new advisor.&nbsp;
              </p>
            </li>
            <li>
              <p>
                Some bank transfers cannot be completed through the electronic
                ACAT system and will need to be delivered out by the terminated
                advisor’s custodian to your new account.
              </p>
            </li>
            <li>
              <p>
                For <strong> privately held assets </strong>, like private
                funds, that you want your new advisor to provide custodianship
                for, the new advisor will reach out to the fund to find out what
                is needed to facilitate a private fund transfer.{" "}
              </p>
            </li>
            <li>
              <p>
                Your new advisor will determine how/if{" "}
                <strong>assets exclusive to your former advisor's firm</strong>{" "}
                can be transferred. If they need to be liquidated, they will
                take current markets and tax consequences into consideration.
              </p>
            </li>
          </ul>
          <p>Typical forms used:</p>
          <ol>
            <li>
              <p>
                ACAT form: This is a form used to transfer assets between
                brokerage firms. The form includes information on the account(s)
                to be transferred, the receiving firm, and any other relevant
                details. The transferring firm typically initiates this process.
                Your new advisor will give you this form to sign, after which
                the advisor’s custodian will send it to the terminated advisor’s
                custodian for processing.{" "}
              </p>
            </li>
            <li>
              <p>
                IRA transfer form: If the account being transferred is an
                individual retirement account (IRA), this form specifies the IRA
                account details and the receiving IRA custodian.
              </p>
            </li>
            <li>
              <p>
                Stock certificates: If you hold physical stock certificates,
                they may need to be transferred to the new advisory firm.
              </p>
            </li>
            <li>
              <p>
                Annuity transfer form: An annuity transfer form transfers an
                annuity contract to the new advisor.
              </p>
            </li>
          </ol>
          <p>Additional Optional Steps:</p>
          <p>
            Seek outside guidance. If your finances are particular complex, or
            you feel nervous about signing a new advisor's contracts, you can
            review with an accountant or attorney.
          </p>
          <p>Additional resource:</p>
          <p>
            Podcast{" "}
            <a href="https://www.whitecoatinvestor.com/how-to-break-up-with-your-financial-advisor-podcast-99/">
              “How to break up with your Financial Advisor”
            </a>
          </p>
          <p style={{ textAlign: "center" }}>
            ValuesAdvisor is a nonprofit platform to help you find an advisor
            with expertise in values aligned investing. <br />
            To sign up:{" "}
            <a href="https://www.valuesadvisor.org/join">
              https://www.valuesadvisor.org/join
            </a>
          </p>
        </div>
      </article>
    </div>
  );
};
export default Component;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';

class HeaderAdmin extends Component {
    render() {
        return (
            <header className="admin-header">
                <div className="navbar">
                    <div className="container custom-container d-flex justify-content-between">
                        <a href={'/admin'} className="navbar-brand d-flex align-items-center">
                            <img src={logo} alt="ValuesAdvisor" className="logo" />
                        </a>
                        <div className="nav-right">
                        {<Link to="/admin-affinity-groups" className="link-profile">Affinity Groups</Link>}
                              {<Link to="/admin-subscriptions" className="link-profile">Subscriptions</Link>}
                            {<Link to="/admin-discount-coupons" className="link-profile">Discount Coupons</Link>}
                            {<Link to="/admin" className="link-profile" onClick={this.props.resetStatus}>All Profiles</Link>}
                            <Link to="" onClick={this.props.logoutHandler}>Logout</Link>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderAdmin;
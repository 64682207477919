import React from "react";

export const CheckboxFilters = ({
  title,
  text,
  items = [],
  selectedFilters,
  filtersId = "",
  onFilterSelected,
  toolTipText,
  toolTipTextId,
  toolTipStyle,
  toolTipTextStyle
}) => {
  return (
    <div className="impacttheme filter-section">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div uk-accordion={"true"} className="uk-accordion">
            <div
              className={
                !(selectedFilters[filtersId] || []).length
                  ? "shareholder-filter-container"
                  : "shareholder-filter-container uk-open"
              }
            >
              <h3 className="uk-accordion-title section-title">
                <a href="/" onClick={(e) => e.preventDefault()}>
                  {title}
                </a>
              </h3>
              {toolTipText ? (
                <span
                  style={toolTipStyle ? toolTipStyle : {}}
                  className="info-top"
                  uk-toggle={`target: #${toolTipTextId}; cls: show; animation:
                                          uk-animation-fade; mode: hover`}
                >
                  <img src="img/info.svg" alt="" />
                  <div
                    className="tooltip"
                    style={toolTipTextStyle ? toolTipTextStyle : {}}
                    id={toolTipTextId}
                    aria-hidden="true"
                  >
                    <p>{toolTipText}</p>
                  </div>
                </span>
              ) : null}

              <div className="uk-accordion-content">
                <p className="section-text">{text}</p>
                <div>
                  <div className="checklist">
                    {items.map((i, index) => {
                      const isChecked =
                        selectedFilters[filtersId] &&
                        selectedFilters[filtersId].includes(i.id);
                      return (
                        <div key={i.value} className="checklist__item">
                          <input
                            className="uk-checkbox"
                            type="checkbox"
                            onChange={(e) => {
                              onFilterSelected(filtersId, i.id);
                            }}
                            checked={isChecked || false}
                          />
                          <label>
                            <span className="checklist__item-label">
                              {i.label}
                            </span>
                            {i.description && (
                              <React.Fragment>
                                <span
                                  className="tooltip-toogle"
                                  id={`tooltip-toogle-${index}${filtersId}`}
                                  uk-toggle={`target: .filter-tooltip-${index}${filtersId}, #tooltip-toogle-${index}${filtersId}; cls: show; mode: click, hover`}
                                >
                                  <img src=" img/info.svg" alt="" />
                                  <div
                                    className={`uk-visible@m strict-desktop-only tooltip filter-tooltip-${index}${filtersId}`}
                                    aria-hidden="true"
                                  >
                                    <p>{i.description}</p>
                                  </div>
                                </span>
                                <div
                                  className={`uk-hidden@m tooltip filter-tooltip-${index}${filtersId}`}
                                  aria-hidden="true"
                                >
                                  <p>{i.description}</p>
                                </div>
                              </React.Fragment>
                            )}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

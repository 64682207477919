import React from "react";
import classes from "./Tabs.module.css";
export const ValuesAlignedTab = ({
  interestedInImpact,
  descOfWhatClientCares,
  impactStrategy,
  proxyVotings,
  proxyVotingType,
  measureAndReport,
  voteProxies,
  engagedWithManagement,
  teamInfo,
}) => {
  const proxyVotingsList = proxyVotings.map((it) => {
    const type = proxyVotingType.find((t) => t.id === it.proxyVotingId);
    return <li key={type.id}>{type.label}</li>;
  });
  const { impactField } = teamInfo;
  return (
    <li className="value uk-margin-large-bottom">
      <div
        className={`uk-grid uk-grid uk-margin-small  ${classes["full-width"]}`}
      >
        <div className="uk-width-1-1">
          <h3 className="generic__title">Values Aligned Investment Services</h3>
        </div>
        <div
          className={`uk-grid first-fix-align fix-align ${classes["full-width"]} uk-margin-remove-top `}
        >
          <div className="uk-width-1-3@s">
            <p className="value__title">
              Process of Identifying, Evaluating, and Selecting Investment
              Opportunities
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace:'pre-wrap' }}>
              <p>{measureAndReport}</p>
            </pre>
          </div>
        </div>
        <div className="uk-width-1-1">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">Impact Genesis Story</p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace:'pre-wrap' }}>
              <p>{interestedInImpact}</p>
            </pre>
          </div>
        </div>
        <div className="uk-width-1-1">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">
              Case Study: an Example of a Meaningful Investment
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace:'pre-wrap' }}>
              <p>{descOfWhatClientCares}</p>
            </pre>
          </div>
        </div>
        <div className="uk-width-1-1@s">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">
              Team’s Leadership in the Impact Field
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace:'pre-wrap' }}>
              <p>{impactField}</p>
            </pre>
          </div>
        </div>
        <div className="uk-width-1-1">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">
              UN SDG Implementation United Nations Sustainable Development Goals
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace:'pre-wrap' }}>
              <p>{impactStrategy}</p>
            </pre>
          </div>
        </div>

        <div className="uk-width-1-1">
          <hr />
        </div>

        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">
            How Team helps with Active Ownership of Public Equities
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <ul className="team__list">{proxyVotingsList}</ul>
          </div>
        </div>

        <div className="uk-width-1-1">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">
              How the Team Enables the Clients to Vote Their Proxies
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace:'pre-wrap' }}>
              <p>{voteProxies}</p>
            </pre>
          </div>
        </div>

        <div className="uk-width-1-1">
          <hr />
        </div>
        <div className={`uk-grid fix-align ${classes["full-width"]}`}>
          <div className="uk-width-1-3@s">
            <p className="value__title">
              Case Study: an Example of How the Team has engaged with company
              management, filed or co-filed an ESG related shareholder
              resolution
            </p>
          </div>
          <div className="uk-width-2-3@s">
            <pre style={{ border: "none", whiteSpace:'pre-wrap' }}>
              <p>{engagedWithManagement}</p>
            </pre>
          </div>
        </div>
      </div>
    </li>
  );
};

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";
import "./vars.css";
import "./styles.css";
import "./App.css";
import Footer from "./components/_shared/footer/footer";
import Routes from "./routes/index";
import { AuthContext } from "./Context/AuthContext";
import { removeAuthData } from "./utils/storeAuthData";
import storeAuthData from "./utils/storeAuthData";
import { fetchLogin, autoSignIn } from "./api/sign-in";
import { toast } from "react-toastify";
import Hotjar from "@hotjar/browser";
import { HelmetProvider } from "react-helmet-async";

if (
  process.env.REACT_APP_HOTJAR_SITE_ID &&
  process.env.REACT_APP_ENVIRONMENT === "production"
) {
  Hotjar.init(
    process.env.REACT_APP_HOTJAR_SITE_ID,
    process.env.REACT_APP_HOTJAR_VERSION || 6
  );
}

if (process.env.REACT_APP_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
 
}

const browserHistory = createBrowserHistory();
browserHistory.listen((location, action) => {
  //ReactGA.pageview(location.pathname + location.search);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
  });
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  ignoreErrors: ["Non-Error promise rejection captured"],
});

const App = (props) => {
  const [authState, setAuthState] = useState({
    id: window.localStorage.getItem("id"),
    token: window.localStorage.getItem("token"),
    role: window.localStorage.getItem("role"),
    isLoggedIn: window.localStorage.getItem("isLoggedIn"),
    status: window.localStorage.getItem("status"),
    firstName: window.localStorage.getItem("firstName"),
    lastName: window.localStorage.getItem("lastName"),
    email: window.localStorage.getItem("email"),
    clientType: window.localStorage.getItem("clientType"),
    advisorId: window.localStorage.getItem("advisorId"),
  });

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  useEffect(() => {
    Sentry.setUser({
      id: authState.id,
      email: authState.email,
    });
    ReactGA.set({ userId: authState.id });
  }, [authState.id, authState.email]);

  function setClientType(clientType) {
    storeAuthData({ ...authState, clientType });
    setAuthState({ ...authState, clientType });
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <AuthContext.Provider
          value={{
            ...authState,
            setClientType,
            login: async (email, password) => {
              try {
                const result = await fetchLogin({ email, password });
                const isAdvisor = result.role === "advisor";
                const isAdmin = result.role === "admin";
                const isVisitor = result.role === "visitor";
               
                storeAuthData({ ...result, isLoggedIn: true });
                setAuthState({ ...result, isLoggedIn: true });
                return {
                  isVisitor,
                  id: result.id,
                  isAdmin,
                  isAdvisor,
                  advisorId: result.advisorId,
                };
              } catch (error) {
                toast.error(error.message);
              }
            },
            logout: () => {
              removeAuthData();
              setAuthState({});
            },
            autoLogin: async (autoSignInHash) => {
              try {
                const response = await autoSignIn({ autoSignInHash });
                if (response.data.success) {
                  storeAuthData({ ...response.data, isLoggedIn: true });
                  setAuthState({ ...response.data, isLoggedIn: true });
                }
              } catch (error) {
                toast.error(error.message);
                throw error;
              }
            },
            checkAuthState: () => {},
            isAdmin: authState.role === "admin",
            isAdvisor: authState.role === "advisor",
            isVisitor: authState.role === "visitor",
            currentUserID: authState.id,
            _setLoginEmail: (value) => {},
            _setLoginPassword: (value) => {},
            fetchLogin: async (email, password) => {
              const result = await fetchLogin({ email, password });
              const isAdvisor = result.role === "advisor";
              const isAdmin = result.role === "admin";
              const isVisitor = result.role === "visitor";
              if (result) {
                storeAuthData(result);
                setAuthState(result);
                return {
                  isVisitor,
                  id: result.id,
                  isAdmin,
                  isAdvisor,
                };
              }
            },
          }}
        >
          <Router>
            <React.Fragment>
              <Routes {...props} />
            </React.Fragment>
          </Router>
          <Footer></Footer>
        </AuthContext.Provider>
      </HelmetProvider>
    </React.Fragment>
  );
};

export default App;

import React from "react";

import { HeadQuarters } from "../HeadQuarters/HeadQuarters";
import { OtherOffices } from "../OtherOffices/OtherOffices";

import capitalize from "../../../utils/capitalize";
import { FirmSizeIcon } from "../UI/FirmSizeIcon";
import classes from "../Introduction/Introduction.module.css";

const OFFERS_PROPIETARY_PRODUCTS_ID = 1;
const PageOne = ({
  username,
  profilePic,
  firm,
  suggestedByMemberTypes,
  user,
  addresses,
  website,
  teamInfo,
  unsdgTypes,
  title,
}) => {
  const womenCharts = [
    { label: "The Firm", value: firm.employeesWomen, notApplyMessage: "N/A" },
    {
      label: "Senior Position",
      value: firm.seniorsWomen,
      notApplyMessage: "N/A",
    },
    {
      label: "Board of Directors",
      value: firm.directorsWomen,
      notApplyMessage: "No Board of Directors",
    },
  ].map((wc) => {
    const content = Number.isFinite(wc.value) ? (
      <svg viewBox="0 0 36 36" className="circular-chart orange">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${wc.value}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" className="percentage">
          {wc.value}%
        </text>
      </svg>
    ) : (
      <div className="not-apply-message-donut">
        <span className="pencentaje">{wc.notApplyMessage}%</span>
      </div>
    );
    return (
      <div
        key={`${wc.label}-${wc.value || 0}`}
        className="uk-width-1-3"
        style={{ textAlign: "center" }}
      >
        <h3 className="chart-title">{wc.label}</h3>
        {content}
      </div>
    );
  });
  const peopleOfColorCharts = [
    { label: "The Firm", value: firm.employeesColor, notApplyMessage: "N/A" },
    {
      label: "Senior Position",
      value: firm.seniorsColor,
      notApplyMessage: "N/A",
    },
    {
      label: "Board of Directors",
      value: firm.directorsColor,
      notApplyMessage: "No Board of Directors",
    },
  ].map((wc) => {
    const content = Number.isFinite(wc.value) ? (
      <svg viewBox="0 0 36 36" className="circular-chart orange">
        <path
          className="circle-bg"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className="circle"
          strokeDasharray={`${wc.value}, 100`}
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" className="percentage">
          {wc.value}%
        </text>
      </svg>
    ) : (
      <div className="not-apply-message-donut">
        <span className="pencentaje">{wc.notApplyMessage}</span>
      </div>
    );
    return (
      <div
        key={`${wc.label}-${wc.value || 0}`}
        className="uk-width-1-3"
        style={{ textAlign: "center" }}
      >
        <h3 className="chart-title">{wc.label}</h3>
        {content}
      </div>
    );
  });

  const percentageOfValuesAligned = teamInfo.percentageOfValuesAligned;
  const minimumAccountSize = firm.minimumAccountSize;
  const unit = firm.unit;
  return (
    <React.Fragment>
      <section className="print-body">
        <div className="print-container uk-container">
          <div className="print-top">
            <img className="print-top__logo" src="img/logo.svg" alt="" />
            <h3 className="print-top__name">
              <span>Advisor Profile: </span>
              {username}
            </h3>
          </div>
          <div className="print-content-div uk-container">
            <div className="uk-grid uk-grid-small uk-grid uk-grid uk-grid-divider profile-print">
              <div className="first-children-no-padding uk-width-expand uk-first-column">
                <img src={profilePic} className="pic" alt="" />
              </div>
              <div className="uk-width-1-3 uk-first-column">
                <div className="profile ">
                  <div>
                    <h5 className="profile__name">{username}</h5>
                    <h6 className="profile__position">
                      {`${title ? title + " | " : ""}`} {firm.name}
                    </h6>
                  </div>
                  <div className="expertise">
                    <div className="expertise__title">
                      <h3>Suggested by:</h3>
                      <p>
                        {suggestedByMemberTypes
                          .map((item) => item.label)
                          .join(" | ")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-4">
                <div className="firm__icons">
                  {firm.publicBenefit && (
                    <div className="fix">
                      <div className="info">
                        <img src="img/certified2.svg" alt="" />
                        <div className="firm__tooltip" id="firm__tooltip1">
                          <span>B Corp Certified</span>
                        </div>
                      </div>
                      <p className="print-text">B Corp Certified</p>
                    </div>
                  )}
                  {minimumAccountSize ? (
                    <div>
                      <div className="info info--align">
                        <img alt="" className="img-fix" src="img/bg.svg" />
                        <p className="above minimumAccountSize-info">
                          <span>$</span>
                          {new Intl.NumberFormat("en-US").format(
                            minimumAccountSize
                          )}
                          <span>{unit}</span>
                        </p>
                      </div>
                      <p className="print-text">Minimum Account Size</p>
                    </div>
                  ) : (
                    <div>
                      <div className="info info--align">
                        <img alt="" className="img-fix" src="img/bg.svg" />
                        <p className="above">None</p>
                      </div>
                      <p className="print-text">Minimum Account Size</p>
                    </div>
                  )}

                  <div>
                    <div className="info info--align">
                      <img alt="" className="img-fix" src="img/bg.svg" />
                      <p className="above">
                        {percentageOfValuesAligned}
                        <small>%</small>
                      </p>
                    </div>
                    <p className="print-text">Team’s Values-Aligned AUM</p>
                  </div>

                  <div className="print-firm-size">
                    <FirmSizeIcon
                      textClassName={classes["firm-size-text"]}
                      firmSize={firm.firmSizeType}
                    ></FirmSizeIcon>
                  </div>
                </div>
              </div>
            </div>

            <hr className="simple-gap" />

            <div className="first-children-no-padding uk-grid uk-grid-small uk-grid uk-grid-divider">
              <div className="uk-width-1-4 uk-first-column">
                <div className="filter-midle">
                  <div className="filter-section">
                    <div className="uk-grid uk-grid-small">
                      <div className="uk-width-1-1">
                        <div uk-accordion="" className="uk-accordion">
                          <div className="uk-open">
                            <HeadQuarters
                              addresses={addresses}
                              website={website}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="filter-section">
                    <div className="uk-grid uk-grid-small">
                      <div className="uk-width-1-1">
                        <OtherOffices addresses={addresses} />
                      </div>
                    </div>
                  </div>
                  {unsdgTypes.length ? (
                    <div className="filter-section">
                      <div className="uk-grid uk-grid-small">
                        <div className="uk-width-1-1">
                          <h3 className="section-title">
                            Impact Expertise in UN Sustainable Development Goals
                          </h3>
                        </div>
                      </div>
                      <div className="report">
                        <div className="uk-grid uk-grid-small">
                          {unsdgTypes.map((u) => {
                            return (
                              <div key={u.id} className="uk-width-1-1">
                                <div className="report__item">
                                  <img src={`img/icons/${u.icon}`} alt="" />
                                  <p>
                                    {u.value
                                      .split(" ")
                                      .map((s) => capitalize(s))
                                      .join(" ")}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="uk-width-3-4">
                <div className="uk-grid uk-grid-small">
                  <div className="uk-width-1-1">
                    <h3 className="generic__title uk-margin margin-title margin-title--no-top">
                      About the Firm
                    </h3>
                  </div>
                  <div className="uk-width-1-1">
                    <p className="firm__parragraf">{firm.mission}</p>
                  </div>
                  <div className="piechart">
                    <div className="uk-grid uk-grid-small uk-margin uk-grid uk-grid--fullscreen">
                      <div className="uk-width-1-1">
                        <div className="piechart__box">
                          <h3 className="piechart__title">
                            <span className="text">
                              Percentage of Women in:
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="uk-grid uk-grid-small uk-grid uk-grid-divider">
                      {womenCharts}
                    </div>
                  </div>
                  <div className="piechart">
                    <div className="uk-grid uk-grid-small uk-margin uk-grid uk-grid-small--fullscreen">
                      <div className="uk-width-1-1">
                        <div className="piechart__box">
                          <h3 className="piechart__title">
                            <span className="text">
                              Percentage of People of color in:
                            </span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="uk-grid uk-grid-small uk-grid uk-grid-divider">
                      {peopleOfColorCharts}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-print-number-container uk-container">
            <p className="page-print">
              <span>1</span> of 7
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

const PageTwo = ({ username, firm, teamInfo, types }) => {
  const {
    servingCountriesType,

    degreesType,
  } = types;
  const FirmPercentageOfValuesAligned =
    firm.aum > 0 ? Math.round((firm.valuesAlignedAum / firm.aum) * 100) : 0;
  const FirmValueAlignedDate = firm.aumUnitAsOfDate
    ? new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(new Date(firm.aumUnitAsOfDate))
    : "";

  const teamPercentageOfValuesAligned =
    teamInfo.aum > 0
      ? Math.round((teamInfo.valuesAlignedAum / teamInfo.aum) * 100)
      : 0;

  const teamServingCountriesList = teamInfo.servingCountries.map((it) => {
    const type = servingCountriesType.find((t) => t.id === it.servingCountryId);
    return <li key={type.id}>{type.label}</li>;
  });
  const teamDegrees = teamInfo.teamInfoDegrees.map((it) => {
    const type = degreesType.find((t) => t.id === it.degreeId);
    return <li key={type.id}>{type.label}</li>;
  });

  const teamValueAlignedDate = teamInfo.aumUnitAsOfDate
    ? new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
      }).format(new Date(teamInfo.aumUnitAsOfDate))
    : "";
  const offersPropietaryProducts = (firm.products || []).find(
    (p) => p.id === OFFERS_PROPIETARY_PRODUCTS_ID
  );
  return (
    <section className="print-body">
      <div className="print-container uk-container">
        <div className="print-top">
          <img className="print-top__logo" src="img/logo.svg" alt="" />
          <h3 className="print-top__name">
            <span>Advisor Profile: </span>
            {username}
          </h3>
        </div>
        <div className="print-content-div uk-container">
          <div className="uk-grid uk-grid-small">
            <div className="uk-width-1-1">
              <h3 className="generic__title uk-margin margin-title">
                About the Firm (continued)
              </h3>
            </div>
            <div className="uk-width-1-1">
              <div className="digits">
                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">Minimum Account Size</h3>
                      <h4 className="digits__number">
                        {firm.minimumAccountSize}
                        {firm.unit}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">Typical Account Size</h3>
                      <h4 className="digits__number">
                        ${firm.typicalAccountSize}
                        {firm.typicalAccountSizeUnit}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">
                        Offers Value-Aligned Services
                      </h3>
                      <h4 className="digits__number">
                        {firm.firmOfferedVaNImpactYears} Years
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">
                        Offers Advisory Services
                      </h3>
                      <h4 className="digits__number">
                        {firm.yearsLicensed} Years
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">Firm’s Total AUM</h3>
                      <h4 className="digits__number">
                        {new Intl.NumberFormat("en-US").format(
                          firm.aum > 1000_000
                            ? firm.aum && firm.aumUnit === "M" / 1000_000
                            : firm.aum
                        )}
                        {firm.aumUnit}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">
                        Values-Aligned AUM
                        {FirmValueAlignedDate && (
                          <React.Fragment>
                            as of {FirmValueAlignedDate}
                          </React.Fragment>
                        )}
                      </h3>
                      <h4 className="digits__number">
                        $
                        {new Intl.NumberFormat("en-US").format(
                          firm.valuesAlignedAum > 1000_000 &&
                            firm.aumUnit === "M"
                            ? firm.valuesAlignedAum / 1000_000
                            : firm.valuesAlignedAum //valuesAlignedAum
                        )}
                        {firm.aumUnit}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">% of Values-Aligned AUM</h3>
                      <h4 className="digits__number">
                        {FirmPercentageOfValuesAligned}%
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">Total Employees</h3>
                      <h4 className="digits__number">{firm.noOfEmployees}</h4>
                    </div>
                  </div>
                </div>

                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">Total Clients</h3>
                      <h4 className="digits__number">{firm.noOfClients}</h4>
                    </div>
                  </div>
                </div>

                <div className="digits__container">
                  <div className="digits__container__inner">
                    <div>
                      <h3 className="digits__title">
                        Offers Proprietary Products s
                      </h3>
                      <h4 className="digits__number">
                        {offersPropietaryProducts ? "Yes" : "No"}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr className="bold-separator" />
            </div>

            <div className="uk-width-1-1">
              <h3 className="generic__title uk-margin margin-title">
                About the Team
              </h3>
            </div>
            <div className="uk-grid uk-grid-small-collapse@s uk-margin-remove-top fix-grid uk-child-width-1-4 uk-grid uk-grid-small uk-flex uk-stretch col-underline__container">
              <div className="col-underline">
                <div className="col-underline__inner">
                  <div className="team__block">
                    <h2 className="team__title">Team’s Total AUM</h2>
                    <h1 className="team__number">
                      ${teamInfo.aum}
                      {teamInfo.aumUnit}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-underline">
                <div className="col-underline__inner">
                  <div className="team__block">
                    <h2 className="team__title">
                      Team’s Values-Aligned AUM
                      {teamValueAlignedDate && (
                        <React.Fragment>
                          as of {teamValueAlignedDate}
                        </React.Fragment>
                      )}
                    </h2>
                    <h1 className="team__number">
                      ${teamInfo.valuesAlignedAum}
                      {teamInfo.aumUnit}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-underline">
                <div className="col-underline__inner">
                  <div className="team__block">
                    <h2 className="team__title">
                      % of AUM that is Values-Aligned
                    </h2>
                    <h1 className="team__number">
                      {teamPercentageOfValuesAligned}%
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-underline">
                <div className="col-underline__inner">
                  <div className="team__block">
                    <h2 className="team__title">Total Clients</h2>
                    <h1 className="team__number">{teamInfo.noOfClients}</h1>
                  </div>
                </div>
              </div>
              <div className="col-underline">
                <div className="col-underline__inner">
                  <div className="team__block">
                    <h2 className="team__title">Typical Account Size</h2>
                    <h1 className="team__number">
                      ${teamInfo.clientAccountSize}
                      {teamInfo.clientAccountSizeUnit}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-underline">
                <div className="col-underline__inner">
                  <div className="team__block">
                    <h2 className="team__title">
                      Offers Values-Aligned Services
                    </h2>
                    <h1 className="team__number">
                      {teamInfo.teamOfferedVaNImpactYears} Years
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-underline">
                <div className="col-underline__inner">
                  <div className="team__block">
                    <h2 className="team__title">Clients Reside in</h2>
                    <ul className="team__list">{teamServingCountriesList}</ul>
                  </div>
                </div>
              </div>
              <div className="col-underline">
                <div className="col-underline__inner">
                  <div className="team__block">
                    <h2 className="team__title">
                      Team’s Professional Degrees, Certifications, Licenses or
                      Designations
                    </h2>
                    <ul className="team__list">{teamDegrees}</ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-print-number-container uk-container">
          <p className="page-print">
            <span>2</span> of 7
          </p>
        </div>
      </div>
    </section>
  );
};

const PageThree = ({
  username,
  teamInfo,
  types,
  valuesAlignedInvestingInfo,
}) => {
  const {
    compensationStructureType,
    advisorOfferingType,
    differentClientType,
    affiliationType,
  } = types;
  const teamProfAffiliations = teamInfo.affiliations.map((it) => {
    const type = affiliationType.find((t) => t.id === it.affiliationId);
    return <li key={type.id}>{type.label}</li>;
  });
  const teamCompensationStructuresList = teamInfo.compensationStructures.map(
    (it) => {
      const type = compensationStructureType.find(
        (t) => t.id === it.compensationStructureId
      );
      return <li key={type.id}>{type.label}</li>;
    }
  );
  const teamAdvisorOfferingsList = teamInfo.advisorOfferings.map((it) => {
    const type = advisorOfferingType.find((t) => t.id === it.advisorOfferingId);
    return <li key={type.id}>{type.label}</li>;
  });
  const teamDifferentClientsList = teamInfo.differentClients.map((it) => {
    const type = differentClientType.find((t) => t.id === it.clientId);
    return <li key={type.id}>{type.label}</li>;
  });

  return (
    <section className="print-body">
      <div className="print-container uk-container">
        <div className="print-top">
          <img className="print-top__logo" src="img/logo.svg" alt="" />
          <h3 className="print-top__name">
            <span>Advisor Profile: </span>
            {username}
          </h3>
        </div>
        <div className="print-content-div">
          <div className="uk-container">
            <div className="uk-grid uk-grid-small">
              <div className="uk-width-1-1">
                <h3 className="generic__title uk-margin margin-title">
                  About the Team (continued)
                </h3>
              </div>
              <div className="uk-grid uk-grid-small-collapse@s uk-margin-remove-top fix-grid uk-child-width-1-4 uk-grid uk-grid-small uk-flex uk-stretch col-underline__container">
                <div className="col-underline">
                  <div className="col-underline__inner">
                    <div className="team__block">
                      <h2 className="team__title">
                        Team’s Professional Affiliations
                      </h2>
                      <ul className="team__list">{teamProfAffiliations}</ul>
                    </div>
                  </div>
                </div>
                <div className="col-underline">
                  <div className="col-underline__inner">
                    <div className="team__block">
                      <h2 className="team__title">Team Size</h2>
                      <h1 className="team__number">{teamInfo.noOfPeople}</h1>
                    </div>
                  </div>
                </div>
                <div className="col-underline">
                  <div className="col-underline__inner">
                    <div className="team__block">
                      <h2 className="team__title">Women / Non-Binary</h2>
                      <div className="piechart">
                        <div
                          className="donut"
                          style={{
                            animationDelay: `-${
                              (teamInfo.employeesWomen || 0) >= 100
                                ? 99.99
                                : teamInfo.employeesWomen || 0
                            }s`,
                          }}
                        >
                          <span className="pencentaje">
                            {teamInfo.employeesWomen || 0}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-underline">
                  <div className="col-underline__inner">
                    <div className="team__block">
                      <h2 className="team__title">People of Color</h2>
                      <div className="piechart">
                        <div
                          className="donut"
                          style={{
                            animationDelay: `-${
                              (teamInfo.employeesColor || 0) >= 100
                                ? 99.99
                                : teamInfo.employeesColor || 0
                            }s`,
                          }}
                        >
                          <span className="pencentaje">
                            {teamInfo.employeesColor || 0}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-underline">
                  <div className="col-underline__inner">
                    <div className="team__block">
                      <h2 className="team__title">Compensation Structure</h2>
                      <ul className="team__list">
                        {teamCompensationStructuresList}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-underline">
                  <div className="col-underline__inner">
                    <div className="team__block">
                      <h2 className="team__title">
                        In Addition to Portfolio Management, the Team offers
                      </h2>
                      <ul className="team__list">{teamAdvisorOfferingsList}</ul>
                    </div>
                  </div>
                </div>
                <div className="col-underline">
                  <div className="col-underline__inner">
                    <div className="team__block">
                      <h2 className="team__title">Types of clients</h2>
                      <ul className="team__list">{teamDifferentClientsList}</ul>
                    </div>
                  </div>
                </div>
                <div className="col-underline"></div>
              </div>
              <div className="uk-width-1-1">
                <hr className="bold-separator" />
              </div>
              <div className="uk-grid uk-grid-small">
                <div className="uk-width-1-1">
                  <h3 className="generic__title uk-margin margin-title">
                    Values Aligned Investment Services
                  </h3>
                </div>
              </div>
              <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB uk-margin-remove-top ">
                <div className="uk-width-1-4@s">
                  <p className="value__title">
                    <b>
                      Process of Identifying, Evaluating, and Selecting
                      Investment Opportunities
                    </b>
                  </p>
                </div>
                <div className="uk-width-3-4@s">
                  <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                    <p>{valuesAlignedInvestingInfo.measureAndReport}</p>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-print-number-container uk-container">
          <p className="page-print">
            <span>3</span> of 7
          </p>
        </div>
      </div>
    </section>
  );
};

const PageFour = ({ username, teamInfo, valuesAlignedInvestingInfo }) => {
  return (
    <section className="print-body">
      <div className="page-print-4 print-container uk-container">
        <div className="print-top">
          <img className="print-top__logo" src="img/logo.svg" alt="" />
          <h3 className="print-top__name">
            <span>Advisor Profile: </span>
            {username}
          </h3>
        </div>
        <div className="print-content-div">
          <h3 className="generic__title uk-margin margin-title">
            Values Aligned Investment Services (continued)
          </h3>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Impact Genesis Story</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.interestedInImpact}</p>
              </pre>
            </div>
          </div>
          <div className="uk-width-1-1">
            <hr className="simple-gap" />
          </div>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Case Study: an Example of a Meaningful Investment</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.descOfWhatClientCares}</p>
              </pre>
            </div>
          </div>
          <div className="uk-width-1-1">
            <hr className="simple-gap" />
          </div>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Team’s Leadership in the Impact Field</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{teamInfo.impactField}</p>
              </pre>
            </div>
          </div>
        </div>
        <div className="page-print-number-container uk-container">
          <p className="page-print">
            <span>4</span> of 7
          </p>
        </div>
      </div>
    </section>
  );
};

const PageFive = ({
  username,
  teamInfo,
  types,
  valuesAlignedInvestingInfo,
}) => {
  const { proxyVotingType } = types;
  const proxyVotingsList = valuesAlignedInvestingInfo.proxyVotings.map((it) => {
    const type = proxyVotingType.find((t) => t.id === it.proxyVotingId);
    return <li key={type.id}>{type.label}</li>;
  });
  return (
    <section className="print-body">
      <div className="print-container uk-container">
        <div className="print-top">
          <img className="print-top__logo" src="img/logo.svg" alt="" />
          <h3 className="print-top__name">
            <span>Advisor Profile: </span>
            {username}
          </h3>
        </div>
        <div className="print-content-div">
          <h3 className="generic__title uk-margin margin-title">
            Values Aligned Investment Services (continued){" "}
          </h3>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>
                  UN SDG Implementation United Nations Sustainable Development
                  Goals
                </b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.impactStrategy}</p>
              </pre>
            </div>
          </div>
          <div className="uk-width-1-1">
            <hr className="simple-gap" />
          </div>

          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>How Team helps with Active Ownership of Public Equities</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <ul className="team__list">{proxyVotingsList}</ul>
            </div>
          </div>
          <div className="uk-width-1-1">
            <hr className="simple-gap" />
          </div>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>How the Team Enables the Clients to Vote Their Proxies</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.voteProxies}</p>
              </pre>
            </div>
          </div>
          <div className="uk-width-1-1">
            <hr className="simple-gap" />
          </div>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>
                  Case Study: an Example of How the Team has engaged with
                  company management, filed or co-filed an ESG related
                  shareholder resolution
                </b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.engagedWithManagement}</p>
              </pre>
            </div>
          </div>
        </div>
        <div className="page-print-number-container uk-container">
          <p className="page-print">
            <span>5</span> of 7
          </p>
        </div>
      </div>
    </section>
  );
};
const PageSix = ({ username, valuesAlignedInvestingInfo }) => {
  return (
    <section className="print-body">
      <div className="print-container uk-container">
        <div className="print-top">
          <img className="print-top__logo" src="img/logo.svg" alt="" />
          <h3 className="print-top__name">
            <span>Advisor Profile: </span>
            {username}
          </h3>
        </div>
        <div className="print-content-div">
          <h3 className="generic__title uk-margin margin-title">
            Philosophy & Communication
          </h3>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Team’s Investment Philosophy and Style</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.meaningfulInvestment}</p>
              </pre>
            </div>
          </div>
          <hr className="simple-gap" />
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Impact Measurement and Reporting</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.assetAllocation}</p>
              </pre>
            </div>
          </div>
          <hr className="simple-gap" />
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Discerning Goals and Priorities</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.impactInvestment}</p>
              </pre>
            </div>
          </div>
          <hr className="hide-firefox simple-gap" />
          <div className="hide-firefox first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Available Resources to Help Clients Make Better Decisions</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.helpClientMakeBetterDecision}</p>
              </pre>
            </div>
          </div>
        </div>
        <div className="page-print-number-container uk-container">
          <p className="page-print">
            <span>6</span> of 7
          </p>
        </div>
      </div>
    </section>
  );
};

const PageSeven = ({ username, firm, valuesAlignedInvestingInfo }) => {
  return (
    <section className="print-body">
      <div className="print-container uk-container">
        <div className="print-top">
          <img className="print-top__logo" src="img/logo.svg" alt="" />
          <h3 className="print-top__name">
            <span>Advisor Profile: </span>
            {username}
          </h3>
        </div>
        <div className="print-content-div">
          <h3 className="generic__title uk-margin margin-title">
            Philosophy & Communication (continued)
          </h3>
          <div className="firefox-only firefox-only-flex first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Available Resources to Help Clients Make Better Decisions</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{valuesAlignedInvestingInfo.helpClientMakeBetterDecision}</p>
              </pre>
            </div>
          </div>
          <div className="firefox-only uk-width-1-1">
            <hr className="simple-gap" />
          </div>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>Disclosures</b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{firm.requiredDisclosures}</p>
              </pre>
            </div>
          </div>
          <div className="firefox-only uk-width-1-1">
            <hr className="simple-gap" />
          </div>
          <div className="first-children-no-padding uk-grid uk-grid-small fix-align Tabs_full-width__3dNqB">
            <div className="uk-width-1-4@s">
              <p className="value__title">
                <b>
                  Past or Ongoing Litigation, Regulatory Actions, or Government
                  Investigations Involving the Firm or the Team
                </b>
              </p>
            </div>
            <div className="uk-width-3-4@s">
              <pre style={{ border: "none", whiteSpace: "pre-wrap" }}>
                <p>{firm.governmentInvestigation}</p>
              </pre>
            </div>
          </div>
        </div>
        <div className="page-print-number-container uk-container">
          <p className="page-print">
            <span>7</span> of 7
          </p>
        </div>
      </div>
    </section>
  );
};

export const PrintProfile = ({
  profilePic,
  name,
  firm,
  user,
  addresses,
  website,
  teamInfo,
  unsdgTypes,
  types,
  valuesAlignedInvestingInfo,
  suggestedByMemberTypes,
  title,
}) => {
  return (
    <React.Fragment>
      <PageOne
        suggestedByMemberTypes={suggestedByMemberTypes}
        teamInfo={teamInfo}
        username={name}
        profilePic={profilePic}
        website={website}
        addresses={addresses}
        firm={firm}
        user={user}
        unsdgTypes={unsdgTypes}
        title={title}
      ></PageOne>
      <PageTwo
        username={name}
        teamInfo={teamInfo}
        firm={firm}
        types={types}
      ></PageTwo>
      <PageThree
        types={types}
        username={name}
        teamInfo={teamInfo}
        valuesAlignedInvestingInfo={valuesAlignedInvestingInfo}
      ></PageThree>
      <PageFour
        types={types}
        username={name}
        teamInfo={teamInfo}
        valuesAlignedInvestingInfo={valuesAlignedInvestingInfo}
      ></PageFour>
      <PageFive
        types={types}
        username={name}
        teamInfo={teamInfo}
        valuesAlignedInvestingInfo={valuesAlignedInvestingInfo}
      ></PageFive>
      <PageSix
        username={name}
        valuesAlignedInvestingInfo={valuesAlignedInvestingInfo}
      ></PageSix>
      <PageSeven
        username={name}
        firm={firm}
        valuesAlignedInvestingInfo={valuesAlignedInvestingInfo}
      ></PageSeven>
    </React.Fragment>
  );
};

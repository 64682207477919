import React, {  useState, useContext } from "react";
import { Form, Input, Label, FormGroup, Modal, ModalBody } from "reactstrap";
import "./OnboardingGetStarted.css";
import cx from "classnames";
import FormInput from "../../../components/_shared/FormInput";
import FormSelect from "../../../components/_shared/FormSelect";
import avatar from "../../../assets/images/avatar-small.png";
import deleteIcon from "../../../assets/images/delete.png";
import editIcon from "../../../assets/images/edit.png";
import Cropper from "react-easy-crop";


import { FADashboardContext } from "../Context";


const fileLimit = 10 * 1024;
const imageLimit = 5 * 1024;
const instructionsDocumentHref =
  "https://docs.google.com/document/d/1HAAMMQdosEN_rleKOuF-fw8_nAfaVnzvtPnv4OLkunk/edit?usp=sharing";

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {HTMLImageElement} image - Image element
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
async function getCroppedImg(image, pixelCrop, rotation = 0) {
  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      //resolve(URL.createObjectURL(file))
      resolve(file);
    }, "image/jpeg");
  });
}

const Crop = ({ img, imageName, onAcceptCrop, onCancelCrop }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, onZoomChange] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "80vh",
          background: "#333",
        }}
      >
        <Cropper
          onZoomChange={onZoomChange}
          zoom={zoom}
          image={img.src}
          crop={crop}
          aspect={506 / 306}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
        />
      </div>
      <div style={{ textAlign: "center" }} className={"buttons-container-form"}>
        <button
          className="rounded-0 btn btn-in-fa-form fa-submit-btn btn btn-secondary"
          onClick={async (e) => {
            try {
              const croppedImage = await getCroppedImg(
                img,
                croppedAreaPixels,
                0
              );

              onAcceptCrop(croppedImage);
            } catch (e) {
              console.error(e);
            }
          }}
        >
          Crop
        </button>
        <button
          className="fa-cancel-btn-modal btn btn-secondary"
          onClick={async (e) => {
            onCancelCrop();
          }}
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

const OBGetStarted = (props) => {
  const { getStarted, typeList, countries } = useContext(FADashboardContext);

  const [isCropImageModalOpen, setIsCropImageModalOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [imgName, setImgName] = useState("");
  const countriesList = countries.map((c) => {
    return { label: c.name, value: c.name };
  });

  const setEmail = (e) => {
    getStarted.setEmail(e.target.value);
  };

  const setDegrees = (value) => {
    getStarted.setDegrees(value);
  };

  const setWebsite = (e) => {
    getStarted.setWebsite(e.target.value);
  };

  const setFirmName = (e) => {
    getStarted.setFirmName(e.target.value);
  };

  const setPassword = (e) => {
    getStarted.setPassword(e.target.value);
  };

  const setCurrentADV = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size / 1024 <= fileLimit) {
        getStarted.setCurrentADV({
          value: e.target.files[0].name,
          error: "",
          showError: false,
          file: e.target.files[0],
        });
      } else {
        getStarted.setCurrentADV({
          value: "",
          error: "File size should be less than 10MB",
          showError: true,
        });
      }
    }
  };

  const setProfilePic = async (e) => {
    const file = e.target.files && e.target.files.length && e.target.files[0];
    if (!file) {
      return;
    }

    const isWithingSizeLimit = file.size / 1024 <= imageLimit;
    if (!isWithingSizeLimit) {
      getStarted.setProfilePic({
        value: "",
        error: "Image size should be less than 5MB",
        showError: true,
      });
      return;
    }

    const _URL = window.URL || window.webkitURL;
    const img = new Image();

    img.src = _URL.createObjectURL(file);
    const imgLoad = new Promise((resolve, reject) => {
      img.onload = resolve;
    });
    await imgLoad;

    const isWithinCorrectDimensions =
      img.naturalHeight >= 308 && img.naturalWidth >= 506;

    if (!isWithinCorrectDimensions) {
      getStarted.setProfilePic({
        value: "",
        error: "Image dimensions should be at least 506 X 308 pixels",
        showError: true,
      });

      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const readerLoad = new Promise((resolve, reject) => {
      reader.onload = resolve;
    });
    await readerLoad;
    setSelectedImg(img);
    setIsCropImageModalOpen(true);
    setImgName(file.name);
  };

  const setAdvisorName = (e) => {
    getStarted.setAdvisorName(e.target.value);
  };
  const setAdvisorTitle = (e) => {
    getStarted.setAdvisorTitle(e.target.value);
  };
  const setYearsWithFirm = (e) => {
    getStarted.setYearsWithFirm(e.target.value);
  };
  const setYearsAsAdvisor = (e) => {
    getStarted.setYearsAsAdvisor(e.target.value);
  };

  const setSuggestedByMember = (value) => {
    getStarted.setSuggestedByMembers(value);
  };

  const updateAddressAtIndex = (e, index, prop) => {
    const address = getStarted.addresses[index];
    getStarted.updateAddressAtIndex(index, {
      ...address,
      [prop]: {
        ...address[prop],
        value: e.target.value,
        error: "",
        showError: false,
      },
    });
  };

  const addAddress = () => {
    getStarted.addAddress();
  };
  const removeAddressAtIndex = (index) => {
    getStarted.removeAddressAtIndex(index);
  };

  const addSocialLink = () => {
    getStarted.addNewSocialLink();
  };
  const updateSocialLinkAtIndex = (e, index, prop) => {
    const socialLink = getStarted.socialLinks[index];
    getStarted.updateSocialLinkAtIndex(index, {
      ...socialLink,
      [prop]: {
        ...socialLink[prop],
        value: e.target.value,
        error: "",
        showError: false,
      },
    });
  };
  const removeSocialLinkAtIndex = (index) => {
    getStarted.removeSocialLinkAtIndex(index);
  };

  const setOfficeSameAsHeadquarters = (isSame = false) => {
    if (
      isSame &&
      getStarted.addresses.length &&
      getStarted.addresses.length >= 1
    ) {
      const headquarters = { ...getStarted.headquarters };
      getStarted.updateAddressAtIndex(0, headquarters);
    }
  };

  return (
    <div>
      <React.Fragment>
        <a
          className="pdf-download-text"
          href={instructionsDocumentHref}
          target="_blank"
          rel="noopener noreferrer"
        >
          Directions for completing your profile, please ONLY use Google Chrome
          Browser to onboard.
        </a>
        <div className="FA-form-container">
          <Form className="FA-form">
            <div className="FA-form-fields FA-form1">
              <div
                className={cx(
                  "input-type-file",
                  "input-full-line",
                  "input-thumbnail"
                )}
              >
                <span id="selected-file-name">
                  {getStarted.profilePic.name && getStarted.profilePic.value
                    ? getStarted.profilePic.name
                    : ""}
                </span>
                <FormInput
                  type="file"
                  accept={"image/x-png, image/jpeg"}
                  label="Please provide a photograph that best represents your team."
                  extraInformation={
                    "Upload an image with minimum size of 506 X 308 pixels."
                  }
                  onChange={setProfilePic}
                  focus={!!getStarted.profilePic.name}
                  error={getStarted.profilePic.error}
                  showError={getStarted.profilePic.showError}
                />

                <img
                  src={
                    getStarted.profilePic.value
                      ? getStarted.profilePic.value
                      : avatar
                  }
                  alt="Member(s)"
                  className="thumbnail-img"
                />
                <img
                  src={editIcon}
                  alt="Edit Icon"
                  className="edit-profile-icon"
                />
              </div>

              <FormInput
                label="Enter Firm name"
                value={getStarted.firmName.value}
                error={getStarted.firmName.error}
                showError={getStarted.firmName.showError}
                onChange={setFirmName}
              />

              <FormInput
                label="Lead Advisor name"
                value={getStarted.advisorName.value}
                placeholder={
                  "The Advisor you wish to represent your Team on this platform."
                }
                focus={true}
                error={getStarted.advisorName.error}
                showError={getStarted.advisorName.showError}
                onChange={setAdvisorName}
              />
              <FormInput
                label="Title"
                value={getStarted.title.value}
                placeholder={"The Advisor Title"}
                focus={true}
                error={getStarted.title.error}
                showError={getStarted.title.showError}
                onChange={setAdvisorTitle}
              />
              <div className="container-SM-links" style={{ width: "50%" }}>
                <div className="SM-link-left">
                  <FormSelect
                    style={{ width: "100%" }}
                    options={typeList.suggestedByMemberType}
                    value={getStarted.suggestedByMembers.value}
                    onChange={setSuggestedByMember}
                    isMulti={true}
                    error={getStarted.suggestedByMembers.error}
                    showError={getStarted.suggestedByMembers.showError}
                    label="Suggested by"
                  />
                </div>
              </div>
              <FormInput
                label="Site Password"
                type="password"
                value={getStarted.password.value}
                disabled={getStarted.update}
                error={getStarted.password.error}
                showError={getStarted.password.showError}
                onChange={setPassword}
              />
              <FormInput
                label="Lead Advisor Number of years with Firm"
                value={getStarted.yearsWithFirm.value}
                error={getStarted.yearsWithFirm.error}
                showError={getStarted.yearsWithFirm.showError}
                onChange={setYearsWithFirm}
              />
              <FormInput
                label="Lead Advisor total number of years offering advisory services"
                error={getStarted.yearsAsAdvisor.error}
                showError={getStarted.yearsAsAdvisor.showError}
                value={getStarted.yearsAsAdvisor.value}
                onChange={setYearsAsAdvisor}
              />
              <div className="container-SM-links" style={{ width: "50%" }}>
                <div className="SM-link-left">
                  <FormSelect
                    style={{ width: "100%" }}
                    options={typeList.degreesType}
                    value={getStarted.degrees.value}
                    label="Lead Advisor degrees, certifications, licenses or designations.Check all that apply."
                    onChange={setDegrees}
                    isMulti={true}
                    error={getStarted.degrees.error}
                    showError={getStarted.degrees.showError}
                  />
                </div>
              </div>

              <div className={cx("input-type-file")}>
                <span id="selected-file-name">
                  {getStarted.currentADV.value ? "Current ADV.pdf" : ""}
                </span>
                <span id="text-choose-file">Choose File...</span>
                <FormInput
                  type="file"
                  accept=".pdf"
                  focus={!!getStarted.currentADV.value}
                  onChange={setCurrentADV}
                  label="Firm’s ADV Part 2 or your current regulatory filing (Advisor upload)"
                  error={getStarted.currentADV.error}
                  showError={getStarted.currentADV.showError}
                />
              </div>
              <FormInput
                label="Lead Advisor Email"
                value={getStarted.email.value}
                //disabled={getStarted.update}
                error={getStarted.email.error}
                showError={getStarted.email.showError}
                onChange={setEmail}
              />

              <FormInput
                label="Headquarters Address Line 1"
                value={getStarted.headquarters.line1.value}
                error={getStarted.headquarters.line1.error}
                showError={getStarted.headquarters.line1.showError}
                onChange={(e) => {
                  getStarted.setHeadQuarters(
                    getStarted.headquarters.setLine1(e.target.value)
                  );
                }}
              />
              <FormInput
                label="Headquarters Address Line 2"
                value={getStarted.headquarters.line2.value}
                onChange={(e) => {
                  getStarted.setHeadQuarters(
                    getStarted.headquarters.setLine2(e.target.value)
                  );
                }}
              />
              <FormInput
                label="Region/State"
                value={getStarted.headquarters.state.value}
                error={getStarted.headquarters.state.error}
                showError={getStarted.headquarters.state.showError}
                onChange={(e) => {
                  getStarted.setHeadQuarters(
                    getStarted.headquarters.setState(e.target.value)
                  );
                }}
              />
              <FormInput
                label="City"
                value={getStarted.headquarters.city.value}
                error={getStarted.headquarters.city.error}
                showError={getStarted.headquarters.city.showError}
                onChange={(e) => {
                  getStarted.setHeadQuarters(
                    getStarted.headquarters.setCity(e.target.value)
                  );
                }}
              />
              <FormInput
                label="Postal Code of Lead Advisor"
                value={getStarted.headquarters.zipCode.value}
                error={getStarted.headquarters.zipCode.error}
                showError={getStarted.headquarters.zipCode.showError}
                //maxLength={5}
                onChange={(e) => {
                  getStarted.setHeadQuarters(
                    getStarted.headquarters.setZipCode(e.target.value)
                  );
                }}
              />
              <div className="container-SM-links" style={{ maxWidth: "50%" }}>
                <FormSelect
                  style={{ width: "100%" }}
                  label="Country"
                  options={countriesList}
                  value={
                    countriesList.find(
                      (o) => o.value === getStarted.headquarters.country.value
                    ) || {}
                  }
                  error={getStarted.headquarters.country.error}
                  showError={getStarted.headquarters.country.showError}
                  onChange={(e) => {
                    getStarted.setHeadQuarters(
                      getStarted.headquarters.setCountry(e.value)
                    );
                  }}
                />
              </div>

              <FormInput
                label="Website"
                value={getStarted.website.value}
                onChange={setWebsite}
                error={getStarted.website.error}
                showError={getStarted.website.showError}
                className="input-full-line"
              />

              <h2 className="title-addresses">Major Offices</h2>
              <div>
                {/* Index 0 for address is alloted to the primary address */}
                {getStarted.addresses.map((address, index) => {
                  const isFirstMajorOffice = index === 0;
                  return (
                    <div
                      className="extra-address-container"
                      key={`address-${index}`}
                    >
                      <div className="button-location-container button-location-container-remove">
                        {isFirstMajorOffice ? (
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={(e) => {
                                  setOfficeSameAsHeadquarters(e.target.checked);
                                }}
                              />{" "}
                              Same as Above
                            </Label>
                          </FormGroup>
                        ) : null}

                        <button
                          className="button-add-location"
                          type="button"
                          onClick={(e) => {
                            removeAddressAtIndex(index);
                          }}
                        >
                          <img
                            className="delete-icon"
                            src={deleteIcon}
                            alt="delete"
                          />
                        </button>
                      </div>
                      <FormInput
                        label="Address Line 1"
                        value={address.line1.value}
                        error={address.line1.error}
                        showError={address.line1.showError}
                        onChange={(e) => {
                          //setAddressLine1(e, index + 1);
                          updateAddressAtIndex(e, index, "line1");
                        }}
                      />
                      <FormInput
                        label="Address Line 2"
                        value={address.line2.value}
                        onChange={(e) => {
                          //setAddressLine2(e, index + 1);
                          updateAddressAtIndex(e, index, "line2");
                        }}
                      />
                      <FormInput
                        label="City"
                        value={address.city.value}
                        error={address.city.error}
                        showError={address.city.showError}
                        onChange={(e) => {
                          //setAddressCity(e, index + 1);
                          updateAddressAtIndex(e, index, "city");
                        }}
                      />
                      <FormInput
                        label="State"
                        value={address.state.value}
                        error={address.state.error}
                        showError={address.state.showError}
                        onChange={(e) => {
                          //setAddressState(e, index + 1);
                          updateAddressAtIndex(e, index, "state");
                        }}
                      />

                      <div
                        className="container-SM-links"
                        style={{ maxWidth: "50%" }}
                      >
                        <FormSelect
                          style={{ width: "100%" }}
                          label="Country"
                          options={countriesList}
                          value={
                            countriesList.find(
                              (o) => o.value === address.country.value
                            ) || {}
                          }
                          error={address.country.error}
                          showError={address.country.showError}
                          onChange={(e) => {
                            //setAddressCountry(e, index + 1);
                            updateAddressAtIndex(
                              {
                                target: { value: e.value },
                              },
                              index,
                              "country"
                            );
                          }}
                        />
                      </div>

                      <FormInput
                        label="Postal code of headquarters and major offices"
                        value={address.zipCode.value}
                        error={address.zipCode.error}
                        showError={address.zipCode.showError}
                        //maxLength={5}
                        onChange={(e) => {
                          //setAddressZipCode(e, index + 1);
                          updateAddressAtIndex(e, index, "zipCode");
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="button-location-container">
                <button
                  className="button-add-location"
                  onClick={(e) => {
                    addAddress();
                  }}
                  type="button"
                >
                  + Add another location
                </button>
              </div>
              <div className="container-SM-links">
                <div className="container-SM-links-inner">
                  <div className="container-add-social">
                    <h3 className="title-addresses">
                      Social Media links LinkedIn, Twitter, Facebook etc.
                    </h3>
                    <button
                      className="btn-add-another-social"
                      type="button"
                      onClick={addSocialLink}
                    >
                      Add
                    </button>
                  </div>
                  {getStarted.socialLinks.map((socialLink, index) => {
                    return (
                      <React.Fragment key={`social-link-${index}`}>
                        <div className="SM-links-container">
                          <div className="SM-links-left">
                            <FormSelect
                              labelClass={"social-media-label"}
                              options={[
                                {
                                  value: "twitter",
                                  label: "Twitter",
                                },
                                {
                                  value: "facebook",
                                  label: "Facebook",
                                },
                                {
                                  value: "linkedin",
                                  label: "LinkedIn",
                                },
                                {
                                  value: "instagram",
                                  label: "Instagram",
                                },
                              ]}
                              value={{
                                value: socialLink.type.value,
                                label:
                                  socialLink.type.value
                                    .charAt(0)
                                    .toUpperCase() +
                                  socialLink.type.value.slice(1),
                              }}
                              label="Social Media links (LinkedIn, Twitter, Facebook etc.)"
                              onChange={(e) => {
                                updateSocialLinkAtIndex(
                                  {
                                    target: { value: e.value },
                                  },
                                  index,
                                  "type"
                                );
                              }}
                              error={socialLink.type.error}
                              showError={socialLink.type.showError}
                            />
                          </div>
                          <div className="SM-links-right">
                            <FormInput
                              label="Add URL"
                              value={socialLink.link.value}
                              onChange={(e) => {
                                updateSocialLinkAtIndex(e, index, "link");
                              }}
                              error={socialLink.link.error}
                              showError={socialLink.link.showError}
                            />
                          </div>
                          <div className="SM-button-container">
                            <button
                              className="button-add-another"
                              type="button"
                              onClick={(e) => {
                                removeSocialLinkAtIndex(index);
                              }}
                            >
                              {
                                <img
                                  className="delete-icon"
                                  src={deleteIcon}
                                  alt="delete"
                                />
                              }
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </Form>
        </div>
      </React.Fragment>

      {selectedImg && (
        <Modal isOpen={isCropImageModalOpen}>
          <ModalBody>
            <Crop
              imageName={imgName}
              onAcceptCrop={(blob) => {
                const profilePicFile = new File([blob], imgName, {
                  lastModified: new Date().getTime(),
                  type: blob.type,
                });
                getStarted.setProfilePic({
                  name: imgName,
                  file: profilePicFile,
                  value: URL.createObjectURL(blob),
                  error: "",
                });

                setIsCropImageModalOpen(false);
                setSelectedImg(null);
                setImgName("");
              }}
              onCancelCrop={() => {
                setIsCropImageModalOpen(false);
                setSelectedImg(null);
                setImgName("");
              }}
              img={selectedImg}
            
            ></Crop>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};
export default OBGetStarted;

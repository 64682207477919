import React from "react";
import classes from "./OtherOffices.module.css"
export const OtherOffices = ({ addresses = [] }) => {
  if (!addresses.length) {
    return null;
  }
  const otherOffices = addresses.filter((a) => !a.primary);
  
  return (
    <React.Fragment>
      <h3 className={`section-title ${classes.title}`}>OTHER Offices</h3>

      <p className="section-text">
        {otherOffices.map((of) => {
          return (
            <React.Fragment key={of.id}>
              {of.city}, {of.state} <br/>
            </React.Fragment>
          );
        })}
      </p>
    </React.Fragment>
  );
  
};

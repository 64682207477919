import React, { Component } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import cx from "classnames";

const isNumber = (value) => {
  return typeof value === "number" || typeof value === "bigint";
};
const isString = (value) => {
  return typeof value === "string";
};
export default class FormInput extends Component {
  state = {
    focused: false,
  };

  handleFocus = (event) => {
    this.setState({
      focused: true,
    });
  };

  handleBlur = (event) => {
    this.setState({
      focused: false,
    });
  };
  renderError = (error, showError = false, value) => {
    if (!showError) {
      return null;
    }
    if (error === "Required" && value) {
      return null;
    }
    return <div className="error-message contains-error">{error}</div>;
  };

  render() {
    const {
      type,
      value,
      onChange,
      label,
      disabled,
      error,
      showError,
      className,
      labelClass,
      maxLength,
      minLength,
      accept,
      readOnly,
      max,
      focus,
      placeholder,
      inputStyle = {},
      extraInformation,
    } = this.props;
    const inputValue = isString(value) || isNumber(value) ? value : "";

    return (
      <React.Fragment>
        <FormGroup className={className}>
          <Input
            style={{ ...inputStyle }}
            type={type || "text" || "file" || "password"}
            className={cx("form-control", {
              "field-focus":
                this.state.focused ||
                (isNumber(inputValue) && !isNaN(inputValue)) ||
                !!inputValue ||
                focus,
            })}
            disabled={disabled}
            value={inputValue}
            readOnly={readOnly}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            maxLength={maxLength}
            minLength={minLength}
            accept={accept}
            max={max}
            data-gramm="false"
            placeholder={placeholder}
          />
          <Label className={labelClass}>{label}</Label>
          {type === "textarea" ? (
            <span className="text-count">
              {inputValue.length}/{maxLength}
            </span>
          ) : null}
          {this.renderError(error, showError, inputValue)}
          {extraInformation && !error && (
            <div
              style={{
                fontSize: "11px",
                color: "#343a40",
                marginLeft: "2rem",
                fontWeight: "600",
              }}
            >
              {extraInformation}
            </div>
          )}
        </FormGroup>
      </React.Fragment>
    );
  }
}

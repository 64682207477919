import initialStates from '../states/modal';
import createNamespace from "../commons/createNamespace";

const nameSpacer = createNamespace("MODAL");

const modalReducer = (state = initialStates, action) => {
    switch (action.type) {
        case nameSpacer('TOGGLE_SIGN_IN'):
            return {
                ...state,
                signInModal: !state.signInModal,
            }

        case nameSpacer('TOGGLE_RESET_PASSWORD'):
            return {
                ...state,
                resetPasswordModal: !state.resetPasswordModal,
            }

        case nameSpacer('TOGGLE_FORGOT_PASSWORD'):
            return {
                ...state,
                forgotPasswordModal: !state.forgotPasswordModal,
            }

        case nameSpacer('TOGGLE_PROFILE_REVIEW'):
            return {
                ...state,
                profileReviewModal: !state.profileReviewModal,
            }

        case nameSpacer('TOGGLE_ACCEPT_ADVISOR'):
            return {
                ...state,
                acceptAdvisorModal: !state.acceptAdvisorModal,
            }

        case nameSpacer('TOGGLE_REJECT_ADVISOR'):
            return {
                ...state,
                rejectAdvisorModal: !state.rejectAdvisorModal,
            }

        case nameSpacer('TOGGLE_PROFILE_PIC'):
            return {
                ...state,
                profilePicModal: !state.profilePicModal,
            }

        case nameSpacer('TOGGLE_FIRST_VISIT'):
            return {
                ...state,
                firstVisitModal: action.payload.value,
            }

        case nameSpacer('TOGGLE_PROFILE_SUBMIT'):
            return {
                ...state,
                profileSubmitModal: action.payload.value,
            }

        case nameSpacer("TOGGLE_SURVEY_MODAL"):
            return {
                ...state,
                surveyModal: !state.surveyModal
            }

        case nameSpacer("TOGGLE_SURVEY_DOWNLOAD_MODAL"):
            return {
                ...state,
                surveyDownloadModal: !state.surveyDownloadModal
            }
        default:
            return state;
    }
}

export default modalReducer;

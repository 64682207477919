import React, { useState, useEffect } from "react";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import classes from "./AccountSizeRangeFilter.module.css";

const SliderWithTooltip = createSliderWithTooltip(Slider);

export const AccountSizeRangeFilter = ({
  accountSizes,
  onAccountSizeChange,
  filtersId,
  initialAccountSize,
  selectedFilters,
}) => {
  const [rangeTooltipText, setRangeTooltipText] = useState(
    initialAccountSize.label
  );
  const [resultMap, setResultsMap] = useState({});

  useEffect(() => {
    const map = {};
    for (let i = 0; i < accountSizes.length; i++) {
      const multiplier = i * (accountSizes.length / 100);
      const roundedKey = Math.round(multiplier * 100);
      map[roundedKey] = accountSizes[i];
      map.maxValue = roundedKey;
    }

    setResultsMap(map);
  }, [initialAccountSize.id, accountSizes]);

  useEffect(() => {
    if (
      selectedFilters["minimumAccountSizeType"] &&
      selectedFilters["minimumAccountSizeType"].length
    ) {
      const selectedRange = selectedFilters["minimumAccountSizeType"][0];
      const mapKey = Object.keys(resultMap).find(
        (k) => resultMap[k].id === selectedRange
      );
      const mapValue = resultMap[mapKey];
      if (resultMap[mapKey]) {
        //setRangeValue(mapKey);
        setRangeTooltipText(mapValue.label);
      }
    }
  }, [
    selectedFilters,
    initialAccountSize.id,
    initialAccountSize.label,
    resultMap,
  ]);

  const onValueChanged = (value) => {
    if (resultMap[value]) {
      setRangeTooltipText(resultMap[value].label);
      onAccountSizeChange(filtersId, resultMap[value].id);
    }

    if (value >= resultMap.maxValue) {
      setRangeTooltipText(resultMap[resultMap.maxValue].label);
      onAccountSizeChange(filtersId, resultMap[resultMap.maxValue].id);
    }
  };

  const startingPointKey =
    Object.keys(resultMap).find(
      (k) => resultMap[k].label.toUpperCase() === rangeTooltipText.toUpperCase()
    ) || 0;

  return (
    <div className={classes["range-fix3"]}>
      <SliderWithTooltip
        //style={{width: "80%"}}
        //tipFormatter={(v) => rangeTooltipText.toUpperCase()}
        tipProps={{ visible: true, overlay: rangeTooltipText.toUpperCase() }}
        trackStyle={{ backgroundColor: "#ff6b00", height: "2px" }}
        railStyle={{ height: "2px" }}
        handleStyle={{
          borderColor: "#ff6b00",
          height: 25,
          width: 25,
          marginLeft: -5,
          marginTop: -13,
          backgroundColor: "#ff6b00",
        }}
        step={accountSizes.length}
        startPoint={startingPointKey}
        dotStyle={{ borderColor: "#ff6b00" }}
        min={0}
        max={resultMap.maxValue}
        marks={{
          0: {
            style: {
              top: "10px",
            },
            label: "250K",
          },
          [resultMap.maxValue]: {
            style: {
              top: "10px",
            },
            label: "100M",
          },
        }}
        onChange={onValueChanged}
        value={+startingPointKey}
      />
    </div>
  );
};

import { isNumeric } from "validator";
import { isValidObj, requiredObj } from "../utils";

const actions = {
  UPDATE_STATE: "UPDATE_STATE",
};

export const aboutFirmReducer = (state = firmState, action) => {
  if (action.type === actions.UPDATE_STATE) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export const state = {
  isValid: false,
  dispatch: () => {},
  validate: async function () {
    let objs = [];
    let isValid = false;
    
    for (const key in this) {
      if (typeof this[key] === "object") {
        requiredObj(this[key]);
        objs.push(this[key]);
      }
    }
    isValid = objs.every((obj) => isValidObj(obj));

    return {
      ...this,
      isValid,
    };
  },
  fromAdvisor: function (advisor) {
    const isNaDirector =
      !Number.isFinite(advisor.firm.directorsColor) &&
      !Number.isFinite(advisor.firm.directorsWomen);

    const isNaEmployee =
      !Number.isFinite(advisor.firm.employeesColor) &&
      !Number.isFinite(advisor.firm.employeesWomen);

    const isNaSenior =
      !Number.isFinite(advisor.firm.seniorsColor) &&
      !Number.isFinite(advisor.firm.seniorsWomen);
    this.setIsDirectorNA(isNaDirector)
      .setIsEmployeeNA(isNaEmployee)
      .setIsSeniorNA(isNaSenior)
      .setIsNoOfClientDisclosable(advisor.firm.isNoOfClientsDisclosable)
      .setIsTypicalAccountSizeDisclosable(
        advisor.firm.isTypicalAccountSizeDisclosable
      );

    this.setOrderOfMagnitude(advisor.firm.unit)
      .setAumUnit(advisor.firm.aumUnit)
      .setTypicalAccountSizeUnit(advisor.firm.typicalAccountSizeUnit)
      .setTypicalAccountSize(advisor.firm.typicalAccountSize)
      .setAum(advisor.firm.aum)
      .setAumUnitAsOfDate(advisor.firm.aumUnitAsOfDate)
      .setMission(advisor.firm.mission)
      .setFirmSize(
        advisor.firm.firmSizeType.value,
        advisor.firm.firmSizeType.label
      )
      .setNoOfClients(advisor.firm.noOfClients)
      .setSeniorsColor(advisor.firm.seniorsColor)
      .setSeniorsWomen(advisor.firm.seniorsWomen)
      .setNoOfEmployees(advisor.firm.noOfEmployees)
      .setPublicBenefit(advisor.firm.publicBenefit)
      .setYearsLicensed(advisor.firm.yearsLicensed)
      .setDirectorsColor(advisor.firm.directorsColor)
      .setDirectorsWomen(advisor.firm.directorsWomen)
      .setEmployeesColor(advisor.firm.employeesColor)
      .setEmployeesWomen(advisor.firm.employeesWomen)
      .setValuesAlignedAum(advisor.firm.valuesAlignedAum)
      .setMinimumAccountSize(advisor.firm.minimumAccountSize)
      .setGovernmentInvestigation(advisor.firm.governmentInvestigation)
      .setFirmOfferedVaNImpactYears(advisor.firm.firmOfferedVaNImpactYears)
      .setRequiredDisclosures(advisor.firm.requiredDisclosures)
      .setProduct([advisor.firm.products[0].productType.value]);

    return this;
  },
  fromSavedState: function (savedState) {
    for(const key in savedState){
      if(this[key]){
        this[key] = savedState[key]
      }
    }
    return this
  },
  setAum: function (value) {
    if (!isNumeric(`${value}`)) {
      this.aum = {
        ...this.aum,
        value: "",
      };
      return this;
    }
    this.aum = {
      ...this.aum,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  aum: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setMission: function (value) {
    this.mission = {
      ...this.mission,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  mission: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setFirmSize: function (value, label) {
    this.firmSize = {
      ...this.firmSize,
      error: "",
      showError: false,
      value,
      label,
    };
    return this;
  },
  firmSize: {
    value: "",
    label: "",
    error: "",
    showError: false,
    required: true,
  },
  setIsNoOfClientDisclosable: function (value) {
    this.isNoOfClientsDisclosable = value;
    return this.setNoOfClients(this.noOfClients.value);
  },
  isNoOfClientsDisclosable: true,
  setNoOfClients: function (value) {
    if (!isNumeric(`${value}`)) {
      this.noOfClients = {
        ...this.noOfClients,
        required: this.isNoOfClientsDisclosable ? true : false,
        value: "",
      };
      return this;
    }
    this.noOfClients = {
      ...this.noOfClients,
      required: this.isNoOfClientsDisclosable ? true : false,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  noOfClients: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setIsSeniorNA: function (value) {
    this.isSeniorNA = value;
    return this.setSeniorsColor(this.seniorsColor.value).setSeniorsWomen(
      this.seniorsWomen.value
    );
  },
  isSeniorNA: false,
  setSeniorsColor: function (value) {
    if (!isNumeric(`${value}`)) {
      this.seniorsColor = {
        ...this.seniorsColor,
        required: this.isSeniorNA ? false : true,
        value: "",
      };
      return this;
    }
    this.seniorsColor = {
      ...this.seniorsColor,
      required: this.isSeniorNA ? false : true,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  seniorsColor: {
    value: "",
    error: "",
    required: true,
    showError: false,
  },
  setSeniorsWomen: function (value) {
    if (!isNumeric(`${value}`)) {
      this.seniorsWomen = {
        ...this.seniorsWomen,
        value: "",
      };
      return this;
    }
    this.seniorsWomen = {
      ...this.seniorsWomen,
      required: this.isSeniorNA ? false : true,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  seniorsWomen: {
    value: "",
    error: "",
    required: true,
    showError: false,
  },
  setNoOfEmployees: function (value) {
    if (!isNumeric(`${value}`)) {
      this.noOfEmployees = {
        ...this.noOfEmployees,
        value: "",
      };
      return this;
    }
    this.noOfEmployees = {
      ...this.noOfEmployees,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  noOfEmployees: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setPublicBenefit: function (value) {
    this.publicBenefit = {
      ...this.publicBenefit,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  publicBenefit: {
    value: "true",
    error: "",
    showError: false,
    required: false,
  },
  setYearsLicensed: function (value) {
    if (!isNumeric(`${value}`)) {
      this.noOfEmployees = {
        ...this.noOfEmployees,
        value: "",
      };
      return this;
    }
    this.yearsLicensed = {
      ...this.yearsLicensed,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  yearsLicensed: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setDirectorsColor: function (value) {
    if (!isNumeric(`${value}`)) {
      this.directorsColor = {
        ...this.directorsColor,
        required: this.isDirectorNA ? false : true,
        value: "",
      };
      return this;
    }
    this.directorsColor = {
      ...this.directorsColor,
      required: this.isDirectorNA ? false : true,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  directorsColor: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setDirectorsWomen: function (value) {
    if (!isNumeric(`${value}`)) {
      this.directorsWomen = {
        ...this.directorsWomen,
        required: this.isDirectorNA ? false : true,
        value: "",
      };
      return this;
    }
    this.directorsWomen = {
      ...this.directorsWomen,
      required: this.isDirectorNA ? false : true,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  directorsWomen: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setIsDirectorNA: function (value) {
    this.isDirectorNA = value;
    return this.setDirectorsColor(this.directorsColor.value)
    .setDirectorsWomen(this.directorsWomen.value);
  },
  isDirectorNA: false,
  setIsEmployeeNA: function (value) {
    this.isEmployeeNA = value;
    return this.setEmployeesColor(this.employeesColor.value)
    .setEmployeesWomen(this.employeesWomen.value);
  },
  isEmployeeNA: false,

  setEmployeesColor: function (value) {
    if (!isNumeric(`${value}`)) {
      this.employeesColor = {
        ...this.employeesColor,
        required: this.isEmployeeNA ? false : true,
        value: "",
      };
      return this;
    }
    this.employeesColor = {
      ...this.employeesColor,
      required: this.isEmployeeNA ? false : true,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  employeesColor: {
    value: "",
    error: "",
    required: true,
    showError: false,
  },
  setEmployeesWomen: function (value) {
    if (!isNumeric(`${value}`)) {
      this.employeesWomen = {
        ...this.employeesWomen,
        required: this.isEmployeeNA ? false : true,
        value: "",
      };
      return this;
    }
    this.employeesWomen = {
      ...this.employeesWomen,
      required: this.isEmployeeNA ? false : true,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  employeesWomen: {
    value: "",
    error: "",
    required: true,
    showError: false,
  },
  setValuesAlignedAum: function (value) {
    if (!isNumeric(`${value}`)) {
      this.valuesAlignedAum = {
        ...this.valuesAlignedAum,
        value: "",
      };
      return this;
    }
    this.valuesAlignedAum = {
      ...this.valuesAlignedAum,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  valuesAlignedAum: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setMinimumAccountSize: function (value) {
    if (!isNumeric(`${value}`)) {
      this.minimumAccountSize = {
        ...this.minimumAccountSize,
        value: "",
      };
      return this;
    }
    this.minimumAccountSize = {
      ...this.minimumAccountSize,
      error: "",
      showError: false,
      value: Number(value),
    };
    return this;
  },
  minimumAccountSize: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setOrderOfMagnitude: function (value) {
    this.unit = {
      ...this.unit,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  unit: {
    value: "M",
    error: "",
    required: false,
    showError: false,
  },
  setAumUnit: function (value) {
    this.aumUnit = {
      ...this.aumUnit,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  aumUnit: {
    value: "M",
    error: "",
    required: false,
    showError: false,
  },
  setAumUnitAsOfDate: function (value) {
    this.aumUnitAsOfDate = {
      ...this.aumUnitAsOfDate,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  aumUnitAsOfDate: {
    value: null,
    error: "",
    required: true,
    showError: false,
  },
  setTypicalAccountSize: function (value) {
    if (!isNumeric(`${value}`)) {
      this.typicalAccountSize = {
        ...this.typicalAccountSize,
        value: "",
      };
      return this;
    }
    this.typicalAccountSize = {
      ...this.typicalAccountSize,
      required: this.isTypicalAccountSizeDisclosable ? true : false,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  typicalAccountSize: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setIsTypicalAccountSizeDisclosable: function (value) {
    this.isTypicalAccountSizeDisclosable = value;
    this.typicalAccountSize = {
      ...this.typicalAccountSize,
      required: value ? true : false,
    };
    return this;
  },
  isTypicalAccountSizeDisclosable: true,
  setTypicalAccountSizeUnit: function (value) {
    this.typicalAccountSizeUnit = {
      ...this.typicalAccountSizeUnit,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  typicalAccountSizeUnit: {
    value: "M",
    error: "",
    required: false,
    showError: false,
  },
  setGovernmentInvestigation: function (value) {
    this.governmentInvestigation = {
      ...this.governmentInvestigation,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  governmentInvestigation: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setFirmOfferedVaNImpactYears: function (value) {
    if (!isNumeric(`${value}`)) {
      this.firmOfferedVaNImpactYears = {
        ...this.firmOfferedVaNImpactYears,
        value: "",
      };
      return this;
    }
    this.firmOfferedVaNImpactYears = {
      ...this.firmOfferedVaNImpactYears,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  firmOfferedVaNImpactYears: {
    value: "",
    error: "",
    showError: false,
    required: true,
  },
  setProduct: function (value) {
    this.product = {
      ...this.product,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  product: {
    value: ["sells proprietary products"],
    error: "",
    showError: false,
    required: false,
  },
  setRequiredDisclosures: function (value) {
    this.requiredDisclosures = {
      ...this.requiredDisclosures,
      error: "",
      showError: false,
      value,
    };
    return this;
  },
  requiredDisclosures: {
    value: "",
    error: "",
    required: false,
    showError: false,
  },
};
export const firmState = new Proxy(state, {
  get: function (target, prop, receiver) {
    const value = target[prop];
    // curry setters so its easier to update state by calling dispatch after modifying state, this avoid having to call dispatch at the end of every setter
    if (value instanceof Function) {
      return function (...args) {
        const obj = this === receiver ? target : this;
        const result = value.apply(obj, args);
        if (result instanceof Promise) {
          result.then((res) => {
            obj.dispatch({
              type: actions.UPDATE_STATE,
              payload: res,
            });
          });
        } else {
          obj.dispatch({
            type: actions.UPDATE_STATE,
            payload: result,
          });
        }
        return result;
      };
    }
    return value;
  },
});



export default function validatePassword(password) {
    if(password.value.length < 8){
        return {
            ...password,
            error: 'Password of length 8 is Required',
            showError: true,
        }
    }

    return {
        ...password,
        error: '',
        showError: false,
    }
}
import React, { useState, useEffect } from "react";

import { resetPassword } from "../../api/sign-in";
const ResetPassword = ({
  newPassword,
  setNewPassword,
  passwordConfirm,
  setPasswordConfirm,
  error,
  onSubmit,
  resetSuccesful,
}) => {
  const _setNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  const _setPasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
  };
  return (
    <div className="uk-container sign-in-container">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <img className="login__logo" src="img/logo.svg" alt="" />
        </div>
        <div className="uk-width-1-1">
          <h3 className="login__title">Reset Password</h3>
        </div>
        {resetSuccesful ? (
          <p className="success-message">
            Your password has been succesfully updated, why don't you try to{" "}
            <a href="/login">Sign In</a> ?
          </p>
        ) : null}
        {error ? <p className="error-message">{error}</p> : null}
        <div className="uk-width-1-1">
          <form
            className="login__form"
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="uk-grid">
              <div className="form-group uk-width-1-1">
                <label htmlFor={"Password-ID"}>New Password</label>
                <input
                  required
                  className="uk-input "
                  type="password"
                  name="Password-ID"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={_setNewPassword}
                />
              </div>

              <div className="form-group uk-width-1-1">
                <label htmlFor="Password">Confirm Password</label>
                <input
                  required
                  className="uk-input "
                  type="password"
                  name="Password-confirm"
                  placeholder="Confirm Password"
                  value={passwordConfirm}
                  onChange={_setPasswordConfirm}
                />
              </div>

              <div className="form-group no-margin-bottom uk-width-1-1">
                <button type="submit" className="homelink">
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
const Container = (props) => {
  const [resetToken, setResetToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [resetSuccesful, setResetSuccesful] = useState(false);
  useEffect(() => {
    if (props.location.search) {
      const token = props.location.search.split("=")[1];
      setResetToken(token);
    }
  }, [props.location]);
  const onSubmit = async () => {
    if (newPassword !== passwordConfirm) {
      setError("password confirmation does not match");
      return;
    }
    try {
      const result = await resetPassword({
        password: newPassword,
        token: resetToken,
      }).then((response) => response.data);
      if (result.success) {
        setResetSuccesful(true);
        setNewPassword("");
        setPasswordConfirm("");
        setError("");
      }
    } catch (err) {
      setError(err.response.data.error);
    }
  };

  return (
    <ResetPassword
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      passwordConfirm={passwordConfirm}
      setPasswordConfirm={setPasswordConfirm}
      error={error}
      onSubmit={onSubmit}
      resetSuccesful={resetSuccesful}
    ></ResetPassword>
  );
};
export default Container;

import { createContext } from "react";
export const AuthContext =  createContext({
    isLoggedIn: false,
    role: "",
    status: "",
    id: "",
    advisorId: "",
    token: "",
    firstName: "",
    lastName: "",
    email: "",
    clientType: "",
    login: async (email, password) => {},
    logout: () => {},
    autoLogin: async (autoSignHash) => {},
    checkAuthState: () => {},
    isAdmin: false,
    isAdvisor: false,
    isVisitor: false,
    currentUserID: "",
    _setLoginEmail: (value) => {},
    _setLoginPassword: (value) => {},
    fetchLogin: async (email, password) => {},
    setClientType: (clientType) => {},
});
import React from "react";
import "./BlogPost.css";
import "./5-questions-to-ask-your-advisor-about-values-aligned-investing.css";
const Component = () => {
  return (
    <div className="blog-post-container">
      <article>
        <h1 className="article-title">
          5 Questions to Ask Your Advisor About Values Aligned Investing
        </h1>
        <h4 className="article-author">By Kate Simpson</h4>
        <div>
          <p>
            <img
              src={`${process.env.PUBLIC_URL}/img/why_use_a_financial_advisor.webp`}
              alt=""
              width="1406"
              loading="lazy"
              sizes="(max-width: 1406px) 100vw, 1406px"
            />
          </p>
          <h3>Why use a financial advisor in the first place?</h3>
          <p>Some life factors which may lead you to hiring an advisor:</p>
          <ul>
            <li>
              A major life event such as marriage or divorce, having kids, or
              new investable assets&nbsp;via&nbsp;an inheritance or a liquidy
              event (<span>acquisition, merger,&nbsp;IPO,</span>
              <span>
                &nbsp;or other action that creates new investable funds)
              </span>
              .&nbsp;
            </li>
            <li>
              Complicated finances or need specific advice about retirement
              planning, charitable giving (
              <a
                href="https://www.investopedia.com/terms/d/donoradvisedfund.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                DAF
              </a>
              s), or tax strategies.
            </li>
            <li>Seek specific investments in particular asset classes.</li>
            <li>
              Feel anxiety about your finances and would benefit from being able
              to speak to someone who knows you and your financial picture.
            </li>
          </ul>
          <p>
            Many investment advisors&nbsp;require&nbsp;a minimum&nbsp;account
            size to work with you, generally ranging from $100,000 to
            $5,000,000.&nbsp;
          </p>
          <h3>
            Three important factors when choosing&nbsp;ANY financial advisor
          </h3>
          <ol>
            <li dir="ltr">
              Make sure they are a&nbsp;
              <a
                href="https://www.investopedia.com/terms/r/ria.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                registered investment advisor
              </a>
              &nbsp;(RIA), which requires them to operate under the "fiduciary
              standard" (meaning they are legally required to put your financial
              interests ahead of their own or their firm's, versus a
              "suitability standard," where investment decisions need only be
              "suitable" for the clients: you can read more about it&nbsp;
              <a
                href="https://www.investopedia.com/articles/professionaleducation/11/suitability-fiduciary-standards.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              ).&nbsp;
              <strong>
                Not&nbsp;all financial professionals are required to put your
                financial interests ahead of their own or their firms',
              </strong>
              <span>&nbsp;</span>so it's critical that you ask and confirm that
              any US-based advisor you work with is an RIA. (Here's a&nbsp;
              <a
                href="https://www.forbes.com/advisor/investing/what-is-registered-investment-advisor/#:~:text=RIAs%20have%20a%20fiduciary%20duty,that%20is%20suitable%20to%20clients."
                target="_blank"
                rel="noopener noreferrer"
              >
                helpful article
              </a>
              &nbsp;from Forbes magazine to learn more).
            </li>
            <li dir="ltr">
              Commissions. Ask whether they receive commissions, and, if they
              do,&nbsp;explore&nbsp;how that impacts their investment advice.
              For example, stockbrokers offer investment advice but can also
              receive a commission (an additional fee) when you invest in
              certain products like funds.<span>&nbsp;</span>
              <strong>
                Stockbrokers do not operate under the fiduciary standard,
              </strong>
              <span>&nbsp;</span>which means they are not required to put your
              financial interests ahead of their firms' so be sure to ask.
            </li>
            <li dir="ltr">
              Financial advisors&nbsp;cost more than{" "}
              <a
                href="https://www.investopedia.com/terms/r/roboadvisor-roboadviser.asp"
                rel="noopener noreferrer"
                target="_blank"
              >
                robo-advisors
              </a>
              . Advisor fees are usually charged as a percent of your assets
              under their management (AUM). Percentage fees tend to be deducted
              directly from your account and are often on a sliding scale: The
              higher the investment, the lower the fee. An advisor might charge
              1.25% to manage assets of $500,000 or less, then drop to 1.0% for
              assets of $501,000 or more, dropping again to 0.090% after the
              first million invested, and so on. They&nbsp;can also charge a
              flat fee (by the hour). Because each person's financial situation
              is different, you need to specifically ask each advisor explicitly
              about their fees&nbsp;&nbsp;
              <br />
              <strong>&nbsp;</strong>
              <strong>
                <br />
                The bottom line: be sure to ask what&nbsp;each advisor charges
                as an annual fee and that they are an RIA (requiring them
                to&nbsp;put your financial interests ahead of their firm's).
              </strong>
            </li>
          </ol>
          <h2 dir="ltr">
            5 Questions to evaluate an advisor's impact expertise
          </h2>
          <p dir="ltr">
            Now that you know a little about advisors in general, you may be
            wondering how to evaluate an advisor's expertise in impact
            investing.&nbsp;
          </p>
          <ol>
            <li dir="ltr">
              <span>
                Which asset classes will you invest in that meet
                both&nbsp;my&nbsp;values aligned investing and financial goals?
              </span>
            </li>
            <li dir="ltr">
              <span>
                Share specific examples of impact investment options in each
                asset class you're recommending to me.
              </span>
            </li>
            <li dir="ltr">
              <span>
                Share the names of asset managers (funds) or individual stocks
                you&nbsp;might choose&nbsp;that meet my&nbsp;values aligned
                investing goals.
              </span>
            </li>
            <li dir="ltr">
              <span>
                Share some examples of direct equity investments that align with
                my values (if you're investing in the private equity asset
                class).&nbsp;
              </span>
            </li>
            <li dir="ltr">
              <span>
                How will&nbsp;you use shareholder advocacy (for the public
                equities portion of your portfolio, if applicable) to further my
                impact goals?
              </span>
            </li>
          </ol>
          <p>
            <span>
              You may also want to share your&nbsp;Values Investment Policy
              Statement with your advisor (don't know what this is? Our{" "}
              <a
                href="https://courses.www.taketwojournal.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Values Aligned Investing Course
              </a>
              &nbsp;guides you through the process of creating one). Notice how
              your advisor reacts to it. Do they receive it positively? Or do
              they tell you it's not possible to implement it?
            </span>
          </p>
          <p>In addition, you may also want to ask:&nbsp;</p>

          <table>
            <tbody>
              <tr>
                <td>
                  <p dir="ltr">Basic questions</p>
                </td>
                <td>
                  <ul>
                    <li dir="ltr">
                      <p dir="ltr">
                        Their total assets under management (AUM).
                      </p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">Their minimum account size</p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">Their typical account size?</p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">
                        In which asset classes they offer investment options
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p dir="ltr">Values aligned questions</p>
                </td>
                <td>
                  <ul>
                    <li dir="ltr">
                      <p dir="ltr">
                        Assets under management (AUM) that&nbsp;is values
                        aligned
                      </p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">
                        Number of years they've offered values aligned services
                      </p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">
                        Asset classes they offer values aligned investments in
                      </p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">
                        Impact themes in which they offer values aligned
                        investments
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p dir="ltr">Other questions that may be of interest:</p>
                </td>
                <td>
                  <ul>
                    <li dir="ltr">
                      <p dir="ltr">
                        How did the advisor become interested in impact/values
                        aligned investing?
                      </p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">
                        How&nbsp;does the advisor&nbsp;measure and report on
                        impact?
                      </p>
                    </li>
                    <li dir="ltr">
                      <p dir="ltr">
                        How does the advisor&nbsp;engage in shareholder
                        advocacy?&nbsp;
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <h2 dir="ltr">
            How to&nbsp;find a new advisor with values aligned&nbsp;investing
            expertise&nbsp;
          </h2>
          <p>
            <a
              href="https://www.valuesadvisor.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ValuesAdvisor
            </a>
            &nbsp;is a non-profit&nbsp;platform that allows you to search
            through a universe of advisors who have expertise in values aligned
            investing, while collecting no personal data about you.
            ValuesAdvisor was founded by<span>&nbsp;</span>
            <a
              href="https://www.valuesadvisor.org/about"
              target="_blank"
              rel="noopener noreferrer"
            >
              Lisa Renstrom
            </a>
            , with support from both Kate and Megan, as a way to help investors
            who had committed to the<span>&nbsp;</span>
            <a
              href="https://www.divestinvest.org/how-to-divestinvest/individuals/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Divest/Invest pledge
            </a>
            <span>&nbsp;</span>(to remove fossil fuel-based companies from their
            investment portfolios) find a new financial advisor to implement
            their goals.&nbsp;
          </p>
          <h6>To gain access to ValuesAdvisor</h6>
          <p>There are 3 ways to gain access to ValuesAdvisor:</p>
          <ol>
            <li>
              Join a group that is a ValuesAdvisor partner. Members of
              ValuesAdvisor's partners are offered complementary access as a
              member benefit. If you'd like to see if you're a member of a
              partner group, you can see a{" "}
              <a
                href="/blog/whos-using-valuesadvisor"
                rel="noopener"
                target="_blank"
              >
                list of our partners here
              </a>
              . If you're a member of a partner group, feel free to email us to
              get your access code:{" "}
              <a href="mailto:info@valuesadvisor.com">info@valuesadvisor.com</a>
              .
            </li>
            <li>
              Sign up for ValuesAdvisor's&nbsp;
              <a href="https://courses.www.taketwojournal.com" rel="noopener">
                ValuesAligned Investing Course
              </a>
              . Students in the course gain free access to ValuesAdvisor.
            </li>
            <li>
              Sign up for an individual subscription at{" "}
              <a
                href="https://www.valuesadvisor.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ValuesAdvisor.
              </a>
            </li>
          </ol>
          <h3 dir="ltr">Other resources</h3>
          <p dir="ltr">
            There are a number of groups that offer lists of advisors who offer
            values aligned investing services.
          </p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                <a href="https://www.ussif.org/AF_MemberDirectory.asp">USSIF</a>
                &nbsp;(the Forum for Sustainable and Responsible Investing)
                offers a listing of its members, many of whom are financial
                advisors.&nbsp;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <a
                  href="https://bcorporation.net/directory?search=&amp;industry=Investment%20Advisor&amp;country=&amp;state=&amp;city="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  B Corp
                </a>
                &nbsp;offers a listing of their members who are investment
                advisors.&nbsp;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <a
                  href="https://www.greenamerica.org/all-business-listings?field_search_terms_value=investment+advisor&amp;title="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Green America
                </a>
                &nbsp;offers a list of names of values aligned investment
                advisors.
              </p>
            </li>
          </ul>
          <h3 dir="ltr">Additional Reading</h3>
        </div>

        <p dir="ltr">
          Blog post: " The Seven Attributes of a Values-Aligned Advisor," an
          excerpt from&nbsp;
          <em>
            <a href="https://www.amazon.com/Activate-Your-Money-Invest-Wealth/dp/1119777089/">
              Activate Your Money: Invest to Grow Your Values and Build A Better
              World
            </a>
            ,&nbsp;
          </em>
          by Janine Firpo.
        </p>
        <p dir="ltr">
          Intentional Endowment Network’s&nbsp;
          <a href="https://d3n8a8pro7vhmx.cloudfront.net/intentionalendowments/pages/2982/attachments/original/1555465698/IEN_HiringInvestmentConsultant.pdf?1555465698">
            Hiring an Investment Consultant: Making your ESG intention
            Actionable
          </a>
        </p>
        <p dir="ltr">
          Omidyar Network:&nbsp;
          <a href="https://www.omidyar.com/sites/default/files/Building%20an%20Impact%20Investing%20Team.pdf">
            Building an Impact Investing Team
          </a>
        </p>
        <p dir="ltr">&nbsp;</p>

        <p
          dir="ltr"
          style={{
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontStyle: "italic",
            }}
          >
            Photo:&nbsp;
          </span>
          <a
            href="http://www.kariherer.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontStyle: "italic",
            }}
          >
            Kari Herer
          </a>
        </p>
      </article>
    </div>
  );
          };
export default Component


import React, { useState } from "react";
import "./PopUpClientType.css";
import { CLIENT_TYPES } from "../../constants/constants";

export const PopUpClientType = ({
  clientType = "",
  setIsVisible = (value) => {},
  isVisible = false,
  next = (value) => {},
}) => {
  const [selectedClientType, setSelectedClientType] = useState();
  return (
    <>
      <div className={`modal__container ${isVisible ? "active" : ""}`}>
        <div
          className="background"
          onClick={() => {
            if (clientType) {
              setIsVisible(false);
              setSelectedClientType("");
            }
          }}
        ></div>
        <div className="modal__content">
          <div
            className="close"
            onClick={() => {
              if (clientType) {
                setIsVisible(false);
                setSelectedClientType("");
              }
            }}
          >
            <span uk-icon="close"></span>
          </div>
          <div className="client-type--container">
            <div className="heading">
              <h3>Select Client Type</h3>
              <p>
                To present you with the best universe of advisors, please select
                whether you are an individual or institutional investor.
              </p>
            </div>
            <div className="grid">
              <div
                className={`grid-item ${
                  selectedClientType === CLIENT_TYPES.INDIVIDUAL ? "active" : ""
                }`}
                onClick={() => {
                  setSelectedClientType(CLIENT_TYPES.INDIVIDUAL);
                }}
              >
                <div className="current-selected-client-type-tags">
                  {clientType === CLIENT_TYPES.INDIVIDUAL ? (
                    <div className="current-client-type">CURRENT</div>
                  ) : null}
                  {selectedClientType === CLIENT_TYPES.INDIVIDUAL ? (
                    <div className="selected-client-type">SELECTED</div>
                  ) : null}
                </div>

                <div className="client_type__container">
                  <div className="lined-title--container">
                    <hr></hr>
                    <h3 className="lined-title">INDIVIDUAL</h3>
                    <hr></hr>
                  </div>
                  <p>Select this option if you are an individual or family</p>
                  <img
                    src="/img/InvestorsType-01.png"
                    alt="Individual investor type"
                  ></img>
                </div>
              </div>
              <div
                className={`grid-item ${
                  selectedClientType === CLIENT_TYPES.INSTITUTIONAL
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setSelectedClientType(CLIENT_TYPES.INSTITUTIONAL);
                }}
              >
                <div className="current-selected-client-type-tags">
                  {clientType === CLIENT_TYPES.INSTITUTIONAL ? (
                    <div className="current-client-type">CURRENT</div>
                  ) : null}
                  {selectedClientType === CLIENT_TYPES.INSTITUTIONAL ? (
                    <div className="selected-client-type">SELECTED</div>
                  ) : null}
                </div>

                <div className="client_type__container">
                  <div className="lined-title--container">
                    <hr></hr>
                    <h3 className="lined-title">INSTITUTIONAL</h3>
                    <hr></hr>
                  </div>
                  <p>
                    Select this option if you are an institutional investor,
                    such as a foundation, endowment, or trust
                  </p>
                  <img
                    src="/img/InvestorsType-02.png"
                    alt="Institutional investor type"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons__container">
            {clientType ? (
              <button
                className="homelink alt"
                onClick={() => {
                  setIsVisible(false);
                  setSelectedClientType("");
                }}
              >
                Cancel
              </button>
            ) : null}
            <button
              className="homelink"
              onClick={() => {
                setIsVisible(false);
                setSelectedClientType("");
                next(selectedClientType);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

import { isURL } from "validator";
import { isValidObj, requiredObj } from "../utils";

const actions = {
  UPDATE_STATE: "UPDATE_STATE",
};

export const materialsReducer = (state = materialsState, action) => {
  if (action.type === actions.UPDATE_STATE) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};
const newRichMediaLink = {
  url: { value: "", error: "", showError: false, required: true },
  description: { value: "", error: "", showError: false, required: true },
};
const state = {
  isValid: false,
  dispatch: () => {},
  validate: async function () {
    let objs = [];
    let isValid = false;
    for (const key in this) {
      if (typeof this[key] === "object") {
        requiredObj(this[key]);
        objs.push(this[key]);
      }
    }
    for (const mediaLink of this.richMediaLinks) {
      for (const key in mediaLink) {
        requiredObj(mediaLink[key]);
        objs.push(mediaLink[key]);
      }
    }
    isValid = objs.every((obj) => isValidObj(obj));
    
    for(const mediaLink of this.richMediaLinks){
      if(mediaLink.url.value && !isURL(mediaLink.url.value)){
        mediaLink.url.error = "Invalid URL";
        mediaLink.url.showError = true;
        isValid = false;
      }
    }
    
    return {
      ...this,
      isValid,
    };
  },
  fromAdvisor: function (advisor) {
    this.setSampleNewsletter({
      ...this.sampleNewsletter,
      value: advisor?.materialsMap?.sampleNewsLetter?.link || "",
      previousObjectUrl: advisor?.materialsMap?.sampleNewsLetter?.link,
    })
      .setRedactedClientReport({
        ...this.sampleRedactedClientReport,
        value: advisor?.materialsMap?.sampleRedactedClientReport?.link || "",
        previousObjectUrl: advisor?.materialsMap?.sampleRedactedClientReport?.link,
      })
      .setRedactedClientReport({
        ...this.sampleRedactedClientReport,
        value: advisor?.materialsMap?.sampleRedactedClientReport?.link || "",
        previousObjectUrl: advisor?.materialsMap?.sampleRedactedClientReport?.link,
      })
      .setResearchReport({
        ...this.researchReport,
        value: advisor?.materialsMap?.researchReport?.link || "",
        previousObjectUrl: advisor?.materialsMap?.researchReport?.link,
      })
      .setThoughtLeadershipPublication({
        ...this.thoughtLeadershipPublication,
        value: advisor?.materialsMap?.thoughtLeadershipPublication?.link || "",
        previousObjectUrl: advisor?.materialsMap?.thoughtLeadershipPublication?.link,
      });
    
      const richMediaLinks = (advisor.richMediaLinks || []).map((ml) => {
        return {
          url: { value: ml.url, error: "", showError: false, required: true },
          description: { value: ml.description, error: "", showError: false, required: true },
        }
      });
      this.setRichMediaLinks(richMediaLinks);

    return this;
  },
  fromSavedState: function (savedState) {
    for(const key in savedState){
      if(this[key]){
        this[key] = savedState[key]
      }
    }
    return this
  },
  addRichMediaLink: function () {
    this.richMediaLinks.push(newRichMediaLink);
    return this;
  },
  removeRichMediaLinkAtIndex: function (index) {
    this.richMediaLinks.splice(index, 1);
    return this;
  },
  updateRichMediaLinkAtIndex: function (index, mediaLink) {
    this.richMediaLinks[index] = {
      ...this.richMediaLinks[index],
      ...mediaLink,
    };
    return this;
  },
  setRichMediaLinks: function (value) {
    this.richMediaLinks = value;
    return this;
  },
  richMediaLinks: [],
  setRedactedClientReport: function (value) {
    this.sampleRedactedClientReport = {
      ...this.sampleRedactedClientReport,
      ...value,
    };
    return this;
  },
  sampleRedactedClientReport: {
    typeId: "sample redacted client report",
    value: "",
    error: "",
    file: {},
    previousObjectUrl: "",
    showError: false,
    required: false,
  },
  setSampleNewsletter: function (value) {
    this.sampleNewsletter = {
      ...this.sampleNewsletter,
      ...value,
    };
    return this;
  },
  sampleNewsletter: {
    typeId: "sample newsletter",
    value: "",
    error: "",
    file: {},
    previousObjectUrl: "",
    showError: false,
    required: false,
  },
  setResearchReport: function (value) {
    this.researchReport = {
      ...this.researchReport,
      ...value,
    };
    return this;
  },
  researchReport: {
    typeId: "research report",
    value: "",
    error: "",
    file: {},
    previousObjectUrl: "",
    showError: false,
    required: false,
  },
  setThoughtLeadershipPublication: function (value) {
    this.thoughtLeadershipPublication = {
      ...this.thoughtLeadershipPublication,
      ...value,
    };
    return this;
  },
  thoughtLeadershipPublication: {
    typeId: "thought leadership publication",
    value: "",
    error: "",
    file: {},
    previousObjectUrl: "",
    showError: false,
    required: false,
  },
};

export const materialsState = new Proxy(state, {
  get: function (target, prop, receiver) {
    const value = target[prop];
    // curry setters so its easier to update state by calling dispatch after modifying state, this avoid having to call dispatch at the end of every setter
    if (value instanceof Function) {
      return function (...args) {
        const obj = this === receiver ? target : this;
        const result = value.apply(obj, args);
        if (result instanceof Promise) {
          result.then((res) => {
            obj.dispatch({
              type: actions.UPDATE_STATE,
              payload: res,
            });
          });
        } else {
          obj.dispatch({
            type: actions.UPDATE_STATE,
            payload: result,
          });
        }
        return result;
      };
    }
    return value;
  },
});

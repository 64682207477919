import React, { useState } from "react";
import classNames from "./SorterMobile.module.css";
import { SORT_BY } from "../../../constants/constants";
export const SorterMobile = ({
  onSortSelected,
  showZipCodeInput = true,
  setSelectedOrder,
  selectedOrder,
}) => {
  const [selectedSortId, setSelectedSortId] = useState("");
  const _onSortSelected = (e) => {
    onSortSelected(e.target.id, {});
    setSelectedSortId(e.target.id);
  };

  const selectedSorterClass =
    selectedOrder && selectedSortId ? selectedOrder : "";
  return (
    <div className="sort-phone" id="sortphone" aria-hidden="true">
      <div className="fix-mobile">
        {/*  <div className="uk-container">
          <div className="uk-grid">
            <form className="uk-form-horizontal uk-width-1-1 form-mobile">
              <div className="firts-form uk-grid-divider">
                <label
                  className="uk-form-label uk-first-column"
                  htmlFor="form-horizontal-text"
                >
                  Distance
                </label>
                <div className="uk-form-controls">
                  <div className="uk-inline">
                    <span className="uk-form-icon uk-form-icon-flip uk-icon">
                      <img src="img/send.svg" alt=""></img>
                    </span>
                    <input
                      className="uk-input"
                      type="text"
                      placeholder="ENTER ZIP CODE"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        */}
        <div className={`${classNames["sort-component-wrapper"]}`}>
          <div
            className={`sort-arrows-wrapper ${selectedSorterClass}`}
            onClick={() => {
              if (selectedSortId) {
                const orderBy =
                  selectedOrder === SORT_BY.HIGH_TO_LOW
                    ? SORT_BY.LOW_TO_HIGH
                    : SORT_BY.HIGH_TO_LOW;
                setSelectedOrder(orderBy);
              }
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/img/new-SortBy.svg`}
              alt="cc-icon"
            />
            <img
              src={`${process.env.PUBLIC_URL}/img/new-SortBy.svg`}
              alt="cc-icon"
            />
          </div>
          <div className={classNames.formContainer}>
          <button
                  onClick={_onSortSelected}
                  className={
                    selectedSortId === "firmSize" ? classNames.sortSelected : ""
                  }
                  id="firmSize"
                >
                  Firm Size
                </button>
          </div>
          <div className={`${classNames.formContainer}`}>
          <button
                  id="alignedAum"
                  onClick={_onSortSelected}
                  className={
                    selectedSortId === "alignedAum" ? classNames.sortSelected : ""
                  }
                >
                  % of Values Aligned AUM
                </button>
          </div>
          <div className={`${classNames.formContainer}`}>
          <button
                  id="teamTypicalAccountSize"
                  onClick={_onSortSelected}
                  className={
                    selectedSortId === "teamTypicalAccountSize" ? classNames.sortSelected : ""
                  }
                >
                  Team Typical Account Size
                </button>
          </div>
          
        </div>
      </div>
    </div>
  );
};

import axiosInstance from '.';

export function contactAdvisorFollowUp(data) {
    return axiosInstance({
        method: 'post',
        url: `/emails/contact-advisor-follow-up`,
        data
    })
}

export function notifyUserDownloadedGuide(data) {
    return axiosInstance({
        method: 'post',
        url: `/emails/user-downloaded-guide`,
        data
    })
}

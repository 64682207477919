import React from "react";
import "./Modal.css";
const Modal = ({ show, onClose, children, title, contentStyle = {} }) => {
  return (
    <>
      {show ? (
        <div
          className="modal-container"
          onClick={(e) => {
            onClose();
          }}
        >
          <div
          style={{...contentStyle}}
            className="modal-content"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="modal-close-icon-container">
              <span
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
                className="modal-close-icon"
                uk-icon="icon: close; ratio: 1"
              ></span>
            </div>
            <h4 className="modal-title">{title}</h4>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;

import {SocialMediaLink} from "../UI/SocialMediaLink";
import React from "react";
import classes from "./SocialMediaLinks.module.css"
import { toLinkWithHttps } from "../../../utils/toLinkWithHttps";

export const SocialMediaLinks = ({socialLinkTypes = []}) => {
    if (socialLinkTypes.length < 1) {
        return null
    }
    const socialinkWithHttps = socialLinkTypes.map(toLinkWithHttps)
    return (
        <ul className="icon-list">
            {socialinkWithHttps.map((sl) => {
                return (
                    <li key={sl.value} className={classes["sm-link-space"]}>
                        <SocialMediaLink url={sl.link} value={sl.value}/>
                    </li>
                );
            })}
        </ul>
    );
};
import React, { useState, useEffect } from "react";
import classes from "./SorterDesktop.module.css";
import autoCompleteTheme from "./SorterAutoCompleteTheme.module.css";
import Autosuggest from "react-autosuggest";
import debounce from "lodash/debounce";
import { getZipCodes } from "../../../api/zipcodes";
import { SORT_BY } from "../../../constants/constants";


const saveZipCode = (zipCode) => { 
  localStorage.setItem("zipCode", zipCode);
}
const getZipCode = () => { 
  return localStorage.getItem("zipCode");
}
export const SorterDesktop = ({
  onSortSelected,
  showZipCodeInput = true,
  setSelectedOrder,
  selectedOrder,
  selectedSort
}) => {
  const [zipCode, setZipCode] = useState("");
  const [zipCodeSuggestions, setZipCodeSuggestions] = useState([]);
  const [isZipCodeVisible, setIsZipCodeVisible] = useState(false);

  useEffect(() => {
    if(getZipCode()) {
      setZipCode(getZipCode());
      setIsZipCodeVisible(true);
    }
  },[])
  const _onSortSelected = (e) => {
    onSortSelected(e.target.id, {});
    setIsZipCodeVisible(false);
  };

  const fetchZipCodes = debounce((zip) => {
    if (Number.isInteger(parseInt(zip, 10))) {
      getZipCodes(zip)
        .then((response) => response.data)
        .then((data) => {
          setZipCodeSuggestions(data);
        });
    }
  }, 1000);

  const onSuggestionsFetchRequested = async ({ value }) => {
    fetchZipCodes(value);
  };

  const onSuggestionsClearRequested = () => {
    setZipCodeSuggestions([]);
  };

  const onZipCodeTyped = (event, { newValue }) => {
    setZipCode(newValue);
  };
  const onShowZipField = (e) => {
    e.preventDefault();
    setIsZipCodeVisible(!isZipCodeVisible);
  };

  const inputProps = {
    placeholder: "Zip Code (U.S Only)",
    value: zipCode,
    onChange: onZipCodeTyped,
  };

  const zipSort = (event) => {
    onShowZipField(event);
  };
  const selectedSorterClass = selectedOrder && selectedSort ? selectedOrder :""
  return (
    <div className={`${classes["sort-component-wrapper"]}`}>
      <div
        className={`sort-arrows-wrapper ${selectedSorterClass}`}
        onClick={() => {
          if (selectedSort) {
            const orderBy =
              selectedOrder === SORT_BY.HIGH_TO_LOW
                ? SORT_BY.LOW_TO_HIGH
                : SORT_BY.HIGH_TO_LOW;
            setSelectedOrder(orderBy);
          }
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/img/new-SortBy.svg`}
          alt="cc-icon"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/new-SortBy.svg`}
          alt="cc-icon"
        />
      </div>
      <label>Sort By</label>
      <div className={`${classes["sort-buttons-wrapper"]}`}>
        <button
          data-testid="sort-option"
          id="minimumAccountSize"
          onClick={_onSortSelected}
          className={selectedSort?.value === "minimumAccountSize" ? classes.sortSelected : ""}
        >
          Minimum Account Size
        </button>
        <button
          id="alignedAum"
          onClick={_onSortSelected}
          className={
            selectedSort?.value === "alignedAum" ? classes.sortSelected : ""
          }
        >
          Team % Values-Aligned AUM
        </button>
        <button
          id="teamTypicalAccountSize"
          onClick={_onSortSelected}
          className={
            selectedSort?.value === "teamTypicalAccountSize" ? classes.sortSelected : ""
          }
        >
          Team Typical Account Size
        </button>

        <div className="zip-distance-wrapper" style={{ display: "flex" }}>
          {showZipCodeInput ? (
            <React.Fragment>
              <button
                id="distance"
                style={{
                  borderTopRightRadius: "3px",
                  borderBottomRightRadius: "3px",
                }}
                className={
                  selectedSort?.value === "distance"
                    ? classes.sortDistanceSelected
                    : ""
                }
                onClick={zipSort}
              >
                Distance
              </button>
              {isZipCodeVisible ? (
                <Autosuggest
                  suggestions={zipCodeSuggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={(value) => {
                    const geoCords = {
                      latitude: value.latitude,
                      longitude: value.longitude,
                    };
                    onSortSelected("distance", geoCords);
                    saveZipCode(value.zipCode);
                    return value.zipCode;
                  }}
                  renderSuggestion={(value) => (
                    <label>{`${value.locationText},${value.state} - ${value.zipCode}`}</label>
                  )}
                  inputProps={inputProps}
                  theme={autoCompleteTheme}
                />
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
};

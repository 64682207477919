function validateZipCode(zip) {
    
    
    if (!zip.value) {
        return {
            ...zip,
            showError: true,
            error: 'Required'
        }
    }

    return zip
}

export default validateZipCode;

import {AboutFirmTab} from "./AboutFirmTab";
import {AboutTeamTab} from "./AboutTeamTab";
import {ValuesAlignedTab} from "./ValuesAlignedTab";
import {PhilosophyAndCommTab} from "./PhilosophyAndCommTab";
import React from "react";

export const Tabs = ({firm, teamInfo, types, valuesAlignedInvestingInfo}) => {
    return <div className="uk-width-3-4@m custom-A7A7A6-border">
        <ul
            className="tab-position-fix advisor-profile-tab uk-tab uk-child-width-expand"
            uk-switcher="animation: uk-animation-fade"
        >
            <li className="uk-active">
                <a href="/" onClick={(e) => e.preventDefault()} aria-expanded="true">
                    About <br /> the Firm
                </a>
            </li>
            <li>
                <a href="/" onClick={(e) => e.preventDefault()} aria-expanded="false">
                    About <br /> the Team
                </a>
            </li>
            <li>
                <a href="/" onClick={(e) => e.preventDefault()} aria-expanded="false">
                    VALUES ALIGNED <br /> INVESTMENT SERVICES
                </a>
            </li>
            <li>
                <a href="/" onClick={(e) => e.preventDefault()} aria-expanded="false">
                    PHILOSOPHY &amp; <br /> COMMUNICATIONS
                </a>
            </li>
        </ul>
        <ul
            className="advisor-profile-switcher uk-switcher uk-margin"
            style={{touchAction: "pan-y pinch-zoom"}}
        >
            <AboutFirmTab {...firm}/>
            <AboutTeamTab {...teamInfo} {...types}/>
            <ValuesAlignedTab
                {...valuesAlignedInvestingInfo}
                teamInfo={teamInfo}
                {...types}
            />
            <PhilosophyAndCommTab
                firm={firm}
                
                {...valuesAlignedInvestingInfo}
            />
        </ul>
    </div>;
}
import React, { useContext, useState, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "./header.css";
import AdminHeader from "../HeaderAdmin/HeaderAdmin";
//import HeaderProfileEdit from "../headerProfileEdit/headerProfileEdit";
import { AuthContext } from "../../../Context/AuthContext";
import { patchUserClientType } from "../../../api/users";

import { CLIENT_TYPES } from "../../../constants/constants";
import { PopUpClientType } from "../../Modals/PopUpClientType";

const NavHeader = (props) => {
  const authContext = useContext(AuthContext);
  const [isSelectClientTypeModalOpen, setIsSetClientTypeModalOpen] =
    useState(false);

  const { isAdvisor, isLoggedIn, authHandler, location, advisorId } = props;

  useEffect(() => {
    if (authContext.clientType) {
      setIsSetClientTypeModalOpen(false);
    }
  }, [authContext.clientType]);
  let links = [
    isLoggedIn && {
      pathnames: ["/search", "/profile"],
      text: <React.Fragment>Find an Advisor</React.Fragment>,
    },
    {
      pathnames: ["/about"],
      text: <React.Fragment>About Us</React.Fragment>,
    },
    {
      pathnames: ["/resources"],
      text: <React.Fragment>Resources</React.Fragment>,
    },
    !isLoggedIn && {
      pathnames: ["/join", "/createaccount"],
      text: <React.Fragment>Subscription</React.Fragment>,
    },
    {
      pathnames: ["/contact"],
      text: <React.Fragment>Contact</React.Fragment>,
    },
  ].filter((path) => Boolean(path));

  if (isAdvisor) {
    links = [
      {
        pathnames: [`/my-profile/${advisorId}`],
        text: <React.Fragment>My Profile</React.Fragment>,
      },
      {
        pathnames: ["/about"],
        text: <React.Fragment>About Us</React.Fragment>,
      },
      {
        pathnames: ["/resources"],
        text: <React.Fragment>Resources</React.Fragment>,
      },
      {
        pathnames: ["/contact"],
        text: <React.Fragment>Contact</React.Fragment>,
      },
    ];
  }

  if (!isLoggedIn || isAdvisor) {
    links.push({
      pathnames: ["/logout"],
      text: (
        <React.Fragment>{isLoggedIn ? "Logout" : "Sign In"} </React.Fragment>
      ),
      onClick: authHandler,
    });
  }

  function DonateButton() {
    if (isLoggedIn) {
      return (
        <>
          <li className="donate">
            <input type="checkbox" id="donate"></input>
            <label className="button" htmlFor="donate">
              <span uk-icon="heart"></span>
              DONATE
            </label>
            <div className="donate__modal">
              <label className="background" htmlFor="donate"></label>
              <div className="donate__modal__content">
                <label className="close" htmlFor="donate">
                  <span uk-icon="close"></span>
                </label>
                <h4>DONATE – SUPPORT VALUESADVISOR’S MISSION</h4>
                <p>
                  If ValuesAdvisor has helped you along your journey to impact,
                  please pay it forward to ensure others will have access to
                  values-aligned financial professionals. Contributions are
                  tax-deductible. Please click donate to visit our fiscal
                  sponsor's website and select ValuesAdvisor from the bottom of
                  the "I would like to donate to" dropdown.
                </p>
                <a
                  className="homelink"
                  href="https://rsfsocialfinance.org/give/give-to-rsf-projects/give-today-projects/"
                >
                  DONATE
                </a>
              </div>
            </div>
          </li>
        </>
      );
    }
    return <></>;
  }
  function onAccountDropdownClick(event) {
    event.stopPropagation();
    document.getElementById("account-initials").classList.toggle("active");
  }
  function AccountDropdown() {
    let { firstName, lastName, email } = authContext;
    return (
      <>
        <li className="account__dropdown">
          <div
            id="account-initials"
            className="account"
            onClick={onAccountDropdownClick}
          >
            {firstName && lastName ? (
              firstName[0].toLocaleUpperCase() + lastName[0].toLocaleUpperCase()
            ) : (
              <img
                onClick={onAccountDropdownClick}
                src="img/icons/user_icon.svg"
                alt="user"
              ></img>
            )}
          </div>
          <div className="modal__dropdown">
            <div className="content">
              <h4>
                {firstName} {lastName}
              </h4>
              <h5>{email}</h5>
              <hr></hr>

              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setIsSetClientTypeModalOpen(true);
                }}
                className="switch--link"
              >
                <img
                  width="42"
                  src="img/icons/switch.svg"
                  alt="Switch client type"
                ></img>
                Switch Client Type
              </a>
            </div>
            <NavLink className="logout" to="/logout" onClick={authHandler}>
              Log out
            </NavLink>
          </div>
        </li>
      </>
    );
  }
  function NavLinks({ isSideBar = false }) {
    return (
      <React.Fragment>
        {links
          .filter((link) => Boolean(link))
          .map((link) => {
            let classes = ["navlist__link"];

            link.pathnames
              .filter(
                (p) =>
                  location.pathname.indexOf(p) !== -1 &&
                  location.pathname !== "/"
              )
              .forEach((ok) => {
                classes.push("navlist__link--active");
              });
            const path = link.pathnames[0];

            return (
              <li
                key={path}
                className={classes.join(" ")}
                onClick={link.onClick}
              >
                {isSideBar ? (
                  <NavLink to={path} uk-toggle="target: #offcanvas-menu">
                    {link.text}
                  </NavLink>
                ) : (
                  <NavLink to={path}>{link.text}</NavLink>
                )}
              </li>
            );
          })}
      </React.Fragment>
    );
  }

  return (
    <nav className="no-print-show">
      <div className="uk-container">
        <div className="uk-navbar">
          <div className="uk-navbar-left">
            <a href="/">
              <span>
                <img src="img/logo.svg" alt="" />
              </span>
            </a>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav navlist uk-visible@m">
              <NavLinks></NavLinks>
              <DonateButton />
              {isLoggedIn && !isAdvisor ? (
                <>
                  <AccountDropdown />
                  <PopUpClientType
                    clientType={authContext.clientType}
                    isVisible={isSelectClientTypeModalOpen}
                    setIsVisible={setIsSetClientTypeModalOpen}
                    next={(clientType) => {
                      if (CLIENT_TYPES[clientType]) {
                        patchUserClientType({ clientType });
                        authContext.setClientType(clientType);
                      }
                    }}
                  />
                </>
              ) : null}
            </ul>
            <div
              style={{ cursor: "pointer" }}
              uk-navbar-toggle-icon={"true"}
              uk-toggle="target: #offcanvas-menu"
              className="uk-navbar-toggle uk-hidden@m uk-icon uk-navbar-toggle-icon"
              id="navbar-toggle-button"
            >
              <svg
                width={20}
                height={20}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                data-svg="navbar-toggle-icon"
              >
                <rect y={9} width={20} height={2} />
                <rect y={3} width={20} height={2} />
                <rect y={15} width={20} height={2} />
              </svg>
            </div>
            <div
              id="offcanvas-menu"
              uk-offcanvas="overlay: true; flip: true;"
              className="offcanvas-menu"
            >
              <div className="uk-offcanvas-bar">
                <div className="top-nav">
                  <span>MENU</span>
                  <button
                    className="uk-offcanvas-close"
                    type="button"
                    uk-close={"true"}
                  ></button>
                </div>
                <ul className="uk-navbar-nav navlist">
                  <NavLinks isSideBar={true} />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
const HeaderComponent = (props) => {
  if (props.isAdmin) {
    return <AdminHeader {...props} />;
  }
  return <NavHeader {...props}></NavHeader>;
};

export default withRouter(HeaderComponent);

import initialStates from '../states/authentication';
import createNamespace from "../commons/createNamespace";

const nameSpacer = createNamespace("AUTH");

const authentication = (state = initialStates, action) => {
    switch (action.type) {

        case nameSpacer("CHECK_LOGIN"):
            return {
                ...state,
                id: action.payload.id,
                loggedIn: action.payload.loggedIn,
                role: action.payload.role,
                status: action.payload.status
            }

        case nameSpacer("LOGOUT"):
            return {
                ...state,
                loggedIn: false,
                role: ''
            }

        case nameSpacer("CLEAR_STATES"):
            return {
                ...initialStates
            }

        default:
            return state
    }
}

export default authentication;

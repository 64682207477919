import React from "react";
import { Row } from "reactstrap";
import { ADVISOR_STATUS } from "../../../../constants/constants";
import "./StatusFilter.css";
const tabs = [
  "All",
  ADVISOR_STATUS.PENDING,
  ADVISOR_STATUS.APPROVED,
  ADVISOR_STATUS.REJECTED,
  ADVISOR_STATUS.DISABLED,
];
const StatusFilter = ({ selectedTab, setTab }) => {
  return (
    <Row>
      <nav className="dashboard-nav-filter">
        <ul>
          {tabs.map((tab, index) => {
            return (
              <li key={index} className={selectedTab === tab ? "always" : null}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setTab(tab);
                  }}
                >
                  {tab}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
    </Row>
  );
};
export default StatusFilter;

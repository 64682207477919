const validateEmptyField = (field) => {
    if (field.mandatory === false) {
        return {
            ...field,
            showError: false
        } 
    }
    
    if(!field){
        return {
            ...field,
            showError: true,
            error: 'Required'
        } 
    }
    if(field.value === null || field.value === undefined){
        return {
            ...field,
            showError: true,
            error: 'Required'
        } 
    }
    if ((typeof field.value === 'string' &&  field.value.trim() === '') || field.value.length === 0) {
        return {
            ...field,
            showError: true,
            error: 'Required'
        }
    }
    return {
        ...field,
        showError: false
    }
}

export default validateEmptyField;

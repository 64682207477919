const initialState =  {
    getStarted: {
      profilePic: {
        value: "",
        error: "",
        showError: false,
        src: "",
        file: {},
        name: "",
        croppedImageUrl: "",
      },
      prevProfilePic: {
        value: "",
        error: "",
        showError: false,
        src: "",
        file: {},
        name: "",
        croppedImageUrl: "",
        mandatory: false,
      },
      profilePicInfo: {
        value: "info",
        error: "",
        showError: false,
      },
      firmName: {
        value: "",
        error: "",
        showError: false,
      },
      suggestedBy: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      suggestedByMembers: {
        value: [],
        error: "",
        showError: false,
        mandatory: false,
      },
      advisorName: {
        value: "",
        error: "",
        showError: false,
      },
      title: {
        value: "",
        error: "",
        showError: false,
      },
  
      email: {
        value: "",
        error: "",
        showError: false,
      },
      password: {
        value: "",
        error: "",
        showError: false,
      },
      yearsWithFirm: {
        value: "",
        error: "",
        showError: false,
      },
      yearsAsAdvisor: {
        value: "",
        error: "",
        showError: false,
      },
      degrees: {
        value: [],
        error: "",
        showError: false,
        mandatory: false,
      },
      currentADV: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      website: {
        value: "",
        error: "",
        showError: false,
      },
      socialLink: [],
      address: [],
      addressCounter: 2,
      socialLinkCounter: 0,
      errorCount: 0,
    },
  
    firm: {
      aum: {
        value: "",
        error: "",
        showError: false,
      },
      mission: {
        value: "",
        error: "",
        showError: false,
      },
      firmSize: {
        value: "",
        label: "",
        error: "",
        showError: false,
      },
      noOfClients: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      isNoOfClientsDisclosable: true,
      seniorsColor: {
        value: "",
        error: "",
        mandatory: false,
        showError: false,
      },
      seniorsWomen: {
        value: "",
        error: "",
        mandatory: false,
        showError: false,
      },
      noOfEmployees: {
        value: "",
        error: "",
        showError: false,
      },
      publicBenefit: {
        value: "true",
        error: "",
        showError: false,
        mandatory: false,
      },
      yearsLicensed: {
        value: "",
        error: "",
        showError: false,
      },
      directorsColor: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      directorsWomen: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      isDirectorNA: false,
      isEmployeeNA: false,
      isSeniorNA: false,
      employeesColor: {
        value: "",
        error: "",
        mandatory: false,
        showError: false,
      },
      employeesWomen: {
        value: "",
        error: "",
        mandatory: false,
        showError: false,
      },
      valuesAlignedAum: {
        value: "",
        error: "",
        showError: false,
      },
      minimumAccountSize: {
        value: "",
        error: "",
        showError: false,
      },
      unit: {
        value: "M",
        error: "",
        mandatory: false,
        showError: false,
      },
      aumUnit: {
        value: "M",
        error: "",
        mandatory: false,
        showError: false,
      },
      aumUnitAsOfDate: {
        value: null,
        error: "",
        mandatory: true,
        showError: false,
      },
      typicalAccountSize: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      isTypicalAccountSizeDisclosable: true,
      typicalAccountSizeUnit: {
        value: "M",
        error: "",
        mandatory: false,
        showError: false,
      },
      governmentInvestigation: {
        value: "",
        error: "",
        showError: false,
      },
      firmOfferedVaNImpactYears: {
        value: "",
        error: "",
        showError: false,
      },
      product: {
        value: ["sells proprietary products"],
        error: "",
        showError: false,
      },
      requiredDisclosures: {
        value: "",
        error: "",
        mandatory: false,
        showError: false,
      },
      errorCount: 0,
    },
  
    teamInfo: {
      aum: {
        value: "",
        error: "",
        showError: false,
      },
      aumUnit: {
        value: "M",
        error: "",
        mandatory: false,
        showError: false,
      },
      aumUnitAsOfDate: {
        value: null,
        error: "",
        mandatory: true,
        showError: false,
      },
      noOfPeople: {
        value: "",
        error: "",
        showError: false,
      },
      impactField: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      noOfClients: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      isNoOfClientsDisclosable: true,
      isTypicalAccountSizeDisclosable: true,
      employeesColor: {
        value: "",
        error: "",
        showError: false,
      },
      employeesWomen: {
        value: "",
        error: "",
        showError: false,
      },
      valuesAlignedAum: {
        value: "",
        error: "",
        showError: false,
      },
      clientAccountSize: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      clientAccountSizeUnit: {
        value: "M",
        error: "",
        mandatory: false,
        showError: false,
      },
      affiliation: {
        value: [],
        error: "",
        showError: false,
        mandatory: false,
      },
      degrees: {
        value: [],
        error: "",
        showError: false,
        mandatory: false,
      },
      servingCountries: {
        value: [],
        error: "",
        showError: false,
      },
      differentClients: {
        value: [],
        error: "",
        showError: false,
      },
      compensationStructure: {
        value: [],
        error: "",
        showError: false,
      },
      investmentServices: {
        value: [],
        error: "",
        showError: false,
        mandatory: false,
      },
      advisorOffering: {
        value: [],
        error: "",
        showError: false,
      },
      impactThemes: {
        value: [],
        error: "",
        showError: false,
      },
      assetClasses: {
        value: [],
        error: "",
        showError: false,
      },
  
      teamOfferedVaNImpactYears: {
        value: "",
        error: "",
        showError: false,
      },
      unSdgs: {
        value: [],
        error: "",
        showError: false,
        mandatory: false,
      },
      errorCount: 0,
    },
  
    valuesAlignedInvestingInfo: {
      voteProxies: {
        value: "",
        error: "",
        showError: false,
      },
      impactStrategy: {
        value: "",
        error: "",
        showError: false,
      },
      assetAllocation: {
        value: "",
        error: "",
        showError: false,
      },
      impactInvestment: {
        value: "",
        error: "",
        showError: false,
      },
      measureAndReport: {
        value: "",
        error: "",
        showError: false,
      },
      interestedInImpact: {
        value: "",
        error: "",
        showError: false,
      },
      meaningfulInvestment: {
        value: "",
        error: "",
        showError: false,
      },
      descOfWhatClientCares: {
        value: "",
        error: "",
        showError: false,
      },
      engagedWithManagement: {
        value: "",
        error: "",
        showError: false,
      },
      relationWithThreshold: {
        value: "",
        error: "",
        showError: false,
        mandatory: false,
      },
      helpClientMakeBetterDecision: {
        value: "",
        error: "",
        showError: false,
      },
      proxyVoting: {
        value: [],
        error: "",
        showError: false,
      },
      errorCount: 0,
    },
  
    material: [],
    richMediaLinks: [],
    typeList: {
      allTypeFetched: false,
      degreesType: [],
      productType: [],
      firmSizeType: [],
      materialType: [],
      socialLinkType: [],
      affiliationType: [],
      proxyVotingType: [],
      assetClassesType: [],
      impactThemesType: [],
      advisorOfferingType: [],
      differentClientType: [],
      servingCountriesType: [],
      investmentServicesType: [],
      minimumAccountSizeType: [],
      compensationStructureType: [],
      suggestedByMemberType: [],
      unSdgType: [],
    },
  
    form: {
      tab: 1,
      update: false,
      loading: true,
      disableSubmit: false,
      submitSuccess: false,
    },
  };
export default initialState

import React from "react";
import { useContactForm,SEND_TO } from "../../hooks/useContactForm";
import "./ContactUs.css";
const ContactUs = ({
  name,
  setName,
  email,
  setEmail,
  subject,
  setSubject,
  message,
  setMessage,
  loading,
  successMessage,
  onSubmit,
}) => {
  const onFormSubmit = async (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <main>
      <div className="questions">
        <div className="uk-container">
          <div className="uk-grid">
            <div className="uk-width-1-1">
              <h3 className="questions__title">Contact us</h3>
            </div>
            <div className="contact-us-grid">
              <div className="grid--item">
                <p className="main">
                  To ask questions, make suggestions to improve the website, or
                  to recommend an affinity group or financial professional. We
                  appreciate your feedback.
                </p>
                <form id="contact-us-form" onSubmit={onFormSubmit}>
                  <div className="uk-grid">
                    <div className="form-group uk-width-1-2@s">
                      <label>FULL NAME</label>
                      <input
                        className="uk-input "
                        name="name"
                        value={name}
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        required={true}
                      />
                    </div>
                    <div className="form-group uk-width-1-2@s">
                      <label>EMAIL</label>
                      <input
                        className="uk-input "
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required={true}
                      />
                    </div>
                    <div className="form-group uk-width-1-1">
                      <label>SUBJECT</label>
                      <input
                        className="uk-input"
                        name="subject"
                        value={subject}
                        type="text"
                        onChange={(e) => setSubject(e.target.value)}
                        required={true}
                      />
                    </div>
                    <div className="form-group uk-width-1-1">
                      <label>ENTER MESSAGE HERE</label>
                      <textarea
                        className="uk-textarea"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required={true}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="homelink"
                        disabled={loading}
                      >
                        Get in touch
                        <svg
                          className="homelink__chevron"
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="19.521"
                          viewBox="0 0 11 19.521"
                        >
                          <g
                            id="Expand"
                            transform="translate(0 19.521) rotate(-90)"
                          >
                            <path
                              id="Path_434"
                              data-name="Path 434"
                              d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                              transform="translate(-178.807 -14.7)"
                              fill="#ff6b00"
                            ></path>
                          </g>
                        </svg>
                      </button>
                      <label style={{ marginLeft: "5rem" }}>
                        {successMessage}
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <hr className="vertical"></hr>
              <div className="grid--item">
                <div className="lined-title--container">
                  <hr></hr>
                  <h3 className="lined-title">RECOMMEND AN ADVISOR</h3>
                  <hr></hr>
                </div>
                <p>
                  Do you know of a Financial Advisor who should be added to the
                  platform? Is there a Financial Advisor already on the platform
                  that you’d like to recommend to your peers?
                  <br />
                  <br />
                  ValuesAdvisor is generated from your recommendations and your
                  participation is critical to help your peers find the right
                  Financial Advisor to assist them on their impact journey.
                </p>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeL2MvCXE7-L14EGE5rlznHyvKemkgHc0ArMs9T3kUEgSqqzQ/viewform"
                  className="homelink homelink--transparent homelink-size-fix"
                >
                  Recommend My Advisor
                </a>
                <div className="lined-title--container">
                  <hr></hr>
                  <h3 className="lined-title">SUPPORT OUR MISSION</h3>
                  <hr></hr>
                </div>
                <p>
                  ValuesAdvisor is a nonprofit supported by individuals,
                  foundations, fees from Financial Advisors, and users. If
                  ValuesAdvisor has helped you along your journey to impact,
                  please pay it forward to ensure others will have access to
                  values-aligned financial professionals. Contributions are tax
                  deductible. You may contribute with your Donor Advised Fund
                  (DAF).
                </p>
                <a
                  href="https://rsfsocialfinance.org/give/give-to-rsf-projects/give-today-projects/"
                  className="homelink homelink--transparent homelink-size-fix"
                >
                  Donate
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const Container = () => {
  const {
    name,
    setName,
    email,
    setEmail,
    subject,
    setSubject,
    message,
    setMessage,
    loading,
    successMessage,
    submitForm
} = useContactForm()
 
  const onSubmit = async () => {
    await submitForm(SEND_TO.VALUES_ADVISOR_STAFF,{
      name: name,
      email: email,
      subject: subject,
      message: message,
    });
  };

  return (
    <ContactUs
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      subject={subject}
      setSubject={setSubject}
      message={message}
      setMessage={setMessage}
      loading={loading}
      successMessage={successMessage}
      onSubmit={onSubmit}
    ></ContactUs>
  );
};
export default Container;

export const INITIAL_MINIMUM_ACCOUNT_SIZE = "50M";
export const ADVISOR_STATUS = {
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  DISABLED: "Disabled",
};
export const SORT_BY = {
  LOW_TO_HIGH: "low-to-high",
  HIGH_TO_LOW: " high-to-low",
};
export const TEST_DATA = {
  minimumAccountSizeType: [
    {
      id: 1,
      label: "250k",
      value: "250k",
      isApproved: true,
      createdAt: "2019-04-25 04:52:23.162000",
      updatedAt: "2019-04-25 04:52:23.162000",
    },
    {
      id: 2,
      label: "1M",
      value: "1m",
      isApproved: true,
      createdAt: "2019-04-25 04:52:23.162000",
      updatedAt: "2019-04-25 04:52:23.162000",
    },
    {
      id: 3,
      label: "2M",
      value: "2m",
      isApproved: true,
      createdAt: "2019-04-25 04:52:23.162000",
      updatedAt: "2019-04-25 04:52:23.162000",
    },
    {
      id: 4,
      label: "5M",
      value: "5m",
      isApproved: true,
      createdAt: "2019-04-25 04:52:23.162000",
      updatedAt: "2019-04-25 04:52:23.162000",
    },
    {
      id: 5,
      label: "10M",
      value: "10m",
      isApproved: true,
      createdAt: "2019-04-25 04:52:23.162000",
      updatedAt: "2019-04-25 04:52:23.162000",
    },
    {
      id: 6,
      label: "500k",
      value: "500k",
      isApproved: true,
      createdAt: "2020-12-03 20:34:50.225000",
      updatedAt: "2020-12-03 20:34:50.225000",
    },
    {
      id: 7,
      label: "25M",
      value: "25m",
      isApproved: true,
      createdAt: "2020-12-03 20:34:50.227000",
      updatedAt: "2020-12-03 20:34:50.227000",
    },
    {
      id: 8,
      label: "250k",
      value: "250k",
      isApproved: true,
      createdAt: "2020-12-03 20:34:50.229000",
      updatedAt: "2020-12-03 20:34:50.229000",
    },
    {
      id: 9,
      label: "50M",
      value: "50m",
      isApproved: true,
      createdAt: "2020-12-03 20:34:50.231000",
      updatedAt: "2020-12-03 20:34:50.231000",
    },
    {
      id: 10,
      label: "100M",
      value: "100m",
      isApproved: true,
      createdAt: "2020-12-03 20:34:50.232000",
      updatedAt: "2020-12-03 20:34:50.232000",
    },
  ],
  suggestedByMemberType: [
    {
      id: 1,
      value: "threshold foundation",
      label: "Threshold Foundation",
      description: "",
      isApproved: true,
      createdAt: "2020-12-03 20:34:50.518000",
      updatedAt: "2020-12-03 20:34:50.518000",
    },
    {
      id: 2,
      value: "toniic",
      label: "Toniic",
      description: "",
      isApproved: true,
      createdAt: "2020-12-03 20:34:50.518000",
      updatedAt: "2020-12-03 20:34:50.518000",
    },
    {
      id: 3,
      value: "valuesadvisor",
      label: "ValuesAdvisor",
      description: "",
      isApproved: true,
      createdAt: "2020-12-03 20:34:50.518000",
      updatedAt: "2020-12-03 20:34:50.518000",
    },
  ],
 
};

export const US_STATES = [
  { name: "Alabama", code: "AL" },
  { name: "Alaska", code: "AK" },
  { name: "Arizona", code: "AZ" },
  { name: "Arkansas", code: "AR" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "District Of Columbia", code: "DC" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Iowa", code: "IA" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Maine", code: "ME" },
  { name: "Maryland", code: "MD" },
  { name: "Massachusetts", code: "MA" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Mississippi", code: "MS" },
  { name: "Missouri", code: "MO" },
  { name: "Montana", code: "MT" },
  { name: "Nebraska", code: "NE" },
  { name: "Nevada", code: "NV" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "New York", code: "NY" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Vermont", code: "VT" },
  { name: "Virginia", code: "VA" },
  { name: "Washington", code: "WA" },
  { name: "West Virginia", code: "WV" },
  { name: "Wisconsin", code: "WI" },
  { name: "Wyoming", code: "WY" },
];

export const CLIENT_TYPES = {
  INDIVIDUAL: "INDIVIDUAL",
  INSTITUTIONAL: "INSTITUTIONAL",
};

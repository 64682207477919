import React from "react";
import { numberToAum } from "../_util";

export const SelectedFilters = ({
  filters = {},
  filterTypes,
  onFilterSelected,
}) => {
  let selectedFilters = [];
  for (const k in filters) {
    if (filters[k].length) {
      const items = filterTypes[k]
        ?.filter((ft) => filters[k].includes(ft.id))
        .map((ft) => {
          return { ...ft, filterTypeKey: k };
        });
      selectedFilters = [...selectedFilters, ...(items || [])];
    }
  }
  const typicalAccountSizeFilter = filters["typicalAccountSizeType"];
  if (typicalAccountSizeFilter && typicalAccountSizeFilter.length) {
    const item = {
      id: 1,
      filterTypeKey: "typicalAccountSizeType",
      label: `$${numberToAum(typicalAccountSizeFilter[0])}-${numberToAum(typicalAccountSizeFilter[1])}`,
    };
    selectedFilters = [...selectedFilters, item];
  } else {
    selectedFilters = selectedFilters.filter(
      (f) => f.filterTypeKey !== "typicalAccountSizeType"
    );
  }
  const CloseTag = ({ onClick }) => {
    return (
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <img
          src="img/x.svg"
          alt=""
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        />
      </a>
    );
  };

  return (
    <div className="uk-grid uk-visible@m tag-search">
      <div className="uk-width-1-1">
        {selectedFilters.map((f, index) => {
          return (
            <span
              data-testid="selected-filter-badge"
              className="uk-badge"
              key={`${f.filterTypeKey}-${index}`}
            >
              <CloseTag
                onClick={() => {
                  onFilterSelected(f.filterTypeKey, f.id);
                }}
              ></CloseTag>

              {f.label}
            </span>
          );
        })}
      </div>
    </div>
  );
};

import React, { Component } from 'react';
import { Label, FormGroup } from 'reactstrap';
import cx from 'classnames';
import Select from 'react-select';


export default class FormSelect extends Component {
    state = {
        focused: false
    }

    handleFocus = (event) => {
        this.setState({
            focused: true,
        })
    }

    handleBlur = (event) => {
        this.setState({
            focused: false,
        })
    }

    getClassName = () => {
        if (this.props.isMulti && Array.isArray(this.props.value)) {
            return cx("select-container", { "field-focus": !!this.props.value.length })
        }
        return cx("select-container", { "field-focus": !!this.props.value?.label })
    }

    render() {
        const { value = [], onChange, label,labelClass, isDisabled,required,options, error, showError, isMulti = false, style = {}, isSearchable, name } = this.props
        
        const selectedValue = isMulti ? value.map(v => {
            const foundOption = options.find(o => o.value === v)
            if (foundOption) {
                
                return foundOption
            }
            return v
        }) : value
      
        return (
            <React.Fragment>
                <FormGroup style={{ ...style }}>
                    <Select classNamePrefix="select-dropdown"
                        name={name}
                        className={cx(this.getClassName(), { "focused-select": !!this.state.focused })}
                        onChange={onChange}
                        value={selectedValue}
                        isDisabled={isDisabled}
                        isClearable={false}
                        placeholder=""
                        options={options}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        isSearchable={isSearchable}
                        isMulti={isMulti}
                        required={required}
                        getOptionLabel={(option) => option.label}
                        
                    />
                    <Label className={labelClass}>{label}</Label>
                    {
                        showError ?
                            (<div className="error-message contains-error">{error}</div>) : null
                    }
                </FormGroup>
            </React.Fragment>
        )
    }
}


import React, { useState, useEffect ,useContext } from "react";
import { Redirect } from "react-router-dom";

import { setVisitorPassword, signUpFreeVisitor } from "../../api/sign-up";
import { checkEmailUnique } from "../../api/advisor";
import {getDiscountCode} from "../../api/discountCodes"

import { useQueryParams } from "../../hooks/useQueryParams";
import { JoinContext } from "./Context";
import { isEmail } from "validator";
import { AuthContext } from "../../Context/AuthContext";
import {getCountries} from "../../api/countries"
import { JoinLanding } from "./Landing/Landing";
import { JoinWizard } from "./Wizard/Wizard";

const Join = ({
  isEmailUnique = () => {},
  setVisitorPassword = async () => {},
  onGetDiscountCode = async () => {},
  onSignUpFreeVisitor = async () => {},
  landingPage = "Landing",
}) => {
  const [JoinPage, setJoinPage] = useState(
    landingPage
  );

  const displayJoinWizard = (e) => {
    e.preventDefault();
    setJoinPage("Wizard");
  };

  const pagesMap = {
    Landing: <JoinLanding onJoinClick={displayJoinWizard}></JoinLanding>,
    Wizard: (
      <JoinWizard
        isEmailUnique={isEmailUnique}
        setVisitorPassword={setVisitorPassword}
        onGetDiscountCode={onGetDiscountCode}
        onSignUpFreeVisitor={onSignUpFreeVisitor}
      ></JoinWizard>
    ),
  };

  return pagesMap[JoinPage];
};


const Container = ({createAccount}) => {
  const authContext = useContext(AuthContext);
  const query = useQueryParams();
  const { isLoggedIn } = authContext
  const [userEmail, setUserEmail] = useState("");
  const [userFirstName,setUserFirstName] = useState("")
  const [userLastName,setUserLastName] = useState("")
  const [userCountry,setUserCountry] = useState(null)
  const [userState,setUserState] = useState({})
  const [userCity,setUserCity] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [discountCodeId, setDiscountCodeId] = useState();
  const [discountCodeText, setDiscountCodeText] = useState("");
  const [clientType,setClientType] = useState("")
  const [countries, setCountries] = useState([]);
  const paymentSuccesful = query.get('success') ? true : false;
  useEffect(() => {
    const fetchCountries = async () => {
      const response = await getCountries();
      setCountries(response.data);
    };
    fetchCountries();
  }, [])
  const isEmailUnique = async (userEmail) => {
    const data = await checkEmailUnique({ email: userEmail }).then(
      (response) => response.data
    );
    return data.unique;
  };

  const onGetDiscountCode =  async (code) => {
      const discountCode = await getDiscountCode(code).then(r => r.data);
      return discountCode
  }
 
  if (isLoggedIn) {
    return <Redirect push to={`/`} />;
  }

  let landingPage = "Landing"; 
  if(paymentSuccesful || createAccount){
    landingPage = "Wizard"
  }
  const areCredentialsValid = async () => {
    // Reset errors
    setEmailError(" ");
    setPasswordError(" ");

    const isUnique = await isEmailUnique(userEmail);
    const isEmailValid = isEmail(userEmail, {
      domain_specific_validation: true,
    });

    if (!isEmailValid) {
      setEmailError("Invalid email");
      return false;
    }

    if (!isUnique) {
      setEmailError("This Email is already in use");
      return false;
    }

    if (userPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters");
      return false;
    }

    if (userPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return false;
    }

    return true;
  };
  const storeClientCredentials = () => {
    window.localStorage.setItem(
      "visitorCred",
      JSON.stringify({
        email: userEmail,
        password: userPassword,
        firstName: userFirstName,
        lastName: userLastName,
        discountCodeId: discountCodeId,
        clientType: clientType,
        address: {
          countryId: countries.find((country) => country.code === userCountry.value).id,
          state: userState.value,
          city: userCity,
        },
      })
    );
  };
  return (
    <React.Fragment>
      <JoinContext.Provider value={{
        paymentSuccesful: query.get('success') ? true : false,
        countries: countries,
        userFirstName: userFirstName,
        setUserFirstName: (e) => {
          setUserFirstName(e.target.value)
        },
        userLastName: userLastName,
        setUserLastName: (e) => {
          setUserLastName(e.target.value)
        },
        userEmail: userEmail,
        setUserEmail: (e) => {
          setUserEmail(e.target.value);
        },
        userPassword: userPassword,
        setUserPassword: (e) => {
          setUserPassword(e.target.value);
        },
        confirmPassword: confirmPassword,
        setconfirmPassword: (e) => {
          setconfirmPassword(e.target.value);
        },
        clientType: clientType,
        setClientType: (type) => {
          setClientType(type);
        },
        areCredentialsValid: areCredentialsValid,
        emailError: emailError,
        passwordError: passwordError,
        storeClientCredentials: storeClientCredentials,
        discountCodeId: discountCodeId,
        setDiscountCodeId: (id) => {
          setDiscountCodeId(id);
        },
        discountCodeText: discountCodeText,
        setDiscountCodeText: (text) => {
          setDiscountCodeText(text);
        },
        userCountry: userCountry,
        setUserCountry: (country) => {
          setUserCountry(country);
        },
        userState: userState,
        setUserState: (state) => {
          setUserState(state);
        },
        userCity: userCity,
        setUserCity: (e) => {
          setUserCity(e.target.value);
        }
      }}>
      <Join
        isEmailUnique={isEmailUnique}
        setVisitorPassword={setVisitorPassword}
        paymentSuccesful={paymentSuccesful}
        onGetDiscountCode={onGetDiscountCode}
        onSignUpFreeVisitor={signUpFreeVisitor}
        landingPage={landingPage}
      />
      </JoinContext.Provider>
    
    </React.Fragment>
  );
};

export default Container

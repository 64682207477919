import React, { useCallback, useState, useEffect, useContext } from "react";
import { JoinClientType } from "../ClientType/ClientType";
import { JoinCreateUser } from "../CreateUser/CreateUser";
import { JoinPayment } from "../Payment/Payment";
import { JoinPaymentComplete } from "../PaymentComplete/PaymentComplete";
import { JoinContext } from "../../../pages/Join/Context";
//import { useAuth } from "../../../hooks/useAuth";
import { AuthContext } from "../../../Context/AuthContext";

export const JoinWizard = ({
  onSignUpFreeVisitor = async () => {},
  setVisitorPassword = async () => {},
  onGetDiscountCode = async () => {},
}) => {
  const authContext = useContext(AuthContext);
  const {
    paymentSuccesful,
    setClientType,
    areCredentialsValid,
    storeClientCredentials,
  } = useContext(JoinContext);
  const { login } = authContext;
  const [WizardPage, setWizardPage] = useState("ClientType");
  const [progressVal, setProgressVal] = useState(25);
  const [subPrice] = useState(process.env.REACT_APP_SUB_PRICE);
  const [discountCodeId, setDiscountCodeId] = useState();
  const [isFreeSubscription, setIsFreeSubscription] = useState(false);

  const _onGetDiscountCode = async (code) => {
    try {
      if (code) {
        const discountCode = await onGetDiscountCode(code);
        let newSubPrice = subPrice;
        if (discountCode.discountPercent) {
          newSubPrice =
            subPrice - subPrice * (discountCode.discountPercent / 100);
        } else {
          newSubPrice = subPrice - discountCode.maxDiscountAmount;
        }
        setDiscountCodeId(discountCode.id);
        if (newSubPrice <= 0) {
          setIsFreeSubscription(true);
        }
        return discountCode;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const proceedToCreateType = useCallback(() => {
    setWizardPage("ClientType");
    setProgressVal(25);
  }, []);

  const proceedToCreateUser = useCallback(() => {
    setWizardPage("CreateUser");
    setProgressVal(50);
  }, []);

  const proceedToPayment = useCallback(() => {
    setWizardPage("Payment");
    setProgressVal(75);
  }, []);

  const proceedToPaymentComplete = useCallback(() => {
    setWizardPage("PaymentComplete");
    setProgressVal(100);
  }, []);

  useEffect(() => {
    if (paymentSuccesful) {
      const creds = JSON.parse(window.localStorage.getItem("visitorCred"));
      setVisitorPassword(creds).then(() => {
        proceedToPaymentComplete();
        setTimeout(async () => {
          const userCreds = await login(creds.email, creds.password);
          if (!userCreds) {
            return;
          }
          if (!userCreds.isVisitor) {
            window.location = `/my-profile/${userCreds.id}`;
            return;
          }
          window.location = "/search";
        }, 5000);
      });
    }
  }, [paymentSuccesful, proceedToPaymentComplete, setVisitorPassword, login]);

  const pagesMap = {
    ClientType: (
      <JoinClientType
        showTip={true}
        next={async (selectedClientType) => {
          setClientType(selectedClientType);
          proceedToCreateUser();
        }}
      ></JoinClientType>
    ),
    CreateUser: (
      <JoinCreateUser
        submitFormId={"create-account-form"}
        next={async (event) => {
          const areCredValid = await areCredentialsValid();

          if (!areCredValid) {
            return;
          }
          storeClientCredentials();
          proceedToPayment();
        }}
        back={(event) => {
          event.preventDefault();
          proceedToCreateType();
        }}
      ></JoinCreateUser>
    ),
    Payment: (
      <JoinPayment
        onGetDiscountCode={_onGetDiscountCode}
        next={async (event) => {
          event.preventDefault();
          if (isFreeSubscription) {
            const creds = JSON.parse(
              window.localStorage.getItem("visitorCred")
            );
            await onSignUpFreeVisitor({ ...creds, discountCodeId });
            window.localStorage.removeItem("visitorCred");
            proceedToPaymentComplete();
            setTimeout(async () => {
              const userCreds = await login(creds.email, creds.password);
              if (!userCreds) {
                return;
              }
              if (!userCreds.isVisitor) {
                window.location = `/my-profile/${userCreds.id}`;
                return;
              }
              window.location = "/search";
            }, 5000);
            return;
          }

          const newForm = document.createElement("form");
          document.getElementsByTagName("body")[0].appendChild(newForm);
          newForm.setAttribute("method", "post");
          newForm.setAttribute(
            "action",
            `${process.env.REACT_APP_BASEURL}/stripe/create-checkout-session?${
              discountCodeId ? `code=${discountCodeId}` : ""
            }`
          );
          newForm.submit();
        }}
        back={(event) => {
          event.preventDefault();
          proceedToCreateUser();
        }}
      ></JoinPayment>
    ),
    PaymentComplete: <JoinPaymentComplete></JoinPaymentComplete>,
  };
  const currentLabelClasses = {
    ClientType: "",
    CreateUser: "",
    Payment: "",
    PaymentComplete: "",
  };
  for (let key in currentLabelClasses) {
    if (key === WizardPage) {
      currentLabelClasses[WizardPage] = "current-progress-label";
    } else {
      currentLabelClasses[key] = "";
    }
  }

  return (
    <div className="uk-container">
      <div className={"progress-bar-container"}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <label
            className={`progress-bar-label ${currentLabelClasses.ClientType}`}
            onClick={() => {
              setProgressVal(25);
              setWizardPage("ClientType");
            }}
          >
            Client Type
          </label>
          <label
            className={`progress-bar-label ${currentLabelClasses.CreateUser}`}
            onClick={() => {
              setProgressVal(50);
              setWizardPage("CreateUser");
            }}
          >
            Account
          </label>
          <label
            className={`progress-bar-label ${currentLabelClasses.Payment}`}
          >
            Payment
          </label>
          <label
            className={`progress-bar-label ${currentLabelClasses.PaymentComplete}`}
          >
            Confirmation
          </label>
        </div>
        <progress
          id="js-progressbar"
          className="uk-progress"
          value={progressVal}
          max="100"
        ></progress>
      </div>

      {pagesMap[WizardPage]}
    </div>
  );
};

import React from "react";

export const ProductFilter = ({
  title,
  text,
  items = [],
  selectedFilters,
  filtersId = "",
  onFilterSelected,
  toolTipText,
  toolTipTextId,
}) => {
  const doesNotsellsPropietaryObj = items.find(
    (product) => product.value !== "sells proprietary products"
  );
  const doesNotsellsPropietary =
    selectedFilters[filtersId] &&
    selectedFilters[filtersId].includes(doesNotsellsPropietaryObj.id);

  return (
    <div className="impacttheme filter-section">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div uk-accordion={"true"} className="uk-accordion">
            <div className={"product-filter-container"}>
              <h3 className="uk-accordion-title section-title">
                <a href="/" onClick={(e) => e.preventDefault()}>
                  {title}
                </a>
              </h3>
              {toolTipText ? (
                <span
                  className="info-top"
                  uk-toggle={`target: #${toolTipTextId}; cls: show; animation:
                                          uk-animation-fade; mode: hover`}
                >
                  <img src="img/info.svg" alt="" />
                  <div
                    className="tooltip"
                    id={toolTipTextId}
                    aria-hidden="true"
                  >
                    <p>{toolTipText}</p>
                  </div>
                </span>
              ) : null}
              <div
                className="tooltip tooltip-dummy"
                id="product-filter"
                aria-hidden="true"
              >
                <p>{toolTipText}</p>
              </div>
              <div className="uk-accordion-content product-filter-grid">
                <p className="section-text">{text}</p>
                <label className="switch">
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    onChange={(e) => {
                      onFilterSelected(filtersId, doesNotsellsPropietaryObj.id);
                    }}
                    checked={doesNotsellsPropietary || false}
                  />
                  <span className="slider round"></span>
                </label>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

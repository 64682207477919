import React from "react";
import classes from "./HeadQuarters.module.css";
export const HeadQuarters = ({ addresses = [], website }) => {
  if (!addresses.length) {
    return null;
  }
  const { line1, line2, city, state, zipCode, country } = addresses.find(
    (a) => a.primary
  );
  return (
    <React.Fragment>
      <h3 className={`section-title ${classes.title}`}>HEADQUARTERS</h3>
      <p className="section-text headquarters-text">
        {line1} {line2} <br /> {city} {state} {zipCode}, {country} 
      </p>
      <a
          target="_blank" rel="noopener noreferrer"
        href={`${!website.includes("http") ? "https://" : ""}${website}`}
        className={`section-link word-break ${classes.website}`}
        style={{ color: "#4D4D4D" }}
      >
        {website
          .toLowerCase()
          .replace("http://", "")
          .replace("https://", "")
          .replace("www.", "")}
      </a>
    </React.Fragment>
  );
};

import axiosInstance from '.';

export function contactValuesAdvisor(data) {
    return axiosInstance({
        method: 'post',
        url: '/contact',
        data,
    })
}

export function contactValuesHelp(data) {
    return axiosInstance({
        method: 'post',
        url: '/contact/helpRequest',
        data,
    })
}
export function contactAdvisor(data) {
    return axiosInstance({
        method: 'post',
        url: '/contact/hiAdvisor',
        data,
    })
}
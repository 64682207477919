import { useState, useCallback } from "react";
import ReactGA from "react-ga4";
import { SORT_BY } from "../constants/constants";
let state = {
  advisors: [],
  filterTypes: {},
  selectedFilters: {},
  selectedSort: {},
};
const filterTypesToFilterName = {
  servingCountriesType: "Geography",
  advisorOfferingType: "Services",
  assetClassesType: "Asset Class",
  impactThemesType: "Impact Themes",
  proxyVotingType: "Shareholder advocacy",
  suggestedByMemberType:"Affinity Group"
}
const saveSearchState = (searchState) => {
  localStorage.setItem("searchState", JSON.stringify(searchState));
}
const getSearchState = () => {
  const savedState = JSON.parse(localStorage.getItem("searchState"));
  const validSavedState =
    savedState &&
    savedState.filterTypes &&
    savedState.selectedFilters &&
    savedState.selectedSort;
  return validSavedState ? savedState : null;
}
export const useSearchResults = () => {
  const [advisors, setAdvisors] = useState([]);
  const [filterTypes, setFilterTypes] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedSort, setSelectedSort] = useState({});
  const [selectedOrder,setSelectedOrder] = useState(SORT_BY.HIGH_TO_LOW)

  const _setAdvisors = useCallback((advisors) => {
    setAdvisors(advisors);
  }, []);
  const _setFilterTypes = useCallback((filterTypes) => {
    state = { ...state, filterTypes };
    saveSearchState(state);
    setFilterTypes(filterTypes);
  }, []);
  const _setSelectedFilters = useCallback((selectedFilters) => {
    state = { ...state, selectedFilters };
    saveSearchState(state);
    setSelectedFilters(selectedFilters);
  }, []);
  const _setSelectedSort = useCallback((selectedSort) => {
    state = { ...state, selectedSort };
    saveSearchState(state);
    setSelectedSort(selectedSort);
  }, []);

  const trackSelectedFilter = ({filtersId,filterItemId}) => {
    const selectedFilter = filterTypes[filtersId]?.find(obj => obj.id === filterItemId)
    if(selectedFilter){ 
      const filterName = filterTypesToFilterName[filtersId]
      ReactGA.event({
        category:`${filterName?filterName:selectedFilter.label} filter selections`,
        action:'selection',
        label:selectedFilter.label,
        value:1
      })
    }
  }

  const trackSelectedAdvisor = (advisorId) => {
    if(selectedFilters.minimumAccountSizeType && selectedFilters.minimumAccountSizeType.length > 0 && filterTypes["minimumAccountSizeType"] && filterTypes["minimumAccountSizeType"].length > 0 ){
      const selectedAccountSizeId = selectedFilters.minimumAccountSizeType[0];
      const selectedAccountSize = filterTypes["minimumAccountSizeType"].find(obj => obj.id === selectedAccountSizeId);
        if(selectedAccountSize){
          ReactGA.event({
            category:`Selected Advisors By Account Size: ${selectedAccountSize.value}`,
            action:'selection',
            label:`/profile/${advisorId}`,
            value:1
          })
      }
    }
  }
  const toObject = (acc, current) => {
    acc[current] = true;
    return acc;
  }
  return {
    advisors: advisors.map(a => {
      return {
        ...a,
        servingCountriesIds : a.teamInfo.servingCountries.map((it) => it.servingCountryId).reduce(toObject , {}),
        impactThemesIds : a.teamInfo.impactThemes.map((it) => it.impactThemeId).reduce(toObject  , {}),
        advisorOfferingsIds : a.teamInfo.advisorOfferings.map((it) => it.advisorOfferingId).reduce(toObject,{}),
        assetClassesIds : a.teamInfo.assetClasses.map((it) => it.assetClassId).reduce(toObject  , {}),
        suggestedByMembersIds : a.suggestedByMembers.map((it) => it.suggestedByMemberId).reduce(toObject , {}),
        differentClientsIds : a.teamInfo.differentClients.map((it) => it.clientId).reduce(toObject  , {}),
      }
    }),
    setAdvisors: _setAdvisors,
    filterTypes,
    setFilterTypes: _setFilterTypes,
    selectedFilters,
    setSelectedFilters: _setSelectedFilters,
    selectedSort,
    setSelectedSort: _setSelectedSort,
    trackSelectedFilter,
    trackSelectedAdvisor,
    selectedOrder,
    setSelectedOrder,
    getSearchState
  };
};

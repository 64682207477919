import { createContext } from "react";

import { getStartedState } from "./OnBoardingGetStarted/state";
import { firmState } from "./OnBoardingAboutFirm/state";
import { teamInfoState } from "./OnBoardingAboutTeam/state";
import { avaiState } from "./OnBoardingAVAI/state";
import { materialsState } from "./OnBoardingMaterials/state";

import { getZipCodes } from "../../api/zipcodes";
import { createAdvisor, updateAdvisor } from "../../api/advisor";

import { upload } from "../../utils/upload";
const getValueObjectsFromArray = (array = []) => {
  return array.map((obj) => {
    if (obj.value) {
      return obj;
    }
    return { value: obj };
  });
};
const getGeoCoding = async (addresses = [], countries = []) => {
  const UsaAddresses = await Promise.all(
    addresses
      .filter((a) => a.country === "United States" && !isNaN(+a.zipCode))
      .map(async (a) => {
        const { data } = await getZipCodes(a.zipCode);
        const [zc] = data;
        if (zc) {
          return { latitude: zc.latitude, longitude: zc.longitude };
        }
        return {};
      }),
  );
  const nonUsaAdresses = addresses.map((singleAddress) => {
    const c = countries.find((c) => c.name === singleAddress.country);
    if (c) {
      return { latitude: c.latitude, longitude: c.longitude };
    }
    return {};
  });
  return [...UsaAddresses, ...nonUsaAdresses];
};
export const FORM_TYPES = {
  CREATE: "create",
  UPDATE: "update",
  CLONE: "clone",
};

export const saveStateToLocalStorage = (state) => {
  const { firm, getStarted, material, valuesAlignedInvestingInfo, teamInfo } =
    state;
  const data = {
    firm: firm || {},
    getStarted: getStarted || {},
    material: material || {},
    valuesAlignedInvestingInfo: valuesAlignedInvestingInfo || {},
    teamInfo: teamInfo || {},
  };
  localStorage.setItem("fa-dashboard", JSON.stringify(data));
};

export const getStateFromLocalStorage = () => {
  const data = localStorage.getItem("fa-dashboard");
  if (data) {
    const { firm, getStarted, material, valuesAlignedInvestingInfo, teamInfo } =
      JSON.parse(data);
    return {
      firm,
      getStarted,
      material,
      valuesAlignedInvestingInfo,
      teamInfo,
    };
  }
};
export const deleteStateFromLocalStorage = () => {
  localStorage.removeItem("fa-dashboard");
};
export const initialState = {
  getStarted: getStartedState,
  firm: firmState,
  teamInfo: teamInfoState,
  valuesAlignedInvestingInfo: avaiState,
  material: materialsState,
  typeList: {
    degreesType: [],
    productType: [],
    firmSizeType: [],
    materialType: [],
    socialLinkType: [],
    affiliationType: [],
    proxyVotingType: [],
    assetClassesType: [],
    impactThemesType: [],
    advisorOfferingType: [],
    differentClientType: [],
    servingCountriesType: [],
    investmentServicesType: [],
    minimumAccountSizeType: [],
    compensationStructureType: [],
    suggestedByMemberType: [],
    unSdgType: [],
  },

  submit: async function () {
    this.form.setIsLoading(true);
    try {
      const {
        firm,
        getStarted,
        material,
        valuesAlignedInvestingInfo,
        teamInfo,
      } = this;
      const userId = getStarted.userId ? getStarted.userId : "";
      const headquarters = {
        city: this.getStarted.headquarters.city.value,
        line1: this.getStarted.headquarters.line1.value,
        line2: this.getStarted.headquarters.line2.value,
        state: this.getStarted.headquarters.state.value,
        country: this.getStarted.headquarters.country.value,
        zipCode: this.getStarted.headquarters.zipCode.value,
        primary: true,
      };
      const fileFields = [
        { field: getStarted.profilePic, fileType: "image" },
        { field: getStarted.currentADV, fileType: "file" },
        { field: material.sampleNewsletter, fileType: "file" },
        { field: material.sampleRedactedClientReport, fileType: "file" },
        { field: material.researchReport, fileType: "file" },
        { field: material.thoughtLeadershipPublication, fileType: "file" },
      ];
      console.log("fileFields", fileFields);
      for (const fileField of fileFields) {
        if (fileField?.field?.file?.name) {
          const uploadResult = await upload(
            fileField.field.file,
            fileField.field.previousObjectUrl,
            fileField.fileType,
          );
          if (uploadResult.data.success) {
            fileField.field.value = uploadResult.data.link;
          }
        }
      }

      const address = [
        headquarters,
        ...getStarted.addresses.map((address) => ({
          city: address.city.value,
          line1: address.line1.value,
          line2: address.line2.value,
          state: address.state.value,
          country: address.country.value,
          zipCode: address.zipCode.value,
          primary: false,
        })),
      ];

      const geocoding = await getGeoCoding(address, this.countries);
      const data = {
        firm: {
          aum: firm.aum.value,
          name: getStarted.firmName.value,
          unit: firm.unit.value,
          aumUnit: firm.aumUnit.value,
          aumUnitAsOfDate: firm.aumUnitAsOfDate.value,
          mission: firm.mission.value,
          firmSize: firm.firmSize.value,
          noOfClients: firm.noOfClients.value,
          seniorsColor: firm.seniorsColor.value,
          seniorsWomen: firm.seniorsWomen.value,
          noOfEmployees: firm.noOfEmployees.value,
          publicBenefit: firm.publicBenefit.value,
          yearsLicensed: firm.yearsLicensed.value,
          directorsColor: firm.directorsColor.value,
          directorsWomen: firm.directorsWomen.value,
          employeesColor: firm.employeesColor.value,
          employeesWomen: firm.employeesWomen.value,
          valuesAlignedAum: firm.valuesAlignedAum.value,
          minimumAccountSize: firm.minimumAccountSize.value,
          typicalAccountSize: firm.typicalAccountSize.value,
          typicalAccountSizeUnit: firm.typicalAccountSizeUnit.value,
          governmentInvestigation: firm.governmentInvestigation.value,
          firmOfferedVaNImpactYears: firm.firmOfferedVaNImpactYears.value,
          requiredDisclosures: firm.requiredDisclosures.value,
          isTypicalAccountSizeDisclosable: firm.isTypicalAccountSizeDisclosable,
          isNoOfClientsDisclosable: firm.isNoOfClientsDisclosable,
        },
        users: {
          userId: userId,
          email: getStarted.email.value,
          password: getStarted.password.value,
        },
        address: address,
        advisor: {
          name: getStarted.advisorName.value,
          title: getStarted.title.value,
          website: getStarted.website.value,
          currentADV: getStarted.currentADV.value,
          profilePic: getStarted.profilePic.value,
          yearsWithFirm: getStarted.yearsWithFirm.value,
          profilePicInfo: "info",
          yearsAsAdvisor: getStarted.yearsAsAdvisor.value,
        },
        degrees: getValueObjectsFromArray(getStarted.degrees.value),
        product: getValueObjectsFromArray(firm.product.value),
        material: [
          material.sampleNewsletter,
          material.sampleRedactedClientReport,
          material.researchReport,
          material.thoughtLeadershipPublication,
        ]
          .filter((m) => m.value)
          .map((m) => ({
            value: m.typeId,
            link: m.value,
          })),
        teamInfo: {
          aum: teamInfo.aum.value,
          aumUnit: teamInfo.aumUnit.value,
          aumUnitAsOfDate: teamInfo.aumUnitAsOfDate.value,
          noOfPeople: teamInfo.noOfPeople.value,
          impactField: teamInfo.impactField.value,
          noOfClients: teamInfo.noOfClients.value,
          employeesColor: teamInfo.employeesColor.value,
          employeesWomen: teamInfo.employeesWomen.value,
          valuesAlignedAum: teamInfo.valuesAlignedAum.value,
          clientAccountSize: teamInfo.clientAccountSize.value,
          clientAccountSizeUnit: teamInfo.clientAccountSizeUnit.value,
          teamOfferedVaNImpactYears: teamInfo.teamOfferedVaNImpactYears.value,
          isNoOfClientsDisclosable: teamInfo.isNoOfClientsDisclosable,
          isTypicalAccountSizeDisclosable:
            teamInfo.isClientAccountSizeDisclosable,
        },
        geocoding: geocoding,
        socialLink: getStarted.socialLinks.map((sl) => ({
          value: sl.type.value,
          link: sl.link.value,
        })),
        affiliation: getValueObjectsFromArray(teamInfo.affiliation.value),
        proxyVoting: getValueObjectsFromArray(
          valuesAlignedInvestingInfo.proxyVoting.value,
        ),
        assetClasses: getValueObjectsFromArray(teamInfo.assetClasses.value),
        impactThemes: getValueObjectsFromArray(teamInfo.impactThemes.value),
        advisorOffering: getValueObjectsFromArray(
          teamInfo.advisorOffering.value,
        ),
        differentClients: getValueObjectsFromArray(
          teamInfo.differentClients.value,
        ),
        servingCountries: getValueObjectsFromArray(
          teamInfo.servingCountries.value,
        ),
        compensationStructure: getValueObjectsFromArray(
          teamInfo.compensationStructure.value,
        ),
        valuesAlignedInvestingInfo: {
          voteProxies: valuesAlignedInvestingInfo.voteProxies.value,
          impactStrategy: valuesAlignedInvestingInfo.impactStrategy.value,
          assetAllocation: valuesAlignedInvestingInfo.assetAllocation.value,
          impactInvestment: valuesAlignedInvestingInfo.impactInvestment.value,
          measureAndReport: valuesAlignedInvestingInfo.measureAndReport.value,
          interestedInImpact:
            valuesAlignedInvestingInfo.interestedInImpact.value,
          meaningfulInvestment:
            valuesAlignedInvestingInfo.meaningfulInvestment.value,
          descOfWhatClientCares:
            valuesAlignedInvestingInfo.descOfWhatClientCares.value,
          engagedWithManagement:
            valuesAlignedInvestingInfo.engagedWithManagement.value,
          helpClientMakeBetterDecision:
            valuesAlignedInvestingInfo.helpClientMakeBetterDecision.value,
        },
        teamDegrees: getValueObjectsFromArray(teamInfo.degrees.value),
        richMediaLinks: material.richMediaLinks.map((rml) => ({
          url: rml.url.value,
          description: rml.description.value,
        })),
        suggestedByMembers: getValueObjectsFromArray(
          getStarted.suggestedByMembers.value,
        ),
        unSdgs: getValueObjectsFromArray(teamInfo.unSdgs.value),
      };

      let response;
      if (this.form.formType === FORM_TYPES.UPDATE) {
        response = await updateAdvisor(data);
      }
      if (
        this.form.formType === FORM_TYPES.CREATE ||
        this.form.formType === FORM_TYPES.CLONE
      ) {
        response = await createAdvisor(data);
      }
      if (response.data.success) {
        this.form.setIsSubmitSuccess(true);
        deleteStateFromLocalStorage();
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.form.setIsLoading(false);
    }
  },
  countries: [],
  form: {
    setIsLoading: (value) => {},
    setIsSubmitSuccess: (value) => {},
    isLoading: false,
    isSubmitSuccess: false,
    formType: FORM_TYPES.CREATE,
  },
};

export const getEmptyState = () => {
  return JSON.parse(JSON.stringify(initialState));
};
export const FADashboardContext = createContext(initialState);


function populateRejectDataCreator(mapper, payload, state) {
    let result = {}
    Object.keys(mapper).forEach((mapperKey) => {
        result[mapper[mapperKey]] = {
            ...state[mapper[mapperKey]],
            value: payload[mapper[mapperKey]]
        }
    })
    return result
}

export default populateRejectDataCreator;

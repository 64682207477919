import axiosInstance from './index';

export function getAdvisorList(status, length = 0) {
    return axiosInstance({
        method: 'get',
        url: `/advisor/dashboard?status=${status}&offset=${length}`,
    })
}

export function acceptAdvisor(id) {
    return axiosInstance({
        method: 'patch',
        url: `/advisor/accept`,
        data:{
            id
        }
    })
}


export function rejectAdvisor(id) {
    return axiosInstance({
        method: 'patch',
        url: `/advisor/reject`,
        data:{
            id
        }
    })
}
export function disableAdvisor(id) {
    return axiosInstance({
        method: 'patch',
        url: `/advisor/disable`,
        data:{
            id
        }
    })
}
export function enableAdvisor(id) {
    return axiosInstance({
        method: 'patch',
        url: `/advisor/enable`,
        data:{
            id
        }
    })
}

export async function getUserToken(id) {
    const response = await axiosInstance({
        method: 'get',
        url: `/advisor/getUserToken?id=${id}`,
    });
    return response
}
import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import "./ClientType.css";
import { CLIENT_TYPES } from "../../../constants/constants";

export const JoinClientType = ({
    next = (value) => {},
    showTip = false
}) => {
    const { clientType } = useContext(AuthContext);

    return <Fragment>
        <div className="client-type--container">
            <div className="heading">
                <h3>Select Client Type</h3>
                <p>
                    To present you with the best universe of advisors,
                    please select whether you are an individual or
                    institutional investor.
                </p>
                {
                    () => {
                        if (showTip) {
                            return <p className="note">
                                You can change Client Type later in Account Settings.
                            </p>
                        }
                    }
                }
            </div>
            <div className="grid">
                <div className={`grid-item ${ clientType === CLIENT_TYPES.INDIVIDUAL ? 'active': ''}`} onClick={() =>{
                    next(CLIENT_TYPES.INDIVIDUAL);
                }}>
                    <div className="current--type">CURRENT</div>
                    <div className="client_type__container">
                        <div className="lined-title--container">
                            <hr></hr>
                            <h3 className="lined-title">INDIVIDUAL</h3>
                            <hr></hr>
                        </div>
                        <p>
                            Select this option if you are an individual or family
                        </p>
                        <img src="/img/InvestorsType-01.png" alt="Individual investor type"></img>
                    </div>
                </div>
                <div className={`grid-item ${ clientType === CLIENT_TYPES.INSTITUTIONAL ? 'active': ''}`} onClick={() =>{
                    next(CLIENT_TYPES.INSTITUTIONAL);
                }}>
                    <div className="current--type">CURRENT</div>
                    <div className="client_type__container">
                        <div className="lined-title--container">
                            <hr></hr>
                            <h3 className="lined-title">INSTITUTIONAL</h3>
                            <hr></hr>
                        </div>
                        <p>
                            Select this option if you are an institutional investor,
                            such as a foundation, endowment, or trust
                        </p>
                        <img src="/img/InvestorsType-02.png" alt="Institutional investor type"></img>
                    </div>
                </div>
            </div>
        
        </div>
    </Fragment>
};
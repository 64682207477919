export const requiredObj = (obj) => {
  const hasValue = obj.value !== undefined && obj.value !== null && obj.value !== "";
  obj.error = obj.required && !hasValue ? "This field is required" : "";
  obj.showError = obj.required && !hasValue;
};

export const requiredArray = (obj) => {
  obj.error = obj.required && !obj.value.length ? "This field is required" : "";
  obj.showError = obj.required && !obj.value.length;
}
export const isValidObj = (obj) => {
  return !Boolean(obj.error);
};

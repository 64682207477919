import React from "react";
import "./BlogPost.css";
const Component = () => {
  return (
    <div className="blog-post-container">
      <article>
        <h1 className="article-title">Genesis Story</h1>
        <h4 className="article-author">By ValuesAdvisor</h4>
        <div>
          <p>
            I created ValuesAdvisor because I want to make it easier to find a
            values-aligned financial advisor, and remove any barriers (or
            excuses) for HNW to align their values with their investments. I
            want to put the investor in control so they can compare, filter, and
            learn on their own time schedule about leading impact advisors.
          </p>

          <p>
            Getting a financial return AND a social return -- is not an
            oxymoron. It is achieved every day. While it took me almost a
            quarter of a century to realize that it's possible to align my
            capital with my values, I am finally doing it with money I steward.
            It is an empowering and joyful feeling I want to share with
            everyone.
          </p>
          <p>
            When I first set about finding a new financial advisor. I spoke with
            friends, did a lot of online research, contacted firms, and dug into
            reports. It was time-consuming and tedious, but I did find an
            Advisor who fit my needs.
          </p>
          <p>
            <img
              src="img/lisa_genesis_blog_post.png"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              width="179"
              height="230"
              alt="lisa"
            />
          </p>
          <p>
            A few years later, I ran a campaign to persuade investors to divest
            from fossil fuels. Investors made the pledge, but came back to me
            saying, “I have committed to divest, but my advisor has no idea how
            to invest fossil free.” Having gone through the search process
            myself, while meeting some stellar professionals along the way, I
            committed to creating a resource to make the process much easier for
            my peers and the next generation of investors.
          </p>
          <p>
            I am quite proud of ValuesAdvisor. We are the{" "}
            <span>
              only non-profit platform in the world where you can search and
              learn about the top financial advisors with impact expertise. We
              are committed to combating greenwashing and preserving the
              integrity of the impact field.{" "}
            </span>
            ValuesAdvisor stands alone as the “go to” for investors, foundations
            and endowments looking for outstanding professionals who are
            pioneers as well as fresh diverse minds propelling the impact space
            forward.
          </p>
          <p>
            Choosing a financial advisor is not like picking out a new
            restaurant for dinner. Even with a beautifully curated, filterable
            platform that allows you to compare and contrast - it still takes
            time. So, ValuesAdvisor is designed to be used over a period of
            weeks or months. If you're like me and you like things on paper,
            print out the profiles of those advisers that interest you. Once
            you're ready to meet with those you've selected, download our free "
            <a href="/resources#working-with-advisors">
              <span>Interview Guide</span>
            </a>
            ” to help guide your questions about their impact expertise,
            investment philosophy and fee structure.
          </p>
          <p>
            Whether you are changing advisors or finding your first one, don’t
            think of this as daunting. I've worked with a lot of individual
            investors, families and foundations. You can find a financial
            advisor who you enjoy meeting, understands your values, and helps
            you on this journey of putting your money to work for YOU… and the
            world you want to see.
          </p>
          <p>With Gratitude,</p>
          <p>
            <span>
              <img
                src="img/lisa_sign.webp"
                alt="signature"
                loading="lazy"
                width="200"
              />
              <br />
            </span>
          </p>
          <p>&nbsp;</p>
        </div>
      </article>
    </div>
  );
};
export default Component 

import React from "react";

export const JoinLanding = ({ onJoinClick = () => {} }) => {
  return (
    <div className="join-page">
      <div className="uk-container benefits-div">
        <h2 className="benefits-title">Subscription</h2>
        <p className="presentation-text">
          We know how important it is to have credible advise you can trust. No
          matter where you are in your impact journey, ValuesAdvisor puts you in
          the driver’s seat to decide what financial and impact goals are
          important to you. With ValuesAdvisor, you can:
        </p>
        <div uk-grid={"true"} className="subscription uk-flex">
          <div className="uk-width-1-1 uk-width-1-3@s">
            <img
              src={`${process.env.PUBLIC_URL}/img/Artboard 1@2x.png`}
              alt=""
            />
            <h3>Save Time</h3>
            <p>
              Decrease your time and friction in finding an advisor who is the
              right fit
            </p>
          </div>
          <div className="uk-width-1-1 uk-width-1-3@s">
            <img
              src={`${process.env.PUBLIC_URL}/img/Artboard 2@2x.png`}
              alt=""
            />
            <h3>Invest in What Matters to You</h3>
            <p>
              Access a curated collection of experts suggested by experienced
              impact investors
            </p>
          </div>
          <div className="uk-width-1-1 uk-width-1-3@s">
            <img
              src={`${process.env.PUBLIC_URL}/img/Artboard 4@2x.png`}
              alt=""
            />
            <h3>Find the Best Fit</h3>
            <p>Sort and filter by the criteria you care about most</p>
          </div>
        </div>
      </div>

      <div className="uk-container join-div">
        <h2 className="benefits-title">Benefits of a Subscription</h2>
        <ul className="list-with-orange-arrows">
          <li>
            Unlimited access to a curated collection of expert and trusted
            financial professionals in impact investing.
          </li>
          <li>
            Unique insight into advisors’ core experience, impact measurement,
            commitment to UNSDG, BIPOC and female leadership, and more — in an
            exceptional user experience.
          </li>
          <li>Search capabilities to find peer recommendations.</li>
          <li>
            Office Hours with a ValuesAdvisor specialist to jumpstart your
            process.
          </li>
        </ul>
        <div className="join-box__container">
          <div className="inner-box">
            <p>
              Member of a <br />
              ValuesAdvisor Partner
            </p>
            <p>Enter your Partner Code for your complimentary subscription.</p>
          </div>
          <div className="inner-box">
            <p>
              Individual <br />
              subscribers
            </p>
            <p>
              Enjoy a subscription for a cost of $
              {process.env.REACT_APP_SUB_PRICE}.
            </p>
          </div>
        </div>
        <div className="uk-text-center">
          <a href={"/"} onClick={onJoinClick} className="homelink">
            Get Started{" "}
            <svg
              className="homelink__chevron"
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="19.521"
              viewBox="0 0 11 19.521"
            >
              <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                <path
                  id="Path_434"
                  data-name="Path 434"
                  d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                  transform="translate(-178.807 -14.7)"
                  fill="#ff6b00"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>

      <div
        className="uk-container questions-div uk-flex-nowrap uk-child-width-1-2 uk-flex uk-flex-center"
        uk-grid={"true"}
      >
        <div className="image-container image-contain-container drop-a-note-question-image-container">
          <img src="img/drop-a-note-3.png" alt="" />
        </div>
        <div className="questions-container uk-flex uk-flex-center uk-flex-column">
          <h2 className="still-have-questions">Still have questions?</h2>
          <div className="uk-width-1-1 uk-flex uk-flex-center">
            <a
              href="mailto:info@valuesadvisor.com"
              className="homelink"
            >
              Get in Touch{" "}
              <svg
                className="homelink__chevron"
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="19.521"
                viewBox="0 0 11 19.521"
              >
                <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                  <path
                    id="Path_434"
                    data-name="Path 434"
                    d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                    transform="translate(-178.807 -14.7)"
                    fill="#ff6b00"
                  />
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

import { isURL } from "validator"

function validateUrl(url) {

    if (!url.value) {
        return {
            ...url,
            showError: true,
            error: 'Required'
        }
    }
    
    if(!isURL(url.value)){
        return {
            ...url,
            showError: true,
            error: 'Enter a valid Link'
        }
    } 

    if(isURL(url.value)){
        return {
            ...url,
            showError: false
        }
    }
      
    return url
}

export default validateUrl;

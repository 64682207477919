import { connect } from 'react-redux';
import App from '../App'


const mapStateToProps = state => {
    return {
        id: state.auth.id,
        role: state.auth.role,
        status: state.auth.status,
        loggedIn: state.auth.loggedIn
    }
}


const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useEffect, useState } from "react";
import "./FADashboard.css";
import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Button,
} from "reactstrap";
import OBGetStarted from "./OnBoardingGetStarted/Container";
import OBAboutFirm from "./OnBoardingAboutFirm/Container";
import OBAboutTeam from "./OnBoardingAboutTeam/Container";
import OBAvai from "./OnBoardingAVAI/Container";
import OBMaterials from "./OnBoardingMaterials/Container";

import { Redirect } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import ProfileSubmit from "./ProfileSubmit";
import { useContext } from "react";
import {
  FADashboardContext,
  FORM_TYPES,
  deleteStateFromLocalStorage,
  saveStateToLocalStorage,
} from "./Context";
import scrollToError from "../../utils/scrollToError";

const TABS = {
  GET_STARTED: 1,
  ABOUT_FIRM: 2,
  ABOUT_TEAM: 3,
  AVAI: 4,
  MATERIALS: 5,
};
const FADashboard = (props) => {
  const context = useContext(FADashboardContext);
  const {
    form,
    getStarted,
    firm,
    teamInfo,
    valuesAlignedInvestingInfo,
    material,
  } = context;
  const [cancelSubmit, setCancelSubmit] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.GET_STARTED);
  useEffect(() => {
    if (getStarted.isValid === false) {
      scrollToError("contains-error");
    }
  }, [getStarted.isValid]);
  const tabHeading = [
    "Get Started",
    "About the Firm",
    "About the Team",
    "About Values Aligned Investing",
    "Advisor Provided Materials",
  ];

  const toggleCancelSubmit = (e) => {
    e.preventDefault();
    deleteStateFromLocalStorage()
    setCancelSubmit(true);
  };

  const navigateToTab = async (tab) => {
    if (activeTab === TABS.GET_STARTED && tab > TABS.GET_STARTED) {
      const newGetStartedState = await getStarted.validate(
        form.formType === FORM_TYPES.UPDATE
      );
      if (!newGetStartedState.isValid) {
        scrollToError("contains-error");
        return;
      }
    }

    if (activeTab === TABS.ABOUT_FIRM && tab > TABS.ABOUT_FIRM) {
      const newFirmState = await firm.validate();
      if (!newFirmState.isValid) {
        scrollToError("contains-error");
        return;
      }
    }

    if (activeTab === TABS.ABOUT_TEAM && tab > TABS.ABOUT_TEAM) {
      const newTeamState = await teamInfo.validate();
      if (!newTeamState.isValid) {
        scrollToError("contains-error");
        return;
      }
    }

    if (activeTab === TABS.AVAI && tab > TABS.AVAI) {
      const newValuesAlignedInvestingInfoState =
        await valuesAlignedInvestingInfo.validate();
      if (!newValuesAlignedInvestingInfoState.isValid) {
        scrollToError("contains-error");
        return;
      }
    }
    setActiveTab(tab);
    if (form.formType === FORM_TYPES.CREATE) {
      saveStateToLocalStorage(context);
    }
  };

  const submit = async () => {
    const newGetStartedState = await getStarted.validate(
      form.formType === FORM_TYPES.UPDATE
    );
    if (!newGetStartedState.isValid) {
      setActiveTab(TABS.GET_STARTED);
      scrollToError("contains-error");
      return;
    }
    const newFirmState = await firm.validate();
    if (!newFirmState.isValid) {
      setActiveTab(TABS.ABOUT_FIRM);
      scrollToError("contains-error");
      return;
    }
    const newTeamState = await teamInfo.validate();
    if (!newTeamState.isValid) {
      setActiveTab(TABS.ABOUT_TEAM);
      scrollToError("contains-error");
      return;
    }
    const newValuesAlignedInvestingInfoState =
      await valuesAlignedInvestingInfo.validate();
    if (!newValuesAlignedInvestingInfoState.isValid) {
      setActiveTab(TABS.AVAI);
      scrollToError("contains-error");
      return;
    }
    const newMaterialState = await material.validate();
    if (!newMaterialState.isValid) {
      scrollToError("contains-error");
      return;
    }
    await context.submit();
  };

  return form.isLoading ? (
    <Spinner />
  ) : (
    <div className="FA-admin-container">
      <Container className="custom-container FA-admin">
        <Nav className="nav-list-dashboard" id="fa-admin-nav">
          {tabHeading.map((tab, index) => (
            <NavItem key={index}>
              <NavLink
                className={classnames({ active: activeTab === index + 1 })}
                onClick={() => {
                  navigateToTab(index + 1);
                }}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TABS.GET_STARTED}>
            <OBGetStarted />
          </TabPane>
          <TabPane tabId={TABS.ABOUT_FIRM}>{<OBAboutFirm />}</TabPane>
          <TabPane tabId={TABS.ABOUT_TEAM}>{<OBAboutTeam />}</TabPane>
          <TabPane tabId={TABS.AVAI}>{<OBAvai />}</TabPane>
          <TabPane tabId={TABS.MATERIALS}>{<OBMaterials />}</TabPane>
        </TabContent>
        <div className="buttons-container-form">
          <Button className="fa-cancel-btn" onClick={toggleCancelSubmit}>
            Cancel
          </Button>

          {activeTab < TABS.MATERIALS ? (
            <Button
              className="rounded-0 btn btn-in-fa-form"
              onClick={(e) => {
                navigateToTab(activeTab + 1);
              }}
            >
              Next
            </Button>
          ) : null}
          {activeTab === TABS.MATERIALS ? (
            <Button
              className="rounded-0 btn btn-in-fa-form fa-submit-btn"
              onClick={submit}
              disabled={form.isLoading}
            >
              Submit
            </Button>
          ) : null}
        </div>
      </Container>
      {form.isSubmitSuccess ? <ProfileSubmit /> : null}
      {cancelSubmit ? <Redirect push to="/" /> : null}
    </div>
  );
};

export default FADashboard;

import classes from "./AdvisorCard.module.css";
import React from "react";

export const AdvisorCard = ({
  profilePic,
  name,
  firmName,
  id,
  toBeCompared,
  onAddToCompare = () => {},
  onRemoveFromCompare = () => {},
  trackSelectedAdvisor = () => {},
}) => {
  const placeholderPicture = "http://via.placeholder.com/323x187";
  const onCompare = toBeCompared ? onRemoveFromCompare : onAddToCompare;
  return (
    <div className={classes.card} data-testid="advisor-card">
      <div className={`${classes["uk-card"]} uk-card-default uk-card-body`}>
        <a href={`/profile/${id}`} onClick={(event) => {
            trackSelectedAdvisor(id)
        }}>
          <img
            className={classes.image}
            src={profilePic ? profilePic : placeholderPicture}
            alt=""
          />
          <div className={classes.body}>
            <h3 className={classes.name}>{name}</h3>
            <p className={classes.firm}>{firmName}</p>
          </div>
        </a>
        <div className={classes.footer}>
          <hr className={classes.separator} />
          <button className={classes.btn} onClick={(e) => onCompare(id)} data-testid={`add-to-compare-button-${id}`}>
            <span
              uk-icon={
                toBeCompared ? "icon: minus-circle" : "icon: plus-circle"
              }
              className="uk-icon"
            />
            {`${toBeCompared ? "ADDED" : "ADD TO COMPARE"}`}
          </button>
        </div>
      </div>
    </div>
  );
};

import React from "react";

export const ButtonFilters = ({
  title,
  text,
  items = [],
  selectedFilters,
  filtersId = "",
  onFilterSelected,
  additionalWrapperClasses = "",
  toolTipText,
  toolTipTextId,
}) => {
  return (
    <div className="geography filter-section">
      <div className="uk-grid">
        <div className="uk-width-1-1">
          <div uk-accordion={"true"}>
            <div className={additionalWrapperClasses}>
              <h3 className="section-title uk-accordion-title">
                <a href="/" onClick={(e) => e.preventDefault()}>
                  {title}
                </a>
              </h3>
              {toolTipText ? (
                <span
                  className="info-top"
                  uk-toggle={`target: #${toolTipTextId}; cls: show; animation:
                                          uk-animation-fade; mode: hover`}
                >
                  <img src="img/info.svg" alt="" />
                  <div
                    className="tooltip"
                    id={toolTipTextId}
                    aria-hidden="true"
                  >
                    <p>{toolTipText}</p>
                  </div>
                </span>
              ) : null}
              <div className="uk-accordion-content">
                <p className="section-text">{text}</p>
                <div className="label-list">
                  {items.map((i) => {
                    const isActiveClass =
                      selectedFilters[filtersId] &&
                      selectedFilters[filtersId].includes(i.id)
                        ? "--active"
                        : "";

                    return (
                      <p
                        style={{ cursor: "pointer", whiteSpace:'break-spaces' }}
                        key={i.value}
                        className={`uk-label ${isActiveClass}`}
                        onClick={() => {
                          onFilterSelected(filtersId, i.id);
                        }}
                      >
                        {i.label}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

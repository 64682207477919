import React from "react";
import PropTypes from "prop-types";
import classNames from "./AdvisorsComparer.module.css";
import { Advisor } from "../../../models/advisor";

export const AdvisorsComparer = ({
  advisors = [],
  types = {},
  onRemoveFromCompare = () => { },
}) => {
  if (!types || !Object.keys(types)) {
    return null;
  }
  if (!advisors || !advisors.length) {
    return null;
  }

  const {
    servingCountriesType,
    degreesType,
    differentClientType,
    compensationStructureType,
    advisorOfferingType,
    affiliationType,
  } = types;
  const nameRows = advisors.map((a) => {
    return {
      name: a.name,
      firmName: a.firm.name,
      profilePic: a.profilePic,
      id: a.id,
    };
  });
  const teamInfoRows = advisors.map((a) => {
    const {
      aum,
      valuesAlignedAum,
      aumUnit,
      teamInfoDegrees,
      differentClients,
      noOfPeople,
      employeesWomen,
      employeesColor,
      id,
      advisorOfferings,
      clientAccountSize,
      clientAccountSizeUnit,
      noOfClients,
      isNoOfClientsDisclosable,
      isTypicalAccountSizeDisclosable
    } = a.teamInfo;

    const servingCountries = a.teamInfo.servingCountries.map((sc) =>
      servingCountriesType.find((sct) => sct.id === sc.servingCountryId)
    );

    const degrees = teamInfoDegrees.map((tid) =>
      degreesType.find((dt) => dt.id === tid.degreeId)
    );
    const clientTypes = differentClients.map((dc) =>
      differentClientType.find((dct) => dct.id === dc.clientId)
    );

    const compensationStructures = a.teamInfo.compensationStructures.map((cs) =>
      compensationStructureType.find(
        (cst) => cst.id === cs.compensationStructureId
      )
    );

    const offerings = advisorOfferings.map((ao) =>
      advisorOfferingType.find((aot) => aot.id === ao.advisorOfferingId)
    );
    const affiliations = a.teamInfo.affiliations.map((a) =>
      affiliationType.find((at) => at.id === a.affiliationId)
    );

    const percentageOfValuesAligned =
      aum > 0 ? Math.round((valuesAlignedAum / aum) * 100) : 0;

    return {
      percentageOfValuesAligned,
      valuesAlignedAum: `$${valuesAlignedAum}${aumUnit}`,
      servingCountries,
      clientTypes,
      degrees,
      affiliations,
      compensationStructures,
      offerings,
      womenPercent: employeesWomen,
      peopleOfColorPercent: employeesColor,
      teamSize: noOfPeople,
      totalAum: `$${aum}${aumUnit}`,
      totalClients: noOfClients,
      isNoOfClientsDisclosable,
      isTypicalAccountSizeDisclosable,
      typicalAccountSize: `$${clientAccountSize}${clientAccountSizeUnit}`,
      id,
    };
  });
  const firmRows = advisors.map((a) => {
    const {
      aum,
      valuesAlignedAum,
      typicalAccountSize,
      typicalAccountSizeUnit,
      id,
      minimumAccountSize,
      unit,
      firmOfferedVaNImpactYears,
      isNoOfClientsDisclosable,
      isTypicalAccountSizeDisclosable,
    } = a.firm;

    const percentageOfValuesAligned =
      aum > 0 ? Math.round((valuesAlignedAum / aum) * 100) : 0;

    return {
      percentageOfValuesAligned,
      typicalAccountSize: `$${typicalAccountSize}${typicalAccountSizeUnit}`,
      isNoOfClientsDisclosable,
      isTypicalAccountSizeDisclosable,
      minimumAccountSize: minimumAccountSize ? `$${minimumAccountSize}${unit}` : 0,
      firmOfferedVaNImpactYears,
      totalClients: a.firm.noOfClients,
      id,
    };
  });

  const showContainerClass = advisors.length ? classNames.showContainer : "";
  return (
    <React.Fragment>
      {showContainerClass ? <div style={{ height: "25vh" }}></div> : null}
      <div
        className={`table-fix ${classNames.compareContainer} ${showContainerClass}`}
        id="compare-container"
        data-testid="compare-container"
      >
        <div className={`uk-container ${classNames.compareContainerPadding}`}>
          <a
            className="close"
            href="/"
            onClick={(e) => {
              e.preventDefault()
              nameRows.forEach( r =>{
                onRemoveFromCompare(r.id);
              })
            }}
          >
            <img src="img/x.svg" alt="" />
          </a>
          <div className="compare">
            <div className="thead">
              <div className="uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__box">
                  <button
                    className="compare__expand"
                    uk-toggle="target: #compare-container, #scroll; cls: show; animation:
                                            uk-animation-fade"
                    onClick={(e) => {
                      // document.body.scrollTop = 0; // For Safari
                      // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

                      // If the compare is up
                      toggleCloseButtonsVisibility();
                    }}
                  ></button>
                  <h3 className="compare__title">
                    Compare <br /> Advisors
                  </h3>
                </div>

                {nameRows.map((r, index) => {
                  return (
                    <div
                      data-testid="compare-advisor-name-row"
                      key={`${r.id}-${index}`}
                      className="compare-container"
                    >
                      <div className="compareadvisor">
                        <a
                          className="close"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            onRemoveFromCompare(r.id);
                          }}
                        >
                          <img src="img/menos.svg" alt="" />
                        </a>
                        <img
                          className="compareadvisor__img"
                          src={r.profilePic}
                          alt=""
                        />
                        <h4 className="compareadvisor__name">{r.name}</h4>
                        <p className="compareadvisor__firm">{r.firmName}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="tbody">
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">Team’s Total AUM</div>
                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__number">
                      {r.totalAum}
                    </div>
                  );
                })}
              </div>

              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  Team’s Values-
                  <br />
                  Aligned AUM
                </div>
                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__number">
                      {r.valuesAlignedAum}
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  % of AUM that is <br /> Values Aligned
                </div>
                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__number">
                      {r.percentageOfValuesAligned}%
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">Total Clients</div>

                {teamInfoRows.map((r, index) => {
                  return <div key={`${r.id}-${index}`} className="compare__number">
                    {r.totalClients}
                  </div>
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  Typical Account <br /> Size
                </div>

                {teamInfoRows.map((r, index) => {
                  return <div key={`${r.id}-${index}`} className="compare__number">
                    {r.typicalAccountSize}
                  </div>
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">Minimum Account Size</div>

                {firmRows.map((r, index) => {
                  return (
                    r.minimumAccountSize ? <div key={`${r.id}-${index}`} className="compare__number">
                      {r.minimumAccountSize}
                    </div> : <div
                      key={`${r.id}-${index}`}
                      className={classNames.notDisclosable}
                    >
                        None
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  Offers Values-Aligned Services
                </div>
                {firmRows.map((r, index) => {
                  const postText =
                    r.firmOfferedVaNImpactYears > 1 ? `years` : "year";
                  return (
                    <div
                      key={`${r.id}-${index}`}
                      className="compare__number"
                    >{`${r.firmOfferedVaNImpactYears} ${postText}`}</div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">Clients Reside in</div>
                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__list">
                      <ul>
                        {r.servingCountries.map((sc) => (
                          <li key={sc.id}>• {sc.label}</li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">Types of clients</div>
                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__list">
                      <ul>
                        {r.clientTypes.map((ct) => (
                          <li key={ct.id}>• {ct.label}</li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>

              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  Team’s Professional <br /> Degrees, <br /> Certifications,{" "}
                  <br />
                  Licenses or
                  <br />
                  Designations
                </div>

                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__list">
                      <ul>
                        {r.degrees.map((d) => (
                          <li key={d.id}>• {d.label}</li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  Team’s Professional
                  <br />
                  Affiliations
                </div>

                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__list">
                      <ul>
                        {r.affiliations.map((d) => (
                          <li key={d.id}>• {d.label}</li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">Team Size</div>

                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__number">
                      {r.teamSize}
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  Women on <br /> Team
                </div>

                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__graph">
                      <div>
                        <figure className="pie-chart" style={{background: `radial-gradient( circle closest-side, transparent 100%, white 0 ), conic-gradient( #ff6b00 0, #ff6b00 ${r.womenPercent >= 100 ? 100 : r.womenPercent}%, #e9e9e8 0, #e9e9e8 100% )`}}>
                          <span className="pencentaje">{r.womenPercent}%</span>
                        </figure>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  People of Color <br /> on Team
                </div>
                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__graph">
                      <div>
                        <figure className="pie-chart" style={{background: `radial-gradient( circle closest-side, transparent 100%, #f6f6f6 0 ), conic-gradient( #ff6b00 0, #ff6b00 ${r.peopleOfColorPercent >= 100 ? 100 : r.peopleOfColorPercent}%, #e9e9e8 0, #e9e9e8 100% )`}}>
                          <span className="pencentaje">{r.peopleOfColorPercent}%</span>
                        </figure>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  Compensation <br /> Structure
                </div>
                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__list">
                      <ul>
                        {r.compensationStructures.map((cs) => (
                          <li key={cs.id}>• {cs.label}</li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat">
                  In Addition to <br /> Portfolio
                  <br /> Management, the
                  <br /> Team Offers
                </div>
                {teamInfoRows.map((r, index) => {
                  return (
                    <div key={`${r.id}-${index}`} className="compare__list">
                      <ul>
                        {r.offerings.map((o) => (
                          <li key={o.id}>• {o.label}</li>
                        ))}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="tfoot">
              <div className="compare__row uk-flex uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-5@l">
                <div className="compare__cat" />
                {advisors.map((a) => (
                  <div key={a.id} id={a.id} className="compare__link">
                    <a href={`/profile/${a.id}`}>Go to Profile</a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
AdvisorsComparer.propTypes = {
  advisors: PropTypes.arrayOf(PropTypes.instanceOf(Advisor)),
  types: PropTypes.object.isRequired,
  onRemoveFromCompare: PropTypes.func.isRequired,
};

const toggleCloseButtonsVisibility = () => {
  const bodyTag = document.getElementsByTagName("body")[0];
  const htmlTag = document.getElementsByTagName("html")[0];
  const compareContainerElement = document.querySelector("#compare-container");
  const compareContainerCloseButtons = document.querySelectorAll("#compare-container .close");

  const isBodyOverflowEmpty = bodyTag.style.overflow === ""
  //if body overflow is empty, then default scroll bar is present
  if (isBodyOverflowEmpty) {
    //hide scroll bar
    [bodyTag, htmlTag].forEach(element => {
      setOverflow(element, "hidden")
    })
    setOverflow(compareContainerElement, "auto")

    setCloseButtonState(compareContainerCloseButtons, "hidden")
  } else {
    [bodyTag, htmlTag, compareContainerElement].forEach(element => {
      setOverflow(element, "")
    });
    setCloseButtonState(compareContainerCloseButtons, "visible")
  }

}

const setOverflow = (element, value) => {
  if (!element.style) {
    return;
  }
  element.style.overflow = value
}
const setCloseButtonState = (buttons = [], visibility = "visible") => {
  buttons.forEach(element => {
    element.style.visibility = visibility;
  });
}

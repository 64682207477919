import React, { useState, useContext, useEffect } from "react";
//import LogIn from "../../components/LogIn/_Login";

import { Redirect } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import LoginForm from "../../components/Forms/LoginForm";
import { listAffinityGroups } from "../../api/affinityGroups";


const Login = (props) => {
  const [affinityGroups, setAffinityGroups] = useState([]);
  useEffect(() => {
    listAffinityGroups().then((response) => {
      setAffinityGroups(response.data.sort((a,b) => {
        const createdAtA = new Date(a.createdAt)
        const createdAtB = new Date(b.createdAt)
        return createdAtA - createdAtB
      }));
    });
  }, []);
  return (
    <div className="uk-container sign-in-container">
      <div className="uk-width-1-1">
        <h3 className="login__title">Sign In</h3>
      </div>
      <div uk-grid={"true"} className="uk-width-1-1 uk-flex">
        <div className="uk-width-1-1 uk-width-1-2@l">
          <LoginForm {...props}></LoginForm>
        </div>
        <div className="uk-width-1-1 uk-width-1-2@l">
          <h2 className="line-behind title">
            <span>AFFINITY GROUP PARTNERS</span>
          </h2>

          <p className="subtitle">ENJOY YOUR COMPLIMENTARY ACCESS</p>
          <p className="main-text">
            ValuesAdvisor partners with philanthropic and investor groups in a
            campaign to move capital out of the extractive economy and into a
            regenerative economy. Therefore, their members have complimentary
            access.
          </p>

        
            <div
              uk-grid={"true"}
              className="affinity-group-logos-container uk-flex"
            >
              {affinityGroups.map((group) => {
                return (
                  <div key={group.id} className="uk-width-1-2 uk-width-1-3@s">
                    <a
                      href={group.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={group.blurb} alt="" />
                    </a>
                  </div>
                );
              })}
            </div>
         
          <div className="contact-us-announcement">
            <p>
              If you are part of a network that you think would benefit from
              partnering with ValuesAdvisor, please{" "}
              <a href="/contact">contact us.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Container = (props) => {
  const [loginEmail, setLoginEmail] = useState({
    value: "",
    error: "",
    showError: false,
  });
  const [loginPassword, setLoginPassword] = useState({
    value: "",
    error: "",
    showError: false,
  });
  const authContext = useContext(AuthContext);
  const { login, isLoggedIn } = authContext;
  const onLogging = async () => {
    const userCreds = await login(loginEmail.value, loginPassword.value);
    if (!userCreds) {
      return;
    }
    if (userCreds.isAdvisor) {
      window.location = `/my-profile/${userCreds.advisorId}`;
      return;
    }
    if (userCreds.isVisitor) {
      window.location = "/search";
      return;
    }
    if (userCreds.isAdmin) {
      window.location = "/admin";
      return;
    }
    window.location = "/search";
  };
  if (isLoggedIn) {
    return <Redirect to="/"></Redirect>;
  }
  return (
    <Login
      email={loginEmail}
      password={loginPassword}
      setEmail={(value) => {
        setLoginEmail({
          ...loginEmail,
          value: value,
        });
      }}
      setPassword={(value) => {
        setLoginPassword({
          ...loginPassword,
          showError: false,
          value: value,
        });
      }}
      submit={onLogging}
    ></Login>
  );
};

export default Container;

import { createContext } from "react";
export const JoinContext = createContext({
  paymentSuccesful: false,
  countries: [],
  userFirstName: "",
  setUserFirstName: (e) => {},
  userLastName: "",
  setUserLastName: (e) => {},
  userEmail: "",
  setUserEmail: (e) => {},
  userPassword: "",
  setUserPassword: (e) => {},
  confirmPassword: "",
  setconfirmPassword: (e) => {},
  emailError: "",
  passworderror: "",
  clientType: "",
  setClientType: (type) => {},
  areCredentialsValid: async () => {},
  storeClientCredentials: () => {},
  discountCodeId: "",
  setDiscountCodeId: (id) => {},
  discountCodeText: "",
  setDiscountCodeText: (text) => {},
  userCountry: {
    value: "",
    label: "",
  },
  setUserCountry: (country) => {},
  userState: {
    value: "",
    label: "",
  },
  setUserState: (state) => {},
  userCity: "",
  setUserCity: (city) => {},
});

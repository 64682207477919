import React from "react";
import "./BlogPost.css";
const Component = () => {
  return (
    <div className="blog-post-container">
      <article>
        <h1 className="article-title">
          How to Find a Values-Aligned Advisor With Confidence
        </h1>
        <h4 className="article-author">By ValuesAdvisor</h4>
        <div>
          <p>
            <img
              src="img/find-advisor-with-confidence.webp"
              alt="Screen Shot 2023-03-27 at 11.09.41 AM"
              width="692"
              height="407"
              loading="lazy"
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "692px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              sizes="(max-width: 692px) 100vw, 692px"
            />
          </p>
          <p>
            Money can be a difficult topic for many people, no matter what their
            financial needs are. Especially for those looking to align their
            money with their values, it is easy to feel uncertain about the
            first steps. Finding the right advisor is about more than just
            picking someone - it’s about finding a person who you can trust with
            the resources you have. At the end of the day, your relationship
            with your financial advisor determines how comfortable you feel
            allowing them to make decisions you can feel good about.&nbsp;
          </p>
          <p>
            Before moving forward, just remember, you aren’t alone in this
            process.{" "}
            <a href="/blog/a-valuesadvisor-success-story">
              <span>Ellen, a ValuesAdvisor user</span>
            </a>
            , found herself looking for a new money manager after her current
            one announced his retirement. Empowered by the ValuesAdvisor tool,
            she felt confident diving into the database knowing that she had all
            the information needed to find the right advisor.&nbsp;
          </p>
          <p>
            How do you get started finding a values-aligned advisor to fit your
            needs?
          </p>
          <p>
            <strong>
              Get Grounded
              <br />
            </strong>
            <span
              //style="font-size: 1rem"
              style={{ fontSize: "1rem" }}
            >
              Before jumping into the search for a values-aligned advisor, take
              some time to reflect on the things you already know. No matter
              your knowledge level about finances, you will likely already have
              some opinions on the things you care about.&nbsp;
            </span>
            <strong>
              <br />
            </strong>
            For example:
          </p>
          <ul>
            <li>What social issues do you care about?&nbsp;</li>
            <li aria-level="1">
              How much capital do you have to invest?&nbsp;
            </li>
            <li aria-level="1">What financial goals do you have?&nbsp;</li>
            <li aria-level="1">
              What are your hesitations about working with a new advisor? What
              makes you nervous?
            </li>
          </ul>
          <p>
            ValuesAdvisor’s “
            <a
              href="https://7887571.fs1.hubspotusercontent-na1.net/hubfs/7887571/VA%20Priority%20Matrix_Final%20Fillable.pdf"
              rel="noopener noreferrer"
              target="_blank"
            >
              Advisor Search Worksheet”
            </a>
            &nbsp;can be a helpful tool to guide this process. The Worksheet
            includes a list of priorities that might be important in your
            search, as well as a space to add notes on your preferences. Later
            in your search, you’ll be able to return to this document with notes
            about advisors you are considering.&nbsp;
          </p>
          <p>
            Having these notes before researching and meeting with advisors will
            help ground you during the search process, especially when you feel
            overwhelmed. Remember, this is your journey, and the things you
            value are important.&nbsp;
          </p>
          <p>
            <strong>
              Begin Searching
              <br />
            </strong>
            Once you have outlined your priorities, you’re ready to start
            researching potential advisors. If you have an existing advisor, you
            don’t have to say goodbye just yet! It’s perfectly normal to take
            your time researching and meeting with potential advisors before
            making a decision. You can take as much time as you need to feel
            confident about your choice of advisor.&nbsp;
          </p>
          <p>
            How do you know what qualifications and specialties advisors have to
            fit your needs? If you’re using the{" "}
            <a
              href="https://www.valuesadvisor.org/join"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>ValuesAdvisor Platform</span>
            </a>
            &nbsp;(login information below), profiles include the firm and
            advisor’s qualifications, including their professional degrees,
            certifications, licenses, and affiliations. Regardless of specialty,
            ensure that the financial advisors operate under a{" "}
            <a href="https://www.investopedia.com/ask/answers/042915/what-are-some-examples-fiduciary-duty.asp">
              <span>fiduciary duty</span>
            </a>{" "}
            to their clients, which means they have a legal obligation to
            prioritize their client's interest over their firms.&nbsp;
          </p>
          <p>
            Additionally, to be added to the ValuesAdvisor platform, advisors
            must be recommended by peer investors, a member of one of our{" "}
            <a href="https://www.valuesadvisor.org/about">
              <span>partner investor networks</span>
            </a>
            . Once recommended, advisors are still required to have an
            established record of values-aligned investing and existing trusted
            relationships. As you start reviewing these pre-screened advisors,
            begin to filter by what’s important to you, such as firm leadership
            diversity, impact themes, or even affinity group affiliation.
          </p>
          <p>
            <strong>
              Start Reaching Out
              <br />
            </strong>
            <span
              style={{
                fontSize: "1rem",
              }}
            >
              Even though ValuesAdvisor makes it easy to reach out to advisors
              directly through the platform, taking that next step can be
              nerve-wracking. To narrow down the list of firms to contact, it
              can be helpful to return to your{" "}
              <a
                href="https://7887571.fs1.hubspotusercontent-na1.net/hubfs/7887571/VA%20Priority%20Matrix_Final%20Fillable.pdf"
                rel="noopener"
                target="_blank noreferrer"
              >
                Priority Worksheet{" "}
              </a>
              to see how the advisors stack up. Remember that reaching out does
              not signal any commitment on your part. They want to hear from
              you, so feel free to meet with anyone who piques your interest!
            </span>
          </p>
          <p>
            You can always talk through your thought process with someone you
            trust with financial topics, or you can
            <a href="https://portal.valuesadvisor.org/15minutes" rel="noopener noreferrer" target="_blank">
              <span> meet with a ValuesAdvisor team member</span>
            </a>{" "}
            who would be happy to help.&nbsp;
          </p>
          <p>
            <strong>
              Prepare for an Advisor Meeting&nbsp;
              <br />
            </strong>
            Once you’ve set up your first meeting, there are a few things you
            can do to feel more confident before and during the meeting.
          </p>
          <strong>
            <span role="img" aria-label="arrow">
              ➡️
            </span>{" "}
            &nbsp;Get grounded (again)
          </strong>
          <strong>
            <br />
          </strong>
          This is a great time to review both your initial grounding document
          and your Priority Worksheet. Are there areas you still have questions
          about? Topics you’d like to dig deeper on? Make a note of your initial
          thoughts on what you’d like to discuss. What are the most important
          things you need to know to feel confident making a decision?
          <br />
          <br />
          <strong>
            <span role="img" aria-label="arrow">
              ➡️
            </span>{" "}
            Look at the{" "}
          </strong>
          <a href="https://f.hubspotusercontent20.net/hubfs/7887571/ValuesAdvisor.Interview.Guide.pdf">
            <strong>
              <span>ValuesAdvisor Interview Guide</span>
            </strong>
            <br />
          </a>
          ValuesAdvisor offers a guide with potential questions to ask an
          advisor when meeting. There are lots of questions in there, but you
          don’t have to use them all! Compare the questions to your Advisor
          Search Worksheet. What are the most important things to cover that
          will help you feel comfortable with this advisor?
          <br />
          <br />
          <strong>
            <span role="img" aria-label="arrow">
              ➡️
            </span>{" "}
            Practice!
          </strong>
          <strong>
            <br />
          </strong>
          Practicing your questions with a friend or family member can be a
          great way to feel confident before meeting with an advisor. Or,
          <a href="https://portal.valuesadvisor.org/15minutes" rel="noopener noreferrer" target="_blank">
            <span> sign up</span>
          </a>{" "}
          for a complementary 15 minute meeting with a ValuesAdvisor team member
          to practice your questions.
          <br />
          <br />
          <strong>
            <span role="img" aria-label="arrow">
              ➡️
            </span>{" "}
            Ask about fees
          </strong>
          <strong>
            <br />
          </strong>
          Don’t be afraid to ask about fees, how the advisor charges for their
          services, and confirm that they are a fiduciary. Not only is this good
          information to have but their level of transparency about cost can be
          an important indicator of future transparency.&nbsp;
          <br />
          <br />
          <p>
            Decide if you need additional meetings to make your decision. There
            is no right timeline for how long it takes to find the right
            values-aligned advisor, so take as much time as you need to
            choose.&nbsp;
          </p>
          <p>
            <strong>
              Choosing an Advisor
              <br />
            </strong>
            Deciding on an advisor may feel like a big leap of faith, but
            remember all the work you’ve done up to this point has prepared you
            to make an informed decision. Trust the research you’ve done, and
            your gut, to take the plunge. The process can be a lot like choosing
            a doctor. Half of your choice is determined by their professional
            competence, and the other half is determined by how you feel about
            the relationship. No matter how good a doctor is, you aren’t going
            to want to visit when you’re feeling your worst. An advisor is
            someone that you need to trust and feel comfortable turning to
            during times of stress, transition, or the unexpected life
            events.&nbsp;
          </p>
          <p>
            You have a lot of power and flexibility as an investor, and you
            shouldn’t be afraid to go in with a trial mindset. There are many
            reasons you might decide to make a change even after choosing a new
            advisor, whether that means exploring other advisors within the same
            firm or deciding to look for something different altogether.
            Whatever decision you make, it doesn’t have to be forever. You only
            need to stay with an advisor as long as they’re working for
            you.&nbsp;&nbsp;&nbsp;
          </p>
          <p>
            No matter where you are in your advisor discovery journey, the
            ValuesAdvisor team can be a helpful resource. Feel free to join
            their complementary
            <a href="https://portal.valuesadvisor.org/office-hours">
              <span> Office Hours</span>
            </a>{" "}
            or{" "}
            <a href="https://www.valuesadvisor.org/contact">
              <span>reach out to a team member with any questions.</span>
            </a>
            &nbsp;
          </p>
          <hr />
          <p
            style={{
              fontSize: "16px",
              textAlign: "left",
            }}
          >
            ValuesAdvisor is a nonprofit platform that features financial
            advisors who specialize in values aligned investing. It partners
            with membership based groups&nbsp;to offer free access to the
            platform. <br />
            <br />
            To access the platform: If you're a&nbsp;
            <a
              href="https://www.valuesadvisor.org/about"
              rel="noopener noreferrer"
              target="_blank"
            >
              member of one of our partners{" "}
            </a>
            and don't know your free login code,{" "}
            <a href="mailto:kate@valuesadvisor.com" rel="noopener">
              email
            </a>
            &nbsp;us to receive it or anyone can&nbsp;
            <a
              href="https://www.valuesadvisor.org/join"
              rel="noopener noreferrer"
              target="_blank"
            >
              sign up here{" "}
            </a>
            for a subscription.&nbsp;
          </p>{" "}
          <div style={{
            textAlign: "center",
          }}>
              <a
                href={`/login`}
                className="homelink "
              >
                {`Login to ValueAdvisor`}
                <svg
                  className="homelink__chevron"
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="19.521"
                  viewBox="0 0 11 19.521"
                >
                  <g id="Expand" transform="translate(0 19.521) rotate(-90)">
                    <path
                      id="Path_434"
                      data-name="Path 434"
                      d="M178.807,15.952l1.276-1.253,8.434,6.694,8.534-6.694,1.276,1.329-9.76,9.671Z"
                      transform="translate(-178.807 -14.7)"
                      fill="#ff6b00"
                    />
                  </g>
                </svg>
              </a>
            </div>
        </div>
      </article>
    </div>
  );
};

export default Component 
import React from "react";
import classes from "./Button.module.css";
import RightChevron from "./RigthChevron";
const ButtonLink =  ({
  children,
  onClick,
  href,
  style = {},
  className,
  type = "primary",
  includeChevron = true,
}) => {
  const buttonTypeClass =
    type === "primary"
      ? classes["primary-button"]
      : classes["secondary-button"];
  return (
    <a
      className={`${classes.button} ${buttonTypeClass} ${className || ""}`}
      style={style}
      onClick={onClick}
      href={href}
    >
      {children}
      {includeChevron ? <RightChevron /> : null}
    </a>
  );
};
export default ButtonLink
